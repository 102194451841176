import md5 from 'js-md5'
import {getUser} from '../components/Users/AuthRouter'
import {AppKey,AppSecret} from './UrlConfig'
import {Buffer} from 'buffer';


const createUuid = ()=> {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const getAuthorization = () =>{
    return new Buffer(AppKey + ':' + AppSecret).toString('base64');
}
const getToken = ()=> {
    return getUser().token
}

function getSign(params, AppKey, AppSecret,Nonce,Token) {
    if (typeof(params) == "string") {
        return paramsStrSort(params,AppKey, AppSecret);
    } else if (typeof(params) == "object") {
        let arr = [];
        arr.push(('Nonce=' + Nonce));
        arr.push(('Token=' + Token));
        for (let i in params) {
            if(params.hasOwnProperty(i)) {
                if (typeof (params[i]) !== 'undefined') {
                    if (i!=='file'){
                        arr.push((i + "=" + params[i]));
                    }
                }
            }
        }
        if (arr.length === 0){
            return paramsStrSort([],AppKey, AppSecret);
        }
        return paramsStrSort(arr.join(("&")),AppKey, AppSecret);
    }
}

function paramsStrSort(paramsStr,AppKey, AppSecret) {
    let url = ''
    if (paramsStr.length === 0){
        url = "AppKey=" + AppKey;
    }
    else {
        url = paramsStr + "&AppKey=" + AppKey;
    }
    let url_Str = url.split("&").sort().join("&");
    let md5Str = url_Str + '&AppSecret=' + AppSecret;
    return md5(md5Str)

}

const getheaders =(params)=>{
    const Nonce = createUuid()
    const Token = getToken()
    const Signature = getSign(params, AppKey, AppSecret,Nonce,Token)
    return(
        {
            'Authorization': 'Basic '+ getAuthorization(),
            'Nonce':Nonce,
            'Token':Token,
            'Signature': Signature
        }
    )
}
export { getheaders }

