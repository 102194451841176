import React, {Component} from 'react';
import '@ant-design/pro-components/dist/components.css';
import {ProCard, Statistic} from '@ant-design/pro-components';
import {
    Space,
    Spin, Badge, Col, Row, Result, Radio, FloatButton, Card,
} from 'antd'


import {getUser, Loginout} from "../Users/AuthRouter";
import LineChart from "./LineChart";
import {base} from "../../api/UrlConfig";
import {DatabaseOutlined, RedoOutlined} from "@ant-design/icons";


export class PerformanceTestDatas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            SpinLoading: this.props.SpinLoading,
            SoloPiUserDatas: this.props.SoloPiUserDatas,
            SoloPiUserDatasTime: this.props.SoloPiUserDatasTime,
            ChartSize: 8,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            SpinLoading: nextProps.SpinLoading,
            SoloPiUserDatas: nextProps.SoloPiUserDatas,
            SoloPiUserDatasTime: nextProps.SoloPiUserDatasTime,
        })
    }


    render() {
        return (
            <Spin spinning={this.state.SpinLoading}>
                {Object.keys(this.state.SoloPiUserDatas).length ? <Card bordered={true} extra={
                    <Space size={20}>
                        <div style={{marginTop: 5, marginBottom: 20}}>
                            <b>图表布局</b>
                        </div>
                        <Radio.Group
                            defaultValue={8} buttonStyle="solid"
                            style={{marginTop: 5, marginBottom: 20}}
                            onChange={(e) => {
                                this.setState({
                                    ChartSize: e.target.value,
                                })
                            }}
                        >
                            <Radio.Button value={24}>1 x 1</Radio.Button>
                            <Radio.Button value={12}>1 x 2</Radio.Button>
                            <Radio.Button value={8}>1 x 3</Radio.Button>
                            <Radio.Button value={6}>1 x 4</Radio.Button>
                        </Radio.Group>
                    </Space>
                }/> : null
                }
                {Object.keys(this.state.SoloPiUserDatas).length ?
                    Object.entries(this.state.SoloPiUserDatas).sort().map(([key, value]) => {
                        return (
                            <Badge.Ribbon text={
                                key === "Battery" ? "Battery(电池)" :
                                    key === "FPS" ? "FPS(帧率)" :
                                        key === "Memory" ? "Memory(内存)" :
                                            key === "Network" ? "Network(网络)" :
                                                key === "Response" ? "Response(耗时)" :
                                                    key === "Temperature" ? "Temperature(CPU温度)" : key
                            } color={
                                key === "Battery" ? "#9b1313" :
                                    key === "FPS" ? "#d57e0d" :
                                        key === "Memory" ? "#1c329f" :
                                            key === "Network" ? "#055903" :
                                                key === "Response" ? "rgba(23,101,112,0.63)" :
                                                    key === "Temperature" ? "#13d0d7" :
                                                        "#342367"
                            }>
                                {
                                    Object.keys(value).length > 0 ?
                                        <ProCard title={
                                            key === "Battery" ? "Battery(电池)" :
                                                key === "FPS" ? "FPS(帧率)" :
                                                    key === "Memory" ? "Memory(内存)" :
                                                        key === "Network" ? "Network(网络)" :
                                                            key === "Response" ? "Response(耗时)" :
                                                                key === "Temperature" ? "Temperature(CPU温度)" : key
                                        } direction="column"
                                                 bordered
                                                 collapsible
                                                 gutter={[10, 10]}
                                                 key={key} wrap={false}>
                                            <Row gutter={[10, 10]}>
                                                {Object.entries(value).sort(function (a, b) {
                                                    let keyA = a[0];
                                                    let keyB = b[0];
                                                    // 提取键中的数字部分
                                                    let numA = parseFloat(keyA.match(/\d+/));
                                                    let numB = parseFloat(keyB.match(/\d+/));

                                                    // 如果两个键都包含数字，按照数字升序排序
                                                    if (!isNaN(numA) && !isNaN(numB)) {
                                                        return numA - numB;
                                                    }

                                                    // 若只有其中一个键包含数字，则将其排在前面
                                                    if (!isNaN(numA)) {
                                                        return -1;
                                                    }
                                                    if (!isNaN(numB)) {
                                                        return 1;
                                                    }

                                                    // 默认情况下保持原始顺序
                                                    return 0;
                                                }).map(([innerKey, innerValue]) => {
                                                    let pj = 0
                                                    let max_value = 0
                                                    let min_value = 0
                                                    let datas = []
                                                    innerValue.map((vv) => {
                                                        if (!isNaN(vv.b)) {
                                                            pj = pj + parseFloat(vv.b)
                                                            datas.push(parseFloat(vv.b))
                                                        }
                                                    })
                                                    min_value = Math.min(...datas)
                                                    max_value = Math.max(...datas)
                                                    return (
                                                        <Col
                                                            span={24 / Object.keys(value).length < this.state.ChartSize ? this.state.ChartSize : 24 / Object.keys(value).length}>
                                                            <ProCard
                                                                title={
                                                                    <Space direction={"vertical"} size={1}>
                                                                        {innerKey === "实时功率" ? innerKey + "(mW)" :
                                                                            innerKey === "实时电流" ? innerKey + "(mA)" :
                                                                                innerKey === "平均功率" ? innerKey + "(mW)" :
                                                                                    innerKey === "平均电流" ? innerKey + "(mA)" :
                                                                                        innerKey === "全局占用" && key === "CPU" ? innerKey
                                                                                            + "(%)" :
                                                                                            key === "Memory" ? innerKey + "(MB)" :
                                                                                                innerKey.includes("应用进程-main-") ? innerKey +
                                                                                                    "(%)" :
                                                                                                    innerKey === "帧率" ? innerKey + "(帧)" :
                                                                                                        innerKey === "延迟占比" ? innerKey + "(%)" :
                                                                                                            innerKey === "延迟次数" ? innerKey + "(次)" :
                                                                                                                innerKey === "最长延迟时间" ? innerKey + "(ms)" :
                                                                                                                    innerKey.includes("耗时") ? innerKey + "(ms)" :
                                                                                                                        innerKey === "CPU温度" ? innerKey + "(度)" :
                                                                                                                            innerKey.includes("流量") ||
                                                                                                                            innerKey.includes("速率") ? innerKey + "(Kb)" : innerKey}
                                                                    </Space>
                                                                }
                                                                bordered
                                                                style={{marginBlockStart: 8}}
                                                                key={innerKey}
                                                            >
                                                                <Space size={20} style={{
                                                                    fontSize: 12,
                                                                    marginBottom: 20,
                                                                    color: "#d7073e"
                                                                }}>
                                                                    <div>{"最大值: " + max_value.toFixed(2)}</div>
                                                                    <div>{"最小值: " + min_value.toFixed(2)}</div>
                                                                    <div>{"平均值: " + (pj / Object.keys(innerValue).length).toFixed(2)}</div>
                                                                </Space>
                                                                <LineChart line_datas={innerValue}/>
                                                            </ProCard>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </ProCard> : null
                                }
                            </Badge.Ribbon>
                        )
                    }) : <Result
                        title="暂无数据,请使用SoloPi测试完成后前往【SoloPi性能数据采集】页面归档测试数据!"
                        subTitle={"SoloPi性能数据上传地址为:  " + base + "/solopi/" + getUser().UserId}
                    />
                }
                <FloatButton.BackTop/>
            </Spin>
        )
    }
}