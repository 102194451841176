import React, {Component} from 'react';
import {Button, Row, Col, Form, Card, Input, Select, message, Space, InputNumber, Avatar} from "antd";
import Modal from "antd/es/modal/Modal";
import {getUser, Loginout} from "../../Users/AuthRouter";
import api from "../../../api/ApiConfig";
import {PlusCircleOutlined} from "@ant-design/icons";
import {object} from "bfj/src/events";
import Text from "antd/es/typography/Text";

const {TextArea} = Input;

export class TestCaseAllocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas,
            isModalOpen: false,
            btn_status: props.btn_status,
            tp: [],
            tt: [],
            userInfo: null,
            ProjectNote: null
        }
        this.form = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas) || JSON.stringify(this.props.btn_status) !== JSON.stringify(prevProps.btn_status)) {
            this.setState({
                datas: this.props.datas,
                btn_status: this.props.btn_status
            })
        }
        console.log("已选择用例", this.state)
    }

    componentDidMount() {
        this.testprojectsandtasks()
    }

    userinfo = getUser()

    testprojectsandtasks = async (params = {}) => {
        params['UserId'] = this.userinfo.UserId
        await api.TestProjectsAndTasks(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (r.datas.length > 0) {
                    this.setState({
                        tp: r.datas,
                        tt: r.datas[0].TaskList,
                        userInfo: r.datas[0].UserName + "(" + r.datas[0].UserId + ")",
                        ProjectNote: r.datas[0].ProjectNote
                    })
                }
            }
        })
    }
    posttesttaskcases = async (values) => {
        values['UserId'] = this.userinfo.UserId
        await api.PostTestTaskCases({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("测试用例,分配成功~")
                this.setState({
                    isModalOpen: false
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const showModal = () => {
            if (this.state.datas.length > 0) {
                this.setState({
                    isModalOpen: true
                })
            } else {
                message.error("请在表格中勾选,要分配的用例~")
            }
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = (values) => {
            values['caseList'] = JSON.stringify(this.state.datas)
            this.posttesttaskcases(values)
            console.log("分配测试用例", values)
        }
        return <>
            <Button size={"middle"} disabled={this.state.btn_status !== "unique"} onClick={showModal}
                    type={"primary"} style={{width: 100}} danger>
                用例分配
            </Button>
            <Modal title="测试用例分配"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{marginTop: 100, minWidth: 600}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 450,
                                marginTop: 30,
                                marginLeft: 50
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="ProjectId"
                                        label="项目列表"
                                        initialValue={this.state.tp.length > 0 ? this.state.tp[0].key : null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择项目",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{width: 300, textAlign: "left"}}
                                            placeholder="请选择项目"
                                            optionFilterProp="children"
                                            showSearch={true}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            onChange={(value) => {
                                                for (let i = 0; i < this.state.tp.length; i++) {
                                                    if (this.state.tp[i].key === value) {
                                                        this.form.current.setFieldsValue({TaskId: null})
                                                        this.setState({
                                                            tt: this.state.tp[i].TaskList.length > 0 ? this.state.tp[i].TaskList : [],
                                                            userInfo: this.state.tp[i].UserName + "(" + this.state.tp[i].UserId + ")",
                                                            ProjectNote: this.state.tp[i].ProjectNote
                                                        }, function () {
                                                            if (this.state.tt.length === 0) {
                                                                message.warning("当前项目没有测试任务,请创建后在分配用例!")
                                                            }
                                                        })
                                                        break
                                                    }
                                                }
                                            }}
                                            options={this.state.tp.map((value, index, array) => ({
                                                value: value.key,
                                                label: value.ProjectName,
                                            }))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="项目归属"
                                        style={{marginLeft: 10}}
                                    >
                                        <Space size={3} style={{fontWeight: 800, fontSize: 12}}>
                                            <Avatar size={20} style={{background: "black", color: "white"}}>U</Avatar>
                                            {this.state.userInfo}
                                        </Space>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="项目描述"
                                        style={{marginLeft: 10}}
                                    >
                                        <TextArea disabled={true} rows={2} style={{width: 300, fontSize: 13}}
                                                  value={this.state.ProjectNote}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="TaskId"
                                        label="任务列表"
                                        initialValue={this.state.tt.length > 0 ? this.state.tt[0].key : null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择任务",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{width: 300, textAlign: "left"}}
                                            placeholder="请选择任务"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            // onChange={(value) => {
                                            //     this.form.current.setFieldsValue({ProjectImageUrl: this.ProjectImageUrl[value]})
                                            // }}
                                            options={this.state.tt.map((value, index, array) => ({
                                                value: value.key,
                                                label: value.TaskName,
                                            }))}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Col span={24}>
                                <Form.Item>
                                    <div style={{fontWeight: 800, fontSize: 12, color: "teal", marginLeft: 5}}>
                                        分配已选的「{this.state.datas.length}」条用例（相同的用例分配到同一个任务会被去重～）
                                    </div>
                                </Form.Item>
                            </Col>
                            <Form.Item style={{marginTop: 30, marginLeft: "30%"}}>
                                <Button type="primary" htmlType="submit">
                                    确认分配
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}