import React from "react";
import {Radar} from '@ant-design/plots';
import {Result, Spin} from "antd";

export default class RadarXp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas,
            textColor: props.textColor

        }
    }

    componentDidMount() {

    }


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
                textColor: this.props.textColor
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }


    render() {
        const config = {
            data: this.state.datas,
            xField: 'pn',
            yField: 'value',
            seriesField: 'type',
            radius: 0.7,
            height: 600,
            legend: {
                layout: 'horizontal',
                position: 'top',
                itemName: {
                    style: {
                        fill: this.state.textColor,
                        fontWeight: 500,
                    }
                },
            },
            meta: {
                // value: {
                //     alias: '分数',
                //     min: 0,
                //     max: 2000,
                // },
            },
            xAxis: {
                line: null,
                tickLine: null,
                grid: {
                    line: {
                        style: {
                            lineDash: null,
                        },
                    },
                },
                label: {
                    style: {
                        fontSize: 13,
                        fill: this.state.textColor,
                    },
                },
            },
            yAxis: {
                line: null,
                tickLine: null,
                grid: {
                    line: {
                        type: 'line',
                        style: {
                            lineDash: null,
                        },
                    },
                    alternateColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
            // label: {
            //     // offset: -10,
            //     style: {
            //         fontSize: 11.5,
            //         textAlign: 'center',
            //         fill: '#ffffff',
            //     },
            // },
            // 开启面积
            area: {},
            // 开启辅助点
            point: {
                size: 2,
            },
            // color: ['#9dbee8', '#eeeeeb', 'rgb(130,93,229)', '#de1147'],
        };
        return <div>{this.state.datas.length > 0 ?
            <Radar {...config} /> :
            <Result
                status="success"
                title={<div style={{color: this.state.textColor}}>
                    暂无数据
                </div>}
            />
        }
        </div>;
    }
};