import React, {Component} from 'react';
import {Button, Row, Col, Form, Card, Input, Select, message, Space} from "antd";
import Modal from "antd/es/modal/Modal";
import {getUser, Loginout} from "../Users/AuthRouter";
import {AppstoreAddOutlined, SisternodeOutlined} from "@ant-design/icons";
import api from "../../api/ApiConfig";


export class CreateVoiceLibrary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fun: props.fun,
            loading: false
        }
        this.form = React.createRef();
    }

    userinfo = getUser()

    post_voice_library = async (values) => {
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        await api.PostVoiceLibrary({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({}, function () {
                    message.success("语音库创建成功")
                    this.state.fun()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.setState({
                isModalOpen: false,
                loading: false
            })
        })
    }

    render() {
        const showModal = () => {
            this.setState({
                isModalOpen: true
            })
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = (values) => {
            this.setState({
                loading: true
            })
            this.post_voice_library(values)
        }
        return <>
            <Button type={"link"}
                    style={{width: 100, color: '#707272', fontSize: 13, fontWeight: 600}}
                    size={"small"}
                    onClick={() => {
                        showModal()
                    }}
                    icon={<AppstoreAddOutlined/>}>
                创建语音库
            </Button>
            <Modal title="创建语音库"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{marginTop: 100, minWidth: 700}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 450,
                                marginTop: 60,
                                marginLeft: 50
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="VoiceKeyWords"
                                        label="语音关键词"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入语音关键词",
                                            }
                                        ]}
                                    >
                                        <Input showCount maxLength={30} placeholder={"请输入语音关键词(如:小牛、小牛)"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="VoiceLibraryNotes"
                                        label="语音库说明"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入语音库说明",
                                            }
                                        ]}
                                    >
                                        <Input.TextArea showCount rows={3} maxLength={300}
                                                        placeholder={"请输入语音库说明(如：语音规则、语言类型等..)"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <div style={{
                                        fontSize: 12,
                                        fontWeight: 600,
                                        color: "darkred",
                                        marginTop: 20,
                                    }}>
                                        Ps: 语音库创建成功后，会创建一个飞书群聊并实时采集群聊中的语音数据
                                    </div>
                                </Col>
                            </Row>

                            <Form.Item style={{marginTop: 30, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit" loading={this.state.loading}>
                                    确认创建
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}