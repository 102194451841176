import React, {Component} from 'react';
import {
    Button,
    Row,
    Col,
    Form,
    Input,
    Select,
    Radio,
    InputNumber, Divider, Tag, message
} from "antd";
import Modal from "antd/es/modal/Modal";
import {
    AppstoreAddOutlined,
} from "@ant-design/icons";
import {getUser, Loginout} from "../../Users/AuthRouter";
import api from "../../../api/ApiConfig";
import AliYunOssUploader from "./AliyunOSSUpload";
import {v1} from 'uuid';

export class FDevCreateTask extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fun: props.fun,
            isModalOpen: false,
            OssUrl: null,
            isGateway: false,
            product_list: []
        }
        this.form = React.createRef();
    }

    // componentDidUpdate(prevProps) {
    //     if (JSON.stringify(this.props.product_list) !== JSON.stringify(prevProps.product_list)) {
    //         this.setState({
    //             product_list: this.props.product_list
    //         })
    //     }
    // }

    componentDidMount() {
        this.getproducts_ypt({env: "Test"})
    }

    get_oss_upload_url = async (file_name, content_type) => {
        // 获取AliYunoss临时上传链接
        let params = {}
        params['file_name'] = file_name
        params['content_type'] = content_type
        await api.GetOssUpLoadUrl(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    OssUrl: r.datas,
                })
            }
        })
    }

    userinfo = getUser()

    makeUuid = () => {
        this.setState({
            TaskId: v1()
        }, function () {
            this.form.current.setFieldsValue({TaskId: this.state.TaskId})
        })

    }
    getproducts_ypt = async (params = {}) => {
        await api.GetProducts_YPT(params).then(r => {
            if (typeof (r.msgcode) == 'number') {
                if (r.msgcode === 20008) {
                    Loginout()
                    this.props.history.push('/login')
                    window.location.reload()
                } else {
                    this.setState({
                        product_list: r.datas.result
                    })
                }
            }
        })
    }
    firmware_project_tasks = async (values) => {
        await api.FirmwareProjectTasks({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    message.success("任务创建成功～")
                    this.state.fun()
                    this.form.current.resetFields()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const showModal = () => {
            this.setState({
                isModalOpen: true
            })

        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })

        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = (values) => {
            this.setState({
                submitBtnLoading: true
            })
            values['UserId'] = this.userinfo.UserId
            values['UserName'] = this.userinfo.username
            values['Precheck'] = JSON.stringify(values['Precheck'])
            values['ReleaseType'] = JSON.stringify(values['ReleaseType'])
            values['Environment'] = 1
            console.log("提交任务", values)
            this.firmware_project_tasks(values).then(() => {
                this.setState({submitBtnLoading: false})
            })
            // this.post_app_project_tasks(values)
        }
        const tagRender = (props) => {
            const {label, value, closable, onClose} = props;
            const onPreventMouseDown = (event) => {
                event.preventDefault();
                event.stopPropagation();
            };
            return (
                <Tag
                    color={
                        value === 1 ? "gold-inverse" :
                            value === 2 ? "green-inverse" :
                                value === 3 ? "blue-inverse" :
                                    value === 4 ? "cyan-inverse" :
                                        value === 5 ? "purple-inverse" : "default"
                    }
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                    style={{
                        marginInlineEnd: 4,
                        fontSize: 11,
                        lineHeight: 2,
                        fontWeight: 600
                    }}
                >
                    {label}
                </Tag>
            );
        }
        return <>
            <Button type={'link'} style={{width: 90, fontWeight: 700, fontSize: 12}} size={"small"} disabled={false}
                    onClick={showModal} icon={<AppstoreAddOutlined/>}>
                发起提测
            </Button>
            <Modal title="创建固件提测任务"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{minWidth: 700}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        maxHeight: "65vh",
                        overflowY: "auto",
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 500,
                                marginTop: 30,
                                marginLeft: 30,
                                fontWeight: 600
                            }}
                        >
                            <Row gutter={0}>
                                <Col span={24}>
                                    <Form.Item
                                        name="ProductName"
                                        label="产品名称"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择提测产品",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{textAlign: "left"}}
                                            showSearch
                                            placeholder="请选择提测产品"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={this.state.product_list.map((value) => {
                                                return {
                                                    value: value.productName + "_" + value.productId + "_" + value.productKey,
                                                    label: "[" + value.productName + "] · [" + value.productId + "] · [" + value.productKey + "]",
                                                    disabled: false,
                                                }
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="FirmwareVersion"
                                        label="固件版本"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入固件版本",
                                            }
                                        ]}
                                    >
                                        <Input maxLength={100} onChange={(e) => {
                                            this.form.current.setFieldsValue({
                                                "OtaVersion": e.target.value
                                            })
                                        }} placeholder={"请输入固件版本"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="TestNumber"
                                        label="提测轮数"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择提测轮数",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{textAlign: "left"}}
                                            showSearch
                                            placeholder="请选择提测轮数"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[
                                                {
                                                    value: 1,
                                                    label: '第一轮',
                                                    disabled: false,
                                                },
                                                {
                                                    value: 2,
                                                    label: '第二轮',
                                                    disabled: false,
                                                },
                                                {
                                                    value: 3,
                                                    label: '第三轮',
                                                    disabled: false,
                                                },
                                                {
                                                    value: 4,
                                                    label: '第四轮',
                                                    disabled: false,
                                                },
                                                {
                                                    value: 5,
                                                    label: '第五轮',
                                                    disabled: false,
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="ReleaseType"
                                        label="发布类型"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择发布类型",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{textAlign: "left"}}
                                            showSearch
                                            placeholder="请选择发布类型"
                                            mode="multiple"
                                            tagRender={tagRender}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[
                                                {
                                                    value: 1,
                                                    label: 'EVT生产',
                                                    disabled: false,
                                                },
                                                {
                                                    value: 2,
                                                    label: 'DVT生产',
                                                    disabled: false,
                                                },
                                                {
                                                    value: 3,
                                                    label: '线上OTA',
                                                    disabled: false,
                                                }, {
                                                    value: 4,
                                                    label: '批量生产',
                                                    disabled: false,
                                                },
                                                {
                                                    value: 5,
                                                    label: '测试版本',
                                                    disabled: false,
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="Precheck"
                                        label="前置检查"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择前置检查",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{textAlign: "left"}}
                                            showSearch
                                            mode="multiple"
                                            tagRender={tagRender}
                                            placeholder="请选择前置检查"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[
                                                {
                                                    value: 1,
                                                    label: '固件已上传confluence',
                                                    disabled: false,
                                                },
                                                {
                                                    value: 2,
                                                    label: '代码差异已检查',
                                                    disabled: false,
                                                },
                                                {
                                                    value: 3,
                                                    label: 'Bug流转已检查',
                                                    disabled: false,
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="TestApp"
                                        label="陪测APP"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请填写陪测App",
                                            }
                                        ]}
                                    >
                                        <Input maxLength={500} placeholder={"请填写陪测App"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="DesignDocument"
                                        label="设计文档"
                                        initialValue={"无"}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入设计文档链接",
                                            },
                                            {
                                                // pattern: /^https:\/\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;%=]+$/,
                                                message: "请输入有效的https链接",
                                            }
                                        ]}
                                    >
                                        <Input maxLength={2000} placeholder={"请输入设计文档链接(飞书文档)"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="TestReport"
                                        label="自测报告"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入测试报告链接",
                                            },
                                            {
                                                pattern: /^https:\/\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;%=]+$/,
                                                message: "请输入有效的https链接",
                                            }
                                        ]}
                                    >
                                        <Input maxLength={2000} placeholder={"请输入自测报告链接(飞书文档)"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="NewDemand"
                                        label="改动内容"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请填写改动内容",
                                            }
                                        ]}
                                    >
                                        <Input.TextArea autoSize={true} maxLength={2000}
                                                        placeholder={"请填写改动内容 如：\n" +
                                                        "1.xxxxxxxxx\n" +
                                                        "2.xxxxxxxxx"
                                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="AffectedScope"
                                        label="影响范围"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请填写影响范围",
                                            }
                                        ]}
                                    >
                                        <Input.TextArea autoSize={true} maxLength={2000}
                                                        placeholder={"请填写影响范围 如：\n" +
                                                        "1.xxxxxxxxx\n" +
                                                        "2.xxxxxxxxx"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="TestSuggestion"
                                        label="测试建议"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请填写测试建议",
                                            }
                                        ]}
                                    >
                                        <Input.TextArea autoSize={true} maxLength={2000}
                                                        placeholder={"请填写测试建议 如：\n" +
                                                        "1.xxxxxxxxx\n" +
                                                        "2.xxxxxxxxx"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="FirmwareAddress"
                                        label="固件地址"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请填写固件地址",
                                            }
                                        ]}
                                    >
                                        <Input.TextArea autoSize={true} maxLength={2000}
                                                        placeholder={"请填写固件地址(与飞书审批流一致)"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="AutoOTA"
                                        label="OTA配置"
                                        initialValue={2}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择OTA升级配置",
                                            }
                                        ]}
                                    >
                                        <Radio.Group
                                            options={[
                                                {label: '快捷配置', value: 1, disabled: false},
                                                {label: '手动配置', value: 2, disabled: false},
                                            ]}
                                            onChange={(e) => {
                                                this.setState({
                                                    isGateway: e.target.value === 1
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                {this.state.isGateway ?
                                    <>
                                        <Divider style={{fontSize: 11, marginTop: -10, color: "goldenrod"}}>
                                            快捷升级配置（目前仅适配C100智能中控大屏）
                                        </Divider>
                                        <Col span={24}>
                                            <Form.Item
                                                name="DevicesName"
                                                label="设备名称"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请输入设备DN",
                                                    }
                                                ]}
                                            >
                                                <Input maxLength={100} placeholder={"设备DevicesName(仅支持单设备)"}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="FirmwareDownloadAddress"
                                                label="固件地址"
                                                initialValue={null}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请输入固件地址",
                                                    },
                                                    {
                                                        pattern: /^https:\/\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;%=]+$/,
                                                        message: "请输入有效的https链接",
                                                    }
                                                ]}
                                            >
                                                <Input.TextArea
                                                    autoSize={true} maxLength={2000}
                                                    placeholder={"固件升级包下载地址(支持confluence下载链接)"}
                                                    onChange={(e) => {
                                                        var fileName = e.target.value.split('?')[0].match(/\/([^/?]+)$/)[1]
                                                        if (fileName == null) {
                                                            this.form.current.setFieldsValue({FirmwareFileName: null})

                                                        } else {
                                                            this.form.current.setFieldsValue({FirmwareFileName: fileName})
                                                        }
                                                    }}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="OtaVersion"
                                                label="OTA版本"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请输入OTA版本号",
                                                    }
                                                ]}
                                            >
                                                <Input maxLength={100} placeholder={"请输入OTA版本号"}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="FirmwareFileName"
                                                label="固件包名"
                                                initialValue={null}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "未解析到合法到文件名称",
                                                    },
                                                    {
                                                        pattern: /^[a-zA-Z0-9_\-\\.]+\.(gz|zip|img|bin)$/,
                                                        message: "文件名不合法仅支持gz、zip、img、bin",
                                                    }
                                                ]}
                                            >
                                                <Input disabled={true} placeholder={"自动解析、校验文件名称"}/>
                                            </Form.Item>
                                        </Col>
                                    </> : <div style={{fontSize: 11, marginTop: -10, color: "hotpink"}}>
                                        Ps:【产品/项目/组长】节点审批通过立即执行自动化测试（请确保发起审批前固件版本已升级完成）
                                    </div>
                                }
                            </Row>
                            <Form.Item style={{marginTop: 30, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit"
                                        loading={this.state.submitBtnLoading}
                                        style={{fontSize: 13, fontWeight: 800}}>
                                    发起提测
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}