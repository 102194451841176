import React, {Component} from 'react'
import { PageHeader } from '@ant-design/pro-layout';
import { Affix, Button, Divider, FloatButton, Input, Space } from 'antd';
import {
    AppstoreAddOutlined,
    CloseOutlined,
    SearchOutlined,
} from "@ant-design/icons";

export class UserCenter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            display_button: false
        }
    }

    componentDidMount() {
    }

    render() {
        return (<>
            <div>
                <Affix offsetTop={63}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        // height: 150
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="个人中心"
                            subTitle="设备诊断平台"
                            onBack={() => window.history.back()}
                            extra={
                                <Space size={[0]}>
                                    {this.state.display_button ?
                                        <Input.Group>
                                            <Input
                                                style={{width: 300}}
                                                placeholder="搜索关键字"
                                                onPressEnter={(e) => {
                                                    console.log()
                                                }}
                                                prefix={<SearchOutlined/>}
                                                suffix={<CloseOutlined onClick={() => {
                                                    this.setState({display_button: false})
                                                    console.log()
                                                }}/>}
                                            />
                                        </Input.Group> :
                                        <Button type={'link'} style={{width: 100, color: '#707272'}}
                                                onClick={() => {
                                                    this.setState({display_button: true})
                                                }} icon={<SearchOutlined/>}>搜索</Button>
                                    }
                                    {/*<Button type={'link'} icon={<UserOutlined />} style={{width:100,color:'#707272'}}>我的项目</Button>*/}
                                    <Divider type="vertical"/>
                                </Space>
                            }
                        >
                        </PageHeader>
                    </div>
                </Affix>
                <FloatButton.BackTop visibilityHeight={100}/>
            </div>
            <div style={{
                margin: 5,
                minHeight: '78vh',
                // minHeight: 750,
            }}>
                暂未开发
            </div>
        </>);
    }
}
