import React, {Component} from 'react'
import {PageHeader} from '@ant-design/pro-layout';
import {Affix, Button, Card, Divider, FloatButton, Steps, Space, Tooltip, Watermark, message} from 'antd';
import {RedoOutlined} from "@ant-design/icons";
import {Step1} from "./Step1";
import api from "../../../api/ApiConfig";
import {Loginout} from "../../Users/AuthRouter";
import Avatar from "antd/es/avatar/avatar";
import Text from "antd/es/typography/Text";
import {Content} from "antd/es/layout/layout";
import {Step2} from "./Step2";
import {Step3} from "./Step3";
import {Step4} from "./Step4";
import {Step5} from "./Step5";

export class FirmwareTaskDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 1,
            userList: [],
            datas: {},
            tasks_datas: {},
            approval_datas: {}
        }

    }

    componentDidMount() {
        this.getuserlist_atmp()
        this.get_firmware_project_tasks()
        this.getproject_tasks_firmware_status()
        this.get_firmware_approval_info()
    }

    get_firmware_approval_info = async (params = {}) => {
        params['key'] = this.props.match.params.id
        await api.GetFirmwareApprovalInfo(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    approval_datas: r.datas
                })
            }

        })
    }


    get_firmware_project_tasks = async () => {
        let params = {}
        params['key'] = this.props.match.params.id
        await api.GetFirmwareProjectTasks(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    tasks_datas: r.datas,
                })
            }
            this.setState({
                spinning: false
            })
        })
    }

    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <Space size={5}>
                            <Avatar key={key} size={30} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, fontWeight: 600}}>{value.name}({value.user_id})</Text>
                        </Space>,
                        label1: <div>
                            <Tooltip title={<div>{value.name}({value.user_id})</div>} color={"#108ee9"} key={key}>
                                <Avatar key={key} size={30} src={value.avatar_url}/>
                            </Tooltip>
                        </div>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }

    getproject_tasks_firmware_status = async (params = {}) => {
        params['key'] = this.props.match.params.id
        await api.GetProjectTasksFirmwareStatus(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                if (r.datas.ApprovalStatus1 === 2 && r.datas.ApprovalStatus2 === 2) {
                    this.setState({
                        Step2_Status: "finish",
                        current: this.state.tasks_datas.AutoOTA === 2 ? 3 : 2
                    })
                    if (r.datas.OtaTaskRes === 4) {
                        this.setState({
                            Step3_Status: "finish",
                            current: 3
                        })
                    }
                    if (r.datas.OtaTaskRes === 5 || r.datas.OtaTaskRes === 6) {
                        this.setState({
                            Step3_Status: "error"
                        })
                    }
                }
                if (r.datas.ApprovalStatus1 === 3 || r.datas.ApprovalStatus2 === 3) {
                    this.setState({
                        Step2_Status: "error"
                    })
                }
                if (r.datas.AutoTest === 2 || r.datas.AutoTest === 4) {
                    this.setState({
                        Step4_Status: "finish",
                        current: 4
                    })
                }
                if (r.datas.AutoTest === 3) {
                    this.setState({
                        Step4_Status: "error",
                    })
                }
                this.setState({
                    datas: r.datas
                })
            }

        })
    }

    render() {
        const steps = [
            {
                title: <div style={{fontSize: 13, fontWeight: 700}}>No.1 提测信息</div>,
                status: 'finish',
                content: <Step1 datas={this.state.tasks_datas} fun={this.get_firmware_project_tasks}/>
            },
            {
                title: <div style={{fontSize: 13, fontWeight: 700}}>No.2 审批信息</div>,
                status: this.state.Step2_Status,
                content: <Step2 taskId={this.props.match.params.id} userList={this.state.userList}
                                datas={this.state.datas} approval_datas={this.state.approval_datas}/>
            },
            {
                title: <div style={{fontSize: 13, fontWeight: 700}}>No.3 OTA升级</div>,
                status: this.state.tasks_datas.AutoOTA === 2 ? "wait" : this.state.Step3_Status,
                content: <Step3 taskId={this.props.match.params.id} userList={this.state.userList}
                                datas={this.state.datas}/>,
                disabled: this.state.tasks_datas.AutoOTA === 2
            },
            {
                title: <div style={{fontSize: 13, fontWeight: 700}}>No.4 自动化测试</div>,
                status: this.state.Step4_Status,
                content: <Step4 datas={this.state.datas} approval_datas={this.state.approval_datas}
                                fun={this.getproject_tasks_firmware_status} taskId={this.props.match.params.id}/>
            },
            {
                title: <div style={{fontSize: 13, fontWeight: 700}}>No.5 提测结果</div>,
                status: this.state.Step4_Status,
                content: <Step5 tasks_datas={this.state.tasks_datas} datas={this.state.datas}
                                fun={this.getproject_tasks_firmware_status}/>
            },
        ]
        return (<>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="固件版本提测"
                            subTitle={"固件版本提测"}
                            onBack={() => window.history.back()}
                            extra={
                                <Space size={[0]}>
                                    <Button type={'link'} icon={<RedoOutlined/>}
                                            style={{width: 100, color: '#707272'}}
                                            onClick={() => {
                                                this.getuserlist_atmp()
                                                this.get_firmware_project_tasks()
                                                this.getproject_tasks_firmware_status()
                                                this.get_firmware_approval_info()
                                                message.success("数据刷新成功～")
                                            }}
                                    >
                                        刷新数据
                                    </Button>
                                    <Divider type="vertical"/>
                                </Space>
                            }
                        >
                            <Content/>
                        </PageHeader>
                    </div>
                </Affix>
                <FloatButton.BackTop visibilityHeight={100}/>
                <Watermark content="公牛Bull">
                    <div style={{minHeight: '80vh', background: '#ffffff'}}>
                        <Card>
                            <Steps
                                type="navigation"
                                className="site-navigation-steps"
                                style={{marginTop: 20}}
                                size="small"
                                current={this.state.current}
                                onChange={(value) => {
                                    this.setState({
                                        current: value
                                    })
                                }}
                                items={steps}
                            />
                            <div style={{
                                minHeight: 500,
                                marginTop: 30,
                                paddingTop: 20,
                                textAlign: 'center',
                                backgroundColor: '#fafafa',
                                border: '1px dashed #e9e9e9',
                                borderRadius: 2,
                            }}>
                                {steps[this.state.current].content}
                            </div>
                        </Card>
                    </div>
                </Watermark>
            </>
        );
    }
}
