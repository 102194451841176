import React, {Component} from "react";
import {
    Affix,
    Button, Card,
    Divider, Input, Segmented, Select, Space, Spin, Table, Tabs, Watermark,
} from "antd";
import {PageHeader} from "@ant-design/pro-layout";
import {
    AndroidOutlined,
    AuditOutlined, ChromeOutlined, ClearOutlined, CloudServerOutlined, GatewayOutlined,
    RedoOutlined, SearchOutlined, SettingOutlined, SyncOutlined,
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import {getUser, Loginout} from "../Users/AuthRouter";
import NOTIFICATION from "./NOTIFICATION_Z";
import {ProjectConfig} from "./ProjectConfig";
import {ProjectList} from "./ProjectList";
import api from "../../api/ApiConfig";
import {AppProjectList} from "./AppSubmitTest/AppProjectList";
import {FirmwareProjectList} from "./FirmwareSubmitTest/FirmwareProjectList";

export class ProjectTestingHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            spinning: false,
            activeKey: localStorage.getItem("PtHomeTabsActiveKey") !== null
            && localStorage.getItem("PtHomeTabsActiveKey") !== undefined
                ? localStorage.getItem("PtHomeTabsActiveKey") : 1
        }
    }

    componentDidMount() {

    }

    userinfo = getUser()

    render() {
        return <>
            <Affix offsetTop={60}>
                <div style={{
                    background: '#ffffff',
                    textAlign: 'center',
                }}>
                    <PageHeader
                        className="site-page-header-responsive"
                        title="版本提测"
                        subTitle={"版本提测"}
                        onBack={() => window.history.back()}
                    >
                        <Content/>
                    </PageHeader>
                </div>
            </Affix>
            <div style={{background: 'rgb(240, 242, 245)', minHeight: '80vh'}}>
                <Watermark content="公牛Bull">
                    <Spin spinning={this.state.spinning} tip="数据加载中...">
                        <Card style={{minHeight: '80vh'}}>
                            <Tabs
                                onChange={(activeKey) => {
                                    this.setState({
                                        activeKey: activeKey
                                    }, function () {
                                        console.log("this.state.activeKey", this.state.activeKey)
                                        localStorage.setItem("PtHomeTabsActiveKey", this.state.activeKey)
                                    })
                                }}
                                defaultActiveKey={Number(this.state.activeKey)}
                                activeKey={Number(this.state.activeKey)}
                                type="card"
                                items={[
                                    {
                                        label: <Space>
                                            <CloudServerOutlined/>
                                            <div style={{marginLeft: -15, fontSize: 13, fontWeight: 700}}>
                                                云服务
                                            </div>
                                        </Space>,
                                        key: 1,
                                        children: <ProjectList/>
                                    },
                                    {
                                        label: <Space>
                                            <AndroidOutlined/>
                                            <div style={{marginLeft: -15, fontSize: 13, fontWeight: 700}}>
                                                APP
                                            </div>
                                        </Space>,
                                        key: 2,
                                        children: <AppProjectList/>
                                    },
                                    {
                                        label: <Space>
                                            <GatewayOutlined/>
                                            <div style={{marginLeft: -15, fontSize: 13, fontWeight: 700}}>
                                                固件
                                            </div>
                                        </Space>,
                                        key: 3,
                                        children: <FirmwareProjectList/>,
                                        disabled: false
                                    },
                                    {
                                        label: <Space>
                                            <ChromeOutlined/>
                                            <div style={{marginLeft: -15, fontSize: 13, fontWeight: 700}}>
                                                WEB
                                            </div>
                                        </Space>,
                                        key: 4,
                                        children: <Card/>,
                                        disabled: true
                                    }
                                ]}
                            />
                        </Card>
                    </Spin>
                </Watermark>
            </div>
        </>
    }
}
