import React, {Component} from 'react';
import '@ant-design/pro-components/dist/components.css';
import {PageHeader} from '@ant-design/pro-layout';
import {ProCard} from '@ant-design/pro-components';
import {
    Affix,
    Button,
    Input,
    Space,
    Table,
    Tag,
    Spin, Watermark, message, Divider, Popconfirm
} from 'antd'

import {
    ClearOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    SearchOutlined,
    SyncOutlined,
    UserOutlined
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import Card from "antd/es/card/Card";
import {AudioPlayer} from "./AudioPlayer";
import Avatar from "antd/es/avatar/avatar";
import Text from "antd/es/typography/Text";
import api from "../../api/ApiConfig";
import {getUser, Loginout} from "../Users/AuthRouter";
import {EditVoiceDetails} from "./EditVoiceDetails";


export class VoiceTestDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            spinning: false,
            userList: [],
            datas: [],
            qText: null
        }
    }

    componentDidMount() {
        this.getuserlist_atmp()
        this.get_voice_detail()
    }

    userinfo = getUser()

    get_voice_detail = async () => {
        // params['UserId'] = this.userinfo.UserId
        let params = {}
        params['key'] = this.props.match.params.id
        params['page'] = this.state.page
        params['pageSize'] = this.state.pageSize
        params['qText'] = this.state.qText
        this.setState({
            spinning: true
        })
        await api.GetVoiceDetail(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    datas: r.datas,
                    Total: r.Total
                })
            }
            this.setState({
                spinning: false
            })
        })
    }

    handleDownload() {
        // 生成 JSON 字符串
        const jsonString = JSON.stringify(this.state.datas, null, 2);

        // 创建 Blob 对象
        const blob = new Blob([jsonString], {type: 'application/json;charset=utf-8;'});

        // 创建下载链接
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'data.json'; // 设置下载文件名

        // 触发点击事件
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    delete_voice_detail = async (key) => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['delete'] = 1
        values['key'] = key
        await api.PostVoiceDetail({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({}, function () {
                    message.success("删除语音数据成功")
                    this.get_voice_detail()
                    this.setState({
                        isModalOpen: false
                    })
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }
    postsyncvoicemessages = async () => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['VoiceLibraryId_id'] = this.props.match.params.id
        await api.PostSyncVoiceMessages({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({}, function () {
                    message.success("开始同步语音消息(该接口为异步接口,稍后请手动刷新获取最新结果)")
                    this.setState({
                        isModalOpen: false
                    })
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }
    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <Space size={2} style={{marginTop: -5}}>
                            <Avatar key={key} shape={'square'} style={{marginTop: -5}} size={30}
                                    src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, fontWeight: 600, color: "#0c0c0c"}}>{value.name}({value.user_id})
                            </Text>
                        </Space>,
                        label1: <Space size={2} style={{marginTop: -5}}>
                            <Avatar key={key} size={20} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, fontWeight: 600, color: "#f6f5f8"}}>{value.name}({value.user_id})
                            </Text>
                        </Space>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }


    render() {
        const columns = [
            {
                title: 'No.',
                width: 30,
                fixed: 'left',
                align: 'center',
                render: (record, text, index) => {
                    return (index + 1)
                }
            },
            {
                title: '关键词',
                align: 'center',
                dataIndex: 'VoiceKeyWords',
                key: 'VoiceKeyWords',
                render: (text, record, index) => {
                    return <Tag color={"#52c41a"} style={{fontWeight: 700}}>{text}</Tag>
                }
            },
            {
                title: '语音贡献人',
                width: 180,
                align: 'center',
                render: (record, text, index) => {
                    return <div style={{fontSize: 12, fontWeight: 500, textAlign: "left"}}>
                        {
                            ([`${record.UserName}(${record.UserId})`]).map((value, index) => {
                                for (let i = 0; i < this.state.userList.length; i++) {
                                    if (value === this.state.userList[i].value) {
                                        return this.state.userList[i].label
                                    }
                                }
                                return <Space size={5}>
                                    <Avatar key={record.key} style={{marginTop: -5}} shape={'square'}
                                            src={record.UserAvatar} size={30}/>
                                    <Text
                                        style={{
                                            fontSize: 12,
                                            fontWeight: 600,
                                            color: "#0c0c0c"
                                        }}>{record.UserName}({record.UserId})
                                    </Text>
                                </Space>
                            })
                        }
                    </div>
                }
            },
            {
                title: '性别',
                width: 80,
                align: 'center',
                dataIndex: 'Gender',
                key: 'Gender',
                render: (text, record, index) => {
                    return <Tag color={text === 0 ? "blue-inverse" : "pink-inverse"}
                                style={{fontSize: 12, fontWeight: 700}}>
                        {text === 0 ? "男性" : "女性"}
                    </Tag>
                }
            },
            {
                title: '年龄',
                width: 80,
                align: 'center',
                dataIndex: 'Age',
                key: 'Age',
                render: (text, record, index) => {
                    return <Tag color={"#342367"} style={{fontWeight: 700}}>{text}</Tag>
                }
            },
            {
                title: '在线播放',
                align: 'center',
                width: 300,
                dataIndex: 'VoiceFileLinks',
                key: 'VoiceFileLinks',
                render: (text, record, index) => {
                    return <AudioPlayer audioSrc={text} audioKey={record.key.replace(/-/g, '')}/>
                }
            },
            {
                title: '等级',
                width: 80,
                align: 'center',
                dataIndex: 'VoiceTypeLevel',
                key: 'VoiceTypeLevel',
                render: (text, record, index) => {
                    return <Tag color={"geekblue-inverse"} style={{fontSize: 12, fontWeight: 700}}>{text} </Tag>
                }
            },
            {
                title: '特殊标注',
                align: 'center',
                dataIndex: 'VoiceFileMarks',
                key: 'VoiceFileMarks',
            },

            {
                title: '任务操作',
                width: 200,
                align: 'center',
                render: (_, record) => {
                    return <Space size={0} direction={"horizontal"}>
                        <Button style={{width: 75, fontWeight: 700, fontSize: 12}}
                                size={"small"} disabled={false} type={"link"} icon={<DownloadOutlined/>}
                                onClick={() => {
                                    fetch(record.VoiceFileLinks)
                                        .then(response => response.blob())
                                        .then(blob => {
                                            const url = window.URL.createObjectURL(blob);
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', record.UserName + "_" + record.VoiceFileKey); // 设置下载文件名
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                        });
                                }}
                        >
                            下载语音
                        </Button>
                        <EditVoiceDetails fun={this.get_voice_detail} datas={record}/>
                        <Popconfirm
                            title="删除语音消息"
                            description={<div style={{fontSize: 12, color: "red"}}>
                                您确定要删除该语音消息吗？
                            </div>}
                            onConfirm={() => {
                                this.delete_voice_detail(record.key)
                            }}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button style={{width: 75, fontWeight: 700, fontSize: 12}}
                                    size={"small"}
                                    disabled={false} type={"link"} danger={true} icon={<DeleteOutlined/>}>
                                删除语音
                            </Button>
                        </Popconfirm>
                    </Space>
                }
            },
        ];
        return (
            <>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="语音采集"
                            subTitle={"语音数据详情"}
                            onBack={() => window.history.back()}
                            extra={
                                <Space size={0} style={{marginRight: 20}}>
                                    <Button type={"link"} style={{width: 80, fontWeight: 700, fontSize: 13}}
                                            icon={<DownloadOutlined/>} onClick={() => {
                                        this.handleDownload()
                                    }}>数据下载</Button>
                                    <Divider type="vertical"/>
                                    <Popconfirm
                                        title="同步语音消息"
                                        description={<div style={{fontSize: 12, color: "red"}}>
                                            您确定要同步所有语音消息吗？
                                        </div>}
                                        onConfirm={() => {
                                            this.postsyncvoicemessages()
                                        }}
                                        okText="确认"
                                        cancelText="取消"
                                    >
                                        <Button type={"link"} danger
                                                style={{width: 80, fontWeight: 700, fontSize: 13}}
                                                icon={<SyncOutlined/>}
                                        >
                                            消息同步
                                        </Button>
                                    </Popconfirm>
                                </Space>
                            }
                        >
                            <Content/>
                        </PageHeader>
                    </div>
                </Affix>
                <div style={{background: 'rgb(240, 242, 245)', minHeight: '80vh'}}>
                    <Watermark content="公牛Bull">
                        <Spin spinning={this.state.spinning} tip="数据加载中...">
                            <Card style={{minHeight: '80vh', textAlign: "left"}}
                                  title={
                                      <Space style={{marginTop: 30, marginBottom: 30}}>

                                          <Input style={{width: "20vw"}} placeholder={"可通过语音提供者、特殊标注字段模糊搜索～"}
                                                 value={this.state.qText}
                                                 onChange={(e) => {
                                                     this.setState({
                                                         qText: e.target.value
                                                     })
                                                 }}/>
                                          <Button type={"primary"} style={{fontWeight: 700}}
                                                  icon={<SearchOutlined/>}
                                                  onClick={() => {
                                                      this.get_voice_detail()
                                                  }
                                                  }>查询</Button>
                                          <Button type={"primary"} style={{fontWeight: 700}}
                                                  icon={<ClearOutlined/>}
                                                  onClick={() => {
                                                      this.setState({
                                                          qText: null,
                                                          UserId: null
                                                      }, function () {
                                                          this.get_voice_detail()
                                                      })
                                                  }
                                                  }>重置</Button>
                                      </Space>
                                  }>
                                <Table columns={columns} dataSource={this.state.datas} bordered={true}
                                       pagination={{
                                           position: ['bottomRight'],
                                           pageSizeOptions: [10, 20, 30, 100],
                                           responsive: true,
                                           size: "middle",
                                           current: this.state.page,
                                           pageSize: this.state.pageSize,
                                           total: this.state.Total,
                                           showTotal: (total, range) => {
                                               return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                                           },
                                           onShowSizeChange: (current, size) => {
                                               this.setState({
                                                   page: current,
                                                   pageSize: size
                                               }, function () {
                                                   this.get_voice_detail()
                                               })
                                           },
                                           onChange: (page, pageSize) => {
                                               this.setState({
                                                   page: page,
                                                   pageSize: pageSize
                                               }, function () {
                                                   this.get_voice_detail()
                                               })
                                           }
                                       }}
                                />
                            </Card>
                        </Spin>
                    </Watermark>
                </div>
            </>
        )
    }

}