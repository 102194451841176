import React, {Component} from 'react';
import '@ant-design/pro-components/dist/components.css';
import {PageHeader} from '@ant-design/pro-layout';
import {
    Affix, Button, message,
    Tabs,
} from 'antd'
import {Content} from "antd/es/layout/layout";
import Card from "antd/es/card/Card";
import api from "../../api/ApiConfig";
import {getUser, Loginout} from "../Users/AuthRouter";
import {PerformanceTestDatas} from "./PerformanceTestDatas";
import html2canvas from "html2canvas";
import {CameraOutlined} from "@ant-design/icons";
import dayjs from "dayjs";


export class PerformanceTestDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            SoloPiUserDatas: {},
            SpinLoading: true,
            SoloPiUserDatasTime: "",
            performancetestitems: []
        }
    }

    componentDidMount() {
        this.getperformancetestitems().then(r => {
            this.getsolopiuserdatas({PerformanceTestItemId: this.state.performancetestitems ? this.state.performancetestitems[0].pk : []})
        })
    }


    getperformancetestitems = async () => {
        let params = {}
        let dataSourcelist = []
        const tabs_items = []
        params['PerformanceTaskId'] = this.props.match.params.id
        await api.GetPerformanceTestItems(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (r.length !== 0) {
                    for (let i = 0; i < r['datas'].length; i++) {
                        dataSourcelist.push(
                            r['datas'][i].fields
                        )
                        dataSourcelist[i]['pk'] = r['datas'][i].pk
                        dataSourcelist[i]['key'] = i
                    }
                }
                this.setState({
                    performancetestitems: dataSourcelist,
                }, function () {
                    console.log("当前任务测试项", this.state.performancetestitems)
                })
            }
        })
    }

    getsolopiuserdatas = async (params = {}) => {
        this.setState({
            SpinLoading: true
        })
        let dataSourcelist = []
        await api.GetSoloPiUserDatas(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (r.length !== 0) {
                    for (let i = 0; i < r['datas'].length; i++) {
                        dataSourcelist.push(
                            r['datas'][i].fields
                        )
                        dataSourcelist[i]['pk'] = r['datas'][i].pk
                        dataSourcelist[i]['key'] = i
                    }
                }
                const dsl = dataSourcelist[0]
                let SoloPiUserDatas = {}
                for (let key in dsl) {
                    let dt = {}
                    try {
                        dt = JSON.parse(dsl[key].replace(/'/g, '"'))
                        if (typeof dt === 'object' && dt !== null && Object.keys(dt).length > 0) {
                            SoloPiUserDatas[key] = dt
                        }
                    } catch (e) {
                    }
                }
                this.setState({
                    SoloPiUserDatas: SoloPiUserDatas,
                    SpinLoading: false,
                    SoloPiUserDatasTime: dsl ? dsl['EditedTime'] : ""
                })
            }
        })
    }
    make_items = () => {
        let items_arr = []
        for (let i = 0; i < this.state.performancetestitems.length; i++) {
            items_arr.push({
                key: this.state.performancetestitems[i].pk,
                label: this.state.performancetestitems[i].ItemName,
                children: <></>
            })
        }
        return items_arr
    }
    screenshotRef = React.createRef();

    handleScreenshot = () => {
        html2canvas(this.screenshotRef.current, {scale: 1}).then(canvas => {
            const image = canvas.toDataURL();
            const link = document.createElement('a');
            link.href = image;
            link.download = '性能测试数据' + dayjs().format("YYYY-MM-DD") + '.png';
            link.click();
        });
    };

    render() {
        return (
            <>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="性能测试"
                            subTitle="性能测试任务详情"
                            onBack={() => window.history.back()}
                            extra={<>
                                <Button type={'link'}
                                        style={{width: 100, color: '#707272'}}
                                        icon={<CameraOutlined/>}
                                        onClick={() => {
                                            message.success("即将为您生成截图数据请稍后...", 3)
                                            setTimeout(() => {
                                                this.handleScreenshot()
                                            }, 500); // 延迟 500 毫秒执行
                                        }}>
                                    数据截图
                                </Button>
                            </>}
                        >
                            <Content/>
                        </PageHeader>
                    </div>
                </Affix>
                <div style={{marginTop: 3, background: '#ffffff', minHeight: "85%"}}>
                    <Card>
                        <Tabs
                            style={{marginLeft: 10, marginTop: 10}}
                            onChange={(value) => {
                                this.getsolopiuserdatas({PerformanceTestItemId: value})
                            }}
                            // type="card"
                            tabPosition={"top"}
                            items={
                                this.make_items()
                            }
                        />
                        <div ref={this.screenshotRef}>
                            <PerformanceTestDatas
                                SpinLoading={this.state.SpinLoading}
                                SoloPiUserDatas={this.state.SoloPiUserDatas}
                                SoloPiUserDatasTime={this.state.SoloPiUserDatasTime}
                            />
                        </div>
                    </Card>
                </div>
            </>
        )
    }

}