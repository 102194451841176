import React, {Component} from 'react';
import {Bullet, Line} from '@ant-design/plots';
import moment from "moment";
import {Empty, Watermark} from "antd";
import {toInteger} from "@antv/util";

export class BulletChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: props.datas ? props.datas : [],
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }


    render() {
        const config = {
            height: 150,
            data: this.state.datas,
            measureField: 'Current',
            rangeField: 'Ranges',
            targetField: 'Target',
            xField: 'title',
            color: {
                range: ['#FFbcb8', '#FFe0b0', '#bfeec8'],
                measure: 'rgb(116,171,225)',
                target: '#0c0c0c',
            },
            label: {
                measure: {
                    position: 'left',
                    style: {
                        fill: '#ffffff',
                        fontSize: 13,
                        fontWeight: 'bold'
                    },
                    formatter: (value) => {
                        return "实际值【" + value.Current + "%】"
                    },
                },
                target: {
                    position: 'middle',
                    style: {
                        fill: '#0c0c0c',
                        fontSize: 13,
                        fontWeight: 'bold'
                    },
                    formatter: (value) => {
                        return "目标值【" + value.Target + "%】"
                    },
                },
            },
            xAxis: {
                line: null,
            },
            yAxis: true,
            tooltip: {
                showMarkers: true,
                // shared: true,
            },
            // 自定义 legend
            legend: {
                custom: true,
                position: 'bottom',
                items: [
                    {
                        value: '极差',
                        name: '极差',
                        marker: {
                            symbol: 'square',
                            style: {
                                fill: '#FFbcb8',
                                r: 5,
                            },
                        },
                    },
                    {
                        value: '良好',
                        name: '良好',
                        marker: {
                            symbol: 'square',
                            style: {
                                fill: '#FFe0b0',
                                r: 5,
                            },
                        },
                    },
                    {
                        value: '优秀',
                        name: '优秀',
                        marker: {
                            symbol: 'square',
                            style: {
                                fill: '#bfeec8',
                                r: 5,
                            },
                        },
                    },
                    {
                        value: '实际值',
                        name: '实际值',
                        marker: {
                            symbol: 'square',
                            style: {
                                fill: '#5B8FF9',
                                r: 5,
                            },
                        },
                    },
                    {
                        value: '目标值',
                        name: '目标值',
                        marker: {
                            symbol: 'square',
                            style: {
                                fill: '#0c0c0c',
                                r: 5,
                            },
                        },
                    },
                ],
            },
        }
        return <Watermark content="公牛Bull"> {this.state.datas.length !== 0 ?
            <Bullet {...config} /> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </Watermark>
    }

}