import React, {Component} from 'react';
import {G2, Line} from '@ant-design/plots';
import moment from "moment";
import insertCss from "insert-css";
import {Empty, Watermark} from "antd";

export class LineChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas ? props.datas.slice(0, 200) : [],
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas.slice(0, 200),
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas.slice(0, 200)) === JSON.stringify(nextProps.datas.slice(0, 200)) &&
            JSON.stringify(this.state.datas.slice(0, 200)) === JSON.stringify(nextState.datas.slice(0, 200)));
    }

    render() {
        insertCss(`
            .custom-tooltip-title {
                margin: 0px 12px;
                font-size: 12px;
                border-bottom-style: solid;
                border-bottom-width: thin;
                border-bottom-color: #e9e9e9;
            }
        
            .custom-tooltip-value {
              display: grid;
              grid-template-columns: 1fr 1fr;
              margin: 8px 12px 0 12px;
              padding-bottom: 1px;
              font-size: 10px;
              text-align: center;
              border-bottom-style: solid;
              border-bottom-width: thin;
              border-bottom-color: #e9e9e9;
              height: 70px;
            }
        
            .custom-tooltip-temp {
              display: flex;
              position: relative;
              align-items: center;
            }
        
            .custom-tooltip-temp span:first-child {
              font-size: 12px;
              position: absolute;
              top: 0px;
              color: rgba(0, 0, 0, 0.45)
            }
        
            .custom-tooltip-temp span:last-child {
              text-align: left;
              margin-top: 10px;
              position: relative;
              color: rgba(0, 0, 0, 0.85)
            }
        
            .custom-tooltip-wind {
                margin: 8px 12px 12px 12px;
                font-size: 12px;
                color: rgba(105, 0, 0, 0.85);
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        `);
        G2.registerShape('point', 'breath-point', {
            draw(cfg, container) {
                const data = cfg.data;
                const point = {
                    x: cfg.x,
                    y: cfg.y,
                };
                const group = container.addGroup();

                if (data.InterfaceTime >= 1000) {
                    const decorator1 = group.addShape('circle', {
                        attrs: {
                            x: point.x,
                            y: point.y,
                            r: 5,
                            fill: '#a377e0',
                            opacity: 0.5,
                        },
                    });
                    const decorator2 = group.addShape('circle', {
                        attrs: {
                            x: point.x,
                            y: point.y,
                            r: 5,
                            fill: '#a377e0',
                            opacity: 0.5,
                        },
                    });
                    const decorator3 = group.addShape('circle', {
                        attrs: {
                            x: point.x,
                            y: point.y,
                            r: 5,
                            fill: '#a377e0',
                            opacity: 0.5,
                        },
                    });
                    decorator1.animate(
                        {
                            r: 10,
                            opacity: 0,
                        },
                        {
                            duration: 1800,
                            easing: 'easeLinear',
                            repeat: true,
                        },
                    );
                    decorator2.animate(
                        {
                            r: 10,
                            opacity: 0,
                        },
                        {
                            duration: 1800,
                            easing: 'easeLinear',
                            repeat: true,
                            delay: 600,
                        },
                    );
                    decorator3.animate(
                        {
                            r: 10,
                            opacity: 0,
                        },
                        {
                            duration: 1800,
                            easing: 'easeLinear',
                            repeat: true,
                            delay: 1200,
                        },
                    );
                    group.addShape('circle', {
                        attrs: {
                            x: point.x,
                            y: point.y,
                            r: 2.5,
                            fill: cfg.color,
                            opacity: 0.7,
                        },
                    });
                    group.addShape('circle', {
                        attrs: {
                            x: point.x,
                            y: point.y,
                            r: 2.5,
                            fill: cfg.color,
                        },
                    });
                }

                return group;
            },
        });
        const config = {
            autoFit: true,
            height: 500,
            data: this.state.datas.reverse(),
            stepType: 'hvh',
            xField: 'CreatedTime',
            yField: 'InterfaceTime',
            tooltip: {
                showMarkers: true,
                customContent: (title, items) => {
                    const data = items[0]?.data || {};
                    const tempDom = `<div class = "custom-tooltip-value"><div class = "custom-tooltip-temp"><span>接口名称：</span><span>${data.Path}</span></div></div>`;
                    const windDom = `<div class = "custom-tooltip-wind"><span>请求耗时：${data.InterfaceTime} ms</span></div>`;
                    const tindDom = `<div class = "custom-tooltip-wind"><span>请求时间：${moment(data.CreatedTime).format("YYYY-MM-DD HH:mm:ss.SSS")}</span></div>`;
                    const sindDom = `<div class = "custom-tooltip-wind"><span>请求方法：${data.Method}</span></div>`;
                    const cindDom = `<div class = "custom-tooltip-wind"><span>服务状态：${data.ServiceCode}</span></div>`;
                    return `<div style="max-width: 700px;white-space: nowrap;text-align: left">${tempDom}${tindDom}${sindDom}${windDom}${cindDom}</div>`;
                },
            },
            // label: {
            //     formatter: (datum) => {
            //         return datum.date;
            //     },
            // },
            label: {
                formatter: (datum) => {
                    return datum.InterfaceTime ? datum.InterfaceTime + " ms" : "0 ms"
                },
            },
            yAxis: {
                // tickCount: 10,
                label: {
                    formatter: (value) => {
                        return value + " ms"
                    },
                },
            },
            xAxis: {
                tickCount: 10,
                label: {
                    formatter: (value) => {
                        return moment(value).format("HH:mm:ss")
                    },
                },
            },
            point: {
                shape: 'breath-point',
            },
            color: ['#6ab7da', '#806bd9', '#da6bcc'],
        };
        return <> {this.state.datas.length !== 0 ?
            <Line {...config} /> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </>
    }
}