import React, {Component} from 'react';
import {Button, Modal, message, Space, Input, DatePicker} from "antd";
import {
    EditOutlined,
    SettingOutlined
} from "@ant-design/icons";
import api from "../../api/ApiConfig";
import {getUser, Loginout} from "../Users/AuthRouter";
import dayjs from "dayjs";

export class CookieConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: props.datas ? props.datas : [],
            isModalOpen: false,
            DatasDate: dayjs().format("YYYY-MM") + "-01",
            Cookie: "",
            confirmLoading: false
        }
    }

    requestcrashtrendmonth = async () => {
        this.setState({
            confirmLoading: true
        }, function () {
            let dateObj = new Date(this.state.DatasDate);
            message.warning("正在为您获取" + dateObj.getFullYear() + "-" + ('0' + (dateObj.getMonth() + 1)).slice(-2) + "月的数据请稍后!"
            )
        })
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['Cookie'] = this.state.Cookie
        values['DatasDate'] = this.state.DatasDate
        await api.RequestCrashTrendMonth({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("数据更新成功!")
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
        this.setState({
            confirmLoading: false,
            isModalOpen: false
        })
    }

    render() {
        const handleOk = () => {
            if (this.state.Cookie === "") {
                message.error("Cookie不可为空!")
            } else {
                this.requestcrashtrendmonth()
            }
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        return <Space className={'ignore-class'}>
            <Button size={'small'} style={{fontWeight: 700, fontSize: 12}} type={'link'} icon={<EditOutlined/>}
                    onClick={() => {
                        this.setState({isModalOpen: true})
                    }}
            >
                更新数据
            </Button>
            <Modal title="更新App崩溃率数据" open={this.state.isModalOpen}
                   onCancel={handleCancel}
                // centered={true}
                   style={{width: 500, height: 300}}
                   onOk={handleOk}
                   okText={"确认更新"}
                   cancelText={"取消更新"}
                   confirmLoading={this.state.confirmLoading}
            >
                <Space>
                    <div style={{marginTop: 30, marginBottom: 20, marginLeft: 20}}>
                        <Space>
                            <div style={{fontWeight: 700, fontSize: 13}}>获取数据时间</div>
                            <DatePicker
                                style={{width: 100}}
                                picker={'month'}
                                placement={"bottomRight"} defaultValue={dayjs()}
                                allowClear={false}
                                onChange={(date, dateString) => {
                                    this.setState({
                                        DatasDate: dateString + "-" + "1",
                                    }, function () {
                                    })
                                }}/>
                        </Space>
                        <Input.TextArea
                            style={{width: 430, height: 200, marginTop: 20}}
                            placeholder={"请填写Bugly平台具有查询权限的用户Cookie."}
                            onChange={(e) => {
                                this.setState({
                                    Cookie: e.target.value
                                })
                            }
                            }
                        />
                        <div style={{
                            fontWeight: 700,
                            fontSize: 11,
                            color: "red",
                            marginTop: 20
                        }}>注意⚠️:更新数据会覆盖已有的相同月份数据,仅保留最新数据！！！
                        </div>
                    </div>
                </Space>
            </Modal>
        </Space>
    }
}