import React, {Component} from 'react'
import {redirect_uri_wx, CorPid, AgentId} from '../../api/UrlConfig'
import Tester from "../Tester/TestHome";
export class LOGIN extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const redirect_uri = encodeURI(`${redirect_uri_wx}`);
        // 授权链接 URL
        const login_url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${CorPid}&agentid=${AgentId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_privateinfo&state=STATE#wechat_redirect`
        // 向授权链接 URL 跳转
        window.location.assign(login_url);
    }

    render() {
        return (
            <><Tester/></>
        )
    }
}
