import React, {Component} from 'react';
import {G2, Gauge} from '@ant-design/plots';
import {Empty, Watermark} from "antd";

export class GaugeChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas ? props.datas.toFixed(3) : 0,
            content: props.content !== "" ? props.content : "可用性"
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas.toFixed(3),
                content: this.props.content!== "" ? this.props.content : "可用性"
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }

    render() {
        const {registerShape, Util} = G2; // 自定义 Shape 部分

        registerShape('point', 'triangle-gauge-indicator', {
            draw(cfg, container) {
                // 使用 customInfo 传递参数
                const {indicator, defaultColor} = cfg.customInfo;
                const {pointer} = indicator;
                const group = container.addGroup(); // 获取极坐标系下画布中心点

                const center = this.parsePoint({
                    x: 0,
                    y: 0,
                }); // 绘制指针

                if (pointer) {
                    const {startAngle, endAngle} = Util.getAngle(cfg, this.coordinate);
                    const radius = this.coordinate.getRadius();
                    const midAngle = (startAngle + endAngle) / 2;
                    const {
                        x: x1,
                        y: y1
                    } = Util.polarToCartesian(center.x, center.y, radius * 0.52, midAngle + Math.PI / 30);
                    const {
                        x: x2,
                        y: y2
                    } = Util.polarToCartesian(center.x, center.y, radius * 0.52, midAngle - Math.PI / 30);
                    const {x, y} = Util.polarToCartesian(center.x, center.y, radius * 0.6, midAngle);
                    const path = [['M', x1, y1], ['L', x, y], ['L', x2, y2], ['Z']]; // pointer

                    group.addShape('path', {
                        name: 'pointer',
                        attrs: {
                            path,
                            fill: defaultColor,
                            ...pointer.style,
                        },
                    });
                }

                return group;
            },
        });
        const config = {
            percent: this.state.datas,
            height: 150,
            // range: {
            //     color: '#30BF78',
            // },
            range: {
                color: ['l(0) 0:#99A99BFF 1:#5FAD69FF'],
            },
            indicator: {
                shape: 'triangle-gauge-indicator',
                pointer: {
                    style: {
                        fill: '#0f961f',
                    },
                },
            },
            statistic: {
                title: {
                    offsetY: -36,
                    style: {
                        fontSize: '18px',
                        color: '#0c0c0c',
                    },
                    formatter: () => this.state.datas + "%",
                },
                content: {
                    style: {
                        fontSize: '12px',
                        fontWeight: "120px",
                        color: '#4B535E',
                    },
                    formatter: () => this.state.content,
                },
            },
        };
        return <Watermark content="公牛Bull"> {this.state.datas.length !== 0 ?
            <Gauge {...config} /> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </Watermark>
    }

}