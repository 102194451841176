import React from 'react';
import {
    Button,
    Input,
    message,
    Select,
    Space,
    Radio,
    Steps,
    Drawer,
    Col,
    Divider,
    Row,
    Tag,
    InputNumber,
    Progress,
    Typography, List, Spin
} from "antd";
import api from '../../api/ApiConfig'
import {getUser, Loginout} from "../Users/AuthRouter";
import {
    CheckCircleTwoTone, CloseCircleOutlined,
    CloseSquareOutlined,
    ContactsOutlined,
    PlusSquareOutlined,
    ScheduleOutlined
} from "@ant-design/icons";
import {now} from "moment";
import TextArea from "antd/es/input/TextArea";

const {Title, Paragraph, Text, Link} = Typography;


export default class AddFiveTuples extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: false,
            applyId: now().toString(),
            applyNote: null,
            DownloadUrl: null,
            spinning: false,
            product_list: [],
            Env:"Test"
        }
    }

    uploadfivetuple = async () => {
        if (this.state.applyId !== null && this.state.applyNote !== null && this.isValidUrl(this.state.DownloadUrl)) {
            let values = {}
            const userinfo = getUser()
            values['userid'] = userinfo.UserId
            values['username'] = userinfo.username
            values['env']=this.state.Env
            values['ProductName'] = this.state.ProductName
            values['productKey'] = this.state.productKey
            values['productId'] = this.state.productId
            values['applyId'] = this.state.applyId
            values['applyNote'] = this.state.applyNote
            values['DownloadUrl'] = this.state.DownloadUrl
            this.setState({spinning: true})
            await api.UploadFiveTuple({values}).then(r => {
                if (r.msgcode === 200) {
                    message.success("五元组添加成功")
                    setTimeout(() => {
                        window.location.reload()
                    }, 500)
                    this.setState({isDrawerOpen: false})
                } else if (r.msgcode === 20008) {
                    Loginout()
                    this.props.history.push('/login')
                    setTimeout(() => {
                        window.location.reload()
                    }, 500)
                } else {
                    message.error(r.results)
                }
            })
            this.setState({spinning: false})
        } else {
            message.error("请检查描述信息是否为空、元组下载链接是否合法！")
        }
    }
    getproducts_ypt = async (params = {}) => {
        await api.GetProducts_YPT(params).then(r => {
            if (typeof (r.msgcode) == 'number') {
                if (r.msgcode === 20008) {
                    Loginout()
                    this.props.history.push('/login')
                    window.location.reload()
                } else {
                    this.setState({
                        product_list: r.datas.result
                    })
                }
            }
        })
    }

    isValidUrl = (url) => {
        // 判断 URL 是否为空
        if (!url) {
            return false;
        }
        // 创建一个正则表达式，用于匹配 URL
        let regex = /^https?:\/\/[^\s/$.?#].[^\s]*$/i;
        return regex.test(url);
    }

    render() {
        return <div>
            <Button type={'link'} style={{width: 100, color: '#707272'}}
                    icon={<PlusSquareOutlined/>}
                    onClick={() => {
                        this.getproducts_ypt({"env": "Test"})
                        this.setState({
                            isDrawerOpen: true
                        });
                    }}
            >
                添加五元组
            </Button>
            <Drawer
                title="添加五元组"
                placement={'right'}
                width={"80vh"}
                onClose={() => {
                    this.setState({
                        isDrawerOpen: false
                    })
                }}
                open={this.state.isDrawerOpen}
            >
                <Spin tip="五元组下载、入库中请稍等片刻....." spinning={this.state.spinning}>
                    <div style={{ marginTop: 40,marginLeft: 20}}>
                        <div style={{
                            // textAlign: 'center',
                            height: "400px",
                            background: "rgba(0, 0, 0, 0.02)",
                            marginTop: 16,
                            borderRadius: "8px",
                            border: "1px dashed rgb(217, 217, 217)"
                        }}>
                            <div style={{marginLeft:40}}>
                                <Space direction={"vertical"} size={20} style={{marginTop: 60}}>
                                    <b style={{color:"red"}}>⚠️注意：切记不要使用ta人注册的五元组创建虚拟设备任务,否则会导致设备争抢,频繁上下线！！!</b>
                                    <Space size={20}>
                                        <div>批次编号</div>
                                        <Input value={this.state.applyId} style={{width: 462}} disabled={true}/>
                                    </Space>
                                    <Space size={20}>
                                        <div>账号环境</div>
                                        <Radio.Group value={this.state.Env} buttonStyle="solid"
                                                     onChange={(e) => {
                                                         this.setState({
                                                             Env: e.target.value,
                                                         }, function () {
                                                             this.getproducts_ypt({"env": this.state.Env}).then(r => {
                                                             })
                                                         })
                                                     }}>
                                            <Radio.Button value="Developer">开发环境</Radio.Button>
                                            <Radio.Button value="Test">测试环境</Radio.Button>
                                            <Radio.Button value="Online" disabled={true}>生产环境</Radio.Button>
                                        </Radio.Group>
                                    </Space>
                                    <Space size={20}>
                                        <div>设备列表</div>
                                        <Select
                                            style={{
                                                width: 462,
                                            }}
                                            showSearch
                                            placeholder="请选择设备类型"
                                            optionFilterProp="children"
                                            onChange={(value) => {
                                                this.setState({
                                                    ProductName: value.split("@")[0],
                                                    productKey: value.split("@")[1],
                                                    productId: value.split("@")[2],
                                                })

                                            }}
                                            value={this.state.ProductName}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.product_list.map((province) => ({
                                                label: province.productName + "@" + province.productId,
                                                value: province.productName + "@" + province.productKey + "@" + province.productId,
                                            }))}/>
                                    </Space>
                                    <Space size={20}>
                                        <div>描述信息</div>
                                        <Input
                                            placeholder="请填写描述信息" value={this.state.applyNote} style={{width: 462}}
                                            onChange={(e) => {
                                                this.setState({
                                                    applyNote: e.target.value
                                                })
                                            }}/>
                                    </Space>
                                    <Space size={20}>
                                        <div>元组链接</div>
                                        <TextArea
                                            allowClear
                                            autoSize={{minRows: 1, maxRows: 4}}
                                            placeholder="请填写五元组注册成功后的下载链接" value={this.state.DownloadUrl}
                                            style={{width: 462}}
                                            onChange={(e) => {
                                                this.setState({
                                                    DownloadUrl: e.target.value
                                                })
                                            }}/>
                                    </Space>
                                </Space>
                                <div
                                    style={{
                                        marginTop: 50,
                                        textAlign: 'center',
                                    }}
                                ><Button type="primary" onClick={() => {
                                    this.uploadfivetuple().then(r => {
                                    })
                                }}>确认提交</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </Drawer>
        </div>
    }
}
