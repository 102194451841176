import React, {Component} from 'react';
import {Button, Modal, message, Space, Form, Select, InputNumber, FloatButton, Slider, Mentions, Segmented} from "antd";
import {
    AlertOutlined,
    ClockCircleOutlined,
    ClusterOutlined,
    CodeOutlined,
    ForkOutlined,
    GitlabOutlined, KeyOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {ModalForm, ProFormText, DrawerForm} from '@ant-design/pro-components';
import api from "../../api/ApiConfig";
import {getUser, Loginout} from "../Users/AuthRouter";
import {object} from "bfj/src/events";
import {values} from "@antv/util";
import Text from "antd/es/typography/Text";
import Avatar from "antd/es/avatar/avatar";

const {Option} = Mentions;

export class AlertConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: props.datas ? props.datas : [],
            isModalOpen: false,
            PatrolConfig: {},
            userList: [],
            availabilityalertdatas: {},
        }
        this.form = React.createRef();
        this.userinfo = getUser()
    }

    componentDidMount() {
        this.getuserlist_atmp()
    }


    getavailabilityalert = async (params = {}) => {
        params['userid'] = this.userinfo.UserId
        params['username'] = this.userinfo.username
        params['AppType'] = this.props.AppType
        params['TaskType'] = this.props.TaskType
        await api.GetAvailabilityAlert(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({isModalOpen: true, availabilityalertdatas: r['datas']})
                console.log("当前告警配置信息", r['datas'])
            }
        })
    }

    postavailabilityalert = async (AlertValue, UserList, timeValue) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['AppType'] = this.props.AppType
        values['TaskType'] = this.props.TaskType
        values['AlertValue'] = AlertValue
        values['UserList'] = JSON.stringify(UserList)
        values['timeValue'] = timeValue
        await api.PostAvailabilityAlert({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("创建或更新成功", 1)
                this.setState({
                    isModalOpen: false,
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }
    // getuserlist_atmp = async () => {
    //     await api.GetUserList_ATMP({}).then(r => {
    //         if (r.msgcode === 20008) {
    //             Loginout()
    //             this.props.history.push('/login')
    //             window.location.reload()
    //         } else {
    //             // console.log("sad;lkasl;",r['datas'])
    //             // const RP = JSON.parse(r['datas'])
    //             let Related_Personnel_str = ""
    //             Object.entries(r['datas']).map(([key, value]) =>
    //                 // console.log(key,value)
    //                 // <Avatar key={index + key} src={value.avatar}/>
    //                 Related_Personnel_str += `@${value.name}(${value.userid}) `
    //             )
    //             this.setState({
    //                 Related_Personnel_str: Related_Personnel_str,
    //                 userList: r['datas']
    //             })
    //         }
    //     })
    // }
    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <Space size={5}>
                            <Avatar key={key} size={20} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 11, fontWeight: 600}}>{value.name}({value.user_id})</Text>
                        </Space>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }

    render() {
        const handleOk = () => {
            this.setState({
                isModalOpen: false,
            }, function () {
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false,
            }, function () {
            })
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 20,
                    offset: 4,
                },
            },
        };
        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24,
                },
                sm: {
                    span: 4,
                },
            },
            wrapperCol: {
                xs: {
                    span: 24,
                },
                sm: {
                    span: 20,
                },
            },
        };
        const onFinish = (values) => {
            console.log("告警配置完成", values)
            this.postavailabilityalert(values['alertValue'], values['UserList'], values['timeValue'])
        }
        return <Space>

            <FloatButton
                tooltip={<div>告警配置</div>}
                icon={<AlertOutlined/>}
                onClick={() => {
                    this.getavailabilityalert()
                }}
                style={{marginBottom: 13}}
            />
            <Modal title="系统可用性告警配置" open={this.state.isModalOpen}
                   footer={null} style={{minWidth: 800}}
                   onCancel={handleCancel} maskClosable={false}>
                <Space>
                    <div style={{marginTop: 40, marginLeft: 20}}>
                        <div style={{
                            // textAlign: 'center',
                            background: "rgba(0, 0, 0, 0.02)",
                            marginTop: 16,
                            marginBottom: 40,
                            borderRadius: "8px",
                            border: "1px dashed rgb(217, 217, 217)"
                        }}>

                            <Form
                                ref={this.form}
                                name="dynamic_form_item"
                                {...formItemLayoutWithOutLabel}
                                onFinish={onFinish}
                                style={{
                                    width: 670,
                                    marginTop: 40,
                                    marginLeft: 40
                                }}
                            >
                                <Space size={30} style={{marginLeft: 32}}>
                                    <Form.Item
                                        {...formItemLayout}
                                        label="告警阈值"
                                        name="alertValue"
                                        initialValue={Object.keys(this.state.availabilityalertdatas) !== 0 ? this.state.availabilityalertdatas.AlertValue : 0}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请设置告警阈值',
                                            }
                                        ]}
                                    >
                                        <Slider
                                            tooltip={{
                                                formatter: (value) => `当服务可用性低于【${value}%】时告警`,
                                            }}
                                            step={0.01}
                                            style={{width: 340, marginLeft: 20}}
                                            max={100}
                                            min={70}
                                            onChange={(value) => {
                                                this.setState({
                                                    InputNumberValue: value,
                                                    SliderValue: value,
                                                })
                                            }}
                                            marks={{
                                                70: {
                                                    style: {
                                                        color: '#ff4d4f',
                                                        fontSize: 12,
                                                    },
                                                    label: <strong>70%</strong>,
                                                },
                                                80: {
                                                    style: {
                                                        color: '#faad14',
                                                        fontSize: 12,
                                                    },
                                                    label: <strong>80%</strong>,
                                                },
                                                90: {
                                                    style: {
                                                        color: '#1487fa',
                                                        fontSize: 12,
                                                    },
                                                    label: <strong>90%</strong>,
                                                },
                                                100: {
                                                    style: {
                                                        color: '#52c41a',
                                                        fontSize: 12,
                                                    },
                                                    label: <strong>100%</strong>,
                                                },
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请设置告警阈值',
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            style={{
                                                width: 90,
                                            }}
                                            min={70}
                                            max={100}
                                            step="0.01"
                                            formatter={(value) => `${value}%`}
                                            defaultValue={Object.keys(this.state.availabilityalertdatas) !== 0 ? this.state.availabilityalertdatas.AlertValue : 0}
                                            value={this.state.SliderValue}
                                            onChange={(value) => {
                                                this.form.current.setFieldsValue({alertValue: value})
                                                this.setState({
                                                    InputNumberValue: value,
                                                    SliderValue: value,
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Space>
                                <Form.Item
                                    {...formItemLayout}
                                    label="告警间隔"
                                    name="timeValue"
                                    initialValue={Object.keys(this.state.availabilityalertdatas) !== 0 ? this.state.availabilityalertdatas.timeValue : 3}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择告警间隔',
                                        }
                                    ]}
                                >
                                    <Segmented
                                        defaultValue={Object.keys(this.state.availabilityalertdatas) !== 0 ? this.state.availabilityalertdatas.timeValue : 3}
                                        onChange={(value) => {
                                            this.form.current.setFieldsValue({timeValue: value})
                                        }
                                        } options={[
                                        {
                                            label: '10分钟',
                                            value: 0.167,
                                        },
                                        {
                                            label: '30分钟',
                                            value: 0.5,
                                        },
                                        {
                                            label: '1小时',
                                            value: 1,
                                        },
                                        {
                                            label: '2小时',
                                            value: 2,
                                        },
                                        {
                                            label: '3小时',
                                            value: 3,
                                        },
                                    ]}/>
                                    <div style={{
                                        marginLeft: 10,
                                        marginTop: 10,
                                        fontSize: 12,
                                        color: "#74ab7b"
                                    }}>Ps:指定时间内不重复发出告警
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    label="告警接收"
                                    name="UserList"
                                    initialValue={this.state.availabilityalertdatas.UserList !== undefined ?JSON.parse(this.state.availabilityalertdatas.UserList) : []}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择告警接收人',
                                        }
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        style={{width: '90%'}}
                                        placeholder="请选择人员"
                                        options={this.state.userList}
                                    />
                                </Form.Item>
                                <Form.Item style={{marginLeft: "30%", marginTop: 50}}>
                                    <Button type="primary" htmlType="submit">
                                        确认修改
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Space>
            </Modal>
        </Space>
    }
}