import React, {Component} from 'react';
import {Button, Row, Col, Form, Card, Input, Select, message, Space} from "antd";
import Modal from "antd/es/modal/Modal";
import {getUser, Loginout} from "../../Users/AuthRouter";
import api from "../../../api/ApiConfig";
import {SisternodeOutlined} from "@ant-design/icons";

const {TextArea} = Input;

export class CreateHelp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fun1: props.fun1
        }
        this.form = React.createRef();
    }

    userinfo = getUser()

    PostDocumentManagement = async (values) => {
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['DocType'] = JSON.stringify(values.DocType)
        console.log("创建文档", values)
        await api.PostDocumentManagement({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("创建成功")
                this.setState({
                    isModalOpen: false
                }, function () {
                    this.state.fun1()
                    this.form.current.resetFields();
                })

            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const showModal = () => {
            this.setState({
                isModalOpen: true
            })
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = (values) => {
            this.PostDocumentManagement(values)
        }
        return <>

            <Button type={"link"}
                    style={{width: 90, color: '#707272'}}
                    size={"small"}
                    onClick={() => {
                        showModal()
                    }}>
                <Space size={5}>
                    <SisternodeOutlined/>
                    <div style={{fontWeight: 800, fontSize: 12}}>创建文档</div>
                </Space>
            </Button>
            <Modal title="创建帮助文档"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{marginTop: 100, minWidth: 700}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 450,
                                marginTop: 30,
                                marginLeft: 50
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="DocTitle"
                                        label="文档标题"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入文档标题",
                                            }
                                        ]}
                                    >
                                        <Input showCount maxLength={20} placeholder={"请输入文档标题"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="DocNotes"
                                        label="文档说明"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入文档说明",
                                            }
                                        ]}
                                    >
                                        <Input.TextArea showCount rows={3} maxLength={50} placeholder={"请输入文档说明"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="DocType"
                                        label="文档类型"
                                        initialValue={["Appium", "TestFW"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择文档类型",
                                            }
                                        ]}
                                    >
                                        <Select
                                            mode="multiple"
                                            style={{textAlign: "center"}}
                                            showSearch
                                            placeholder="请选择文档类型"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            // onChange={(value) => {
                                            //     this.form.current.setFieldsValue({ProjectImageUrl: this.ProjectImageUrl[value]})
                                            // }}
                                            options={[
                                                {
                                                    value: "Tools",
                                                    label: '测试工具',
                                                },
                                                {
                                                    value: 'TestFW',
                                                    label: '测试框架',
                                                },
                                                {
                                                    value: 'Appium',
                                                    label: 'App自动化',
                                                },
                                                {
                                                    value: 'Selenium',
                                                    label: 'Web自动化',
                                                },
                                                {
                                                    value: 'Interface',
                                                    label: '接口自动化',
                                                },
                                                {
                                                    value: 'Devices',
                                                    label: '设备自动化',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="DocLink"
                                        label="详情链接"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                validator: (rule, value, callback) => {
                                                    const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+)(?:\/|$)/;
                                                    if (!domainRegex.test(value)) {
                                                        callback("请输入合法的跳转、下载链接");
                                                    } else {
                                                        callback();
                                                    }
                                                }
                                            },
                                        ]}
                                    >
                                        <Input showCount maxLength={1000} placeholder={"请输入合法的跳转、下载链接"}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item style={{marginTop: 30, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit">
                                    确认创建
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}