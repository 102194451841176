import React, {Component} from "react";
import {
    Affix,
    Button,
    Card,
    Col,
    Row,
    Divider,
    Select,
    Radio,
    Statistic,
    Table,
    Space,
    Avatar,
    Badge, message, Input, Tag, Pagination, Spin, Segmented, FloatButton, Watermark
} from "antd";
import {PageHeader} from "@ant-design/pro-layout";
import {
    AreaChartOutlined,
    BarChartOutlined,
    CloudDownloadOutlined,
    DotChartOutlined,
    FilterOutlined,
    FunnelPlotOutlined, GitlabOutlined,
    LineChartOutlined,
    RedoOutlined,
    StockOutlined,
    UserOutlined
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import {ColumnCharts} from "./ColumnCharts";
import {DualAxesCharts} from "./DualAxesCharts";
import {TestCaseBaseConfig} from "./TestCaseBaseConfig";
import {getUser, Loginout} from "../../Users/AuthRouter";
import api from "../../../api/ApiConfig";
import {PieCharts} from "./PieCharts";
import {TestCaseAllocation} from "./TestCaseAllocation";

export class Repository extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TestCases: [],
            Total: 0,
            page: 1,
            pageSize: 10,
            CaseType: "AppiumTest",
            CaseStatistics: {},
            CaseCoverageRate: {},
            ProjectList: [],
            ProjectValue: null,
            UserList: [],
            UserValue: null,
            CaseValue: "BLOCKER",
            QText: null,
            spinning: false,
            TableLoading: false,
            SelectedRowKeys: [],
            SelectedRows: [],
            QueryMode: "unique"
        }
    }

    componentDidMount() {
        this.getcasestatisticsdatas()
        this.getcasecoveragerate()
        this.getprojectlist()
        this.gettestcases()
    }

    userinfo = getUser()

    testcasesync = async () => {
        this.setState({
            spinning: true
        })
        let values = {}
        values['userid'] = this.userinfo.UserId
        values['ProjectName'] = this.state.CaseType
        await api.TestCaseSync({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("【" + this.state.CaseType + "】用例同步中,请稍等手动刷新页面～", 5)
                this.setState({
                    isModalOpen: false,
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.setState({
                spinning: false
            })
        })
    }
    AutoTestGitWebHook = async (params = {}) => {
        this.setState({
            spinning: true
        })
        params['userid'] = this.userinfo.UserId
        await api.AutoTestGitWebHook(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            this.setState({
                spinning: false
            })
        })
    }


    getcasestatisticsdatas = async (params = {}) => {
        this.setState({
            spinning: true
        })
        params['userid'] = this.userinfo.UserId
        await api.GetCaseStatisticsDatas(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    CaseStatistics: r['datas'],
                })
            }
            this.setState({
                spinning: false
            })
        })
    }

    getcasecoveragerate = async (params = {}) => {
        this.setState({
            spinning: true
        })
        params['userid'] = this.userinfo.UserId
        params['CaseType'] = this.state.CaseType
        await api.GetCaseCoverageRate(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    CaseCoverageRate: Object.keys(r['datas']).length > 0 ? r['datas'] : {},
                }, function () {
                })
            }
            this.setState({
                spinning: false
            })
        })
    }
    gettestcases = async (params = {}) => {
        this.setState({
            TableLoading: true
        })
        params['userid'] = this.userinfo.UserId
        params['CaseType'] = this.state.CaseType
        params['page'] = this.state.page
        params['pageSize'] = this.state.pageSize
        params['projectName'] = this.state.ProjectValue
        params['QueryMode'] = this.state.QueryMode
        if (this.state.CaseValue !== null) {
            params['markNmae'] = this.state.CaseValue
        }
        if (this.state.QText !== null) {
            params['QText'] = this.state.QText
        }
        params['userName'] = this.state.UserValue
        await api.GetTestCases(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    TestCases: typeof r['datas'] !== undefined ? r['datas'] : [],
                    Total: typeof r['Total'] !== undefined ? r['Total'] : 0
                }, function () {

                })
            }
            this.setState({
                TableLoading: false
            })
        })
    }
    getprojectlist = async (params = {}) => {
        params['userid'] = this.userinfo.UserId
        params['CaseType'] = this.state.CaseType
        await api.GetProjectList(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    ProjectList: r['datas'].projectList.length > 0 ? r['datas'].projectList : [],
                    ProjectValue: r['datas'].projectList.length > 0 ? r['datas'].projectList[0].value : null,
                    UserList: r['datas'].userList.length > 0 ? r['datas'].userList : [],
                    UserValue: r['datas'].userList.length > 0 ? r['datas'].userList[0].value : null
                }, function () {
                    this.gettestcases()
                })
            }
            this.setState({
                spinning: false
            })
        })
    }
    onSelectChange = (newSelectedRowKeys, selectedRows) => {
        this.setState({SelectedRowKeys: newSelectedRowKeys, SelectedRows: selectedRows});
    };

    render() {
        const columns = [
            {
                title: 'No.',
                key: '1',
                fixed: 'left',
                width: 60,
                align: 'center',
                render: (record, text, index) => {
                    return (index + 1)
                }
            },
            {
                title: '用例描述',
                dataIndex: 'func_doc',
                align: 'center',
                ellipsis: true,
                width: 400,
                render: (record, text, index) => {
                    return <div style={{
                        // maxWidth: '300px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textAlign: "left",
                        fontSize: 13,
                        color: "rgb(12,12,12)"
                    }}>「{record}」</div>
                }
            },
            {
                title: '用例信息',
                align: 'center',
                maxWidth: 300,
                ellipsis: true,
                // dataIndex: 'func_name',
                render: (record, text, index) => {
                    return <div style={{
                        // maxWidth: 500,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textAlign: "left",
                        fontSize: 11,
                        color: "rgb(33,105,105)"
                    }}>
                        <div>文件路径：{text.file_path}</div>
                        <div>用例类名：{text.class_name}</div>
                        <div>用例方法：{text.func_name}</div>
                    </div>
                }
            },
            {
                title: '用例作者',
                dataIndex: 'user_name',
                align: 'center',
                width: 170,
                render: (record, text, index) => {
                    return <Space style={{fontSize: 13}}>
                        <Avatar shape="square"
                                size={28}
                                style={{
                                    backgroundColor: '#024605',
                                }}
                                icon={<UserOutlined/>}
                        >
                        </Avatar>
                        {record.split("@")[1]}
                    </Space>
                }
            },
            {
                title: '用例等级',
                dataIndex: 'case_mark',
                align: 'center',
                width: 140,
                render: (record, text, index) => {
                    return <div style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: 13,
                        color: "rgb(112,114,114)"
                    }}>
                        <Tag color={"geekblue"}>{record}</Tag>
                    </div>
                }
            },
            {
                title: '数据驱动',
                align: 'center',
                dataIndex: 'test_datas',
                maxWidth: 500,
                render: (record, text, index) => {
                    return <div>
                        {Array.isArray(record) && record.length > 0
                            ? record.map((s, index) => {
                                let str_1 = "";
                                try {
                                    // 可能会引发异常的代码块
                                    if (typeof s === 'object') {
                                        str_1 = JSON.stringify(s);
                                    } else {
                                        str_1 = s;
                                    }
                                } catch (error) {
                                    // 异常发生时执行的代码块
                                    str_1 = JSON.stringify(s);
                                }
                                return <div style={{
                                    maxWidth: 500,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    textAlign: "left",
                                    fontSize: 11,
                                    color: "rgb(112,114,114)",
                                }}>
                                    {index === 0 ? "用例编号" :
                                        index === 1 ? "用例名称" :
                                            index === 2 ? "测试数据" :
                                                index === 3 ? "期望结果" : "未知参数"}: {str_1}
                                </div>;
                            })
                            : "-"}
                    </div>
                }
            },
        ];
        const {SelectedRowKeys} = this.state;
        const rowSelection = {
            preserveSelectedRowKeys: true,
            selectedRowKeys: SelectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = SelectedRowKeys.length > 0
        return <>
            <Affix offsetTop={63}>
                <div style={{
                    background: '#ffffff',
                    textAlign: 'center',
                }}>
                    <PageHeader
                        className="site-page-header-responsive"
                        title="自动化测试"
                        subTitle={"用例仓库"}
                        onBack={() => window.history.back()}
                        extra={<>
                            <Divider type="vertical"/>
                            <Button type={'link'} style={{width: 100, color: '#707272'}}
                                    icon={<RedoOutlined/>}
                                    onClick={() => {
                                        this.setState({
                                            SelectedRowKeys: [],
                                            SelectedRows: [],
                                        })
                                        this.getcasestatisticsdatas()
                                        this.getcasecoveragerate()
                                        this.getprojectlist()
                                    }}>
                                刷新数据
                            </Button>
                            <Divider type="vertical"/>
                            <Button type={'link'} style={{color: '#707272'}}
                                    icon={<GitlabOutlined/>}
                                    onClick={() => {
                                        this.AutoTestGitWebHook()
                                    }}>
                                更新仓库(git pull)
                            </Button>
                            <Divider type="vertical"/>
                        </>
                        }
                    >
                        <Content/>
                    </PageHeader>
                </div>
            </Affix>
            <div style={{marginTop: 3, background: 'rgb(240, 242, 245)', minHeight: 763}}>
                <Watermark content="公牛Bull">
                    <Spin spinning={this.state.spinning} tip="数据加载中...">
                        <div>
                            <div style={{margin: 20}}>
                                <Row gutter={[10, 10]}>
                                    <Col span={4}>
                                        <Card bordered={false}>
                                            <Statistic
                                                title="自动化用例总数"
                                                value={Object.keys(this.state.CaseStatistics).length > 0 ? this.state.CaseStatistics.TestCasesTotal : 0}
                                                valueStyle={{
                                                    color: '#3f8600',
                                                    marginLeft: 10,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><AreaChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card bordered={false}>
                                            <Statistic
                                                title="接口自动化用例数"
                                                value={Object.keys(this.state.CaseStatistics).length > 0 ? this.state.CaseStatistics.Interface : 0}
                                                valueStyle={{
                                                    color: '#63296b',
                                                    marginLeft: 10,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><LineChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card bordered={false}>
                                            <Statistic
                                                title="设备自动化用例数"
                                                value={Object.keys(this.state.CaseStatistics).length > 0 ? this.state.CaseStatistics.Devices : 0}
                                                valueStyle={{
                                                    color: '#0c0c0c',
                                                    marginLeft: 10,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><DotChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card bordered={false}>
                                            <Statistic
                                                title="App自动化用例数"
                                                value={Object.keys(this.state.CaseStatistics).length > 0 ? this.state.CaseStatistics.AppiumTest : 0}
                                                valueStyle={{
                                                    color: '#004a86',
                                                    marginLeft: 10,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><StockOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card bordered={false}>
                                            <Statistic
                                                title="Web自动化用例数"
                                                value={Object.keys(this.state.CaseStatistics).length > 0 ? this.state.CaseStatistics.SeleniumTest : 0}
                                                valueStyle={{
                                                    color: '#6342c9',
                                                    marginLeft: 10,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><BarChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card bordered={false}>
                                            <Statistic
                                                title="自动化测试参与人数"
                                                value={Object.keys(this.state.CaseStatistics).length > 0 ? this.state.CaseStatistics.UserListTotal : 0}
                                                valueStyle={{
                                                    color: '#de1147',
                                                    marginLeft: 10,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><UserOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={14}>
                                        <Card bordered={true}
                                              title={"自动化用例「人员分布」"}
                                              style={{marginLeft: 0, marginTop: 0, borderRadius: 0}}>
                                            <ColumnCharts
                                                datas={Object.keys(this.state.CaseStatistics).length > 0 ? this.state.CaseStatistics.UserList : []}
                                                color="#4096ff"/>
                                        </Card>
                                    </Col>
                                    <Col span={10}>
                                        <Card bordered={true} title={"自动化用例「项目分布」"}
                                              style={{marginLeft: 0, marginTop: 0, borderRadius: 0}}>
                                            <ColumnCharts
                                                datas={Object.keys(this.state.CaseStatistics).length > 0 ? this.state.CaseStatistics.ProjectList : []}
                                                color="#4096ff"/>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{background: 'rgb(259,259,259)', height: 20, marginTop: 30}}>
                                <div style={{
                                    // width: 120,
                                    position: "absolute",
                                    marginTop: "-14px",
                                    marginLeft: "20px",
                                    background: "#ffffff",
                                    borderRadius: "6px 6px 0 0",
                                    fontSize: 14,
                                    textAlign: 'center',
                                    padding: "3px 10px  0px 10px",
                                }}>
                                    <Space size={2}>
                                        <AreaChartOutlined/>
                                        <b>
                                            数据概览
                                        </b>
                                    </Space>
                                </div>
                            </div>
                            <Row gutter={[3, 3]} style={{marginTop: 0}}>
                                <Col span={24}>
                                    <Card bordered={false} style={{borderRadius: 0}}>
                                        <Row gutter={[3, 3]} style={{marginTop: 0}}>
                                            <Col span={24}>
                                                <Card bordered={true}
                                                      extra={
                                                          <Space>
                                                              <Button type={"link"}
                                                                      style={{
                                                                          width: 100,
                                                                          color: '#707272',
                                                                          marginLeft: 40
                                                                      }}
                                                                      onClick={() => {
                                                                          this.testcasesync()
                                                                      }}
                                                              >
                                                                  <Space>
                                                                      <CloudDownloadOutlined/>
                                                                      <div style={{marginTop: -2}}> 用例同步</div>
                                                                  </Space>
                                                              </Button>
                                                              <Divider type="vertical"/>
                                                              <TestCaseBaseConfig datas={[]}/>
                                                          </Space>
                                                      }
                                                      style={{marginLeft: 0, marginTop: 20, borderRadius: 0}}>
                                                    <div>
                                                        <Divider>
                                                            <Space>
                                                                <Radio.Group
                                                                    onChange={(value) => {
                                                                        this.setState({
                                                                            CaseType: value.target.value,
                                                                            TestCases: [],
                                                                            Total: 0,
                                                                            page: 1,
                                                                            pageSize: 10,
                                                                            SelectedRowKeys: [],
                                                                            SelectedRows: [],
                                                                        }, function () {
                                                                            this.getcasecoveragerate()
                                                                            this.getprojectlist()
                                                                        })
                                                                    }}
                                                                    value={this.state.CaseType}
                                                                    style={{
                                                                        marginBottom: 20,
                                                                    }}
                                                                >
                                                                    <Radio.Button value="Interface">
                                                                        <Avatar size={30}
                                                                                src={"https://gn-ota.oss-cn-shanghai.aliyuncs.com/zhangfan/%E8%B5%84%E6%BA%90/pythonIcon.webp"}/>
                                                                        <b>接口自动化用例</b>
                                                                    </Radio.Button>
                                                                    <Radio.Button value="Devices">
                                                                        <Avatar size={30}
                                                                                src={"https://saas-product-image.oss-cn-shanghai.aliyuncs.com/app2_0/%E4%BA%A7%E5%93%81%E5%9B%BE%E7%89%87/177.png"}/>
                                                                        <b>设备自动化用例</b>
                                                                    </Radio.Button>
                                                                    <Radio.Button value="AppiumTest">
                                                                        <Avatar size={25}
                                                                                src={"https://gn-ota.oss-cn-shanghai.aliyuncs.com/zhangfan/%E8%B5%84%E6%BA%90/AppiumIcon.png"}/>
                                                                        <b>App自动化用例</b>
                                                                    </Radio.Button>
                                                                    <Radio.Button value="SeleniumTest">
                                                                        <Avatar size={33}
                                                                                src={"https://gn-ota.oss-cn-shanghai.aliyuncs.com/zhangfan/%E8%B5%84%E6%BA%90/%E8%B0%B7%E6%AD%8CIcon.jpeg"}/>
                                                                        <b>Web自动化用例</b>
                                                                    </Radio.Button>
                                                                </Radio.Group>
                                                            </Space>
                                                        </Divider>
                                                        <Card bordered={false}
                                                              style={{
                                                                  marginLeft: 0,
                                                                  marginTop: 10,
                                                                  marginBottom: 30,
                                                                  borderRadius: 10
                                                              }}>
                                                            <Row gutter={[20, 20]}>
                                                                <Col span={12}>
                                                                    <Card title={"自动化用例「覆盖率」"}>
                                                                        <DualAxesCharts
                                                                            datas={Object.keys(this.state.CaseCoverageRate).length > 0 ?
                                                                                this.state.CaseCoverageRate : {}}/>
                                                                    </Card>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Card title={"自动化用例「贡献人」"}>
                                                                        <PieCharts
                                                                            datas={Object.keys(this.state.CaseCoverageRate).length > 0 ?
                                                                                this.state.CaseCoverageRate : {}}/>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </div>
                                                    <Badge.Ribbon text="自动化用例仓库">
                                                        <div>
                                                            <Table
                                                                scroll={{
                                                                    y: 1000,
                                                                }}
                                                                rowSelection={this.state.QueryMode === "unique" ? rowSelection : null}
                                                                loading={this.state.TableLoading}
                                                                bordered={true}
                                                                columns={columns}
                                                                dataSource={this.state.TestCases}
                                                                pagination={false}
                                                                size={"small"}
                                                                title={() => {
                                                                    return <>
                                                                        <Space style={{marginLeft: 20, marginTop: 20}}>
                                                                            <Segmented
                                                                                onChange={(value) => {
                                                                                    this.setState({
                                                                                        page: 1,
                                                                                        QueryMode: value,
                                                                                        SelectedRowKeys: [],
                                                                                        SelectedRows: [],
                                                                                        QText: null
                                                                                    }, function () {
                                                                                        this.gettestcases()
                                                                                        console.log("当前模式", this.state.QueryMode)
                                                                                    })
                                                                                }}
                                                                                value={this.state.QueryMode}
                                                                                options={[
                                                                                    {
                                                                                        label: '正常模式',
                                                                                        value: "normal",
                                                                                        icon: <FilterOutlined/>
                                                                                    },
                                                                                    {
                                                                                        label: '去重模式',
                                                                                        value: "unique",
                                                                                        icon: <FunnelPlotOutlined/>

                                                                                    },

                                                                                ]}/>
                                                                            <div style={{
                                                                                fontSize: 11,
                                                                                color: "#8f8f8f"
                                                                            }}> 正常模式：数据驱动「展开」、去重模式：数据驱动「收起」
                                                                            </div>
                                                                        </Space>
                                                                        <div>
                                                                            <Space wrap={true}
                                                                                   style={{
                                                                                       textAlign: "center",
                                                                                       padding: 20
                                                                                   }}
                                                                                   size={10}>
                                                                                <Select
                                                                                    style={{width: 150}}
                                                                                    showSearch
                                                                                    placeholder={"请选择用例分类"}
                                                                                    optionFilterProp="children"
                                                                                    onChange={(value) => {
                                                                                        this.setState({
                                                                                            ProjectValue: value
                                                                                        })
                                                                                    }}
                                                                                    filterOption={(input, option) =>
                                                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                                                    value={this.state.ProjectValue}
                                                                                    options={
                                                                                        this.state.ProjectList
                                                                                    }
                                                                                />
                                                                                <Select
                                                                                    style={{width: 190}}
                                                                                    showSearch
                                                                                    placeholder="请选择用例作者"
                                                                                    optionFilterProp="children"
                                                                                    onChange={(value) => {
                                                                                        this.setState({
                                                                                            UserValue: value
                                                                                        })
                                                                                    }}
                                                                                    onSearch={(value) => {

                                                                                    }}
                                                                                    filterOption={(input, option) =>
                                                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                                                    value={this.state.UserValue}
                                                                                    options={this.state.UserList}
                                                                                />
                                                                                <Select
                                                                                    style={{width: 120}}
                                                                                    showSearch
                                                                                    placeholder="请选择用例等级"
                                                                                    optionFilterProp="children"
                                                                                    onChange={(value) => {
                                                                                        this.setState({
                                                                                            CaseValue: value
                                                                                        })
                                                                                    }}
                                                                                    onSearch={(value) => {

                                                                                    }}
                                                                                    filterOption={(input, option) =>
                                                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                                                    value={this.state.CaseValue}
                                                                                    options={[
                                                                                        {
                                                                                            value: null,
                                                                                            label: '所有等级',
                                                                                        },
                                                                                        {
                                                                                            value: 'BLOCKER',
                                                                                            label: 'BLOCKER',
                                                                                        },
                                                                                        {
                                                                                            value: 'CRITICAL',
                                                                                            label: 'CRITICAL',
                                                                                        },
                                                                                        {
                                                                                            value: 'NORMAL',
                                                                                            label: 'NORMAL',
                                                                                        },
                                                                                        {
                                                                                            value: 'MINOR',
                                                                                            label: 'MINOR',
                                                                                        },
                                                                                        {
                                                                                            value: 'TRIVIAL',
                                                                                            label: 'TRIVIAL',
                                                                                        },
                                                                                        {
                                                                                            value: 'STABILITY',
                                                                                            label: 'STABILITY',
                                                                                        },
                                                                                        {
                                                                                            value: 'DEBUG',
                                                                                            label: 'DEBUG',
                                                                                        },
                                                                                        {
                                                                                            value: 'UNKNOWN',
                                                                                            label: 'UNKNOWN',
                                                                                        },
                                                                                    ]}
                                                                                />
                                                                                <Input style={{width: 250}}
                                                                                       value={this.state.QText}
                                                                                       placeholder="请输入类、方法、描述"
                                                                                       onChange={(e) => {
                                                                                           this.setState({
                                                                                               QText: e.target.value
                                                                                           })
                                                                                       }}
                                                                                />

                                                                                <Button type={"default"} size={"middle"}
                                                                                        onClick={() => {
                                                                                            console.log("当前搜索条件", this.state.ProjectValue, this.state.UserValue, this.state.CaseValue)
                                                                                            this.setState({
                                                                                                page: 1,
                                                                                                SelectedRowKeys: [],
                                                                                                SelectedRows: [],
                                                                                            }, function () {
                                                                                                this.gettestcases()
                                                                                            })

                                                                                        }}>查询</Button>
                                                                                <Button type={"primary"} size={"middle"}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                ProjectValue: null,
                                                                                                UserValue: null,
                                                                                                CaseValue: "BLOCKER",
                                                                                                QText: null,
                                                                                                SelectedRowKeys: [],
                                                                                                SelectedRows: [],
                                                                                            }, function () {
                                                                                                this.getprojectlist()
                                                                                            })

                                                                                        }}>
                                                                                    重置
                                                                                </Button>
                                                                                {/*<Button type={"primary"}*/}
                                                                                {/*        danger*/}
                                                                                {/*        size={"middle"}*/}
                                                                                {/*        disabled={this.state.QueryMode !== "unique"}*/}
                                                                                {/*        onClick={() => {*/}
                                                                                {/*            console.log("已选择用例", this.state.SelectedRows)*/}
                                                                                {/*            for (let i = 0; i <= this.state.SelectedRows.length; i++) {*/}
                                                                                {/*                console.log("用例名称:", this.state.SelectedRows[i].file_path +*/}
                                                                                {/*                    "::" + this.state.SelectedRows[i].class_name +*/}
                                                                                {/*                    "::" + this.state.SelectedRows[i].func_name*/}
                                                                                {/*                )*/}
                                                                                {/*            }*/}
                                                                                {/*        }}*/}
                                                                                {/*>*/}
                                                                                {/*    分配*/}
                                                                                {/*</Button>*/}
                                                                                <TestCaseAllocation
                                                                                    datas={this.state.SelectedRows}
                                                                                    btn_status={this.state.QueryMode}/>
                                                                                <Button type={"primary"}
                                                                                        disabled={!hasSelected}
                                                                                        size={"middle"}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                SelectedRowKeys: [],
                                                                                                SelectedRows: [],
                                                                                            })
                                                                                        }}
                                                                                >
                                                                                    清除已选
                                                                                </Button>
                                                                                <div style={{
                                                                                    fontSize: 12,
                                                                                    color: "#707272",
                                                                                    marginTop: 5
                                                                                }}>{hasSelected ? `已选择 ${this.state.SelectedRowKeys.length} 条用例` : ''}</div>
                                                                            </Space>
                                                                        </div>
                                                                    </>
                                                                }}/>
                                                            <Pagination
                                                                style={{textAlign: "center", marginTop: 30}}
                                                                position={["bottomCenter"]}
                                                                pageSizeOptions={[10, 100]}
                                                                size={"default"}
                                                                showSizeChanger={true}
                                                                showTotal={(total, range) => {
                                                                    return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                                                                }}
                                                                total={this.state.Total}
                                                                defaultPageSize={this.state.pageSize}
                                                                defaultCurrent={this.state.page}
                                                                current={this.state.page}
                                                                pageSize={this.state.pageSize}
                                                                onShowSizeChange={(current, size) => {
                                                                    this.setState({
                                                                        page: current,
                                                                        pageSize: size
                                                                    }, function () {
                                                                        this.gettestcases()
                                                                    })
                                                                }}
                                                                onChange={(page, pageSize) => {
                                                                    this.setState({
                                                                        page: page,
                                                                        pageSize: pageSize
                                                                    }, function () {
                                                                        this.gettestcases()
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </Badge.Ribbon>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <FloatButton.BackTop/>
                        </div>
                    </Spin>
                </Watermark>
            </div>
        </>
    }
}
