import React, {Component} from "react";
import {
    Affix, Card, Space, Tabs, Watermark,
} from "antd";

import {PageHeader} from "@ant-design/pro-layout";
import {
    AndroidOutlined, ChromeOutlined,
    CloudServerOutlined,
    GatewayOutlined,
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import {CsProjectConfig} from "./CsProjectConfig";
import {AppProjectConfig} from "./AppSubmitTest/AppProjectConfig";
import {FirmwareProjectConfig} from "./FirmwareSubmitTest/FirmwareProjectConfig";

export class ProjectConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {

    }


    render() {
        return <>
            <Affix offsetTop={60}>
                <div style={{
                    background: '#ffffff',
                    textAlign: 'center',
                }}>
                    <PageHeader
                        className="site-page-header-responsive"
                        title="任务配置"
                        subTitle={"版本提测任务配置"}
                        onBack={() => window.history.back()}
                    >
                        <Content/>
                    </PageHeader>
                </div>
            </Affix>
            <div style={{background: 'rgb(240, 242, 245)', minHeight: "80vh"}}>
                <Watermark content="公牛Bull">
                    <Card style={{minHeight: '80vh'}}>
                        <Tabs
                            defaultActiveKey={3}
                            type="card"
                            items={[
                                {
                                    label: <Space>
                                        <CloudServerOutlined/>
                                        <div style={{marginLeft: -15, fontSize: 13, fontWeight: 700}}>
                                            云服务
                                        </div>
                                    </Space>,
                                    key: 1,
                                    children: <CsProjectConfig/>
                                },
                                {
                                    label: <Space>
                                        <AndroidOutlined/>
                                        <div style={{marginLeft: -15, fontSize: 13, fontWeight: 700}}>
                                            APP
                                        </div>
                                    </Space>,
                                    key: 2,
                                    children: <AppProjectConfig/>
                                },
                                {
                                    label: <Space>
                                        <GatewayOutlined/>
                                        <div style={{marginLeft: -15, fontSize: 13, fontWeight: 700}}>
                                            固件
                                        </div>
                                    </Space>,
                                    key: 3,
                                    children: <FirmwareProjectConfig/>
                                },
                                {
                                    label: <Space>
                                        <ChromeOutlined/>
                                        <div style={{marginLeft: -15, fontSize: 13, fontWeight: 700}}>
                                            WEB
                                        </div>
                                    </Space>,
                                    key: 4,
                                    children: <Card/>
                                }
                            ]}
                        />
                    </Card>
                </Watermark>
            </div>
        </>
    }
}
