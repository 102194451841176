import React, {Component} from 'react';
import {Empty} from "antd";
import {Pie, G2} from '@ant-design/plots';

export class PieCharts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }

    render() {
        const G = G2.getEngine('canvas');
        const config = {
            appendPadding: 10,
            data: this.state.datas.l3,
            angleField: 'value',
            colorField: 'type',
            height: 300,
            radius: 0.75,
            legend: false,
            label: {
                type: 'spider',
                labelHeight: 40,
                formatter: (data, mappingData) => {
                    const group = new G.Group({});
                    group.addShape({
                        type: 'circle',
                        attrs: {
                            x: 0,
                            y: 0,
                            width: 40,
                            height: 50,
                            r: 5,
                            fill: mappingData.color,
                        },
                    });
                    group.addShape({
                        type: 'text',
                        attrs: {
                            x: 10,
                            y: 8,
                            text: `${data.type}`,
                            fill: mappingData.color,
                        },
                    });
                    group.addShape({
                        type: 'text',
                        attrs: {
                            x: 0,
                            y: 25,
                            text: `${data.value}条 占比 ${(data.percent * 100).toFixed(2)}%`,
                            fill: 'rgba(0, 0, 0, 0.65)',
                            fontWeight: 700,
                        },
                    });
                    return group;
                },
            },
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
            ],
        };
        return <> {Object.keys(this.state.datas).length > 0 ?
            <Pie {...config} /> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </>
    }
}