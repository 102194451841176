import React, {Component} from 'react';
import {Button, Empty, message, Popconfirm, Space, Table, Modal, List, Avatar, Badge, Card} from "antd";
import {BarsOutlined, DeleteOutlined} from "@ant-design/icons";
import {getUser, Loginout} from "../Users/AuthRouter";
import api from "../../api/ApiConfig";
import "./DatePicker.css"
import {mg_categories, zj_categories} from '../../api/categoriesConfig'

export class DevicesOffLineTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            apptype: props.apptype,
            datas: props.datas ? props.datas.slice(0, 200) : [],
            ShowModal: false,
            detailsList: [],
            fun1: props.fun1,
            fun2: props.fun2,
            fun3: props.fun3,
            fun4: props.fun4
        }
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                apptype: this.props.apptype,
                datas: this.props.datas.slice(0, 200),
                fun1: this.props.fun1,
                fun2: this.props.fun2,
                fun3: this.props.fun3,
                fun4: this.props.fun4
            })
        }
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     // 比较新的属性和状态与当前的属性和状态是否相等
    //     return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
    //         JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    // }

    deldeviceinterface = async (DeviceAvailabilityId, DeviceInterfaceId) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['DeviceAvailabilityId'] = DeviceAvailabilityId
        values['DeviceInterfaceId'] = DeviceInterfaceId
        console.log("sa;dlk", values)
        await api.delDeviceInterface({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("删除成功!")
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.state.fun1()
            this.state.fun2({DatasType: 2})
            this.state.fun3()
            this.state.fun4()
        })
    }

    render() {
        const columns = [
            {
                title: '巡检时间',
                dataIndex: 'DatasDate',
                key: 'DatasDate',
                align: 'center',
            },
            {
                title: '设备在线率',
                dataIndex: 'OnLineRate',
                key: 'OnLineRate',
                align: 'center',
                render: ((text) => {
                    return <>{text}%</>
                })
            },
            {
                title: '设备总数',
                align: 'center',
                dataIndex: 'DeviceTotal',
                key: 'DeviceTotal',
            },
            {
                title: '在线总数',
                align: 'center',
                dataIndex: 'OnLineTotal',
                key: 'OnLineTotal',
            },
            {
                title: '离线总数',
                dataIndex: 'OffLineTotal',
                key: 'OffLineTotal',
                align: 'center',
            },
            // {
            //     title: '任务ID',
            //     width: 100,
            //     dataIndex: 'key',
            //     key: 'key',
            //     align: 'center',
            // },
            {
                title: '操作数据',
                fixed: 'right',
                width: 40,
                align: 'center',
                render: ((text, re) => (
                    <Space>
                        <Button type="link" size={"small"} icon={<BarsOutlined/>}
                                onClick={() => {
                                    let details_list = []
                                    try {
                                        details_list = JSON.parse(re.details.replace(/'/g, "\""))
                                        this.setState({
                                            ShowModal: true,
                                            detailsList: details_list
                                        }, function () {
                                        })
                                    } catch (e) {
                                        console.log("异常数据不解析", e)
                                        return <div>数据格式有异常,暂不解析</div>
                                    }
                                }}>
                            详情
                        </Button>
                        <Popconfirm
                            title="删除数据"
                            description="您确定要删除这条数据?"
                            onConfirm={() => {
                                this.deldeviceinterface(re.DeviceAvailability_id, re.id)
                            }}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button
                                type="link" danger
                                size={"small"}
                                icon={<DeleteOutlined/>}
                            >删除</Button>
                        </Popconfirm>
                    </Space>

                ))
            },
        ]
        return <>
            {this.state.datas.length !== 0 ?
                <>
                    <Modal
                        centered
                        title="设备在离线详情"
                        open={this.state.ShowModal}
                        onOk={() => {
                            this.setState({
                                ShowModal: false
                            })
                        }}
                        onCancel={() => {
                            this.setState({
                                ShowModal: false
                            })
                        }}
                        style={{minWidth: 800}}
                    >
                        <Card style={{margin: 20}}>
                            <List style={{marginLeft: 20}}>
                                <div className="scroll-container" style={{height: 500, overflow: "auto"}}>
                                    {this.state.detailsList.map((item) => {
                                            let image = ""
                                            try {
                                                // image = mg_categories[item.globalProductId]['image']
                                                if (this.state.apptype === "mg") {
                                                    image = mg_categories[item.globalProductId]['image']
                                                }
                                                if (this.state.apptype === "zj") {
                                                    image = zj_categories[item.globalProductId]['image']
                                                }
                                            } catch (e) {

                                            }
                                            return <List.Item key={item.deviceName}>
                                                <List.Item.Meta
                                                    avatar={<Avatar shape="square" size={40}
                                                                    src={image}/>}
                                                />
                                                <List.Item.Meta
                                                    title={"设备DN"}
                                                    description={item.deviceName}
                                                    style={{marginLeft: -40}}
                                                />
                                                <List.Item.Meta
                                                    title={"设备名称"}
                                                    description={item.purposeName}
                                                />
                                                <List.Item.Meta
                                                    title={"设备PID"}
                                                    description={item.globalProductId}
                                                />
                                                <List.Item.Meta
                                                    title={"设备状态"}
                                                    description={
                                                        <div style={{fontSize: 12, marginLeft: 0}}>
                                                            {item.status === "OFFLINE" ?
                                                                <span> <Badge
                                                                    status="error"/> 离线 </span> :
                                                                item.status === "ONLINE" ?
                                                                    <span> <Badge status="processing"/> 在线 </span>
                                                                    : <span> <Badge
                                                                        status="Warning"/> 离线 </span>}
                                                        </div>
                                                    }
                                                />
                                            </List.Item>
                                        }
                                    )
                                    }
                                </div>
                            </List>
                        </Card>
                    </Modal>
                    <Table dataSource={this.state.datas}
                           columns={columns}
                        // scroll={{
                        //     x: 1600,
                        // }}
                           bordered
                    />
                </> :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </>
    }
}
