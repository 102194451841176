import {G2, Line} from '@ant-design/plots';
import React from "react";
import {Empty, Watermark} from "antd";
import Text from "antd/es/typography/Text";
import moment from 'moment';

export default class LineChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            line_datas: props.line_datas ? props.line_datas : [],
            height: props.height
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            line_datas: nextProps.line_datas,
            height: nextProps.height
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(this.props.line_datas === nextProps.line_datas && this.state.line_datas === nextState.line_datas);
    }

    //获取十六进制颜色

    formatTimestamp = (timestamp, format) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return format
            .replace('YYYY', year)
            .replace('MM', month)
            .replace('DD', day)
            .replace('HH', hours)
            .replace('mm', minutes)
            .replace('ss', seconds);

    }

    render() {
        G2.registerShape('point', 'custom-point', {
            draw(cfg, container) {
                const point = {x: cfg.x, y: cfg.y};
                const group = container.addGroup();
                const __GetColor = () => {
                    let r = Math.floor(Math.random() * 256);
                    let g = Math.floor(Math.random() * 256);
                    let b = Math.floor(Math.random() * 256);

                    if (r < 16) {//当小于16时，只有一位数，所以给它前面串联一个0
                        r = "0" + r.toString(16);
                    } else {
                        r = r.toString(16);
                    }
                    if (g < 16) {
                        g = "0" + g.toString(16);
                    } else {
                        g = g.toString(16);
                    }
                    if (b < 16) {
                        b = "0" + b.toString(16);
                    } else {
                        b = b.toString(16);
                    }
                    return "#" + r + g + b;
                }
                const decorator1 = group.addShape('circle', {
                    attrs: {
                        x: point.x,
                        y: point.y,
                        r: 2,
                        fill: __GetColor(),
                        opacity: 0.5,
                    },
                });
                const decorator2 = group.addShape('circle', {
                    attrs: {
                        x: point.x,
                        y: point.y,
                        r: 2,
                        fill: __GetColor(),
                        opacity: 0.5,
                    },
                });
                const decorator3 = group.addShape('circle', {
                    attrs: {
                        x: point.x,
                        y: point.y,
                        r: 1,
                        fill: __GetColor(),
                        opacity: 0.5,
                    },
                });
                decorator1.animate(
                    {
                        r: 2,
                        opacity: 0,
                    },
                    {
                        duration: 1800,
                        easing: 'easeLinear',
                        repeat: true,
                    }
                );
                decorator2.animate(
                    {
                        r: 2,
                        opacity: 0,
                    },
                    {
                        duration: 1800,
                        easing: 'easeLinear',
                        repeat: true,
                        delay: 1600,
                    }
                );
                decorator3.animate(
                    {
                        r: 2,
                        opacity: 0,
                    },
                    {
                        duration: 1800,
                        easing: 'easeLinear',
                        repeat: true,
                        delay: 1200,
                    }
                );
                group.addShape('circle', {
                    attrs: {
                        x: point.x,
                        y: point.y,
                        r: 2,
                        fill: cfg.color,
                        opacity: 1.7,
                    },
                });
                group.addShape('circle', {
                    attrs: {
                        x: point.x,
                        y: point.y,
                        r: 0.5,
                        fill: cfg.color,
                    },
                });
                return group;
            },
        });
        const config = {
            data: this.state.line_datas,
            xField: 'a',
            yField: 'b',
            height: 200,
            label: {
                formatter: (datum) => {
                    return datum.b;
                },
            },
            xAxis: {
                tickCount: 2,
                label: {
                    formatter: (value) => {
                        return moment(parseInt(value)).format("YYYY-MM-DD HH:mm:ss")
                    },
                },
            },
            // slider: {
            //     start: 0,
            //     end: 1,
            // },
            point: {
                size: 1,
                shape: 'custom-point',
            },
            // seriesField: 'b',
            tooltip: {
                formatter: (datum) => {
                    return {
                        title: moment(parseInt(datum.a)).format("YYYY-MM-DD HH:mm:ss"),
                        name: "value",
                        value: datum.b
                    };
                },
            },
            lineStyle: {
                lineWidth: 1,
            },
            animation: {
                appear: {
                    animation: 'wave-in',
                    duration: 2000,
                    offset: 20
                },
            },
        }
        return this.state.line_datas.length !== 0 ?
            <Watermark content="公牛Bull">
                <Line {...config} />
            </Watermark> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    }

};