import React, {Component} from 'react';
import {G2, Line} from '@ant-design/plots';
import moment from "moment";
import insertCss from "insert-css";
import {Empty, Watermark} from "antd";

export class DevicesLineChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas ? props.datas : [],
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }

    render() {
        insertCss(`
            .custom-tooltip-title {
                margin: 0px 12px;
                font-size: 12px;
                border-bottom-style: solid;
                border-bottom-width: thin;
                border-bottom-color: #e9e9e9;
            }
        
            .custom-tooltip-value {
              display: grid;
              grid-template-columns: 1fr 1fr;
              margin: 8px 12px 0 12px;
              padding-bottom: 1px;
              font-size: 10px;
              text-align: center;
              border-bottom-style: solid;
              border-bottom-width: thin;
              border-bottom-color: #e9e9e9;
              height: 70px;
            }
        
            .custom-tooltip-temp {
              display: flex;
              position: relative;
              align-items: center;
            }
        
            .custom-tooltip-temp span:first-child {
              font-size: 12px;
              position: absolute;
              top: 0px;
              color: rgba(0, 0, 0, 0.45)
            }
        
            .custom-tooltip-temp span:last-child {
              text-align: left;
              margin-top: 10px;
              position: relative;
              color: rgba(0, 0, 0, 0.85)
            }
        
            .custom-tooltip-wind {
                margin: 8px 12px 12px 12px;
                font-size: 12px;
                color: rgba(105, 0, 0, 0.85);
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        `);
        G2.registerShape('point', 'breath-point', {
            draw(cfg, container) {
                const data = cfg.data;
                const point = {
                    x: cfg.x,
                    y: cfg.y,
                };
                const group = container.addGroup();

                if (data.DeviceTotal >= 1000) {
                    const decorator1 = group.addShape('circle', {
                        attrs: {
                            x: point.x,
                            y: point.y,
                            r: 5,
                            fill: '#a377e0',
                            opacity: 0.5,
                        },
                    });
                    const decorator2 = group.addShape('circle', {
                        attrs: {
                            x: point.x,
                            y: point.y,
                            r: 5,
                            fill: '#a377e0',
                            opacity: 0.5,
                        },
                    });
                    const decorator3 = group.addShape('circle', {
                        attrs: {
                            x: point.x,
                            y: point.y,
                            r: 5,
                            fill: '#a377e0',
                            opacity: 0.5,
                        },
                    });
                    decorator1.animate(
                        {
                            r: 10,
                            opacity: 0,
                        },
                        {
                            duration: 1800,
                            easing: 'easeLinear',
                            repeat: true,
                        },
                    );
                    decorator2.animate(
                        {
                            r: 10,
                            opacity: 0,
                        },
                        {
                            duration: 1800,
                            easing: 'easeLinear',
                            repeat: true,
                            delay: 600,
                        },
                    );
                    decorator3.animate(
                        {
                            r: 10,
                            opacity: 0,
                        },
                        {
                            duration: 1800,
                            easing: 'easeLinear',
                            repeat: true,
                            delay: 1200,
                        },
                    );
                    group.addShape('circle', {
                        attrs: {
                            x: point.x,
                            y: point.y,
                            r: 2.5,
                            fill: cfg.color,
                            opacity: 0.7,
                        },
                    });
                    group.addShape('circle', {
                        attrs: {
                            x: point.x,
                            y: point.y,
                            r: 2.5,
                            fill: cfg.color,
                        },
                    });
                }

                return group;
            },
        });
        const config = {
            autoFit: true,
            height: 300,
            data: this.state.datas,
            stepType: 'hvh',
            xField: 'DatasDate',
            yField: 'OnLineRate',
            // seriesField: 'DeviceTotal',
            tooltip: {
                showMarkers: true,
                formatter: (datum) => {
                    return {
                        title: "接口名称:" + datum.Path,
                        name: "耗时",
                        value: datum.DeviceTotal + "ms"
                    };
                },
                customContent: (title, items) => {
                    const data = items[0]?.data || {};
                    const tempDom = `<div class = "custom-tooltip-value"><div class = "custom-tooltip-temp"><span>本次巡检设备信息：</span></div></div>`;
                    const tindDom = `<div class = "custom-tooltip-wind"><span>巡检时间：${moment(data.DatasDate).format("YYYY-MM-DD HH:mm:ss.SSS")}</span></div>`;
                    const DeviceTotal = `<div class = "custom-tooltip-wind"><span>设备总数：${data.DeviceTotal}</span></div>`;
                    const sindDom = `<div class = "custom-tooltip-wind"><span>在线设备：${data.OnLineTotal}</span></div>`;
                    const cindDom = `<div class = "custom-tooltip-wind"><span>离线设备：${data.OffLineTotal}</span></div>`;
                    const OnLineRate = `<div class = "custom-tooltip-wind"><span>设备在线率：${data.OnLineRate}%</span></div>`;
                    return `<div style="max-width: 700px;white-space: nowrap;">${tempDom}${DeviceTotal}${tindDom}${sindDom}${cindDom}${OnLineRate}</div>`;
                },
            },
            label: {
                formatter: (datum) => {
                    return datum.OnLineRate?datum.OnLineRate+"%":"0%";
                },
            },
            yAxis: {
                max: 120,
                label: {
                    formatter: (value) => {
                        return value + "%"
                    },
                },
            },
            xAxis: {
                // type: 'timeCat',
                tickCount: 20,
                label: {
                    formatter: (value) => {
                        return moment(value).format("HH:mm:ss")
                    },
                },
            },
            point: {
                shape: 'breath-point',
            },
            smooth: true,
            color: ['#6ab7da', '#806bd9', '#da6bcc'],
        };
        return <div style={{textAlign: "left"}}> {this.state.datas.length !== 0 ?
            <Line {...config} /> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </div>
    }
}