import React, {Component} from 'react';
import ReactJson from 'react-json-view'

export class JsonTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jsonData: props.jsonData,
            collapsed: props.collapsed ? props.collapsed : 4
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.jsonData) !== JSON.stringify(prevProps.jsonData)) {
            this.setState({
                jsonData: this.props.jsonData,
            })
        }
    }

    handleOnChange = (newData) => {
        this.setState({jsonData: newData.jsObject});
    };

    render() {
        const {jsonData} = this.state;
        const jsonTo = (result) => {
            if (typeof result === 'string') {
                try {
                    result = JSON.parse(result);
                } catch (error) {
                    result = {"errmsg": error.toString()};
                }
            }
            if (typeof result !== 'object' && result === null) {
                return {};
            }
            return result
        }
        const propsVal = {
            name: false, // JSON数据的根节点(用默认或指定的根节点包裹自己的数据)
            src: jsonTo(jsonData), // 需要展示的JSON数据
            style: {
                padding: 10,
                minHeight: '100px',
            },
            // theme: 'monokai', // 支持base-16主题
            iconStyle: 'triangle', // circle(圆)、triangle(三角形)、square(正方形)
            indentWidth: 4, // 首行缩进长度
            collapsed: this.state.collapsed, // 节点折叠
            collapseStringsAfterLength: true, // 超出内容会变成…的功能
            displayDataTypes: true, // 数据类型会出现在数据的前缀值
            displayObjectSize: true, // 对象和数组被标记为大
            sortKeys: true,
            sortKeysDepth: 3,
        }
        return (
            <ReactJson {...propsVal} />
        );
    }
}