import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './AppRouter/AppRouter'
import * as serviceWorker from './serviceWorker';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment'
import 'moment/locale/zh-cn';

moment.locale('zh-cn')
ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <AppRouter/>
    </ConfigProvider>,
    document.getElementById('root')
);
serviceWorker.unregister();
