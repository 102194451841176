import React, {Component} from "react";
import {
    Badge,
    Button,
    Card, DatePicker,
    Descriptions,
    Divider,
    FloatButton,
    Input,
    Mentions,
    message,
    Popconfirm,
    Progress,
    Rate,
    Segmented,
    Select,
    Space,
    Spin,
    Table,
    Tag,
    Tooltip
} from "antd";

import api from "../../../api/ApiConfig";
import {getUser, Loginout} from "../../Users/AuthRouter";
import dayjs from "dayjs";
import {NavLink} from "react-router-dom";
import {
    AppstoreOutlined, ClearOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";
import {JsonTools} from "../../AutoTest/TestProjectManagement/JsonTools";
import Avatar from "antd/es/avatar/avatar";
import Text from "antd/es/typography/Text";
import {FDevCreateTask} from "./FDevCreateTask";

const {RangePicker} = DatePicker;

export class FirmwareProjectList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: [],
            userList: [],
            product_list: [],
            Environment: -1,
            productName: null,
            ProjectTaskStatus: -1,
            pageSize: 10,
            page: 1,
            Total: 0,
            fptTime: [dayjs().subtract(12, 'month'), dayjs()]
        }
    }

    componentDidMount() {
        this.getuserlist_atmp()
        this.get_firmware_project_tasks()
        this.getproducts_ypt({env: "Test"})
    }

    getproducts_ypt = async (params = {}) => {
        await api.GetProducts_YPT(params).then(r => {
            if (typeof (r.msgcode) == 'number') {
                if (r.msgcode === 20008) {
                    Loginout()
                    this.props.history.push('/login')
                    window.location.reload()
                } else {
                    this.setState({
                        product_list: r.datas.result
                    })
                }
            }
        })
    }

    userinfo = getUser()

    get_firmware_project_tasks = async () => {
        let params = {}
        this.setState({
            spinning: true
        })
        params['UserId'] = this.userinfo.UserId
        if (this.state.productName !== null) {
            params['productName'] = this.state.productName
        }
        if (this.state.Environment !== -1) {
            params['Environment'] = this.state.Environment
        }
        if (this.state.ProjectTaskStatus !== -1) {
            params['ProjectTaskStatus'] = this.state.ProjectTaskStatus
        }
        params['stime'] = this.state.fptTime[0].format("YYYY-MM-DD 00:00:00")
        params['etime'] = this.state.fptTime[1].format("YYYY-MM-DD 23:59:59")
        params['pageSize'] = this.state.pageSize
        params['page'] = this.state.page

        await api.GetFirmwareProjectTasks(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    datas: r.datas,
                })
            }
            this.setState({
                spinning: false
            })
        })
    }
    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <Space size={2} style={{marginTop: -5}}>
                            <Avatar key={key} style={{marginTop: -5}} size={30} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, color: "white", fontWeight: 600}}>{value.name}({value.user_id})
                            </Text>
                        </Space>,
                        label1: <Space size={2} style={{marginTop: -5}}>
                            <Avatar key={key} size={20} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, fontWeight: 600, color: "#1677ff"}}>{value.name}({value.user_id})
                            </Text>
                        </Space>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }

    del_firmware_project_task = async (TaskId) => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['key'] = TaskId
        await api.DelFirmwareProjectTasks({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    message.success("删除任务成功")
                    this.get_firmware_project_tasks()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const ProjectColumns = [
            {
                title: 'No.',
                align: 'center',
                width: 40,
                render: (_, record, index) => {
                    return <div>{index + 1}</div>
                }
            },
            {
                title: '提测信息',
                align: 'center',
                width: 180,
                render: (text, record, index) => {
                    return <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}>
                        <Badge.Ribbon
                            placement={"start"}
                            color={"blue-inverse"}
                            text={<div style={{fontSize: 12, fontWeight: 500, textAlign: "center"}}>
                                {
                                    ([`${record.UserName}(${record.UserId})`]).map((value, index) => {
                                        for (let i = 0; i < this.state.userList.length; i++) {
                                            if (value === this.state.userList[i].value) {
                                                return this.state.userList[i].label
                                            }
                                        }
                                    })
                                }
                            </div>}>
                            <Card bordered={true}
                                  style={{
                                      fontSize: 12, fontWeight: 500, maxHeight: 100, textAlign: "left", minWidth: 400
                                  }}
                            >
                                <Space direction={"vertical"} size={2}>
                                    <Tag
                                        color={"#6d45c2"}
                                        style={{
                                            position: "absolute",
                                            marginLeft: "75%",
                                            marginTop: "-8%",
                                            textAlign: "center",
                                            fontSize: 11,
                                            fontWeight: 600,
                                            width: 70,
                                        }}
                                    >
                                        第 {record.TestNumber} 轮
                                    </Tag>
                                    <div style={{marginTop: 0, fontSize: 12}}>{record.ProductName}</div>
                                    <Space direction={"horizontal"} size={5}>
                                        <div style={{
                                            fontSize: 10,
                                            color: "#938f8f"
                                        }}>创建时间 {dayjs(record.cDatasDate).format("YYYY-MM-DD HH:mm:ss")}</div>
                                        <div style={{
                                            fontSize: 10,
                                            color: "#938f8f"
                                        }}>更新时间 {dayjs(record.uDatasDate).format("YYYY-MM-DD HH:mm:ss")}</div>
                                    </Space>
                                </Space>
                            </Card>
                        </Badge.Ribbon>
                    </div>
                }
            },
            // {
            //     title: '提测轮数',
            //     align: 'center',
            //     width: 100,
            //     dataIndex: 'TestNumber',
            //     render: (text, record, index) => {
            //         return <div style={{fontSize: 12, minWidth: 60, fontWeight: 600}}>
            //             第 {text} 轮
            //         </div>
            //     }
            // },
            {
                title: '固件版本',
                align: 'center',
                width: 150,
                dataIndex: 'FirmwareVersion',
                render: (text, record, index) => {
                    return <div style={{textAlign: "left"}}>
                        <Space size={2} direction={"vertical"} style={{
                            fontSize: 11,
                            fontWeight: 500,
                            minWidth: 100,
                            whiteSpace: 'normal',
                            wordBreak: 'break-all'
                        }}>
                            <div>提测版本: {text}</div>
                            <div>OTA版本: {record.OtaVersion !== "" ? record.OtaVersion : "-"}</div>
                        </Space>
                    </div>
                }
            },
            {
                title: 'OTA配置',
                align: 'center',
                width: 100,
                dataIndex: 'AutoOTA',
                render: (text, record, index) => {
                    return <div style={{fontSize: 12, fontWeight: 600}}>
                        <Tag
                            color={
                                text === 1 ? "#053879" :
                                    text === 2 ? "#707272" : "default"
                            }
                            style={{
                                marginRight: 5,
                                textAlign: "center",
                                fontSize: 11,
                                fontWeight: 600,
                                width: 70,
                            }}
                        >
                            {text === 1 ? "快捷配置" :
                                text === 2 ? "手动配置" : "未知类型"}
                        </Tag>
                    </div>
                }
            },
            {
                title: '发布类型',
                align: 'center',
                width: 150,
                dataIndex: 'ReleaseType',
                render: (text, record, index) => {
                    return <div>
                        {
                            JSON.parse(text).map((value) => {
                                return (
                                    <Tag
                                        color={
                                            value === 1 ? "#5b8c00" :
                                                value === 2 ? "#8d062c" :
                                                    value === 3 ? "#d05715" :
                                                        value === 4 ? "#165dcb" :
                                                            "#9254de"
                                        }
                                        style={{
                                            marginRight: 5,
                                            textAlign: "center",
                                            fontSize: 11,
                                            fontWeight: 600,
                                            width: 70,
                                        }}
                                    >
                                        {value === 1 ? "EVT生产" :
                                            value === 2 ? "DVT生产" :
                                                value === 3 ? "线上OTA" :
                                                    value === 4 ? "批量生产" :
                                                        value === 5 ? "测试版本" : "未知类型"}
                                    </Tag>
                                )
                            })
                        }
                    </div>
                }
            },
            {
                title: '提测、测试状态',
                align: 'center',
                width: 100,
                dataIndex: 'ProjectTaskStatus',
                render: (text, record) => {
                    return <Space direction={"vertical"}>
                        <Space>
                            <Badge status={
                                text === 1 ? "warning" :
                                    text === 2 ? "processing" :
                                        text === 3 ? "success" :
                                            text === 4 ? "error" :
                                                "default"
                            }/>
                            <Tag color={text === 1 ? "#fadb14" :
                                text === 2 ? "#9254de" :
                                    text === 3 ? "green-inverse" :
                                        text === 4 ? "#ff7875" :
                                            "#707272"
                            } style={{
                                color: "white",
                                marginRight: 5,
                                textAlign: "center",
                                fontSize: 12,
                                fontWeight: 700,
                                width: 80,
                            }}>
                                {text === 1 ? "待确认" :
                                    text === 2 ? "进行中" :
                                        text === 3 ? "提测成功" :
                                            text === 4 ? "提测失败" :
                                                "未知状态"}
                            </Tag>
                        </Space>
                        <Space>
                            <Badge status={
                                record.ProjectTestStatus === 1 ? "success" :
                                    record.ProjectTestStatus === 2 ? "error" :
                                        "default"
                            }/>
                            <Tag
                                color={
                                    record.ProjectTestStatus === 1 ? "#13c429" :
                                        record.ProjectTestStatus === 2 ? "#9b1313" : "default"
                                }
                                style={{
                                    marginRight: 5,
                                    textAlign: "center",
                                    fontSize: 12,
                                    fontWeight: 700,
                                    width: 80,
                                }}
                            >
                                {record.ProjectTestStatus === 1 ? "测试通过" :
                                    record.ProjectTestStatus === 2 ? "测试失败" : "等待测试"}
                            </Tag>
                        </Space>
                    </Space>
                }
            },
            // {
            //     title: '测试状态',
            //     align: 'center',
            //     width: 100,
            //     dataIndex: 'ProjectTestStatus',
            //     render: (text, record, index) => {
            //         return <div style={{fontSize: 12, fontWeight: 600}}>
            //             <Tag
            //                 color={
            //                     text === 1 ? "#13c429" :
            //                         text === 2 ? "#9b1313" : "default"
            //                 }
            //                 style={{
            //                     marginRight: 5,
            //                     textAlign: "center",
            //                     fontSize: 12,
            //                     fontWeight: 700,
            //                     width: 70,
            //                 }}
            //             >
            //                 {text === 1 ? "测试通过" :
            //                     text === 2 ? "测试失败" : "等待提测"}
            //             </Tag>
            //         </div>
            //     }
            // },
            {
                title: '任务操作',
                align: 'center',
                width: 100,
                render: (_, record) => {
                    return <Space size={0} direction={"horizontal"}>
                        <NavLink to={'/FirmwareTaskDetails/' + record.key}>
                            <Button style={{width: 80, fontWeight: 700, fontSize: 12}}
                                    size={"small"}
                                    disabled={false} type={"link"} icon={<AppstoreOutlined/>}>
                                任务详情
                            </Button>
                        </NavLink>
                        <Divider type={"vertical"}/>
                        <Popconfirm
                            title="删除任务"
                            description="确定要删除这个提测任务吗?"
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red'
                                    }}
                                />
                            }
                            onConfirm={() => {
                                this.del_firmware_project_task(record.key)
                            }}
                        >
                            <Button style={{width: 80, fontWeight: 700, fontSize: 12, marginLeft: -5}} size={"small"}
                                    disabled={false} type={"link"} danger={true} icon={<DeleteOutlined/>}>
                                删除任务
                            </Button>
                        </Popconfirm>
                    </Space>
                }
            },
        ];
        const expandedRowRender = (record, index) => {
            return <Card>
                <Descriptions bordered={true} size={"small"}
                              title={<Space size={2} style={{fontSize: 12}}>固件提测信息</Space>}
                              style={{whiteSpace: "pre-wrap", fontSize: 12, fontWeight: 500}}
                              labelStyle={{width: "5%", fontSize: 12, fontWeight: 500, textAlign: "center"}}
                              contentStyle={{fontSize: 12, fontWeight: 500, width: "20%"}}

                >
                    <Descriptions.Item label="前置检查" span={1} style={{textAlign: "center"}}>
                        <Space size={1}>
                            {
                                JSON.parse(record.Precheck).map((value) => {
                                    return (
                                        <Tag
                                            color={
                                                value === 1 ? "#5b8c00" :
                                                    value === 2 ? "#8d062c" :
                                                        value === 3 ? "#d05715" : "#9254de"
                                            }
                                            style={{
                                                marginRight: 5,
                                                textAlign: "center",
                                                fontSize: 11,
                                                fontWeight: 600,
                                                // width: 90,
                                            }}
                                        >
                                            {value === 1 ? "固件已上传confluence" :
                                                value === 2 ? "代码差异已检查" :
                                                    value === 3 ? "Bug流转已检查" : "未知类型"}
                                        </Tag>
                                    )
                                })
                            }
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="设计文档" span={1} style={{textAlign: "center"}}>
                        <a href={record.DesignDocument} target={'_blank'}>点击查看</a>
                    </Descriptions.Item>
                    <Descriptions.Item label="自测报告" span={1} style={{textAlign: "center"}}>
                        <a href={record.TestReport} target={'_blank'}>点击查看</a>
                    </Descriptions.Item>
                    <Descriptions.Item label="改动内容" span={1}>
                        {record.NewDemand}
                    </Descriptions.Item>
                    <Descriptions.Item label="影响范围" span={1}>
                        {record.AffectedScope}
                    </Descriptions.Item>
                    <Descriptions.Item label="测试建议" span={3}>
                        {record.TestSuggestion}
                    </Descriptions.Item>
                    <Descriptions.Item label="固件地址" span={3}>
                        {record.FirmwareAddress}
                    </Descriptions.Item>
                    {record.AutoOTA === 1 ?
                        <>
                            <Descriptions.Item label="" span={3}>
                                <Divider style={{fontSize: 11, color: "goldenrod"}}>
                                    快捷升级配置（目前仅支持C100智能中控大屏）
                                </Divider>
                            </Descriptions.Item>
                            <Descriptions.Item label="ProductKey" span={1} style={{textAlign: "center"}}>
                                {record.ProductName.split('_')[2]}
                            </Descriptions.Item>
                            <Descriptions.Item label="固件地址" span={1} style={{textAlign: "center"}}>
                                <a href={record.FirmwareDownloadAddress} target={'_blank'}>点击下载</a>
                            </Descriptions.Item>
                            <Descriptions.Item label="升级版本" span={1} style={{textAlign: "center"}}>
                                {record.FirmwareVersion}
                            </Descriptions.Item>
                            <Descriptions.Item label="DevicesName" span={3}>
                                {record.DevicesName}
                            </Descriptions.Item>
                        </>
                        : null
                    }
                </Descriptions>
            </Card>
        };

        return <Card
            extra={<Space>
                <FDevCreateTask fun={this.get_firmware_project_tasks}/>
            </Space>}
            title={
                <Space size={10} style={{margin: "20px 20px 20px 0px"}}>
                    <Select
                        style={{minWidth: 150, textAlign: "center"}}
                        showSearch={false}
                        placeholder="请选择环境"
                        disabled={true}
                        value={this.state.Environment}
                        onChange={(value, option) => {
                            this.setState({
                                Environment: value
                            })
                        }}
                        options={[
                            {
                                value: -1,
                                label: '所有环境',
                            },
                            {
                                value: 1,
                                label: '测试环境',
                            },
                            {
                                value: 2,
                                label: '生产环境',
                            },
                            {
                                value: 3,
                                label: '预发布环境',
                            }
                        ]}
                    />
                    <Select
                        style={{minWidth: 150, textAlign: "center"}}
                        showSearch={false}
                        placeholder="请选择任务状态"
                        value={this.state.ProjectTaskStatus}
                        onChange={(value, option) => {
                            this.setState({
                                ProjectTaskStatus: value
                            })
                        }}
                        options={[
                            {
                                value: -1,
                                label: '所有状态',
                            },
                            {
                                value: 2,
                                label: '进行中',
                            },
                            {
                                value: 3,
                                label: '提测成功',
                            },
                            {
                                value: 4,
                                label: '提测失败',
                            }
                        ]}
                    />
                    <Select
                        style={{minWidth: 250, textAlign: "center"}}
                        showSearch={true}
                        placeholder="产品名称"
                        value={this.state.productName}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        onChange={(value, option) => {
                            this.setState({
                                productName: value
                            })
                        }}
                        options={this.state.product_list.map((value) => {
                            return {
                                value: value.productName + "_" + value.productId + "_" + value.productKey,
                                label: value.productName + "_" + value.productId + "_" + value.productKey,
                                disabled: false,
                            }
                        })}
                    />
                    <RangePicker style={{textAlign: "center"}} allowClear={false}
                                 onChange={(date, dateString) => {
                                     this.setState({
                                         fptTime: date,
                                     })
                                 }} value={this.state.fptTime}/>
                    <Button size={"middle"} type={"primary"}
                            style={{fontSize: 12, fontWeight: 700}}
                            onClick={() => {
                                this.get_firmware_project_tasks()
                            }}
                    >
                        <Space size={5}>
                            <SearchOutlined/>搜索
                        </Space>
                    </Button>
                    <Button size={"middle"} type={"primary"}
                            style={{fontSize: 12, fontWeight: 700}}
                            onClick={() => {
                                this.setState({
                                    productName: null,
                                    ProjectTaskStatus: -1,
                                    Environment: -1,
                                    fptTime: [dayjs().subtract(12, 'month'), dayjs()]
                                }, function () {
                                    this.get_firmware_project_tasks()
                                })
                            }}
                    >
                        <Space size={5}>
                            <ClearOutlined/>重置
                        </Space>
                    </Button>
                </Space>
            }>
            <Table
                scroll={
                    {
                        x: 1000
                    }
                }
                // expandable={{
                //     expandedRowRender: (record, index, indent, expanded) => {
                //         return expandedRowRender(record, index)
                //     },
                //     defaultExpandedRowKeys: [0]
                // }}
                loading={this.state.spinning}
                bordered={true} dataSource={this.state.datas}
                columns={ProjectColumns}
                pagination={{
                    position: ['bottomRight'],
                    pageSizeOptions: [10, 20, 30],
                    responsive: true,
                    size: "middle",
                    current: this.state.page,
                    pageSize: this.state.pageSize,
                    total: this.state.Total,
                    showTotal: (total, range) => {
                        return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                    },
                    onShowSizeChange: (current, size) => {
                        this.setState({
                            page: current,
                            pageSize: size
                        }, function () {
                        })
                    },
                    onChange: (page, pageSize) => {
                        this.setState({
                            page: page,
                            pageSize: pageSize
                        }, function () {
                        })
                    }
                }}
            />
            <FloatButton.BackTop visibilityHeight={100}/>
        </Card>
    }
}
