import React, {Component} from 'react';
import {
    Space,
    Card, List, Divider, Button, message, Tag, Spin, Input, Select, Pagination
} from "antd";
import {getUser, Loginout} from "../../Users/AuthRouter";
import {
    AppstoreAddOutlined, ClearOutlined,
    DeleteOutlined,
    EditOutlined,
    LikeOutlined,
    LinkOutlined,
    MessageOutlined,
    RetweetOutlined,
    SearchOutlined,
    SisternodeOutlined,
    StarOutlined,
    UserOutlined,
} from "@ant-design/icons";
import api from "../../../api/ApiConfig";
import dayjs from "dayjs";
import Avatar from "antd/es/avatar/avatar";
import {CreateHelp} from "./CreateHelp";
import {log} from "@antv/g2plot/lib/utils";
import {EditHelp} from "./EditHelp";


export class HelpPageHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            DocumentManagementPage: 1,
            DocumentManagementPageSize: 20,
            DocumentManagementDatas: [],
            DocumentManagementTotal: 0,
            DocType: null,
            QText: null

        }
        this.form = React.createRef();
    }

    userinfo = getUser()

    componentDidMount() {
        this.getdocumentmanagement()
    }


    getdocumentmanagement = async () => {
        this.setState({
            spinning: true
        })
        let params = {}
        params['Page'] = this.state.DocumentManagementPage
        params['PageSize'] = this.state.DocumentManagementPageSize
        if (this.state.DocType != null) {
            params['DocType'] = this.state.DocType
        }
        if (this.state.QText != null) {
            params['QText'] = this.state.QText
        }
        await api.GetDocumentManagement(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    DocumentManagementDatas: r['datas'],
                    DocumentManagementTotal: r['total'],
                }, function () {

                })
            }
            setTimeout(() => {
                this.setState({
                    spinning: false
                })
            }, 500)
        })
    }

    PostDocumentManagement = async (key) => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['DocId'] = key
        values['OperationType'] = 2
        console.log("删除文档", values)
        await api.PostDocumentManagement({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("删除文档成功")
                this.getdocumentmanagement()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const IconText = ({icon, text}) => (
            <Space size={1}>
                {React.createElement(icon)} {text}
            </Space>
        );
        return <Card
            extra={
                <Space size={1}>
                    <Divider type="vertical"/>
                    <CreateHelp fun1={this.getdocumentmanagement}/>
                    <Divider type="vertical"/>
                    <Button type={"link"}
                            style={{width: 90, color: '#707272'}}
                            size={"small"}
                            onClick={() => {
                                this.setState({
                                    DocType: null,
                                    QText: null
                                }, function () {
                                    this.getdocumentmanagement()
                                })
                            }}>
                        <Space size={5}>
                            <RetweetOutlined/>
                            <div style={{fontWeight: 800, fontSize: 12}}>刷新数据</div>
                        </Space>
                    </Button>
                    <Divider type="vertical"/>
                </Space>
            }>
            <Spin spinning={this.state.spinning} tip={"文档数据获取中,请稍后..."}>
                <div style={{textAlign: "center", width: "99%"}}>
                    <Card style={{textAlign: "center", borderRadius: 30, marginTop: 10}} bordered={true}>
                        <Space size={20} style={{textAlign: "center"}}>
                            <Input style={{minWidth: 200}}
                                   value={this.state.QText}
                                   onChange={(v) => {
                                       this.setState({
                                           QText: v.target.value
                                       })
                                   }}
                                   placeholder={"标题、描述、作者模糊搜索"}/>
                            <Select
                                style={{textAlign: "left", minWidth: 170}}
                                showSearch={false}
                                placeholder="请选择文档类型"
                                optionFilterProp="children"
                                filterOption={false}
                                value={this.state.DocType}
                                onChange={(value, option) => {
                                    this.setState({
                                        DocType: value
                                    })
                                }}
                                options={[
                                    {
                                        value: "Tools",
                                        label: '测试工具',
                                    },
                                    {
                                        value: 'TestFW',
                                        label: '测试框架',
                                    },
                                    {
                                        value: 'Appium',
                                        label: 'App自动化',
                                    },
                                    {
                                        value: 'Selenium',
                                        label: 'Web自动化',
                                    },
                                    {
                                        value: 'Interface',
                                        label: '接口自动化',
                                    },
                                    {
                                        value: 'Devices',
                                        label: '设备自动化',
                                    },
                                ]}
                            />
                            <Button type={"primary"} size={"middle"}
                                    onClick={() => {
                                        console.log("查询条件", this.state.SelectValue, this.state.InputValue)
                                        this.getdocumentmanagement()
                                    }
                                    }>
                                <Space size={5} style={{fontSize: 13, fontWeight: 800}}>
                                    <SearchOutlined/> 查询
                                </Space>
                            </Button>
                            <Button type={"primary"} size={"middle"}
                                    onClick={() => {
                                        this.setState({
                                            DocType: null,
                                            QText: null
                                        }, function () {
                                            this.getdocumentmanagement()
                                        })
                                    }}
                            >
                                <Space size={5} style={{fontSize: 13, fontWeight: 800}}>
                                    <ClearOutlined/> 重置
                                </Space>
                            </Button>
                        </Space>
                    </Card>
                </div>
                <div style={{overflow: 'auto', maxHeight: 700, marginTop: 10}}>
                    <List
                        style={{width: "99%"}}
                        bordered={false}
                        itemLayout="vertical"
                        size="small"
                        pagination={false}
                        dataSource={this.state.DocumentManagementDatas}
                        renderItem={(item, index) => (
                            <Card key={item.key}
                                  style={{borderRadius: 30, backgroundColor: "rgba(190,194,194,0.05)"}} bordered={true}>
                                <List.Item
                                    key={item.key}
                                    actions={[
                                        <IconText icon={LinkOutlined} text={
                                            <Button type={"link"}
                                                    key="list-vertical-star-o"
                                                    style={{width: 40, marginLeft: -5, color: '#707272'}}
                                                    size={"small"}>
                                                <a href={item.DocLink} target={"_blank"}
                                                   style={{fontWeight: 800, fontSize: 12}}>详情</a>
                                            </Button>
                                        }/>,
                                        <IconText icon={EditOutlined} text={
                                            <EditHelp datas={item} fun1={this.getdocumentmanagement}/>
                                        }/>,
                                        <IconText icon={DeleteOutlined} text={
                                            <Button type={"link"}
                                                    key="list-vertical-star-o"
                                                    style={{width: 40, marginLeft: -5, marginTop: 2, color: '#707272'}}
                                                    size={"small"}
                                                    onClick={() => {
                                                        this.PostDocumentManagement(item.key)
                                                    }}>
                                                <div style={{fontWeight: 800, fontSize: 12}}>删除</div>
                                            </Button>
                                        }/>,
                                    ]}
                                >
                                    <List.Item.Meta
                                        // avatar={<div/>}
                                        title={<Space size={2} style={{fontWeight: 700, fontSize: 15}}>
                                            No&nbsp;{index + 1}.{item.DocTitle}
                                            <div style={{marginTop: -2}}>
                                                <Space size={2} style={{fontWeight: 700, fontSize: 12, marginLeft: 10}}>
                                                    {JSON.parse(item.DocType).map((v) => {
                                                        return <Tag style={{fontWeight: 600, fontSize: 12, height: 22}}
                                                                    color={v === "Appium" ? "#722ed1" :
                                                                        v === "Selenium" ? "#434343" :
                                                                            v === "Interface" ? "#87d068" :
                                                                                v === "Devices" ? "#2db7f5" :
                                                                                    v === "Tools" ? "#3f6600" :
                                                                                        v === "TestFW" ? "#3f6600" : null}>
                                                            {v === "Appium" ? "App自动化" :
                                                                v === "Selenium" ? "Web自动化" :
                                                                    v === "Interface" ? "接口自动化" :
                                                                        v === "Devices" ? "设备自动化" :
                                                                            v === "Tools" ? "测试工具" :
                                                                                v === "TestFW" ? "测试框架" :
                                                                                    "未知类型"}
                                                        </Tag>
                                                    })
                                                    }
                                                </Space>
                                            </div>
                                        </Space>}
                                        description={
                                            <Space size={0} direction={"vertical"}>
                                                <Divider style={{marginTop: 10, width: "100vh"}}/>
                                                <div style={{marginTop: -10}}>
                                                    <Space size={2} style={{
                                                        fontWeight: 350,
                                                        fontSize: 12,
                                                        marginLeft: 0,
                                                        color: "rgba(112,114,114,0.77)",
                                                    }}>
                                                        {/*<Avatar size={20}*/}
                                                        {/*    // icon={<UserOutlined/>}*/}
                                                        {/*        style={{marginTop: -2, backgroundColor: "black"}}>U</Avatar>*/}

                                                        Created by
                                                        <div>「{item.UserName}_{item.UserId}」</div>And Last Updated
                                                        On
                                                        {dayjs(item.DatasDate).format("YYYY-MM-DD HH:mm:ss")}
                                                    </Space>
                                                </div>
                                            </Space>}
                                    />
                                    <div style={{marginTop: -5}}>
                                        <Space style={{color: "#1a1919"}}>Description: <div
                                            style={{fontSize: 13.5, fontWeight: 500}}>{item.DocNotes}</div></Space>
                                    </div>
                                </List.Item>
                            </Card>
                        )}
                    />
                </div>
                <Pagination
                    size={"default"}
                    style={{textAlign: "right", marginTop: 30, marginBottom: 30}}
                    position={["bottomRight"]}
                    showTotal={(total, range) => {
                        return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                    }}
                    total={this.state.DocumentManagementTotal}
                    pageSize={this.state.DocumentManagementPageSize}
                    defaultPageSize={this.state.DocumentManagementPageSize}
                    defaultCurrent={this.state.DocumentManagementPage}
                    current={this.state.DocumentManagementPage}
                    onShowSizeChange={(current, size) => {
                        this.setState({
                            DocumentManagementPage: current,
                            DocumentManagementPageSize: size
                        }, function () {
                            this.getdocumentmanagement()
                        })
                    }}
                    onChange={(page, pageSize) => {
                        this.setState({
                            DocumentManagementPage: page,
                            DocumentManagementPageSize: pageSize
                        }, function () {
                            this.getdocumentmanagement()
                        })
                    }}
                />
            </Spin>
        </Card>
    }
}