import React, {Component} from 'react';
import {Bullet, Line} from '@ant-design/plots';
import moment from "moment";
import {Button, Empty, message, Popconfirm, Space, Table, TimePicker} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {getUser, Loginout} from "../Users/AuthRouter";
import api from "../../api/ApiConfig";
import dayjs from "dayjs";

export class InterfaceTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: props.datas ? props.datas.slice(0, 200) : [],
            DatasDate: props.DatasDate,
            fun: props.fun,
            fun1: props.fun1,
            fun2: props.fun2,
        }
    }

    // componentDidMount() {
    //     this.setState({
    //         timeString: ['00:00:00', '23:59:59'],
    //     })
    // }

    componentDidUpdate(prevProps) {

        // if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
        console.log("asdlkasl;d")
        this.setState({
            datas: this.props.datas.slice(0, 200),
            DatasDate: this.props.DatasDate,
            fun: this.props.fun,
            fun1: this.props.fun1,
            fun2: this.props.fun2
        })
        // }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas.slice(0, 200)) === JSON.stringify(nextProps.datas.slice(0, 200)) &&
            JSON.stringify(this.state.datas.slice(0, 200)) === JSON.stringify(nextState.datas.slice(0, 200)));
    }

    delinterfacedatas = async (ServiceAvailabilityId, ServiceInterface) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['ServiceAvailabilityId'] = ServiceAvailabilityId
        values['ServiceInterface'] = ServiceInterface
        await api.delInterfaceDatas({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("删除成功!")
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.state.fun({DatasType: 2})
            this.state.fun1()
            this.state.fun2()
        })
    }

    render() {
        const columns = [
            {
                title: '接口路径',
                dataIndex: 'Path',
                key: 'Path',
            },
            {
                title: '请求时间',
                width: 240,
                align: 'center',
                dataIndex: 'CreatedTime',
                key: 'CreatedTime',
            },
            {
                title: '请求方法',
                width: 100,
                dataIndex: 'Method',
                key: 'Method',
                align: 'center',
            },
            {
                title: '服务Code',
                width: 100,
                dataIndex: 'ServiceCode',
                key: 'ServiceCode',
                align: 'center',
            },
            {
                title: '业务Code',
                width: 100,
                dataIndex: 'BusinessCode',
                key: 'BusinessCode',
                align: 'center',
            },
            {
                title: '请求耗时',
                width: 100,
                dataIndex: 'InterfaceTime',
                key: 'InterfaceTime',
                align: 'center',
                render: ((text, re) => (
                    <div>{re.InterfaceTime} ms</div>
                ))
            },
            {
                title: '请求参数',
                dataIndex: 'Datas',
                key: 'Datas',
                width: 150,
                render: ((text, re) => (
                    <div style={{
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}>{re.Datas}</div>
                ))
            },
            {
                title: '查询参数',
                dataIndex: 'Params',
                key: 'Params',
                width: 150,
                render: ((text, re) => (
                    <div style={{
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}>{re.Params}</div>
                ))
            },
            {
                title: '返回结果',
                dataIndex: 'Response',
                key: 'Response',
                width: 150,
                render: ((text, re) => (
                    <div style={{
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}>{re.Response}</div>
                ))
            },
            {
                title: '操作数据',
                fixed: 'right',
                width: 100,
                align: 'center',
                render: ((text, re) => (
                    <Popconfirm
                        title="删除数据"
                        description="您确定要删除这条数据?"
                        onConfirm={() => {
                            this.delinterfacedatas(re.ServiceAvailability_id, re.id)
                        }}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button
                            type="link" danger
                            size={"small"}
                            icon={<DeleteOutlined/>}
                        >删除</Button>
                    </Popconfirm>
                ))
            },
        ]
        return <>
            {this.state.datas.length !== 0 ?
                <Table dataSource={this.state.datas}
                       columns={columns}
                       title={() => {
                       }}
                       scroll={{
                           x: 1600,
                       }}
                       bordered
                /> :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </>
    }
}
