import React, {Component} from 'react';
import {Empty} from "antd";
import {Column} from '@ant-design/plots';
import moment from "moment";

export class ColumnCharts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas,
            color: props.color
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas.length > 0 ? this.props.datas : [],
                color: prevProps.color
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }

    render() {
        const config = {
            data: this.state.datas,
            xField: 'type',
            height: 300,
            yField: 'value',
            // color: this.state.color,
            seriesField: 'type',
            label: {
                position: 'top',
                autoHide: true,
                autoRotate: false,
                content: (item) => {
                    return item.value;
                },
                style: {
                    fill: '#0c0c0c',
                },
            },
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                    formatter: (value) => {
                        return value
                    },
                },
            },
            yAxis: {
                max: Object.keys(this.state.datas).length > 0 ? this.state.datas[0].value + 1000 : 5000,
            },
            meta: {
                type: {
                    alias: '类别',
                },
                value: {
                    alias: '用例数量',
                },
            },
        };
        return <> {this.state.datas.length !== 0 ?
            <Column {...config} /> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </>
    }
}