import React, {Component} from 'react';
import {
    Space,
    Table,
    Progress,
    Badge,
    Divider,
    Card, Row, Col,
    Statistic, Tag, message, Popconfirm, Button
} from "antd";
import {getUser, Loginout} from "../../Users/AuthRouter";
import {
    CheckCircleTwoTone, CloseCircleOutlined, DeleteOutlined,
    ExclamationCircleOutlined,
    WarningOutlined
} from "@ant-design/icons";
import api from "../../../api/ApiConfig";
import dayjs from "dayjs";


export class TaskListLocalTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas,
            BuildsStatus: props.datas.length > 0 ? props.datas[0] : {},
            Total: props.Total,
            setBuildsPageSize: props.setBuildsPageSize,
            CheckCardValue: props.CheckCardValue,
            fun: props.fun
        }
        this.form = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
                Total: this.props.Total,
                CheckCardValue: this.props.CheckCardValue,
                BuildsStatus: this.props.datas.length > 0 ? this.props.datas[0] : {},
            })
        }
    }

    userinfo = getUser()

    componentDidMount() {
        console.log("开始定时器")
        this.timer = setInterval(() => {
            if (this.state.BuildsStatus.JenkinsTaskStatus === "WAITING" || this.state.BuildsStatus.JenkinsTaskStatus === "RUNNING") {
                this.state.fun()
            }
        }, 2000);
    }

    componentWillUnmount() {
        // 在组件即将被销毁时执行清理操作
        clearInterval(this.timer);
        console.log("清理定时器")
    }

    deltesttaskbuilds = async (TaskBuildId) => {
        this.setState({
            spinning: true
        })
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['TaskId'] = this.state.CheckCardValue
        values['TaskBuildId'] = TaskBuildId
        await api.DelTestTaskBuilds({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("删除构建任务成功!")
                this.state.fun()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
        setTimeout(() => {
            this.setState({
                spinning: false
            })
        }, 300)
    }

    render() {
        const TaskColumns = [
            {
                title: '构建编号',
                width: 40,
                fixed: 'left',
                dataIndex: 'JenkinsTaskNumber',
                align: 'center',
                render: (record, text, index) => {
                    return <div style={{fontSize: 12}}>
                        {record}
                    </div>
                }
            },
            {
                title: '任务时间',
                width: 90,
                align: 'center',
                render: (record, text, index) => {
                    return <Space direction={"vertical"}>
                        <div style={{color: "#a6a4a4", fontSize: 11.5, fontWeight: 500, textAlign: "left"}}>
                            触发方式: {
                            text.TaskTrigger === 1 ? "测试平台[用户触发]" :
                                text.TaskTrigger === 2 ? "Jenkins[定时、其他]" :
                                    text.TaskTrigger === 3 ? "测试平台[项目提测]" :
                                        text.TaskTrigger === 4 ? "测试平台[本地任务]" :
                                            "未知"
                        }
                            <br/>
                            开始时间: {dayjs(text.sDatasDate).format("YYYY-MM-DD HH:mm:ss")}
                            <br/>
                            结束时间: {dayjs(text.eDatasDate).format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                    </Space>
                }
            },
            {
                title: '任务进度',
                width: 120,
                dataIndex: 'TaskProgress',
                align: 'center',
                render: (record, text, index) => {
                    return <Space size={0} direction={"vertical"}
                                  style={{fontSize: 12, fontWeight: 800, marginLeft: 20}}>
                        <Space style={{marginLeft: -42}}>
                            <div style={{fontSize: 12, minWidth: 80}}>「{text.CaseExecuted} / {text.CaseTotal}」</div>
                            <Space size={5}>
                                <Badge status={
                                    text.JenkinsTaskStatus === "RUNNING" || text.JenkinsTaskStatus === "WAITING" ? "processing" :
                                        text.JenkinsTaskStatus === "FAILURE" || text.JenkinsTaskStatus === "ABORTED" ? "error" :
                                            text.JenkinsTaskStatus === "SUCCESS" ? "success" :
                                                text.JenkinsTaskStatus === "UNSTABLE" ? "error" : "error"
                                }/>
                                <div style={{fontSize: 11, textAlign: "center"}}>{text.JenkinsTaskStatus}</div>
                            </Space>
                        </Space>
                        {/*<Tag color="#722ed1" style={{maxWidth: 100,textAlign:"center"}}>9999 / 9999</Tag>*/}
                        <Space size={0}>
                            <Progress percent={record} showInfo={true} status={
                                text.JenkinsTaskStatus === "RUNNING" ? "active" :
                                    text.JenkinsTaskStatus === "ABORTED" || text.JenkinsTaskStatus === "FAILURE" ? "exception" :
                                        text.JenkinsTaskStatus === "SUCCESS" ? "success" :
                                            text.JenkinsTaskStatus === "UNSTABLE" ? "exception" : "exception"
                            } style={{width: 220}} size={"small"}/>
                        </Space>
                    </Space>

                }
            },
            {
                title: '测试结果',
                dataIndex: 'TestResult',
                align: 'center',
                width: 110,
                render: (record, text, index) => {
                    // {'passed': 68, 'skipped': 6, 'failed': 5, 'total': 79, 'collected': 80, 'deselected': 1}
                    record = eval("(" + record + ")")
                    let passed = record.passed ? record.passed : 0
                    let skipped = record.skipped ? record.skipped : 0
                    let failed = record.failed ? record.failed : 0
                    let error = record.error ? record.error : 0
                    return <Space direction={"vertical"}>
                        <Space size={1}>
                            <Tag icon={<CheckCircleTwoTone twoToneColor="#52c41a"/>} color={"green"}
                                 style={{width: 100, fontWeight: 800, fontSize: 12}}>
                                成功: {passed}</Tag>
                            <Tag icon={<CloseCircleOutlined/>} color={"red"}
                                 style={{width: 100, fontWeight: 800, fontSize: 12}}>
                                失败: {failed}
                            </Tag>
                        </Space>
                        <Space size={1}>
                            <Tag icon={<ExclamationCircleOutlined/>} color={"orange"}
                                 style={{width: 100, fontWeight: 800, fontSize: 12}}>
                                跳过: {skipped}
                            </Tag>
                            <Tag icon={<WarningOutlined/>} color={"warning"}
                                 style={{width: 100, fontWeight: 800, fontSize: 12}}>
                                错误: {error}
                            </Tag>
                        </Space>
                    </Space>
                }
            },
            {
                title: '任务操作',
                width: this.userinfo.UserId === "00244204" ? 120 : 80,
                // fixed: 'right',
                align: 'center',
                dataIndex: 'key',
                render: (record, text, index) => {
                    return <Space size={0}>
                        <Divider type={"vertical"}/>
                        <Popconfirm
                            title="删除构建"
                            description="确定要删除该任务下的构建吗?"
                            onConfirm={() => {
                                this.deltesttaskbuilds(text.key)
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type={"link"}
                                    style={{width: 90}}
                                    danger
                                    size={"small"}>
                                <Space size={5}>
                                    <DeleteOutlined/>
                                    <div
                                        style={{
                                            fontWeight: 800,
                                            fontSize: 12
                                        }}>删除构建
                                    </div>
                                </Space>
                            </Button>
                        </Popconfirm>
                        <Divider type={"vertical"}/>
                    </Space>
                }
            }
        ];
        return <>
            {this.state.BuildsStatus.JenkinsTaskStatus === "WAITING" || this.state.BuildsStatus.JenkinsTaskStatus === "RUNNING" ?
                <div style={{height: 160}}>
                    <Card style={{
                        backgroundColor: 'rgb(240, 242, 245)',
                        textAlign: "center",
                        fontSize: 12,
                        fontWeight: 800,
                    }}>
                        <Row gutter={10} style={{marginTop: 10, marginBottom: 10}}>
                            <Col span={10}>
                                <Card bordered={false}>
                                    <div style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        textAlign: "center",
                                    }}>
                                        <Statistic
                                            title={<div style={{fontSize: 11}}>正在执行的用例</div>}
                                            value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.ExecutingCase : "无"}
                                            valueStyle={{
                                                color: '#3f8600',
                                                fontSize: 11,
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card bordered={false}>
                                    <div style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        textAlign: "center",
                                    }}>
                                        <Statistic
                                            title={<div style={{fontSize: 11}}>已执行/总数</div>}
                                            // value={"1999/2000"}
                                            value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.CaseExecuted + "/" + this.state.BuildsStatus.CaseTotal : "0/0"}
                                            valueStyle={{
                                                color: '#3f8600',
                                                fontSize: 11,
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col span={10}>
                                <Card bordered={false}>
                                    <div style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        textAlign: "center",
                                    }}>
                                        <Statistic
                                            title={<div style={{fontSize: 11}}>即将执行的用例</div>}
                                            value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.NextExecutionCase : "无"}
                                            valueStyle={{
                                                color: '#3f8600',
                                                fontSize: 11,
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <div style={{
                        position: "absolute",
                        marginTop: "-13px",
                        marginLeft: "20px",
                        background: "#ffffff",
                        borderRadius: "15px 15px 15px 15px",
                        border: '0.5px solid rgb(240, 242, 245)',
                        textAlign: 'center',
                        padding: "4px 10px  0px 10px",
                    }}>
                        <Space size={5} style={{fontSize: 12, fontWeight: 800, marginTop: -5}}>
                            执行进度
                            <Progress percent={
                                Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.TaskProgress : 0
                            } style={{width: 100, marginLeft: 5, marginTop: 5}} size={"small"}/>
                        </Space>
                    </div>
                </div> : null}
            <Table
                size={"middle"}
                bordered={true} columns={TaskColumns}
                dataSource={this.state.datas}
                scroll={{
                    x: 1100,
                    y: 500
                }}
                pagination={{
                    defaultPageSize: 10,
                    position: ['bottomRight'],
                    pageSizeOptions: [10, 100],
                    responsive: true,
                    size: "middle",
                    total: this.state.Total,
                    showTotal: (total, range) => {
                        return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                    },
                    onShowSizeChange: (current, size) => {
                        this.state.setBuildsPageSize(current, size)
                    },
                    onChange: (page, pageSize) => {
                        this.state.setBuildsPageSize(page, pageSize)

                    }
                }}
            />
        </>
    }
}