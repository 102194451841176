import React, {Component} from 'react';
import {Button, Row, Col, Form, Card, Input, Select, message, Space, InputNumber} from "antd";
import Modal from "antd/es/modal/Modal";
import {getUser, Loginout} from "../Users/AuthRouter";
import {AppstoreAddOutlined, EditOutlined, SisternodeOutlined} from "@ant-design/icons";
import api from "../../api/ApiConfig";

export class EditVoiceDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas,
            fun: props.fun,
        }
        this.form = React.createRef();
    }

    userinfo = getUser()

    post_voice_detail = async (values) => {
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        await api.PostVoiceDetail({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({}, function () {
                    message.success("语音库编辑成功")
                    this.state.fun()
                    this.setState({
                        isModalOpen: false
                    })
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const showModal = () => {
            this.setState({
                isModalOpen: true
            })
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = (values) => {
            values['key'] = this.state.datas.key
            this.post_voice_detail(values)
        }
        return <>
            <Button style={{width: 75, fontWeight: 700, fontSize: 12}}
                    size={"small"}
                    onClick={() => {
                        showModal()
                    }}
                    disabled={false} type={"link"} icon={<EditOutlined/>}>
                标注语音
            </Button>
            <Modal title="语音数据标注"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{marginTop: 100, minWidth: 600}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 450,
                                marginTop: 60,
                                marginLeft: 50
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="Gender"
                                        label="性别"
                                        initialValue={this.state.datas.Gender}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择语音提供者性别",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{width: 100, textAlign: "left"}}
                                            placeholder="请选择任务类型"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[
                                                {
                                                    value: 0,
                                                    label: '男',
                                                },
                                                {
                                                    value: 1,
                                                    label: '女',
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="Age"
                                        label="年龄"
                                        initialValue={this.state.datas.Age}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入语音提供者年龄",
                                            }
                                        ]}
                                    >
                                        <InputNumber style={{width: 100, textAlign: "center"}} min={0} max={100}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="VoiceTypeLevel"
                                        label="等级"
                                        initialValue={this.state.datas.VoiceTypeLevel}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择标准等级",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{width: 100, textAlign: "left"}}
                                            placeholder="请选择标准等级"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[
                                                {
                                                    value: 1,
                                                    label: 1,
                                                },
                                                {
                                                    value: 2,
                                                    label: 2,
                                                },
                                                {
                                                    value: 3,
                                                    label: 3,
                                                },
                                                {
                                                    value: 4,
                                                    label: 4,
                                                },
                                                {
                                                    value: 5,
                                                    label: 5,
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="VoiceFileMarks"
                                        label="标注"
                                        initialValue={this.state.datas.VoiceFileMarks}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入自定义特殊标注",
                                            }
                                        ]}
                                    >
                                        <Input style={{width: 300}} showCount maxLength={30}
                                               placeholder={"请输入自定义特殊标注"}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item style={{marginTop: 30, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit">
                                    确认修改
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}