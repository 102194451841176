import React, {Component} from 'react'
import {Descriptions, Space, Tag} from "antd";
import api from "../../../api/ApiConfig";
import {Loginout} from "../../Users/AuthRouter";
import {JsonTools} from "../../AutoTest/TestProjectManagement/JsonTools";
import {SettingOutlined} from "@ant-design/icons";

export class Step1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            taskId: props.taskId,
            datas: {}
        }
    }


    componentDidMount() {
        this.get_app_project_tasks()
    }

    get_app_project_tasks = async (params = {}) => {
        this.setState({
            spinning: true
        })
        params['key'] = this.state.taskId
        await api.GetAppProjectTasks(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    datas: r.datas,
                }, function () {
                })
            }
            setTimeout(() => {
                this.setState({
                    spinning: false
                })
            }, 100)

        })
    }

    render() {
        return (
            <div style={{margin: "20px 80px 30px 80px"}}>
                <Descriptions bordered={true}
                              title={<Space size={5}>
                                  <svg d="1715914535241" className="icon" viewBox="0 0 1024 1024" version="1.1"
                                       xmlns="http://www.w3.org/2000/svg" p-id="15178" width="23" height="23">
                                      <path
                                          d="M1024 232.533333V960c0 36.266667-27.733333 64-64 64H64c-36.266667 0-64-27.733333-64-64V232.533333h1024z"
                                          fill="#E8EAE9" p-id="15179"/>
                                      <path
                                          d="M1024 232.533333V64c0-36.266667-27.733333-64-64-64H64C27.733333 0 0 27.733333 0 64v168.533333h1024z"
                                          fill="#55C0EB" p-id="15180"/>
                                      <path
                                          d="M113.066667 117.333333m-42.666667 0a42.666667 42.666667 0 1 0 85.333333 0 42.666667 42.666667 0 1 0-85.333333 0Z"
                                          fill="#F3705A" p-id="15181"/>
                                      <path
                                          d="M236.8 117.333333m-42.666667 0a42.666667 42.666667 0 1 0 85.333334 0 42.666667 42.666667 0 1 0-85.333334 0Z"
                                          fill="#FFD15C" p-id="15182"/>
                                      <path
                                          d="M885.333333 157.866667c23.466667 0 42.666667-19.2 42.666667-42.666667s-19.2-42.666667-42.666667-42.666667H362.666667c-23.466667 0-42.666667 19.2-42.666667 42.666667s19.2 42.666667 42.666667 42.666667h522.666666z"
                                          fill="#FFFFFF" p-id="15183"/>
                                      <path
                                          d="M563.2 900.266667v-106.666667l-100.266667-21.333333c-6.4-21.333333-14.933333-42.666667-25.6-59.733334l55.466667-87.466666-74.666667-74.666667-87.466666 55.466667c-19.2-10.666667-38.4-19.2-59.733334-25.6l-23.466666-102.4h-106.666667l-23.466667 102.4c-21.333333 6.4-40.533333 14.933333-59.733333 25.6L0 567.466667V960c0 36.266667 27.733333 64 64 64h398.933333l-25.6-40.533333c10.666667-19.2 19.2-38.4 25.6-59.733334l100.266667-23.466666zM194.133333 974.933333c-70.4 0-128-57.6-128-128s57.6-128 128-128 128 57.6 128 128-57.6 128-128 128z"
                                          fill="#415A6B" p-id="15184"/>
                                      <path d="M561.066667 337.066667h366.933333v53.333333H561.066667z" fill="#F3705A"
                                            p-id="15185"/>
                                      <path d="M561.066667 484.266667h366.933333v53.333333H561.066667z" fill="#CED6E0"
                                            p-id="15186"/>
                                      <path d="M561.066667 633.6h247.466666v53.333333H561.066667z" fill="#CED6E0"
                                            p-id="15187"/>
                                  </svg>
                                  <div style={{marginTop: -6}}>提测详情</div>
                              </Space>}
                              style={{display: "block", width: "100%", margin: "0 auto"}}
                              labelStyle={{fontSize: 13, fontWeight: 600, textAlign: "center"}}
                              contentStyle={{whiteSpace: "pre-wrap", fontSize: 12, width: "20%"}}
                >
                    <Descriptions.Item label="发起人员" span={1}>{this.state.datas.PmUserName}</Descriptions.Item>
                    <Descriptions.Item label="配置人员" span={1}>{this.state.datas.DevUserName}</Descriptions.Item>
                    <Descriptions.Item label="创建时间" span={1}>{this.state.datas.cDatasDate}</Descriptions.Item>
                    <Descriptions.Item label="提测标题" span={3}>{this.state.datas.AppTitle}</Descriptions.Item>
                    <Descriptions.Item label="提测备注" span={3}>{this.state.datas.AppNotes}</Descriptions.Item>
                    <Descriptions.Item label="提测环境" span={1}>
                        <Tag
                            color={
                                this.state.datas.AppEnv === "TEST" ? "#5b8c00" :
                                    this.state.datas.AppEnv === "RELEASE" ? "#8d062c" :
                                        this.state.datas.AppEnv === "PRE" ? "#d05715" : "#9254de"
                            }
                            style={{
                                marginRight: 5, textAlign: "center", fontSize: 12,
                                fontWeight: 700, width: 70
                            }}
                        >
                            {this.state.datas.AppEnv === "TEST" ? "测试环境" :
                                this.state.datas.AppEnv === "RELEASE" ? "生产环境" :
                                    this.state.datas.AppEnv === "PRE" ? "预发布" : "未知环境"}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="App类型" span={1}>{this.state.datas.AppType}</Descriptions.Item>
                    <Descriptions.Item label="App版本" span={1}>
                        <Space direction={"vertical"} size={0}>
                            {Object.keys(this.state.datas).length > 0 ? JSON.parse(this.state.datas.AppPlatformType).map((item, index) => {
                                if (item === 1) {
                                    return (
                                        <Space size={0} key={item}>
                                            <Tag color={"#007AFF"} style={{width: 100, textAlign: "center"}}>
                                                IOS
                                            </Tag>
                                            <Tag color={"#007AFF"} style={{width: 100, textAlign: "center"}}>
                                                {this.state.datas.IosVersion}
                                            </Tag>
                                        </Space>
                                    )
                                }
                                if (item === 2) {
                                    return (
                                        <Space size={0}>
                                            <Tag color={"#406244"} style={{width: 100, textAlign: "center"}}>
                                                Android
                                            </Tag>
                                            <Tag color={"#406244"} style={{width: 100, textAlign: "center"}}>
                                                {this.state.datas.AndroidVersion}
                                            </Tag>
                                        </Space>
                                    )
                                } else {
                                    return (
                                        <Space size={0}>
                                            <Tag color={"#d6a746"} style={{width: 100, textAlign: "center"}}>
                                                HarmonyOS
                                            </Tag>
                                            <Tag color={"#d6a746"} style={{width: 100, textAlign: "center"}}>
                                                {this.state.datas.HarmonyOSVersion}
                                            </Tag>
                                        </Space>
                                    )
                                }
                            }) : "无"
                            }
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="代码分支" span={1}>{this.state.datas.GitBranch}</Descriptions.Item>
                    <Descriptions.Item label="所属项目" span={1}>{this.state.datas.Subproject}</Descriptions.Item>
                    <Descriptions.Item label="提测轮数" span={1}>{this.state.datas.TestNumber}</Descriptions.Item>
                    <Descriptions.Item label="提测内容" span={3} labelStyle={{textAlign: "center"}}
                                       style={{textAlign: "left"}}>
                        {this.state.datas.TestContents}
                    </Descriptions.Item>
                    <Descriptions.Item label="影响范围" span={3} labelStyle={{textAlign: "center", fontSize: 13}}
                                       style={{textAlign: "left"}}>
                        {this.state.datas.AffectedScope}
                    </Descriptions.Item>
                    <Descriptions.Item label="测试建议" span={3} labelStyle={{textAlign: "center", fontSize: 13}}
                                       style={{textAlign: "left"}}>
                        {this.state.datas.TestSuggestions}
                    </Descriptions.Item>
                    <Descriptions.Item label="打包配置" span={3} labelStyle={{textAlign: "center", fontSize: 13}}
                                       style={{textAlign: "left"}}>
                        <JsonTools jsonData={this.state.datas.PackageConfiguration} collapsed={true}/>
                    </Descriptions.Item>
                </Descriptions>
            </div>
        );
    }
}
