import React, {Component} from 'react';
import {Button, Row, Col, Form, Card, Input, Select, message, Space, Checkbox, Tooltip, Avatar, Tag} from "antd";
import Modal from "antd/es/modal/Modal";
import {AppstoreAddOutlined, InfoCircleOutlined, QuestionCircleOutlined, RedoOutlined} from "@ant-design/icons";
import {getUser, Loginout} from "../../Users/AuthRouter";
import api from "../../../api/ApiConfig";

export class PmCreateTask extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            fun: props.fun,
            IosVersion: false,
            AndroidVersion: false,
            HarmonyOSVersion: false
        }
        this.form = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
        }
    }

    componentDidMount() {

    }

    userinfo = getUser()

    post_app_project_tasks = async (values) => {
        await api.PostAppProjectTasks({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    message.success("创建成功")
                    this.state.fun()
                    this.form.current.resetFields();
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const showModal = () => {
            this.setState({
                isModalOpen: true
            })
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = (values) => {
            console.log("提交任务", values)
            values['PmUserId'] = this.userinfo.UserId
            values['PmUserName'] = this.userinfo.username
            values['AppPlatformType'] = JSON.stringify(values['AppPlatformType'])
            this.post_app_project_tasks(values)
        }
        return <>
            <Button type={'link'} style={{width: 90, fontWeight: 700, fontSize: 12}} size={"small"} disabled={false}
                    onClick={showModal} icon={<AppstoreAddOutlined/>}>
                发起提测
            </Button>
            <Modal title="创建提测任务"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{minWidth: 700}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 470,
                                marginTop: 30,
                                marginLeft: 50,
                                fontWeight: 600
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="AppTitle"
                                        label="提测标题"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入提测标题",
                                            }
                                        ]}
                                    >
                                        <Input showCount maxLength={100} placeholder={"请输入提测标题"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="AppType"
                                        label="App类型"
                                        initialValue={2}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择App类型",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{textAlign: "left"}}
                                            showSearch
                                            placeholder="请选择项目类型"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[
                                                {
                                                    value: 1,
                                                    label: 'MosHome',
                                                    disabled: false
                                                },
                                                {
                                                    value: 2,
                                                    label: '公牛E家',
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="AppEnv"
                                        label="提测环境"
                                        initialValue={"TEST"}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择提测环境",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{textAlign: "left"}}
                                            showSearch
                                            placeholder="请选择提测环境"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[
                                                {
                                                    value: "TEST",
                                                    label: '测试环境',
                                                },
                                                {
                                                    value: "RELEASE",
                                                    label: '生产环境',
                                                    disabled: true
                                                },
                                                {
                                                    value: "PRE",
                                                    label: '预发环境',
                                                    disabled: true
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="AppPlatformType"
                                        label="平台类型"
                                        initialValue={[]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择App平台",
                                            }
                                        ]}
                                    >
                                        <Checkbox.Group
                                            onChange={(checkedValue) => {
                                                console.log("e", checkedValue)
                                                if (checkedValue.includes(1)) {
                                                    this.setState({
                                                        IosVersion: true
                                                    })
                                                } else {
                                                    this.setState({
                                                        IosVersion: false
                                                    })
                                                }
                                                if (checkedValue.includes(2)) {
                                                    this.setState({
                                                        AndroidVersion: true
                                                    })
                                                } else {
                                                    this.setState({
                                                        AndroidVersion: false
                                                    })
                                                }
                                                if (checkedValue.includes(3)) {
                                                    this.setState({
                                                        HarmonyOSVersion: true
                                                    })
                                                } else {
                                                    this.setState({
                                                        HarmonyOSVersion: false
                                                    })
                                                }
                                            }}
                                            options={[
                                                {label: 'IOS', value: 1, disabled: false},
                                                {label: 'Android', value: 2, disabled: false},
                                                {label: 'HarmonyOS', value: 3, disabled: true},
                                            ]}/>
                                    </Form.Item>
                                </Col>
                                {this.state.IosVersion ?
                                    <Col span={24}>
                                        <Form.Item
                                            name="IosVersion"
                                            label="苹果版本"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入IOS提测版本号",
                                                }
                                            ]}
                                        >
                                            <Input showCount maxLength={100} placeholder="请输入IOS版本号"
                                                // onPressEnter={(e) => {
                                                //     this.form.current.setFieldsValue({"AndroidVersion": e.target.value})
                                                //     this.form.current.setFieldsValue({"HarmonyOSVersion": e.target.value})
                                                // }}
                                            />
                                        </Form.Item>
                                    </Col> : null
                                }
                                {this.state.AndroidVersion ?
                                    <Col span={24}>
                                        <Form.Item
                                            name="AndroidVersion"
                                            label="安卓版本"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入Android版本号",
                                                }
                                            ]}
                                        >
                                            <Input showCount maxLength={100} placeholder="请输入Android版本号"/>
                                        </Form.Item>
                                    </Col> : null
                                }
                                {this.state.HarmonyOSVersion ? <Col span={24}>
                                    <Form.Item
                                        name="HarmonyOSVersion"
                                        label="鸿蒙版本"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入HarmonyOS版本号",
                                            }
                                        ]}
                                    >
                                        <Input showCount maxLength={100} placeholder="请输入HarmonyOS版本号"/>
                                    </Form.Item>
                                </Col> : null
                                }
                                <Col span={24}>
                                    <Form.Item
                                        name="AppNotes"
                                        label="提测备注"
                                        initialValue={"无"}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入提测备注",
                                            }
                                        ]}
                                    >
                                        <Input.TextArea autoSize={true} allowClear={true} showCount maxLength={300}
                                                        placeholder={"请输入提测备注"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Space size={5} style={{fontSize: 12, fontWeight: 500, color: "#9b1313"}}>
                                        Ps：任务发起后会通知【研发同学】补充构建、提测相关参数执行后续流程
                                    </Space>
                                </Col>
                            </Row>
                            <Form.Item style={{marginTop: 30, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit" style={{fontSize: 13, fontWeight: 800}}>
                                    发起提测
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}