import React, {Component} from "react";
import {
    Badge,
    Button,
    Card,
    Descriptions,
    Divider,
    FloatButton,
    Input,
    Mentions,
    message,
    Popconfirm,
    Progress,
    Rate,
    Segmented,
    Select,
    Space,
    Spin,
    Table,
    Tag,
    Tooltip
} from "antd";

import api from "../../../api/ApiConfig";
import {getUser, Loginout} from "../../Users/AuthRouter";
import dayjs from "dayjs";
import {NavLink} from "react-router-dom";
import {
    AppstoreOutlined, ClearOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";
import {PmCreateTask} from "./PmCreateTask";
import {DevCreateTask} from "./DevCreateTask";
import {JsonTools} from "../../AutoTest/TestProjectManagement/JsonTools";
import Avatar from "antd/es/avatar/avatar";
import Text from "antd/es/typography/Text";

export class AppProjectList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: [],
            AppTitle: null,
            AppType: -1,
            AppEnv: -1,
            ProjectTaskStatus: -1,
            pageSize: 10,
            page: 1,
            Total: 0,
            spinning: false,
            userList: []
        }
    }

    componentDidMount() {
        this.get_app_project_tasks()
        this.getuserlist_atmp()
    }

    userinfo = getUser()

    get_app_project_tasks = async (params = {}) => {
        this.setState({
            spinning: true
        })
        params['UserId'] = this.userinfo.UserId
        if (this.state.AppTitle !== null) {
            params['AppTitle'] = this.state.AppTitle
        }
        if (this.state.AppEnv !== -1) {
            params['AppEnv'] = this.state.AppEnv
        }
        if (this.state.ProjectTaskStatus !== -1) {
            params['ProjectTaskStatus'] = this.state.ProjectTaskStatus
        }
        if (this.state.AppType !== -1) {
            params['AppType'] = this.state.AppType
        }
        params['pageSize'] = this.state.pageSize
        params['page'] = this.state.page

        await api.GetAppProjectTasks(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    datas: r.datas,
                    Total: r.Total
                })
            }
            setTimeout(() => {
                this.setState({
                    spinning: false
                })
            }, 100)

        })
    }

    delappprojecttask = async (TaskId) => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['key'] = TaskId
        await api.DelAppProjectTask({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    message.success("删除任务成功")
                    this.get_app_project_tasks()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }
    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <Space size={2} style={{marginTop: -5}}>
                            <Avatar key={key} style={{marginTop: -5}} size={30} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, fontWeight: 600}}>{value.name}({value.user_id})
                            </Text>
                        </Space>,
                        label1: <Space size={2} style={{marginTop: -5}}>
                            <Avatar key={key} size={20} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, fontWeight: 600, color: "#1677ff"}}>{value.name}({value.user_id})
                            </Text>
                        </Space>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }

    render() {
        const ProjectColumns = [
            {
                title: 'No.',
                align: 'center',
                width: 40,
                render: (_, record, index) => {
                    return <div>{index + 1}</div>
                }
            },
            {
                title: '提测信息',
                align: 'center',
                width: 350,
                dataIndex: 'AppTitle',
                render: (text, record, index) => {
                    return <div
                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <Badge.Ribbon
                            placement={"start"}
                            color={"yellow"}
                            text={<div style={{fontSize: 12, fontWeight: 500, textAlign: "center"}}>
                                {
                                    ([`${record.PmUserName}(${record.PmUserId})`]).map((value, index) => {
                                        for (let i = 0; i < this.state.userList.length; i++) {
                                            if (value === this.state.userList[i].value) {
                                                return this.state.userList[i].label
                                            }
                                        }
                                    })
                                }
                            </div>}>
                            <Card bordered={true} style={{
                                fontSize: 12, fontWeight: 500, width: 400, maxHeight: 100, textAlign: "left"
                            }}>
                                <Space direction={"vertical"} size={2}>
                                    <div style={{marginTop: 10, fontSize: 12}}>{text}</div>
                                    <Space direction={"horizontal"} size={5}>
                                        <div style={{
                                            fontSize: 10,
                                            color: "#938f8f"
                                        }}>创建时间 {dayjs(record.cDatasDate).format("YYYY-MM-DD HH:mm:ss")}</div>
                                        <div style={{
                                            fontSize: 10,
                                            color: "#938f8f"
                                        }}>更新时间 {dayjs(record.uDatasDate).format("YYYY-MM-DD HH:mm:ss")}</div>
                                    </Space>
                                </Space>
                            </Card>
                        </Badge.Ribbon>
                    </div>
                }
            },
            {
                title: '提测环境',
                width: 100,
                align: 'center',
                dataIndex: 'AppEnv',
                render: (text, record, index) => {
                    return <div>
                        <Tag
                            color={
                                text === "TEST" ? "#5b8c00" :
                                    text === "RELEASE" ? "#8d062c" :
                                        text === "PRE" ? "#d05715" : "#9254de"
                            }
                            style={{
                                marginRight: 5,
                                textAlign: "center",
                                fontSize: 12,
                                fontWeight: 700,
                                width: 70,
                            }}
                        >
                            {text === "TEST" ? "测试环境" :
                                text === "RELEASE" ? "生产环境" :
                                    text === "PRE" ? "预发布" : "未知环境"}
                        </Tag>
                    </div>
                }
            },
            {
                title: 'App类型',
                align: 'center',
                width: 100,
                dataIndex: 'AppType',
                render: (text, record, index) => {
                    return <div style={{fontSize: 12, fontWeight: 600}}>
                        {text === 1 ? <Tag color={"#1d2ea0"}>MosHome</Tag> : text === 2 ?
                            <Tag color={"#982206"}>公牛E家</Tag> : text === 3 ? "其他" : "未知"}
                    </div>
                }
            },
            {
                title: 'App版本',
                width: 140,
                align: 'center',
                dataIndex: 'AppPlatformType',
                render: (text, record, index) => {
                    return <div style={{textAlign: "center", fontSize: 12, fontWeight: 600}}>
                        <Space direction={"vertical"} size={0}>
                            {JSON.parse(text).map((item, index) => {
                                if (item === 1) {
                                    return (
                                        <Space size={0}>
                                            <Tag color={"#007AFF"} style={{width: 100, textAlign: "center"}}>
                                                IOS
                                            </Tag>
                                            <Tag color={"#007AFF"} style={{width: 100, textAlign: "center"}}>
                                                {record.IosVersion}
                                            </Tag>
                                        </Space>
                                    )
                                }
                                if (item === 2) {
                                    return (
                                        <Space size={0}>
                                            <Tag color={"#406244"} style={{width: 100, textAlign: "center"}}>
                                                Android
                                            </Tag>
                                            <Tag color={"#406244"} style={{width: 100, textAlign: "center"}}>
                                                {record.AndroidVersion}
                                            </Tag>
                                        </Space>
                                    )
                                } else {
                                    return (
                                        <Space size={0}>
                                            <Tag color={"#d6a746"} style={{width: 100, textAlign: "center"}}>
                                                HarmonyOS
                                            </Tag>
                                            <Tag color={"#d6a746"} style={{width: 100, textAlign: "center"}}>
                                                {record.HarmonyOSVersion}
                                            </Tag>
                                        </Space>
                                    )
                                }
                            })}
                        </Space>
                    </div>
                }
            },
            // {
            //     title: '发起人',
            //     align: 'center',
            //     width: 170,
            //     render: (text, record, index) => {
            //         return <div style={{fontSize: 12, fontWeight: 500, textAlign: "left"}}>
            //             {
            //                 ([`${record.PmUserName}(${record.PmUserId})`]).map((value, index) => {
            //                     for (let i = 0; i < this.state.userList.length; i++) {
            //                         if (value === this.state.userList[i].value) {
            //                             return this.state.userList[i].label
            //                         }
            //                     }
            //                 })
            //             }
            //         </div>
            //     }
            // },
            {
                title: '任务状态',
                width: 100,
                align: 'center',
                dataIndex: 'ProjectTaskStatus',
                render: (text, record) => {
                    return <Space size={0} direction={"vertical"}>
                        {/*<Progress percent={98} width={50} type={"dashboard"} status={"active"}/>*/}
                        {text === 1 ?
                            <Space>
                                <Badge status="warning"/>
                                <Tag color="#fadb14" style={{
                                    color: "black",
                                    marginRight: 5,
                                    textAlign: "center",
                                    fontSize: 12,
                                    fontWeight: 700,
                                    width: 80,
                                }}>
                                    待确认
                                </Tag>
                            </Space>
                            : text === 2 ?
                                <Space>
                                    <Badge status="processing"/>
                                    <Tag color="#9254de" style={{
                                        color: "white",
                                        marginRight: 5,
                                        textAlign: "center",
                                        fontSize: 12,
                                        fontWeight: 700,
                                        width: 80,
                                    }}>
                                        进行中
                                    </Tag></Space>
                                : text === 3 ?
                                    <Space>
                                        <Badge status="success"/>
                                        <Tag color="green-inverse"
                                             style={{
                                                 color: "white",
                                                 marginRight: 5,
                                                 textAlign: "center",
                                                 fontSize: 12,
                                                 fontWeight: 700,
                                                 width: 80,
                                             }}>
                                            提测成功
                                        </Tag>
                                    </Space>
                                    : text === 4 ?
                                        <Space>
                                            <Badge status="error"/>
                                            <Tag color="#ff7875" style={{
                                                color: "white",
                                                marginRight: 5,
                                                textAlign: "center",
                                                fontSize: 12,
                                                fontWeight: 700,
                                                width: 80,
                                            }}>
                                                提测失败
                                            </Tag>
                                        </Space> : "未知"}
                    </Space>
                }
            },
            // {
            //     title: '操作时间',
            //     width: 200,
            //     align: 'center',
            //     render: (text, record, index) => {
            //         return <Space direction={"vertical"} size={0}>
            //             <Space size={1} style={{
            //                 fontSize: 10,
            //                 // fontWeight: 500,
            //                 color: "#938f8f"
            //             }}>开始时间：{dayjs(record.cDatasDate).format("YYYY-MM-DD HH:mm:ss")} </Space>
            //             <Space size={1} style={{
            //                 fontSize: 10,
            //                 // fontWeight: 500,
            //                 color: "#938f8f"
            //             }}>结束时间：{dayjs(record.uDatasDate).format("YYYY-MM-DD HH:mm:ss")}</Space>
            //         </Space>
            //     }
            // },
            {
                title: '任务操作',
                align: 'center',
                width: 150,
                render: (_, record) => {
                    return <Space size={0} direction={"horizontal"}>
                        {
                            record.ProjectTaskStatus === 1 ?
                                <DevCreateTask record={record} fun={this.get_app_project_tasks}/> :
                                <NavLink to={'/AppProjectDetails/' + record.key}>
                                    <Button style={{width: 80, fontWeight: 700, fontSize: 12}}
                                            size={"small"}
                                            disabled={false} type={"link"} icon={<AppstoreOutlined/>}>
                                        任务详情
                                    </Button>
                                </NavLink>
                        }
                        <Divider type={"vertical"}/>
                        <Popconfirm
                            title="删除任务"
                            description="确定要删除这个提测任务吗?"
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red'
                                    }}
                                />
                            }
                            onConfirm={() => {
                                this.delappprojecttask(record.key)
                            }}
                        >
                            <Button style={{width: 80, fontWeight: 700, fontSize: 12, marginLeft: -5}} size={"small"}
                                    disabled={false} type={"link"} danger={true} icon={<DeleteOutlined/>}>
                                删除任务
                            </Button>
                        </Popconfirm>
                    </Space>
                }
            },
        ];
        const expandedRowRender = (record, index) => {
            let PackageConfigurationObj = {}
            try {
                // 将单引号替换为双引号
                const jsonStringWithDoubleQuotes = record.PackageConfiguration
                // 解析 JSON 字符串为 JavaScript 对象
                PackageConfigurationObj = JSON.parse(jsonStringWithDoubleQuotes);
                console.log("PackageConfigurationObj", PackageConfigurationObj)
            } catch (e) {
                console.log("服务列表解析异常", record)
            }
            return <Card>
                <Descriptions bordered={true} size={"small"}
                              contentStyle={{whiteSpace: "pre-wrap", fontSize: 12, width: "20%"}}
                              labelStyle={{width: "5%", textAlign: "center"}}
                              title={<Space size={2} style={{fontSize: 12}}>
                                  提测配置
                                  <div style={{fontSize: 12, fontWeight: 500, textAlign: "left"}}>
                                      {
                                          ([`${record.DevUserName}(${record.DevUserId})`]).map((value, index) => {
                                              for (let i = 0; i < this.state.userList.length; i++) {
                                                  if (value === this.state.userList[i].value) {
                                                      return this.state.userList[i].label1
                                                  }
                                              }
                                          })
                                      }
                                  </div>
                              </Space>}
                              style={{whiteSpace: "pre-wrap", fontSize: 12, fontWeight: 500}}>
                    <Descriptions.Item label="提测备注" span={3}
                                       style={{whiteSpace: "pre-wrap", fontSize: 12, fontWeight: 500}}>
                        {record.AppNotes}
                    </Descriptions.Item>
                    <Descriptions.Item label="代码分支" span={1}
                                       style={{
                                           whiteSpace: "pre-wrap",
                                           fontSize: 12,
                                           fontWeight: 500,
                                           textAlign: "center"
                                       }}>
                        {record.GitBranch}
                    </Descriptions.Item>
                    <Descriptions.Item label="提测轮数" span={1}
                                       style={{
                                           whiteSpace: "pre-wrap",
                                           fontSize: 12,
                                           fontWeight: 500,
                                           textAlign: "center"
                                       }}>
                        {record.TestNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="所属项目" span={1}
                                       style={{
                                           whiteSpace: "pre-wrap",
                                           fontSize: 12,
                                           fontWeight: 500,
                                           textAlign: "center"
                                       }}>
                        {record.Subproject}
                    </Descriptions.Item>
                    <Descriptions.Item label="提测内容" span={1}
                                       style={{whiteSpace: "pre-wrap", fontSize: 12, fontWeight: 500}}>
                        {record.TestContents}
                    </Descriptions.Item>
                    <Descriptions.Item label="影响范围" span={1}
                                       style={{whiteSpace: "pre-wrap", fontSize: 12, fontWeight: 500}}>
                        {record.AffectedScope}
                    </Descriptions.Item>
                    <Descriptions.Item label="测试建议" span={1}
                                       style={{whiteSpace: "pre-wrap", fontSize: 12, fontWeight: 500}}>
                        {record.TestSuggestions}
                    </Descriptions.Item>
                    <Descriptions.Item label="打包配置" span={3}
                                       style={{whiteSpace: "pre-wrap", fontSize: 12, fontWeight: 500}}>
                        <JsonTools jsonData={PackageConfigurationObj} collapsed={true}/></Descriptions.Item>
                </Descriptions>
            </Card>
        };
        return <Card
            extra={<Space>
                <PmCreateTask fun={this.get_app_project_tasks}/>
                <DevCreateTask record={{}} fun={this.get_app_project_tasks}/>
            </Space>}
            title={
                <Space size={10} style={{margin: "20px 20px 20px 0px"}}>
                    <Select
                        style={{minWidth: 150, textAlign: "center"}}
                        showSearch={false}
                        placeholder="请选择环境"
                        value={this.state.AppEnv}
                        onChange={(value, option) => {
                            this.setState({
                                AppEnv: value
                            })
                        }}
                        options={[
                            {
                                value: -1,
                                label: '所有环境',
                            },
                            {
                                value: "TEST",
                                label: '测试环境',
                            },
                            {
                                value: "RELEASE",
                                label: '生产环境',
                            },
                            {
                                value: "PRE",
                                label: '预发布环境',
                            }
                        ]}
                    />
                    <Select
                        style={{minWidth: 150, textAlign: "center"}}
                        showSearch={false}
                        placeholder="App类型"
                        value={this.state.AppType}
                        onChange={(value, option) => {
                            this.setState({
                                AppType: value
                            })
                        }}
                        options={[{
                            value: -1, label: '所有类型',
                        }, {
                            value: 1, label: 'MosHome',
                        }, {
                            value: 2, label: '公牛E家',
                        }]}
                    />
                    <Select
                        style={{minWidth: 150, textAlign: "center"}}
                        showSearch={false}
                        placeholder="请选择任务状态"
                        value={this.state.ProjectTaskStatus}
                        onChange={(value, option) => {
                            this.setState({
                                ProjectTaskStatus: value
                            })
                        }}
                        options={[
                            {
                                value: -1,
                                label: '所有状态',
                            },
                            {
                                value: 1,
                                label: '待确认',
                            },
                            {
                                value: 2,
                                label: '进行中',
                            },
                            {
                                value: 3,
                                label: '提测成功',
                            },
                            {
                                value: 4,
                                label: '提测失败',
                            }
                        ]}
                    />
                    <Input style={{minWidth: 200, textAlign: "center"}}
                           value={this.state.AppTitle}
                           placeholder={"提测标题(模糊查询)"}
                           onChange={(v) => {
                               this.setState({
                                   AppTitle: v.target.value
                               })
                           }}
                    />
                    <Button size={"middle"} type={"primary"}
                            style={{fontSize: 12, fontWeight: 700}}
                            onClick={() => {
                                this.get_app_project_tasks()
                            }}
                    >
                        <Space size={5}>
                            <SearchOutlined/>搜索
                        </Space>
                    </Button>
                    <Button size={"middle"} type={"primary"}
                            style={{fontSize: 12, fontWeight: 700}}
                            onClick={() => {
                                this.setState({
                                    AppEnv: -1,
                                    AppPlatformType: -1,
                                    AppType: -1,
                                    ProjectTaskStatus: -1,
                                    AppTitle: null
                                }, function () {
                                    this.get_app_project_tasks()
                                })
                            }}
                    >
                        <Space size={5}>
                            <ClearOutlined/>重置
                        </Space>
                    </Button>
                </Space>
            }>
            <Table
                loading={this.state.spinning}
                expandable={{
                    expandedRowRender: (record, index, indent, expanded) => {
                        return expandedRowRender(record, index)
                    },
                    defaultExpandedRowKeys: [0]
                }}
                scroll={
                    {
                        x: 1300
                    }
                }
                bordered={true} dataSource={this.state.datas}
                columns={ProjectColumns}
                pagination={{
                    position: ['bottomRight'],
                    pageSizeOptions: [10, 20, 30],
                    responsive: true,
                    size: "middle",
                    current: this.state.page,
                    pageSize: this.state.pageSize,
                    total: this.state.Total,
                    showTotal: (total, range) => {
                        return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                    },
                    onShowSizeChange: (current, size) => {
                        this.setState({
                            page: current,
                            pageSize: size
                        }, function () {
                            this.get_app_project_tasks()
                        })
                    },
                    onChange: (page, pageSize) => {
                        this.setState({
                            page: page,
                            pageSize: pageSize
                        }, function () {
                            this.get_app_project_tasks()
                        })
                    }
                }}
            />
            <FloatButton.BackTop visibilityHeight={100}/>
        </Card>
    }
}
