import React, {Component} from 'react';
import '@ant-design/pro-components/dist/components.css';
import {PageHeader} from '@ant-design/pro-layout';
import {Affix, Button, Card, Col, DatePicker, Divider, message, Row, Space, Spin, Table, Tabs} from 'antd'
import {mg, zj, ej} from './IconConfig'
import {
    ArrowDownOutlined, ArrowRightOutlined,
    ArrowUpOutlined,
    BarChartOutlined, BugOutlined, CameraOutlined,
    DashboardOutlined, RedoOutlined,
    SendOutlined,
    TeamOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import {getUser, Loginout} from "../Users/AuthRouter";
import {LineChart} from "./LineChart";
import {BulletChart} from "./BulletChart";
import api from "../../api/ApiConfig";
import moment from "moment";
import dayjs from 'dayjs';
import {NavLink} from "react-router-dom";
import MonthLineChart from "./MonthLineChart";
import html2canvas from "html2canvas";
import {CookieConfig} from "../AppAvailability/CookieConfig";

const {Meta} = Card;

export class ServiceAvailability extends Component {
    constructor(props) {
        super(props)
        this.state = {
            qs: "all",
            env: "all",
            userid: null,
            datas: [],
            table_datas: [],
            Availability: [],
            DeviceAvailability: [],
            DatasDate: dayjs().format("YYYY-MM-DD"),
            spinning: true,
            AppType: "mg",
            SYSTEMAVAILABILITYMONTH: {},
            AppAvailabilityMonth: [],
            SYSTEMAVAILABILITYAPPMONTH: [],
            ReportLoading: false

        }
    }

    componentDidMount() {
        this.getserviceavailability()
        this.getdeviceavailability()
        this.systemavailabilitymonth()
        this.systemavailabilityappmonth()
        this.appavailabilitycrashtrendmonth()
        this.interval = setInterval(() => {
            // this.GetInterfaceDatas({DatasType: 1})
            this.getserviceavailability()
            this.getdeviceavailability()
            this.systemavailabilitymonth()
            this.appavailabilitycrashtrendmonth()
        }, 500000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    getserviceavailability = async (params = {}) => {
        this.setState({
            spinning: true
        })
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['DatasDate'] = this.state.DatasDate
        params['AppType'] = this.state.AppType
        await api.GetServiceAvailability(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    Availability: r.datas
                })
            }
        })
        this.setState({
            spinning: false
        })
    }


    appavailabilitycrashtrendmonth = async (params = {}) => {
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['DatasDate'] = this.state.DatasDate
        params['AppType'] = this.state.AppType
        await api.AppAvailabilityCrashTrendMonth(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    AppAvailabilityMonth: r.datas
                })
            }
        })
    }

    getdeviceavailability = async (params = {}) => {
        this.setState({
            spinning: true
        })
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['DatasDate'] = this.state.DatasDate
        params['AppType'] = this.state.AppType
        await api.GetDeviceAvailability(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    DeviceAvailability: r.datas
                })
            }
        })
        this.setState({
            spinning: false
        })
    }

    systemavailabilitymonth = async (params = {}) => {
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['DatasDate'] = this.state.DatasDate
        params['AppType'] = this.state.AppType
        await api.SystemAvailabilityMonth(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    SYSTEMAVAILABILITYMONTH: Object.keys(r.datas).length > 0 ? r.datas : {}
                })
            }
        })
    }
    systemavailabilityappmonth = async (params = {}) => {
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['DatasDate'] = this.state.DatasDate
        params['AppType'] = this.state.AppType
        await api.SystemAvailabilityAppMonth(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                        SYSTEMAVAILABILITYAPPMONTH: r.datas.length > 0 ? r.datas : []
                    }
                )
            }
        })
    }


    screenshotRef = React.createRef();
    handleScreenshot = (image_type = 0, scale = 1) => {
        html2canvas(this.screenshotRef.current, {
            scale: scale, ignoreElements: function (element) {
                // 返回 true 表示忽略该元素，返回 false 表示不忽略
                return element.classList.contains('ignore-class');
            }
        }).then(async canvas => {
            const image = canvas.toDataURL();
            const link = document.createElement('a');
            link.href = image;
            if (image_type === 1) {
                let values = {}
                const userinfo = getUser()
                values['userid'] = userinfo.UserId
                values['ReportType'] = 1
                values['DatasDate'] = dayjs(this.state.DatasDate).format("YYYY年MM月")
                values['image'] = image
                values['AppType'] = this.state.AppType
                await api.uploadImage({values}).then(r => {
                    if (r.msgcode === 20008) {
                        Loginout()
                        this.props.history.push('/login')
                        window.location.reload()
                    }
                    if (r.msgcode === 200) {
                        message.success("报告发送成功")
                    } else {
                        message.error(r.results)
                    }
                    this.setState({
                        ReportLoading: false
                    })
                })
            } else {
                link.download = this.state.AppType + '_系统可用性汇总数据_' + dayjs(this.state.DatasDate).format("YYYY年MM月") + '.png';
                link.click();
            }
        });
    };


    render() {
        const growth = (datas) => {
            try {
                const currentMonth = dayjs(this.state.DatasDate).month() + 1
                if (currentMonth === 1) {
                    return 0
                }
                let currentValue = 0
                let lastMonth = currentMonth - 1
                let lastValue = 0
                if (datas) {
                    for (let i = 0; i < datas.length; i++) {
                        if (dayjs(datas[i]['month_str']).month() + 1 === currentMonth) {
                            currentValue = datas[i]['avg_rate']
                        }
                        if (dayjs(datas[i]['month_str']).month() + 1 === lastMonth) {
                            lastValue = datas[i]['avg_rate']
                        }
                    }
                    return (currentValue - lastValue).toFixed(2)
                }
                // if (datas.length >= 2) {
                //     const currentValue = datas.slice(-1)[0]
                //     const lastValue = datas.slice(-2, -1)[0]
                //     console.log("当前待计算数据", datas, currentValue, lastValue['avg_rate'])
                //     return (currentValue['avg_rate'] - lastValue['avg_rate']).toFixed(3)
                // }
                return 0
            } catch (e) {
                return 0
            }
        };

        const ServiceAvailabilityValue = growth(this.state.SYSTEMAVAILABILITYMONTH['ServiceAvailabilityList'])
        const DeviceAvailabilityValue = growth(this.state.SYSTEMAVAILABILITYMONTH['DeviceAvailabilityList'])
        let iosValue = []
        let androidValue = []
        for (let i = 0; i < this.state.SYSTEMAVAILABILITYAPPMONTH.length; i++) {
            if (this.state.SYSTEMAVAILABILITYAPPMONTH[i]['type'].includes("Android")) {
                androidValue.push(this.state.SYSTEMAVAILABILITYAPPMONTH[i])
            }
            if (this.state.SYSTEMAVAILABILITYAPPMONTH[i]['type'].includes("Ios")) {
                iosValue.push(this.state.SYSTEMAVAILABILITYAPPMONTH[i])
            }

        }
        const IosAvailabilityValue = growth(iosValue)
        const AndroidAvailabilityValue = growth(androidValue)

        return (
            <div style={{marginTop: 0, background: '#ffffff', minHeight: "87vh"}} ref={this.screenshotRef}>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="系统可用性"
                            subTitle="数据概览"
                            extra={<Space size={0}>
                                <Divider type="vertical"/>
                                <DatePicker
                                    picker={'month'}
                                    placement={"bottomRight"} defaultValue={dayjs()}
                                    allowClear={false}
                                    onChange={(date, dateString) => {
                                        this.setState({
                                            DatasDate: dateString + "-" + "1",
                                        }, function () {
                                            this.getserviceavailability()
                                            this.getdeviceavailability()
                                            this.appavailabilitycrashtrendmonth()
                                            this.systemavailabilitymonth()
                                            this.systemavailabilityappmonth()
                                        })
                                    }}/>
                                <Divider type="vertical"/>
                                <Button type={'link'} style={{width: 100, color: '#707272'}}
                                        icon={<CameraOutlined/>}
                                        onClick={() => {
                                            message.success("即将为您生成截图数据请稍后...", 3)
                                            setTimeout(() => {
                                                this.handleScreenshot()
                                            }, 200); // 延迟 500 毫秒执行
                                        }}>
                                    数据截图
                                </Button>
                                <Divider type="vertical"/>
                                <Button type={'link'} style={{width: 100, color: '#707272'}}
                                        icon={<RedoOutlined/>}
                                        onClick={() => {
                                            this.getserviceavailability()
                                            this.getdeviceavailability()
                                            this.appavailabilitycrashtrendmonth()
                                            this.systemavailabilitymonth()
                                            this.systemavailabilityappmonth()
                                        }}>
                                    刷新数据
                                </Button>
                                <Divider type="vertical"/>
                                <Button icon={<SendOutlined/>} type={'link'} style={{width: 100, color: '#707272'}}
                                        onClick={() => {
                                            this.setState({
                                                ReportLoading: true
                                            })
                                            message.success("正在为您生成测试报告请稍后...", 3)
                                            setTimeout(() => {
                                                this.handleScreenshot(1, 1)
                                            }, 500);
                                        }}
                                        loading={this.state.ReportLoading}
                                >
                                    发送报告
                                </Button>
                                <Divider type="vertical"/>
                            </Space>}
                        >
                            <Content/>
                        </PageHeader>
                    </div>
                </Affix>
                    <Spin tip="请稍后,数据加载中..." spinning={this.state.spinning}>
                        <Card>
                            <Tabs
                                // tabBarExtraContent={<Button disabled={true}>切换账号、环境</Button>}
                                // centered
                                // activeKey={'2'}
                                style={{marginLeft: 10, marginTop: 10}}
                                onChange={(value) => {
                                    this.setState({
                                        AppType: value
                                    }, function () {
                                        this.getserviceavailability()
                                        this.getdeviceavailability()
                                        this.appavailabilitycrashtrendmonth()
                                        this.systemavailabilitymonth()
                                        this.systemavailabilityappmonth()
                                    })
                                }}
                                type="card"
                                // tabPosition={"top"}
                                items={
                                    [
                                        {
                                            key: 'mg',
                                            label: (
                                                <Space className={'ignore-class'}>
                                                    <img style={{
                                                        width: 30, height: 30, borderRadius: 1
                                                    }}
                                                        // src={"https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/%E6%B2%90%E5%85%89log.png"}
                                                         src={mg}
                                                         alt="pro-logo"/>
                                                    <b>沐光</b>
                                                </Space>
                                            ),
                                            children: <Row gutter={[16, 16]}>
                                                <Col span={11}>
                                                    <Card
                                                        title={"年度服务可用性" + "(" + dayjs(this.state.DatasDate).year() + "年)"}
                                                        style={{height: 300}}
                                                        extra={<Space size={20} style={{
                                                            fontSize: 12,
                                                            color: "#d7073e"
                                                        }}>
                                                            {ServiceAvailabilityValue > 0 ?
                                                                <div
                                                                    style={{color: "#bb1d1d"}}>相较于上月【增长 <ArrowUpOutlined
                                                                    style={{fontSize: 12}}/> +{ServiceAvailabilityValue}%
                                                                    】
                                                                </div> :
                                                                ServiceAvailabilityValue < 0 ?
                                                                    <div
                                                                        style={{color: "#055008"}}>相较于上月【增长 <ArrowDownOutlined
                                                                        style={{fontSize: 12}}/> {ServiceAvailabilityValue}%
                                                                        】
                                                                    </div> : <div
                                                                        style={{color: "#6d45c2"}}>相较于上月【增长 <ArrowRightOutlined
                                                                        style={{fontSize: 12}}/> +{ServiceAvailabilityValue}%
                                                                        】
                                                                    </div>}
                                                        </Space>
                                                        }
                                                    >
                                                        <MonthLineChart
                                                            datas={this.state.SYSTEMAVAILABILITYMONTH['ServiceAvailabilityList'] ? this.state.SYSTEMAVAILABILITYMONTH['ServiceAvailabilityList'] : []}/>
                                                    </Card>
                                                </Col>
                                                <Col span={13}>
                                                    <Card
                                                        bordered={true}
                                                        style={{height: 300}}
                                                        extra={
                                                            <Space size={5}>
                                                                <NavLink
                                                                    to={'/ServiceDetail/' + this.state.AppType + "/" + "day"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<DashboardOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -150, width: 80}}>*/}
                                                                        今日详情
                                                                    </Button>
                                                                </NavLink>
                                                                <NavLink
                                                                    to={'/ServiceDetail/' + this.state.AppType + "/" + "month"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<BarChartOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -11, width: 80}}>*/}
                                                                        月度详情
                                                                    </Button>
                                                                </NavLink>
                                                            </Space>
                                                        }
                                                        title={
                                                            <div>服务可用性({dayjs(this.state.DatasDate).month() + 1}月)</div>}
                                                    >
                                                        <Meta
                                                            description={
                                                                <div style={{marginTop: 40}}>
                                                                    <BulletChart datas={this.state.Availability}/>
                                                                </div>
                                                            }
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={11}>
                                                    <Card
                                                        title={"年度设备可用性" + "(" + dayjs(this.state.DatasDate).year() + "年)"}
                                                        style={{height: 300}}
                                                        extra={<Space size={20} style={{
                                                            fontSize: 12,
                                                            color: "#d7073e"
                                                        }}>
                                                            {DeviceAvailabilityValue > 0 ?
                                                                <div
                                                                    style={{color: "#bb1d1d"}}>相较于上月【增长 <ArrowUpOutlined
                                                                    style={{fontSize: 12}}/> +{DeviceAvailabilityValue}%
                                                                    】
                                                                </div> :
                                                                DeviceAvailabilityValue < 0 ?
                                                                    <div
                                                                        style={{color: "#74ab7b"}}>相较于上月【增长 <ArrowDownOutlined
                                                                        style={{fontSize: 12}}/> {DeviceAvailabilityValue}%
                                                                        】
                                                                    </div> : <div
                                                                        style={{color: "#6d45c2"}}>相较于上月【增长 <ArrowRightOutlined
                                                                        style={{fontSize: 12}}/> +{DeviceAvailabilityValue}%
                                                                        】
                                                                    </div>}
                                                        </Space>
                                                        }
                                                    >
                                                        <MonthLineChart
                                                            datas={this.state.SYSTEMAVAILABILITYMONTH['DeviceAvailabilityList'] ? this.state.SYSTEMAVAILABILITYMONTH['DeviceAvailabilityList'] : []}/>
                                                    </Card>
                                                </Col>
                                                <Col span={13}>
                                                    <Card
                                                        bordered={true}
                                                        style={{height: 300}}
                                                        extra={
                                                            <Space size={5}>
                                                                <NavLink
                                                                    to={'/DevicesDetail/' + this.state.AppType + "/" + "day"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<DashboardOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -150, width: 80}}>*/}
                                                                        今日详情
                                                                    </Button>
                                                                </NavLink>
                                                                <NavLink
                                                                    to={'/DevicesDetail/' + this.state.AppType + "/" + "month"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<BarChartOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -11, width: 80}}>*/}
                                                                        月度详情
                                                                    </Button>
                                                                </NavLink>
                                                            </Space>
                                                        }
                                                        title={
                                                            <div>设备可用性({dayjs(this.state.DatasDate).month() + 1}月)</div>}
                                                    >
                                                        <Meta
                                                            description={
                                                                <div style={{marginTop: 40}}>
                                                                    <BulletChart datas={this.state.DeviceAvailability}/>
                                                                </div>
                                                            }
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={11}>
                                                    <Card
                                                        title={"年度App可用性" + "(" + dayjs(this.state.DatasDate).year() + "年)"}
                                                        extra={
                                                            <div>
                                                                <Space>
                                                                    <Space size={1} style={{
                                                                        fontSize: 12,
                                                                        color: "#d7073e"
                                                                    }} direction="vertical">
                                                                        {IosAvailabilityValue > 0 ?
                                                                            <div
                                                                                style={{color: "#bb1d1d"}}>苹果相较于上月【增长 <ArrowUpOutlined
                                                                                style={{fontSize: 12}}/> +{IosAvailabilityValue}%
                                                                                】
                                                                            </div> :
                                                                            IosAvailabilityValue < 0 ?
                                                                                <div
                                                                                    style={{color: "#055008"}}>苹果相较于上月【增长 <ArrowDownOutlined
                                                                                    style={{fontSize: 12}}/> {IosAvailabilityValue}%
                                                                                    】
                                                                                </div> : <div
                                                                                    style={{color: "#6d45c2"}}>苹果相较于上月【增长 <ArrowRightOutlined
                                                                                    style={{fontSize: 12}}/> +{IosAvailabilityValue}%
                                                                                    】
                                                                                </div>}
                                                                        {AndroidAvailabilityValue > 0 ?
                                                                            <div
                                                                                style={{color: "#bb1d1d"}}>安卓相较于上月【增长 <ArrowUpOutlined
                                                                                style={{fontSize: 12}}/> +{AndroidAvailabilityValue}%
                                                                                】
                                                                            </div> :
                                                                            AndroidAvailabilityValue < 0 ?
                                                                                <div
                                                                                    style={{color: "#055008"}}>安卓相较于上月【增长 <ArrowDownOutlined
                                                                                    style={{fontSize: 12}}/> {AndroidAvailabilityValue}%
                                                                                    】
                                                                                </div> : <div
                                                                                    style={{color: "#6d45c2"}}>安卓相较于上月【增长 <ArrowRightOutlined
                                                                                    style={{fontSize: 12}}/> +{AndroidAvailabilityValue}%
                                                                                    】
                                                                                </div>}
                                                                    </Space>
                                                                </Space>

                                                            </div>}
                                                        actions={[<CookieConfig/>]}
                                                    >
                                                        <div style={{
                                                            minHeight: 175
                                                        }}>
                                                            <MonthLineChart
                                                                datas={this.state.SYSTEMAVAILABILITYAPPMONTH}/>
                                                        </div>
                                                    </Card>
                                                </Col>
                                                <Col span={13}>
                                                    <Card
                                                        style={{height: 360}}
                                                        bordered={true}
                                                        extra={
                                                            <Space size={5}>
                                                                <Button size={'small'} type={'link'}
                                                                        icon={<BugOutlined/>}
                                                                        href={"https://bugly.qq.com/v2/crash-reporting/dashboard"}
                                                                        target={'_blank'}
                                                                >
                                                                    {/*style={{fontSize: 13, marginLeft: -11, width: 80}}>*/}
                                                                    Bugly 月度详情
                                                                </Button>
                                                            </Space>
                                                        }
                                                        title={
                                                            <div>App可用性({dayjs(this.state.DatasDate).month() + 1}月)</div>}
                                                    >
                                                        <Meta
                                                            description={
                                                                <div style={{marginTop: 60}}>
                                                                    <BulletChart
                                                                        datas={this.state.AppAvailabilityMonth}/>
                                                                </div>}
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        },
                                        {
                                            key: 'zj',
                                            label: (
                                                <Space className={'ignore-class'}>
                                                    <img style={{
                                                        width: 30, height: 30, borderRadius: 5
                                                    }}
                                                        // src={"https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/%E5%85%AC%E7%89%9B%E6%99%BA%E5%AE%B6log.png"}
                                                         src={zj}
                                                         alt="pro-logo"/>
                                                    <b>公牛智家</b>
                                                </Space>
                                            ),
                                            children: <Row gutter={[16, 16]}>
                                                <Col span={11}>
                                                    <Card
                                                        title={"年度服务可用性" + "(" + dayjs(this.state.DatasDate).year() + "年)"}
                                                        style={{height: 300}}
                                                        extra={<Space size={20} style={{
                                                            fontSize: 12,
                                                            color: "#d7073e"
                                                        }}>
                                                            {ServiceAvailabilityValue > 0 ?
                                                                <div
                                                                    style={{color: "#bb1d1d"}}>相较于上月【增长 <ArrowUpOutlined
                                                                    style={{fontSize: 12}}/> +{ServiceAvailabilityValue}%
                                                                    】
                                                                </div> :
                                                                ServiceAvailabilityValue < 0 ?
                                                                    <div
                                                                        style={{color: "#055008"}}>相较于上月【增长 <ArrowDownOutlined
                                                                        style={{fontSize: 12}}/> {ServiceAvailabilityValue}%
                                                                        】
                                                                    </div> : <div
                                                                        style={{color: "#6d45c2"}}>相较于上月【增长 <ArrowRightOutlined
                                                                        style={{fontSize: 12}}/> +{ServiceAvailabilityValue}%
                                                                        】
                                                                    </div>}
                                                        </Space>
                                                        }
                                                    >
                                                        <MonthLineChart
                                                            datas={this.state.SYSTEMAVAILABILITYMONTH['ServiceAvailabilityList'] ? this.state.SYSTEMAVAILABILITYMONTH['ServiceAvailabilityList'] : []}/>
                                                    </Card>
                                                </Col>
                                                <Col span={13}>
                                                    <Card
                                                        bordered={true}
                                                        style={{height: 300}}
                                                        extra={
                                                            <Space size={5}>
                                                                <NavLink
                                                                    to={'/ServiceDetail/' + this.state.AppType + "/" + "day"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<DashboardOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -150, width: 80}}>*/}
                                                                        今日详情
                                                                    </Button>
                                                                </NavLink>
                                                                <NavLink
                                                                    to={'/ServiceDetail/' + this.state.AppType + "/" + "month"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<BarChartOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -11, width: 80}}>*/}
                                                                        月度详情
                                                                    </Button>
                                                                </NavLink>
                                                            </Space>
                                                        }
                                                        title={<div>当月服务可用性</div>}
                                                    >
                                                        <Meta
                                                            description={
                                                                <div style={{marginTop: 40}}>
                                                                    <BulletChart datas={this.state.Availability}/>
                                                                </div>
                                                            }
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={11}>
                                                    <Card
                                                        title={"年度设备可用性" + "(" + dayjs(this.state.DatasDate).year() + "年)"}
                                                        style={{height: 300}}
                                                        extra={<Space size={20} style={{
                                                            fontSize: 12,
                                                            color: "#d7073e"
                                                        }}>
                                                            {DeviceAvailabilityValue > 0 ?
                                                                <div
                                                                    style={{color: "#bb1d1d"}}>相较于上月【增长
                                                                    <ArrowUpOutlined
                                                                        style={{fontSize: 12}}/> +{DeviceAvailabilityValue}%
                                                                    】
                                                                </div> :
                                                                DeviceAvailabilityValue < 0 ?
                                                                    <div
                                                                        style={{color: "#74ab7b"}}>相较于上月【增长 <ArrowDownOutlined
                                                                        style={{fontSize: 12}}/> {DeviceAvailabilityValue}%
                                                                        】
                                                                    </div> : <div
                                                                        style={{color: "#6d45c2"}}>相较于上月【增长 <ArrowRightOutlined
                                                                        style={{fontSize: 12}}/> +{DeviceAvailabilityValue}%
                                                                        】
                                                                    </div>}
                                                        </Space>
                                                        }
                                                    >
                                                        <MonthLineChart
                                                            datas={this.state.SYSTEMAVAILABILITYMONTH['DeviceAvailabilityList'] ? this.state.SYSTEMAVAILABILITYMONTH['DeviceAvailabilityList'] : []}/>
                                                    </Card>
                                                </Col>
                                                <Col span={13}>
                                                    <Card
                                                        bordered={true}
                                                        style={{height: 300}}
                                                        extra={
                                                            <Space size={5}>
                                                                <NavLink
                                                                    to={'/DevicesDetail/' + this.state.AppType + "/" + "day"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<DashboardOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -150, width: 80}}>*/}
                                                                        今日详情
                                                                    </Button>
                                                                </NavLink>
                                                                <NavLink
                                                                    to={'/DevicesDetail/' + this.state.AppType + "/" + "month"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<BarChartOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -11, width: 80}}>*/}
                                                                        月度详情
                                                                    </Button>
                                                                </NavLink>
                                                            </Space>
                                                        }
                                                        title={<div>当月设备可用性</div>}
                                                    >
                                                        <Meta
                                                            description={
                                                                <div style={{marginTop: 40}}>
                                                                    <BulletChart
                                                                        datas={this.state.DeviceAvailability}/>
                                                                </div>
                                                            }
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={11}>
                                                    <Card
                                                        title={"年度App可用性" + "(" + dayjs(this.state.DatasDate).year() + "年)"}
                                                        style={{height: 360}}
                                                        extra={
                                                            <div>
                                                                <Space style={{fontSize: 12}}>
                                                                    <Space size={1} style={{
                                                                        fontSize: 12,
                                                                        color: "#d7073e"
                                                                    }}
                                                                           direction="vertical">
                                                                        {IosAvailabilityValue > 0 ?
                                                                            <div
                                                                                style={{
                                                                                    color: "#bb1d1d",
                                                                                    fontSize: 12
                                                                                }}>苹果相较于上月【增长 <ArrowUpOutlined
                                                                                style={{fontSize: 12}}/> +{IosAvailabilityValue}%
                                                                                】
                                                                            </div> :
                                                                            IosAvailabilityValue < 0 ?
                                                                                <div
                                                                                    style={{
                                                                                        color: "#055008",
                                                                                        fontSize: 12
                                                                                    }}>苹果相较于上月【增长 <ArrowDownOutlined
                                                                                    style={{fontSize: 12}}/> {IosAvailabilityValue}%
                                                                                    】
                                                                                </div> : <div
                                                                                    style={{
                                                                                        color: "#6d45c2",
                                                                                        fontSize: 12
                                                                                    }}>苹果相较于上月【增长 <ArrowRightOutlined
                                                                                    style={{fontSize: 12}}/> +{IosAvailabilityValue}%
                                                                                    】
                                                                                </div>}
                                                                        {AndroidAvailabilityValue > 0 ?
                                                                            <div
                                                                                style={{color: "#bb1d1d"}}>安卓相较于上月【增长 <ArrowUpOutlined
                                                                                style={{fontSize: 12}}/> +{AndroidAvailabilityValue}%
                                                                                】
                                                                            </div> :
                                                                            AndroidAvailabilityValue < 0 ?
                                                                                <div
                                                                                    style={{color: "#055008"}}>安卓相较于上月【增长 <ArrowDownOutlined
                                                                                    style={{fontSize: 12}}/> {AndroidAvailabilityValue}%
                                                                                    】
                                                                                </div> : <div
                                                                                    style={{color: "#6d45c2"}}>安卓相较于上月【增长 <ArrowRightOutlined
                                                                                    style={{fontSize: 12}}/> +{AndroidAvailabilityValue}%
                                                                                    】
                                                                                </div>}
                                                                    </Space>
                                                                </Space>

                                                            </div>}
                                                        actions={[<CookieConfig/>]}
                                                    >
                                                        <MonthLineChart
                                                            datas={this.state.SYSTEMAVAILABILITYAPPMONTH}/>
                                                    </Card>
                                                </Col>
                                                <Col span={13}>
                                                    <Card
                                                        style={{height: 360}}
                                                        bordered={true}
                                                        extra={
                                                            <Space size={5}>
                                                                <Button size={'small'} type={'link'}
                                                                        icon={<BugOutlined/>}
                                                                        href={"https://bugly.qq.com/v2/crash-reporting/dashboard"}
                                                                        target={'_blank'}
                                                                >
                                                                    {/*style={{fontSize: 13, marginLeft: -11, width: 80}}>*/}
                                                                    Bugly 月度详情
                                                                </Button>
                                                            </Space>
                                                        }
                                                        title={<div>当月App可用性</div>}
                                                    >
                                                        <Meta
                                                            description={
                                                                <div style={{marginTop: 60}}>
                                                                    <BulletChart
                                                                        datas={this.state.AppAvailabilityMonth}/>
                                                                </div>}
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        },
                                        {
                                            // disabled: true,
                                            key: 'ej',
                                            label: (
                                                <Space className={'ignore-class'}>
                                                    <img style={{
                                                        width: 30, height: 30, borderRadius: 1
                                                    }}
                                                        // src={"https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/favicon.ico"}
                                                         src={ej}
                                                         alt="pro-logo"/>
                                                    <b>公牛E家</b>
                                                </Space>
                                            ),
                                            children: <Row gutter={[16, 16]}>
                                                <Col span={11}>
                                                    <Card
                                                        title={"年度服务可用性" + "(" + dayjs(this.state.DatasDate).year() + "年)"}
                                                        style={{height: 300}}
                                                        extra={<Space size={20} style={{
                                                            fontSize: 12,
                                                            color: "#d7073e"
                                                        }}>
                                                            {ServiceAvailabilityValue > 0 ?
                                                                <div
                                                                    style={{color: "#bb1d1d"}}>相较于上月【增长 <ArrowUpOutlined
                                                                    style={{fontSize: 12}}/> +{ServiceAvailabilityValue}%
                                                                    】
                                                                </div> :
                                                                ServiceAvailabilityValue < 0 ?
                                                                    <div
                                                                        style={{color: "#055008"}}>相较于上月【增长 <ArrowDownOutlined
                                                                        style={{fontSize: 12}}/> {ServiceAvailabilityValue}%
                                                                        】
                                                                    </div> : <div
                                                                        style={{color: "#6d45c2"}}>相较于上月【增长 <ArrowRightOutlined
                                                                        style={{fontSize: 12}}/> +{ServiceAvailabilityValue}%
                                                                        】
                                                                    </div>}
                                                        </Space>
                                                        }
                                                    >
                                                        <MonthLineChart
                                                            datas={this.state.SYSTEMAVAILABILITYMONTH['ServiceAvailabilityList'] ? this.state.SYSTEMAVAILABILITYMONTH['ServiceAvailabilityList'] : []}/>
                                                    </Card>
                                                </Col>
                                                <Col span={13}>
                                                    <Card
                                                        bordered={true}
                                                        style={{height: 300}}
                                                        extra={
                                                            <Space size={5}>
                                                                <NavLink
                                                                    to={'/ServiceDetail/' + this.state.AppType + "/" + "day"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<DashboardOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -150, width: 80}}>*/}
                                                                        今日详情
                                                                    </Button>
                                                                </NavLink>
                                                                <NavLink
                                                                    to={'/ServiceDetail/' + this.state.AppType + "/" + "month"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<BarChartOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -11, width: 80}}>*/}
                                                                        月度详情
                                                                    </Button>
                                                                </NavLink>
                                                            </Space>
                                                        }
                                                        title={<div>当月服务可用性</div>}
                                                    >
                                                        <Meta
                                                            description={
                                                                <div style={{marginTop: 40}}>
                                                                    <BulletChart datas={this.state.Availability}/>
                                                                </div>
                                                            }
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col span={11}>
                                                    <Card
                                                        title={"年度App可用性" + "(" + dayjs(this.state.DatasDate).year() + "年)"}
                                                        style={{height: 360}}
                                                        extra={
                                                            <div>
                                                                <Space>

                                                                    <Space size={1} style={{
                                                                        fontSize: 12,
                                                                        color: "#d7073e"
                                                                    }} direction="vertical">
                                                                        {IosAvailabilityValue > 0 ?
                                                                            <div
                                                                                style={{color: "#bb1d1d"}}>苹果相较于上月【增长 <ArrowUpOutlined
                                                                                style={{fontSize: 12}}/> +{IosAvailabilityValue}%
                                                                                】
                                                                            </div> :
                                                                            IosAvailabilityValue < 0 ?
                                                                                <div
                                                                                    style={{color: "#055008"}}>苹果相较于上月【增长 <ArrowDownOutlined
                                                                                    style={{fontSize: 12}}/> {IosAvailabilityValue}%
                                                                                    】
                                                                                </div> : <div
                                                                                    style={{color: "#6d45c2"}}>苹果相较于上月【增长 <ArrowRightOutlined
                                                                                    style={{fontSize: 12}}/> +{IosAvailabilityValue}%
                                                                                    】
                                                                                </div>}
                                                                        {AndroidAvailabilityValue > 0 ?
                                                                            <div
                                                                                style={{color: "#bb1d1d"}}>安卓相较于上月【增长 <ArrowUpOutlined
                                                                                style={{fontSize: 12}}/> +{AndroidAvailabilityValue}%
                                                                                】
                                                                            </div> :
                                                                            AndroidAvailabilityValue < 0 ?
                                                                                <div
                                                                                    style={{color: "#055008"}}>安卓相较于上月【增长 <ArrowDownOutlined
                                                                                    style={{fontSize: 12}}/> {AndroidAvailabilityValue}%
                                                                                    】
                                                                                </div> : <div
                                                                                    style={{color: "#6d45c2"}}>安卓相较于上月【增长 <ArrowRightOutlined
                                                                                    style={{fontSize: 12}}/> +{AndroidAvailabilityValue}%
                                                                                    】
                                                                                </div>}
                                                                    </Space>
                                                                </Space>

                                                            </div>}
                                                        actions={[<CookieConfig/>]}
                                                    >
                                                        <MonthLineChart
                                                            datas={this.state.SYSTEMAVAILABILITYAPPMONTH}/>
                                                    </Card>
                                                </Col>
                                                <Col span={13}>
                                                    <Card
                                                        style={{height: 360}}
                                                        bordered={true}
                                                        extra={
                                                            <Space size={5}>
                                                                <Button size={'small'} type={'link'}
                                                                        icon={<BugOutlined/>}
                                                                        href={"https://bugly.qq.com/v2/crash-reporting/dashboard"}
                                                                        target={'_blank'}
                                                                >
                                                                    {/*style={{fontSize: 13, marginLeft: -11, width: 80}}>*/}
                                                                    Bugly 月度详情
                                                                </Button>
                                                            </Space>
                                                        }
                                                        title={<div>当月App可用性</div>}
                                                    >
                                                        <Meta
                                                            description={
                                                                <div style={{marginTop: 60}}>
                                                                    <BulletChart
                                                                        datas={this.state.AppAvailabilityMonth}/>
                                                                </div>}
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        },
                                        {
                                            // disabled: true,
                                            key: 'dp1',
                                            label: (
                                                <Space className={'ignore-class'}>
                                                    <img style={{
                                                        width: 30, height: 40, borderRadius: 10
                                                    }}
                                                         src={"https://saas-product-image.oss-cn-shanghai.aliyuncs.com/app2_0/%E4%BA%A7%E5%93%81%E5%9B%BE%E7%89%87/177.png"}
                                                        // src={ej}
                                                         alt="pro-logo"/>
                                                    <b>中控大屏</b>
                                                </Space>
                                            ),
                                            children: <Row gutter={[16, 16]}>
                                                <Col span={11}>
                                                    <Card
                                                        title={"年度中控大屏可用性" + "(" + dayjs(this.state.DatasDate).year() + "年)"}
                                                        style={{height: 360}}
                                                        extra={
                                                            <div>
                                                                <Space>
                                                                    <Space size={1} style={{
                                                                        fontSize: 12,
                                                                        color: "#d7073e"
                                                                    }} direction="vertical">
                                                                        {AndroidAvailabilityValue > 0 ?
                                                                            <div
                                                                                style={{color: "#bb1d1d"}}>相较于上月【增长 <ArrowUpOutlined
                                                                                style={{fontSize: 12}}/> +{AndroidAvailabilityValue}%
                                                                                】
                                                                            </div> :
                                                                            AndroidAvailabilityValue < 0 ?
                                                                                <div
                                                                                    style={{color: "#055008"}}>相较于上月【增长 <ArrowDownOutlined
                                                                                    style={{fontSize: 12}}/> {AndroidAvailabilityValue}%
                                                                                    】
                                                                                </div> :
                                                                                <div
                                                                                    style={{color: "#6d45c2"}}>相较于上月【增长 <ArrowRightOutlined
                                                                                    style={{fontSize: 12}}/> +{AndroidAvailabilityValue}%
                                                                                    】
                                                                                </div>
                                                                        }
                                                                    </Space>
                                                                </Space>
                                                            </div>}
                                                        actions={[<CookieConfig/>]}
                                                    >
                                                        <div style={{minHeight: 170}}>
                                                            <MonthLineChart
                                                                datas={this.state.SYSTEMAVAILABILITYAPPMONTH}/>
                                                        </div>
                                                    </Card>
                                                </Col>
                                                <Col span={13}>
                                                    <Card
                                                        style={{height: 360}}
                                                        bordered={true}
                                                        extra={
                                                            <Space size={5}>
                                                                <Button size={'small'} type={'link'}
                                                                        icon={<BugOutlined/>}
                                                                        href={"https://bugly.qq.com/v2/crash-reporting/dashboard"}
                                                                        target={'_blank'}
                                                                >
                                                                    {/*style={{fontSize: 13, marginLeft: -11, width: 80}}>*/}
                                                                    Bugly 月度详情
                                                                </Button>
                                                            </Space>
                                                        }
                                                        title={<div>当月中控大屏可用性</div>}
                                                    >
                                                        <Meta
                                                            description={
                                                                <div style={{marginTop: 60}}>
                                                                    <BulletChart
                                                                        datas={this.state.AppAvailabilityMonth.length >= 0 ? [this.state.AppAvailabilityMonth[0]] : this.state.AppAvailabilityMonth}/>
                                                                </div>}
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        },
                                        {
                                            // disabled: true,
                                            key: 'gx',
                                            label: (
                                                <Space className={'ignore-class'}>
                                                    <img style={{
                                                        width: 30, height: 30, borderRadius: 5
                                                    }}
                                                         src={"https://console.iotbull.com/static/login_bg.337c3c03.png"}
                                                        // src={ej}
                                                         alt="pro-logo"/>
                                                    <b>光效云平台</b>
                                                </Space>
                                            ),
                                            children: <Row gutter={[16, 16]}>
                                                <Col span={11}>
                                                    <Card
                                                        title={"年度服务可用性" + "(" + dayjs(this.state.DatasDate).year() + "年)"}
                                                        style={{height: 360}}
                                                        extra={<Space size={20} style={{
                                                            fontSize: 12,
                                                            color: "#d7073e"
                                                        }}>
                                                            {ServiceAvailabilityValue > 0 ?
                                                                <div
                                                                    style={{color: "#bb1d1d"}}>相较于上月【增长 <ArrowUpOutlined
                                                                    style={{fontSize: 12}}/> +{ServiceAvailabilityValue}%
                                                                    】
                                                                </div> :
                                                                ServiceAvailabilityValue < 0 ?
                                                                    <div
                                                                        style={{color: "#055008"}}>相较于上月【增长 <ArrowDownOutlined
                                                                        style={{fontSize: 12}}/> {ServiceAvailabilityValue}%
                                                                        】
                                                                    </div> : <div
                                                                        style={{color: "#6d45c2"}}>相较于上月【增长 <ArrowRightOutlined
                                                                        style={{fontSize: 12}}/> +{ServiceAvailabilityValue}%
                                                                        】
                                                                    </div>}
                                                        </Space>
                                                        }
                                                    >
                                                        <div style={{minHeight: 170}}>
                                                            <MonthLineChart
                                                                datas={this.state.SYSTEMAVAILABILITYMONTH['ServiceAvailabilityList'] ? this.state.SYSTEMAVAILABILITYMONTH['ServiceAvailabilityList'] : []}/>
                                                        </div>
                                                    </Card>
                                                </Col>
                                                <Col span={13}>
                                                    <Card
                                                        bordered={true}
                                                        style={{height: 360}}
                                                        extra={
                                                            <Space size={5}>
                                                                <NavLink
                                                                    to={'/ServiceDetail/' + this.state.AppType + "/" + "day"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<DashboardOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -150, width: 80}}>*/}
                                                                        今日详情
                                                                    </Button>
                                                                </NavLink>
                                                                <NavLink
                                                                    to={'/ServiceDetail/' + this.state.AppType + "/" + "month"}>
                                                                    <Button size={'small'} type={'link'}
                                                                            icon={<BarChartOutlined/>}>
                                                                        {/*style={{fontSize: 13, marginLeft: -11, width: 80}}>*/}
                                                                        月度详情
                                                                    </Button>
                                                                </NavLink>
                                                            </Space>
                                                        }
                                                        title={<div>当月服务可用性</div>}
                                                    >
                                                        <Meta
                                                            description={
                                                                <div style={{marginTop: 40}}>
                                                                    <BulletChart datas={this.state.Availability}/>
                                                                </div>
                                                            }
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        },
                                    ]
                                }
                            />
                        </Card>
                    </Spin>
            </div>
        )
    }

}