import React, {Component} from 'react';
import '@ant-design/pro-components/dist/components.css';
import {PageHeader} from '@ant-design/pro-layout';
import {ProCard} from '@ant-design/pro-components';
import {
    Affix,
    Button,
    Descriptions,
    Divider,
    Input,
    Radio,
    Space,
    Table,
    Tag,
    List,
    message,
    Popconfirm,
    Drawer, Spin, Tabs, Badge, Col, Row, Result, Skeleton, Select
} from 'antd'

import {NavLink} from "react-router-dom";
import {
    ApartmentOutlined, BarChartOutlined, DatabaseOutlined,
    DeleteOutlined, PlusSquareOutlined,
    QuestionCircleOutlined, RedoOutlined, RightOutlined, RocketOutlined, TeamOutlined, UserOutlined,
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import Card from "antd/es/card/Card";
import Avatar from "antd/es/avatar/avatar";
import api from "../../api/ApiConfig";
import {getUser, Loginout} from "../Users/AuthRouter";
import AddPerformanceTestProject from "./AddPerformanceTestProject";
import LineChart from "./LineChart";
import {base} from "../../api/UrlConfig";
import {PerformanceTestDatas} from "./PerformanceTestDatas";
import {PerformanceTestSelect} from "./PerformanceTestSelect";


export class PerformanceTestHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            qs: "all",
            env: "all",
            userid: null,
            dataSource: [],
            performancetestitems: {},
            SoloPiUserDatas: {},
            SpinLoading: false,
            project: {}
        }
    }

    componentDidMount() {
        this.getperformancetesttask()
        this.getproject()
    }

    getperformancetestitems = async (PerformanceTaskId) => {
        let params = {}
        let dataSourcelist = []
        params['PerformanceTaskId'] = PerformanceTaskId
        await api.GetPerformanceTestItems(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (r.length !== 0) {
                    for (let i = 0; i < r['datas'].length; i++) {
                        dataSourcelist.push(
                            r['datas'][i].fields
                        )
                        dataSourcelist[i]['pk'] = r['datas'][i].pk
                        dataSourcelist[i]['key'] = i
                    }
                }
                this.setState({
                    performancetestitems: dataSourcelist
                })
            }
        })
    }

    delperformancetesttask = async (PerformanceTaskId) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['PerformanceTaskId'] = PerformanceTaskId
        await api.DelPerformanceTestTask({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("任务删除成功")
                this.getperformancetesttask()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }
    getperformancetesttask = async (params = {}) => {
        this.setState({
            TaskListLoading: true
        })
        let dataSourcelist = []
        params['qs'] = this.state.qs
        params['env'] = this.state.env
        if (this.state.userid != null) {
            params['userid'] = this.state.userid
        }
        await api.GetPerformanceTestTask(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (r.length !== 0) {
                    for (let i = 0; i < r['datas'].length; i++) {
                        dataSourcelist.push(
                            r['datas'][i].fields
                        )
                        dataSourcelist[i]['pk'] = r['datas'][i].pk
                        dataSourcelist[i]['key'] = i
                    }
                }
                this.setState({
                    dataSource: dataSourcelist
                })
            }
            this.setState({
                TaskListLoading: false
            })
        })
    }

    getsolopiuserdatas = async (params = {}) => {
        this.setState({
            SpinLoading: true
        })
        let dataSourcelist = []
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        await api.GetSoloPiUserDatas(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (r.length !== 0) {
                    for (let i = 0; i < r['datas'].length; i++) {
                        dataSourcelist.push(
                            r['datas'][i].fields
                        )
                        dataSourcelist[i]['pk'] = r['datas'][i].pk
                        dataSourcelist[i]['key'] = i
                    }
                }
                const dsl = dataSourcelist[0]
                let SoloPiUserDatas = {}
                for (let key in dsl) {
                    let dt = {}
                    try {
                        dt = JSON.parse(dsl[key].replace(/'/g, '"'))
                        if (typeof dt === 'object' && dt !== null && Object.keys(dt).length > 0) {
                            SoloPiUserDatas[key] = dt
                        }
                    } catch (e) {
                    }
                }
                this.setState({
                    SoloPiUserDatas: SoloPiUserDatas,
                    SpinLoading: false,
                    SoloPiUserDatasTime: dsl ? dsl['EditedTime'] : null
                })
            }
        })
    }
    getproject = async (params = {}) => {
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        await api.GetProject(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    project: r.datas
                })
            }
        })
    }

    render() {
        const expandedRowRender = (record, index) => {
            const items = JSON.parse(record.TestingItems.replace(/'/g, '"'))
            let table_datas = []
            for (let i = 0; i < items.length; i++) {
                table_datas.push(
                    {
                        key: i,
                        pk: record.pk,
                        ItemName: items[i]
                    }
                )
            }
            const columns = [
                {
                    title: 'No.',
                    key: Math.random() + index,
                    fixed: 'left',
                    width: 30,
                    align: 'center',
                    render: (record, text, index) => {
                        return (
                            index + 1
                        )
                    }

                },
                {
                    title: '测试检点',
                    width: 100,
                    dataIndex: 'ItemName',
                    key: 'ItemName',
                },
                {
                    title: '采集时间',
                    dataIndex: 'CreatedTime',
                    key: 'CreatedTime',
                    width: 100,
                    align: 'center',
                },
                {
                    title: '操作',
                    width: 120,
                    dataIndex: 'key',
                    key: 'key',
                    align: 'center',
                    render: (text, re) => (
                        <Space size={[1]}>
                            <Button
                                type="link"
                            >
                                采集数据
                            </Button>
                            <Divider type="vertical"/>
                            <Button
                                type="link"
                            >
                                查看数据
                            </Button>
                        </Space>
                    )
                }
            ];
            return <Badge.Ribbon key={Math.random()} placement={"start"} text="测试检点" color="blue">
                <Card bordered={true} key={Math.random()}>
                    <Table columns={columns}
                           key={Math.random()}
                           style={{marginLeft: 5, marginTop: 5}}
                           dataSource={table_datas}
                           size={"small"}
                           pagination={false}
                           bordered
                           scroll={{
                               y: '20vh',
                           }}
                    />
                </Card>
            </Badge.Ribbon>
        };

        const columns = [
            {
                title: 'No.',
                width: 30,
                fixed: 'left',
                align: 'center',
                render: (record, text, index) => {
                    return (index + 1)
                }
            },
            {
                title: '任务名称',
                fixed: 'left',
                width: 70,
                dataIndex: 'TaskName',
                key: 'TaskName',
            },
            {
                title: 'APP类型',
                width: 50,
                align: 'center',
                render: (text, re) => (
                    re.App === "1" ? <Tag color={"#15ad9b"}>公牛智家</Tag> :
                        re.App === "2" ? <Tag color={"rgb(246,158,57)"}>公牛沐光</Tag> :
                            re.App === "3" ? <Tag color={"rgb(209,87,69)"}>公牛调试</Tag> : null
                ),
            },
            {
                title: 'APP版本',
                width: 50,
                align: 'center',
                dataIndex: 'AppVersion',
                key: 'AppVersion',
            },
            {
                title: '测试环境',
                width: 60,
                align: 'center',
                render: (text, re) => (
                    re.Env === "Developer" ? <Tag color={"#d2a616"}>开发环境 </Tag> :
                        re.Env === "Test" ? <Tag color={"#2db7f5"}>测试环境 </Tag> :
                            re.Env === "Online" ? <Tag color={"#9b1313"}>生产环境 </Tag> : null
                ),
                sorter: (a, b) => a.Env !== undefined ? (a.Env.length - b.Env.length) : null
            },
            {
                title: '添加、更新人',
                width: 80,
                render: (text, re) => (
                    <Space>
                        <Avatar
                            size={30}
                            style={{
                                backgroundColor: "#141414",
                                verticalAlign: 'middle',
                            }}
                        >U</Avatar>
                        <div style={{fontSize: 11, color: '#707272'}}>{re.username}({re.userid})</div>
                    </Space>
                )
            },
            {
                title: '创建时间',
                dataIndex: 'CreatedTime',
                key: 'CreatedTime',
                width: 100,
                align: 'center',
            },
            {
                title: '设备信息',
                width: 120,
                dataIndex: 'TaskNote',
                key: 'TaskNote',
            },
            {
                title: '操作',
                dataIndex: 'operate',
                key: 'operate',
                fixed: 'right',
                width: 90,
                align: 'center',
                render: (text, re) => (
                    <Space size={[1]}>
                        <NavLink to={'/PerformanceTestDetails/' + re.pk}>
                            <Button size={'small'} type={'link'} icon={<BarChartOutlined/>}
                                    style={{fontSize: 13, marginLeft: -11, width: 80}}>
                                任务详情
                            </Button>
                        </NavLink>
                        <Divider type="vertical"/>
                        <Popconfirm
                            title="删除批次"
                            description="你确定要删除任务和与其相关的测试数据吗？"
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            } onConfirm={() => {
                            this.delperformancetesttask(re.pk)
                        }}
                        >
                            <Button
                                type="link" danger
                                icon={<DeleteOutlined/>}
                                style={{fontSize: 13, marginLeft: -13, width: 80}}
                            >
                                删除任务
                            </Button>
                        </Popconfirm>
                    </Space>
                )
            },
        ];
        return (
            <div style={{minHeight: "87vh", marginTop: 0, background: '#ffffff'}}>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="性能测试"
                            subTitle="性能测试项目管理"
                            extra={<Space size={0}>
                                <Button icon={<UserOutlined/>} type={'link'} style={{width: 100, color: '#707272'}}
                                        onClick={() => {
                                            const userinfo = getUser()
                                            this.setState({
                                                userid: userinfo.UserId
                                            }, function () {
                                                this.getperformancetesttask()
                                            })
                                        }}
                                >
                                    我的任务
                                </Button>
                                <Divider type="vertical"/>
                                <Button icon={<TeamOutlined/>} type={'link'} style={{width: 100, color: '#707272'}}
                                        onClick={() => {
                                            this.setState({
                                                userid: null
                                            }, function () {
                                                this.getperformancetesttask()
                                            })
                                        }}
                                >
                                    全部任务
                                </Button>
                                <Divider type="vertical"/>
                                <AddPerformanceTestProject fun={this.getperformancetesttask}/>
                            </Space>}
                        >
                            <Content/>
                        </PageHeader>
                    </div>
                </Affix>
                {/*<div style={{marginTop: 0, background: '#ffffff', minHeight: "85%"}}>*/}
                <Card>
                    <Tabs
                        // activeKey={'2'}
                        style={{marginLeft: 10, marginTop: 0}}
                        onChange={(value) => {
                            if (value === '1') {
                                this.getperformancetesttask()
                            }
                            if (value === '2') {
                                this.getsolopiuserdatas()
                                this.getproject()
                            }
                        }}
                        type="card"
                        tabPosition={"top"}
                        items={
                            [
                                {
                                    key: '1',
                                    label: `SoloPi性能测试任务`,
                                    children: <Card bordered={false}>
                                        <Table
                                            title={() =>
                                                <Card bordered={true}>
                                                    <Space>
                                                        <Select
                                                            defaultValue={this.state.env}
                                                            value={this.state.env}
                                                            onChange={(value) => {
                                                                this.setState({
                                                                    env: value
                                                                })
                                                            }}
                                                            style={{
                                                                width: 120,
                                                            }}
                                                            options={[
                                                                {
                                                                    value: 'all',
                                                                    label: '所有环境',
                                                                },
                                                                {
                                                                    value: 'Developer',
                                                                    label: '开发环境',
                                                                },
                                                                {
                                                                    value: 'Test',
                                                                    label: '测试环境',
                                                                },
                                                                {
                                                                    value: 'Online',
                                                                    label: '生产环境',
                                                                },
                                                            ]}
                                                        />
                                                        <Input style={{width: 300}} placeholder={"请输入任务名称(模糊查询)"}
                                                               value={this.state.qs === "all" ? null : this.state.qs}
                                                               onChange={(e) => {
                                                                   this.setState({
                                                                       qs: e.target.value
                                                                   })
                                                               }}/>
                                                        <Button type="primary" ghost onClick={() => {
                                                            this.getperformancetesttask({"qs": this.state.qs ? this.state.qs : "all"})
                                                        }}>查询</Button>
                                                        <Button type="primary" ghost onClick={() => {
                                                            this.setState({qs: "all", env: "all"}, function () {
                                                                this.getperformancetesttask()
                                                            })
                                                        }}>重置</Button>
                                                    </Space>
                                                </Card>
                                            }
                                            pagination={
                                                {
                                                    showTotal: () => `共 ${this.state.dataSource ? this.state.dataSource.length : 0} 条 `,
                                                    defaultPageSize: 100
                                                }

                                            }
                                            loading={this.state.TaskListLoading}
                                            columns={columns}
                                            dataSource={this.state.dataSource}
                                            scroll={{
                                                x: 1800,
                                            }}
                                            bordered={true}
                                            // expandable={{
                                            //     expandedRowRender: (record, index) => {
                                            //         return expandedRowRender(record, index)
                                            //     },
                                            //     defaultExpandedRowKeys: [0],
                                            // }}
                                        />
                                    </Card>,
                                },
                                {
                                    key: '2',
                                    label: `SoloPi性能数据采集`,
                                    children:
                                        <Card bordered={true}
                                              title={
                                                  <Space>待归档的测试数据(
                                                      <div style={{
                                                          fontSize: 13,
                                                          color: "red"
                                                      }}>数据更新时间: {this.state.SoloPiUserDatasTime}</div>
                                                      )</Space>
                                              }
                                              extra={<Space size={[0]}>
                                                  <Divider type="vertical"/>
                                                  <Button type={'link'} size={"small"}
                                                          style={{width: 100, color: '#707272'}}
                                                          icon={<RedoOutlined/>}
                                                          onClick={() => {
                                                              this.getsolopiuserdatas()
                                                              this.getproject()
                                                          }}
                                                  >
                                                      刷新数据
                                                  </Button>
                                                  {
                                                      Object.keys(this.state.project).length > 0 && Object.keys(this.state.SoloPiUserDatas).length > 0 ?
                                                          <>
                                                              <Divider type="vertical"/>
                                                              <PerformanceTestSelect TaskList={this.state.project}/>
                                                          </>
                                                          : null}
                                              </Space>}
                                        >
                                            <PerformanceTestDatas
                                                SpinLoading={this.state.SpinLoading}
                                                SoloPiUserDatas={this.state.SoloPiUserDatas}
                                                SoloPiUserDatasTime={this.state.SoloPiUserDatasTime}
                                            />
                                        </Card>
                                }
                            ]
                        }
                    />
                </Card>
                {/*</div>*/}
            </div>
        )
    }

}