import React from 'react';
import QRCode from 'qrcode-react';

class QRCodeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logoUrl: props.logoUrl,
            qrCodeValue: props.qrCodeValue,
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.qrCodeValue) !== JSON.stringify(prevProps.qrCodeValue)) {
            this.setState({
                qrCodeValue: this.props.qrCodeValue
            })
        }
    }

    render() {
        console.log("获取到的二维码链接", this.state.qrCodeValue)
        return (
            <div>
                <QRCode
                    value={this.state.qrCodeValue}
                    logo={this.state.logoUrl}
                    size={160} // 设置二维码的大小，默认单位是像素
                    fgColor="#000000" // 前景色（二维码的颜色）
                    bgColor="#ffffff" // 背景色，默认为白色
                    logoWidth={45}
                    logoHeight={45}
                />
            </div>
        );
    }
}

export default QRCodeComponent;