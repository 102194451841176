import React, {Component} from 'react'
import {Card, Descriptions, Divider, Space, Tag} from "antd";
import api from "../../../api/ApiConfig";
import {Loginout} from "../../Users/AuthRouter";
import {JsonTools} from "../../AutoTest/TestProjectManagement/JsonTools";
import {LinkOutlined, SettingOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

export class Step1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            taskId: props.taskId,
            datas: props.datas,
            fun: props.fun,
            userList: props.userList
        }
    }

    componentDidMount() {
        this.state.fun()
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas) ||
            JSON.stringify(this.props.userList) !== JSON.stringify(prevProps.userList)) {
            this.setState({
                datas: this.props.datas,
                userList: this.props.userList,
            })
        }
    }

    render() {
        return (
            <div style={{margin: "20px 30px 30px 30px"}}>
                {Object.keys(this.state.datas).length > 0 ?
                    <Card style={{minHeight:"50vh"}}>
                        <Descriptions bordered={true} size={"middle"}
                                      title={<Space size={5}>
                                          <svg d="1719652254632" className="icon" viewBox="0 0 1024 1024" version="1.1"
                                               xmlns="http://www.w3.org/2000/svg" p-id="8583" width="25" height="25">
                                              <path
    d="M534.656 361.408C528.864 355.6 520.848 352 512 352s-16.864 3.6-22.656 9.408l-127.952 127.952a32 32 0 1 0 45.248 45.248L480 461.248V640a32 32 0 1 0 64 0V461.248l73.36 73.36a32 32 0 1 0 45.232-45.264l-127.936-127.936z"
    fill="#231815" p-id="8584"/>
                                              <path
    d="M928 576h-32v-128h32a32 32 0 0 0 0-64h-32v-32c0-123.52-100.48-224-224-224h-32V96a32 32 0 0 0-64 0v32h-128V96a32 32 0 0 0-64 0v32h-32C228.48 128 128 228.48 128 352v32H96a32 32 0 0 0 0 64h32v128H96a32 32 0 0 0 0 64h32v32c0 123.52 100.48 224 224 224h32v32a32 32 0 0 0 64 0v-32h128v32a32 32 0 0 0 64 0v-32h32c123.52 0 224-100.48 224-224v-32h32a32 32 0 0 0 0-64z m-96 96c0 88.224-71.776 160-160 160H352c-88.224 0-160-71.776-160-160V352c0-88.224 71.776-160 160-160h320c88.224 0 160 71.776 160 160v320z"
    fill="#231815" p-id="8585"/>
                                          </svg>
                                          <div style={{marginTop:-5}}>固件提测详情</div>
                                      </Space>}
                                      style={{whiteSpace: "pre-wrap", fontSize: 12, fontWeight: 500}}
                                      labelStyle={{width: "10%", fontSize: 12, fontWeight: 500, textAlign: "center"}}
                                      contentStyle={{fontSize: 12, fontWeight: 500, width: "20%"}}
                        >
                            <Descriptions.Item label="产品名称" span={1}>
                                {this.state.datas.ProductName}
                            </Descriptions.Item>
                            <Descriptions.Item label="固件版本" span={1}>
                                {this.state.datas.FirmwareVersion}
                            </Descriptions.Item>
                            <Descriptions.Item label="OTA配置" span={1}>
                                <Tag
                                    color={
                                        this.state.datas.AutoOTA === 1 ? "#5b8c00" :
                                            this.state.datas.AutoOTA === 2 ? "#8b7aaf" : "default"
                                    }
                                    style={{
                                        marginRight: 5,
                                        textAlign: "center",
                                        fontSize: 11,
                                        fontWeight: 600,
                                        width: 70,
                                    }}
                                >
                                    {this.state.datas.AutoOTA === 1 ? "快捷配置" :
                                        this.state.datas.AutoOTA === 2 ? "手动配置" : "未知类型"}
                                </Tag>
                            </Descriptions.Item>
                            <Descriptions.Item label="发布类型" span={1}>
                                <Space size={1}>
                                    {
                                        JSON.parse(this.state.datas.ReleaseType).map((value) => {
                                            return (
                                                <Tag
                                                    color={
                                                        value === 1 ? "#5b8c00" :
                                                            value === 2 ? "#8d062c" :
                                                                value === 3 ? "#d05715" : "#9254de"
                                                    }
                                                    style={{
                                                        marginRight: 5,
                                                        textAlign: "center",
                                                        fontSize: 11,
                                                        fontWeight: 600,
                                                        width: 70,
                                                    }}
                                                >
                                                    {value === 1 ? "EVT生产" :
                                                        value === 2 ? "DVT生产" :
                                                            value === 3 ? "线上OTA" :
                                                                value === 4 ? "批量生产" :
                                                                    value === 5 ? "测试版本" : "未知类型"}
                                                </Tag>
                                            )
                                        })
                                    }
                                </Space>
                            </Descriptions.Item>

                            <Descriptions.Item label="提测轮数" span={1}>
                                第 {this.state.datas.TestNumber} 轮
                            </Descriptions.Item>
                            <Descriptions.Item label="操作时间" span={1}>
                                <Space direction={"vertical"} size={2}>
                                    <div style={{
                                        fontSize: 10,
                                        color: "#938f8f"
                                    }}>创建时间 {dayjs(this.state.datas.cDatasDate).format("YYYY-MM-DD HH:mm:ss")}</div>
                                    <div style={{
                                        fontSize: 10,
                                        color: "#938f8f"
                                    }}>更新时间 {dayjs(this.state.datas.uDatasDate).format("YYYY-MM-DD HH:mm:ss")}</div>
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label="前置检查" span={1}>
                                <Space size={1}>
                                    {
                                        JSON.parse(this.state.datas.Precheck).map((value) => {
                                            return (
                                                <Tag
                                                    color={
                                                        value === 1 ? "#5b8c00" :
                                                            value === 2 ? "#8d062c" :
                                                                value === 3 ? "#d05715" : "#9254de"
                                                    }
                                                    style={{
                                                        marginRight: 5,
                                                        textAlign: "center",
                                                        fontSize: 11,
                                                        fontWeight: 600,
                                                        // width: 90,
                                                    }}
                                                >
                                                    {value === 1 ? "固件已上传confluence" :
                                                        value === 2 ? "代码差异已检查" :
                                                            value === 3 ? "Bug流转已检查" : "未知类型"}
                                                </Tag>
                                            )
                                        })
                                    }
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label="设计文档" span={1}>
                                <a href={this.state.datas.DesignDocument} target={'_blank'}>点击查看 <LinkOutlined/></a>
                            </Descriptions.Item>
                            <Descriptions.Item label="自测报告" span={1}>
                                <a href={this.state.datas.TestReport} target={'_blank'}>点击查看 <LinkOutlined/></a>
                            </Descriptions.Item>
                            <Descriptions.Item label="改动内容" span={1} labelStyle={{textAlign: "center"}}
                                               style={{textAlign: "left"}}>
                                {this.state.datas.NewDemand}
                            </Descriptions.Item>
                            <Descriptions.Item label="影响范围" span={1} labelStyle={{textAlign: "center"}}
                                               style={{textAlign: "left"}}>
                                {this.state.datas.AffectedScope}
                            </Descriptions.Item>
                            <Descriptions.Item label="测试建议" span={3} labelStyle={{textAlign: "center"}}
                                               style={{textAlign: "left"}}>
                                {this.state.datas.TestSuggestion}
                            </Descriptions.Item>
                            <Descriptions.Item label="固件地址" span={3} labelStyle={{textAlign: "center"}}
                                               style={{textAlign: "left"}}>
                                {this.state.datas.FirmwareAddress}
                            </Descriptions.Item>
                            {this.state.datas.AutoOTA === 1 ?
                                <>
                                    <Descriptions.Item label="" span={3}>
                                        <Divider style={{fontSize: 11, color: "goldenrod"}}>
                                            快捷升级配置（目前仅支持C100智能中控大屏）
                                        </Divider>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="ProductKey" span={1}>
                                        {this.state.datas.ProductName.split('_')[2]}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="固件地址" span={1}>
                                        <a href={this.state.datas.FirmwareDownloadAddress} target={'_blank'}>点击下载</a>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="OTA版本" span={1}>
                                        {this.state.datas.OtaVersion}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="DevicesName" span={3} labelStyle={{textAlign: "center"}}
                                                       style={{textAlign: "left"}}>
                                        {this.state.datas.DevicesName}
                                    </Descriptions.Item>
                                </>
                                : null
                            }
                        </Descriptions>
                    </Card> : null}
            </div>
        );
    }
}
