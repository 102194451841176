import React, {Component} from 'react'
import {Button, Card, Col, Descriptions, Divider, Progress, Row, Space, Steps, Tag} from "antd";
import Avatar from "antd/es/avatar/avatar";
import api from "../../../api/ApiConfig";
import {Loginout} from "../../Users/AuthRouter";
import {AreaChartOutlined, SyncOutlined} from "@ant-design/icons";


export class Step3 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userList: props.userList,
            datas: props.datas,
            taskId: props.taskId
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas) ||
            JSON.stringify(this.props.userList) !== JSON.stringify(prevProps.userList)) {
            this.setState({
                datas: this.props.datas,
                userList: this.props.userList,
            })
        }
    }

    componentDidMount() {
        this.getproject_tasks_firmware_status()
        this.timer = setInterval(() => {
            // 设置定时器实时获取自动化测试任务执行详情
            console.log("this.state.datas.FirmwareStorage", this.state.datas.FirmwareStorage)
            if (this.state.datas.FirmwareStorage === 0 ||
                this.state.datas.FirmwareStorage === 1 ||
                this.state.datas.FirmwareStorage === 2 ||
                this.state.datas.FirmwareStorage === 4 ||
                this.state.datas.FirmwareStorage === 5 ||
                this.state.datas.OtaTaskRes !== 4 ||
                this.state.datas.OtaTaskRes !== 5 ||
                this.state.datas.OtaTaskRes !== 6
            ) {
                this.getproject_tasks_firmware_status()
            }
        }, 5000);
    }

    getproject_tasks_firmware_status = async (params = {}) => {
        params['key'] = this.state.taskId
        await api.GetProjectTasksFirmwareStatus(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    datas: r.datas
                })
            }

        })
    }

    componentWillUnmount() {
        // 在组件即将被销毁时执行清理操作
        clearInterval(this.timer);
    }

// <svg t="1719652958316" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16899" width="25" height="25"><path d="M485.083429 292.571429c140.068571 0 264.045714 88.795429 307.2 208.969142A263.314286 263.314286 0 0 1 1024 762.88c0 135.826286-107.812571 245.540571-242.541714 261.193143H242.541714C107.812571 1024 0 919.478857 0 788.918857c0-104.521143 70.070857-193.316571 161.645714-224.694857C183.296 412.745143 318.025143 292.571429 485.156571 292.571429z m18.285714 256.438857L323.437714 723.602286l72.045715 69.778285 56.539428-54.857142v209.481142h102.912v-209.481142l56.612572 54.857142 71.972571-69.778285-180.004571-174.592z" fill="#36383B" p-id="16900"></path><path d="M731.428571 292.571429a146.285714 146.285714 0 0 1 145.993143 136.630857L877.714286 438.857143h-58.514286a87.771429 87.771429 0 0 0-79.286857-87.405714L731.428571 351.085714V292.571429z m0-146.285715a292.571429 292.571429 0 0 1 292.278858 278.820572L1024 438.857143h-65.024a227.547429 227.547429 0 0 0-215.04-227.254857L731.428571 211.382857V146.285714z" fill="#36383B" opacity=".6" p-id="16901"></path></svg>

    render() {
        return (
            <div style={{margin: "60px 40px 0px 40px", display: "flex", justifyContent: "center"}}>
                <div>
                    <div style={{
                        backgroundColor: "rgb(240, 242, 245)", height: 300,
                        fontSize: 12, fontWeight: 600, borderRadius: "10px 10px 10px 10px",
                        // width: "50vw"
                    }}>
                        <Row gutter={[20]} style={{marginLeft: 20, marginRight: 20}} wrap={false}>
                            <Col span={12}>
                                <Card style={{marginTop: 40, fontSize: 12, minWidth: 350, height: 230}}>
                                    <Row gutter={[0, 20]} style={{textAlign: "left"}}>
                                        <Col span={24}>
                                            <Divider style={{fontSize: 12, fontWeight: 600}}>
                                                Step1 固件转存
                                            </Divider>
                                        </Col>
                                        <Col span={4} style={{fontSize: 12, fontWeight: 600, minWidth: 70}}>下载进度：</Col>
                                        <Col span={18} style={{fontSize: 12, fontWeight: 600}}>
                                            <Progress size={"small"}
                                                      percent={this.state.datas.FirmwareDownLoadProgress}/>
                                        </Col>
                                        <Col span={4} style={{fontSize: 12, fontWeight: 600, minWidth: 70}}>上传进度：</Col>
                                        <Col span={18} style={{fontSize: 12, fontWeight: 600}}>
                                            <Progress size={"small"} percent={this.state.datas.FirmwareUpLoadProgress}/>
                                        </Col>
                                        <Col span={4} style={{fontSize: 12, fontWeight: 600, minWidth: 70}}>转存结果：</Col>
                                        <Col span={18} style={{
                                            fontSize: 12,
                                            fontWeight: 600
                                        }}>
                                            {/*0:待下载 1:下载中 2:下载成功 3:下载失败 4:待上传 5:上传中 6:上传成功 7:上传失败'*/}
                                            <Tag style={{fontSize: 11, marginTop: -5}} color={
                                                this.state.datas.FirmwareStorage === 0 ? "yellow-inverse" :
                                                    this.state.datas.FirmwareStorage === 1 ? "blue-inverse" :
                                                        this.state.datas.FirmwareStorage === 2 ? "green-inverse" :
                                                            this.state.datas.FirmwareStorage === 3 ? "red-inverse" :
                                                                this.state.datas.FirmwareStorage === 4 ? "yellow-inverse" :
                                                                    this.state.datas.FirmwareStorage === 5 ? "blue-inverse" :
                                                                        this.state.datas.FirmwareStorage === 6 ? "green-inverse" :
                                                                            this.state.datas.FirmwareStorage === 7 ? "red-inverse" :
                                                                                "yellow-inverse"
                                            }>
                                                {
                                                    this.state.datas.FirmwareStorage === 0 ? "等待下载" :
                                                        this.state.datas.FirmwareStorage === 1 ? "正在下载" :
                                                            this.state.datas.FirmwareStorage === 2 ? "下载成功" :
                                                                this.state.datas.FirmwareStorage === 3 ? "下载失败" :
                                                                    this.state.datas.FirmwareStorage === 4 ? "等待上传" :
                                                                        this.state.datas.FirmwareStorage === 5 ? "正在上传" :
                                                                            this.state.datas.FirmwareStorage === 6 ? "上传成功" :
                                                                                this.state.datas.FirmwareStorage === 7 ? "上传失败" : "未知状态"
                                                }
                                            </Tag>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card style={{marginTop: 40, fontSize: 12, minWidth: 350, height: 230}}>
                                    <Row gutter={[0, 20]} style={{textAlign: "left"}}>
                                        <Col span={24}>
                                            <Divider style={{fontSize: 12, fontWeight: 600}}>
                                                Step2 升级进度
                                            </Divider>
                                        </Col>
                                        <Col span={4} style={{fontSize: 12, fontWeight: 600, minWidth: 70}}>升级任务：</Col>
                                        <Col span={18} style={{fontSize: 12, fontWeight: 600}}>
                                            <a href={"https://dashboard-appsmith.iotbull.com/app/ota-test/package-detail-63ae56af01ffa91c6fc7221f?branch=master&Id=" + this.state.datas.OtaVersionId}
                                               target={'_blank'}>
                                                任务详情(OTA平台)
                                            </a>
                                        </Col>
                                        <Col span={4} style={{fontSize: 12, fontWeight: 600, minWidth: 70}}>升级进度：</Col>
                                        <Col span={18} style={{fontSize: 12, fontWeight: 600}}>
                                            <Progress size={"small"} percent={this.state.datas.OtaTaskProgress}/>
                                        </Col>
                                        <Col span={4} style={{fontSize: 12, fontWeight: 600, minWidth: 70}}>升级结果：</Col>
                                        <Col span={18} style={{fontSize: 12, fontWeight: 600}}>
                                            <Tag style={{fontSize: 11, marginTop: -5}} color={
                                                this.state.datas.OtaTaskRes === 4 ? "green-inverse" :
                                                    this.state.datas.OtaTaskRes === 5 ? "red-inverse" :
                                                        "blue-inverse"
                                            }>
                                                {/*0:待升级 1:待推送 2:已推送 3:升级中 4:升级成功 5:升级失败 6.任务取消 7:准备中*/}
                                                {
                                                    this.state.datas.OtaTaskRes === 0 ? "待升级" :
                                                        this.state.datas.OtaTaskRes === 1 ? "待推送" :
                                                            this.state.datas.OtaTaskRes === 2 ? "已推送" :
                                                                this.state.datas.OtaTaskRes === 3 ? "升级中" :
                                                                    this.state.datas.OtaTaskRes === 4 ? "升级成功" :
                                                                        this.state.datas.OtaTaskRes === 5 ? "升级失败" :
                                                                            this.state.datas.OtaTaskRes === 6 ? "任务取消" :
                                                                                this.state.datas.OtaTaskRes === 7 ? "准备中" : "未知状态"
                                                }
                                            </Tag>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div style={{
                        // width: 120,
                        position: "absolute",
                        marginTop: "-14px",
                        marginLeft: "20px",
                        background: "#fafafa",
                        borderRadius: "6px 6px 0 0",
                        textAlign: 'center',
                        padding: "3px 10px  0px 10px",
                    }}>
                        <Space size={5}>
                            <SyncOutlined spin/>
                            <b style={{fontSize: 13}}>
                                快捷升级
                            </b>
                        </Space>
                    </div>
                </div>
            </div>
        );
    }
}
