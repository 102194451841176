import React, {Component} from 'react';
import {RadialBar} from '@ant-design/plots';
import {Empty, Watermark} from "antd";
import moment from "moment";
import insertCss from "insert-css";

export class RadialBarChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas ? props.datas : [],
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }

    render() {
        insertCss(`
            .custom-tooltip-title {
                margin: 0px 12px;
                font-size: 12px;
                border-bottom-style: solid;
                border-bottom-width: thin;
                border-bottom-color: #e9e9e9;
            }
        
            .custom-tooltip-value {
              display: grid;
              grid-template-columns: 1fr 1fr;
              margin: 8px 12px 0 12px;
              padding-bottom: 1px;
              font-size: 10px;
              text-align: center;
              border-bottom-style: solid;
              border-bottom-width: thin;
              border-bottom-color: #e9e9e9;
              height: 70px;
            }
        
            .custom-tooltip-temp {
              display: flex;
              position: relative;
              align-items: center;
            }
        
            .custom-tooltip-temp span:first-child {
              font-size: 12px;
              position: absolute;
              top: 0px;
              color: rgba(0, 0, 0, 0.45)
            }
        
            .custom-tooltip-temp span:last-child {
              text-align: left;
              margin-top: 10px;
              position: relative;
              color: rgba(0, 0, 0, 0.85)
            }
        
            .custom-tooltip-wind {
                margin: 8px 12px 12px 12px;
                font-size: 12px;
                color: rgba(105, 0, 0, 0.85);
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        `);
        const datas = [...this.state.datas]
        console.log("啊是的啦；啊是；'",datas)
        const config = {
            data: datas.reverse(),
            xField: 'Path',
            yField: 'InterfaceTime',
            radius: 1,
            innerRadius: 0.1,
            // 设置坐标系的起始角度和终止角度
            startAngle: Math.PI * 0.5,
            endAngle: Math.PI * 2.5,
            tooltip: {
                showMarkers: true,
                customContent: (title, items) => {
                    const data = items[0]?.data || {};
                    const tempDom = `<div class = "custom-tooltip-value"><div class = "custom-tooltip-temp"><span>接口名称：</span><span>${data.Path}</span></div></div>`;
                    const windDom = `<div class = "custom-tooltip-wind"><span>请求耗时：${data.InterfaceTime} ms</span></div>`;
                    const sindDom = `<div class = "custom-tooltip-wind"><span>请求方法：${data.Method}</span></div>`;
                    return `<div style="max-width: 700px;white-space: nowrap;text-align: left">${tempDom}${sindDom}${windDom}</div>`;
                },
            },
            barStyle: {
                lineCap: 'round',
            },
            annotations: [
                {
                    type: 'text',
                    position: ['57%', '50%'],
                    content: '耗时Top10',
                    style: {
                        textAlign: 'center',
                        fontSize: 24,
                    },
                },
            ],
            colorField: 'InterfaceTime',
            color: ({InterfaceTime}) => {
                if (InterfaceTime > 15000) {
                    return '#ff9300';
                } else if (InterfaceTime > 10000) {
                    return '#2194ff';
                }
                return '#36c361';
            },
        };
        return <>
            {this.state.datas.length !== 0 ?
                <RadialBar {...config} style={{marginLeft: 0, marginTop: 50}}/> :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </>
    }

}