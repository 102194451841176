module.exports = {
    // base: "http://127.0.0.1:7777",
    // redirect_uri_wx: "http://127.0.0.1:3001/",
    base: "https://tap.iotbull.com:443",
    redirect_uri_wx: "https://tap.iotbull.com/",
    // base: "http://10.24.10.50:7777",
    // redirect_uri_wx:"http://10.24.10.50:7777",
    AppKey: "be1814d3a11e911ec83c5f09e4a62f1b4",
    AppSecret: "be181d21811e911eca8b3f09e4a62f1b4",
    CorPid: "wx61dbff19a640ddfd",
    AgentId: "1000129",
};

