import React from 'react';
import "./TestHome.css"

const Tester = () => {

    return (
        <div style={{
            // backgroundImage: `url('https://files.catbox.moe/tmnsc9.png')`,
            backgroundImage: `url('https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/aut.png')`,
            // backgroundImage: `url('https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr')`,
            backgroundSize: 'cover',
            // backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100vh',
        }}/>
    );
};

export default Tester

