import React from "react";
import {Button, Result} from "antd";
export default class index extends React.Component {

    back_home = ()=>{
        this.props.history.push('/')
        window.location.reload()
    }
    render() {
        return(
            <Result
                status="404"
                title="404"
                subTitle="对不起,页面未找到"
                extra={<Button type="primary" onClick={this.back_home}>Back Home</Button>}
            />
            )
    }
}
