import React, {Component} from 'react'
import {
    Card,
    Descriptions,
    Space,
    Tag,
    Tooltip
} from "antd";
import {DownloadOutlined, ExportOutlined} from "@ant-design/icons";


export class Step5 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fun: props.fun,
            datas: props.datas,
            taskId: props.taskId,
            tasks_datas: props.tasks_datas,
        }

    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas) ||
            JSON.stringify(this.props.tasks_datas) !== JSON.stringify(prevProps.tasks_datas)) {
            this.setState({
                datas: this.props.datas,
                tasks_datas: this.props.tasks_datas,
            }, function () {
                this.state.fun()
            })
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div style={{margin: 20}}>
                <Card style={{margin: 20, minHeight: "50vh"}}>
                    <Descriptions
                        title={<Space size={5}>
                            <svg d="1719652813427" className="icon" viewBox="0 0 1024 1024" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="13107" width="25" height="25">
                                <path
                                    d="M536.545997 67.185034c-6.281041-6.285134-14.956614-10.178806-24.54395-10.178806-9.589383 0-18.265979 3.897766-24.545997 10.182899-6.273878 6.243179-10.160387 14.922844-10.160387 24.521438s3.886509 18.278259 10.160387 24.563393a34.792341 34.792341 0 0 0 22.758284 10.104105h3.572355a34.761642 34.761642 0 0 0 22.759308-10.104105c6.273878-6.285134 10.160387-14.960707 10.160387-24.563393s-3.886509-18.278259-10.160387-24.521438v-0.004093z m-24.544974-48.738953c22.360219 0 42.604245 1.347692 57.260007 16.004477 14.648599 14.648599 23.71712 34.896718 23.717121 57.26103h92.544558V207.393053H338.477291V91.710565h92.545581c0-22.364312 9.067498-42.608338 23.727354-57.261031 14.644506-14.655762 34.888532-16.003454 57.250797-16.003453zM207.161786 126.378063h100.468002v46.268697h-100.468002c-8.465795 0-16.170252 3.475141-21.772842 9.063405-5.59645 5.629196-9.075685 13.303977-9.075685 21.788192V928.433625c0 16.886565 13.932286 30.848527 30.848527 30.848527h609.677451c16.907031 0 30.848527-13.960939 30.848527-30.848527V203.494264c0-8.442259-3.479234-16.150809-9.082847-21.785122-5.595427-5.591334-13.308071-9.060335-21.76568-9.060335l-100.468001-0.004093V126.378063h100.468001c21.230491 0 40.518751 8.676596 54.481737 22.635488 13.970148 13.955822 22.638558 33.239989 22.638558 54.485829V928.433625c0 42.412887-34.674661 77.121318-77.120295 77.121318l-609.677451-0.004094c-42.454843 0-77.121318-34.662382-77.121318-77.117224V203.494264c0-21.245841 8.669433-40.522844 22.632418-54.481737 13.970148-13.958892 33.257385-22.634465 54.4889-22.634464z"
                                    fill="#515151" p-id="13108"/>
                                <path
                                    d="M288.356877 797.324827h447.288292v30.848527H288.356877v-30.848527z m-0.008186-285.306408h70.591614l45.654714-113.519222c3.163033-7.908094 12.117968-11.722972 20.002527-8.55994a15.329096 15.329096 0 0 1 8.654083 8.78916h0.021489l68.569565 179.07311 61.529232-236.337211c2.131543-8.213039 10.52059-13.146388 18.737722-11.026102 4.805436 1.235128 8.481145 4.627381 10.2883 8.867954h0.01535l72.391606 168.856441h70.840276v30.848527h-80.969964v-0.036839c-5.987353 0-11.67283-3.509933-14.158436-9.369372l-58.70696-136.930376-62.051117 238.34391c-0.962929 5.088892-4.465699 9.561754-9.656921 11.530592-7.95005 3.04433-16.855866-0.962929-19.8828-8.907863L418.512979 446.355039l-34.48535 85.754934c-1.973954 6.247272-7.810881 10.763113-14.701811 10.763113l-80.977127-0.004093v-30.850574z m0.008186 204.333374h447.288292v30.844434H288.356877v-30.844434z"
                                    fill="#515151" p-id="13109"/>
                            </svg>
                            <div style={{marginTop: -5}}>提测结果</div>
                        </Space>}
                        style={{display: "block", width: "80%", margin: "0 auto"}}
                        bordered={true}
                        labelStyle={{fontSize: 12, textAlign: "left", width: "18%", fontWeight: 600}}
                        contentStyle={{
                            textAlign: "left",
                            fontSize: 12,
                            fontWeight: 600,
                            width: "35%"
                        }}
                    >
                        <Descriptions.Item label="产品名称" span={1.5}>
                            {this.state.tasks_datas.ProductName}
                        </Descriptions.Item>
                        <Descriptions.Item label="自测报告" span={1.5}>
                            <a href={this.state.tasks_datas.TestReport}><Space
                                style={{fontSize: 13}}><ExportOutlined/>自测报告</Space>
                            </a>
                        </Descriptions.Item>
                        <Descriptions.Item label="提测结果" span={1.5}>
                            <Space>
                                <Tag color={this.state.tasks_datas.ProjectTaskStatus === 1 ? "#fadb14" :
                                    this.state.tasks_datas.ProjectTaskStatus === 2 ? "#9254de" :
                                        this.state.tasks_datas.ProjectTaskStatus === 3 ? "green-inverse" :
                                            this.state.tasks_datas.ProjectTaskStatus === 4 ? "#ff7875" :
                                                "#707272"
                                }
                                     style={{
                                         color: "white",
                                         marginRight: 5,
                                         textAlign: "center",
                                         fontSize: 12,
                                         fontWeight: 700,
                                         width: 80
                                     }}
                                >
                                    {this.state.tasks_datas.ProjectTaskStatus === 1 ? "待确认" :
                                        this.state.tasks_datas.ProjectTaskStatus === 2 ? "进行中" :
                                            this.state.tasks_datas.ProjectTaskStatus === 3 ? "提测成功" :
                                                this.state.tasks_datas.ProjectTaskStatus === 4 ? "提测失败" :
                                                    "未知状态"}
                                </Tag>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="审批结果" span={1.5}>
                            <Tag style={{
                                color: "white",
                                marginRight: 5,
                                textAlign: "center",
                                fontSize: 12,
                                fontWeight: 700,
                                width: 80
                            }} color={this.state.datas.ApprovalStatus1 === 2 && this.state.datas.ApprovalStatus2 === 2 ?
                                "green-inverse" :
                                this.state.datas.ApprovalStatus1 === 3 && this.state.datas.ApprovalStatus2 === 3 ? "red-inverse" :
                                    "blue-inverse"}>
                                {this.state.datas.ApprovalStatus1 === 2 && this.state.datas.ApprovalStatus2 === 2 ?
                                    "审批通过" :
                                    this.state.datas.ApprovalStatus1 === 3 && this.state.datas.ApprovalStatus2 === 3 ? "审批驳回" :
                                        "审批中"
                                }
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="OTA升级" span={1.5}>
                            {this.state.tasks_datas.AutoOTA === 1 ?
                                <Tag style={{
                                    color: "white",
                                    marginRight: 5,
                                    textAlign: "center",
                                    fontSize: 12,
                                    fontWeight: 700,
                                    width: 80
                                }}
                                     color={
                                         this.state.datas.OtaTaskRes === 4 ? "green-inverse" :
                                             this.state.datas.OtaTaskRes === 5 ? "red-inverse" :
                                                 "blue-inverse"
                                     }>
                                    {/*0:待升级 1:待推送 2:已推送 3:升级中 4:升级成功 5:升级失败 6.任务取消 7:准备中*/}
                                    {
                                        this.state.datas.OtaTaskRes === 0 ? "待升级" :
                                            this.state.datas.OtaTaskRes === 1 ? "待推送" :
                                                this.state.datas.OtaTaskRes === 2 ? "已推送" :
                                                    this.state.datas.OtaTaskRes === 3 ? "升级中" :
                                                        this.state.datas.OtaTaskRes === 4 ? "升级成功" :
                                                            this.state.datas.OtaTaskRes === 5 ? "升级失败" :
                                                                this.state.datas.OtaTaskRes === 6 ? "任务取消" :
                                                                    this.state.datas.OtaTaskRes === 7 ? "准备中" : "未知状态"
                                    }
                                </Tag>
                                : <Tag style={{
                                    color: "white",
                                    marginRight: 5,
                                    textAlign: "center",
                                    fontSize: 12,
                                    fontWeight: 700,
                                    width: 80
                                }} color={"#323136"}>
                                    Uninvolved
                                </Tag>
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="自动化测试结果" span={1.5}>
                            <Tag style={{
                                color: "white",
                                marginRight: 5,
                                textAlign: "center",
                                fontSize: 12,
                                fontWeight: 700,
                                minWidth: 80
                            }} color={this.state.datas.AutoTest === 2 || this.state.datas.AutoTest === 4 ?
                                "green-inverse" : this.state.datas.AutoTest === 3 ? "red-inverse" :
                                    "blue-inverse"}>
                                {this.state.datas.AutoTest === 2 ? "测试通过" :
                                    this.state.datas.AutoTest === 4 ? <Tooltip title={
                                            <div style={{fontSize: 12, fontWeight: 600}}>
                                                手动通过类型:
                                                【{this.state.datas.ManualValue === "1" ? "环境问题" :
                                                this.state.datas.ManualValue === "2" ? "服务依赖" :
                                                    this.state.datas.ManualValue === "3" ? "用例问题" :
                                                        this.state.datas.ManualValue === "4" ? "新增需求" :
                                                            this.state.datas.ManualValue === "5" ? "其他问题" : "未知问题"}】
                                                <br/>
                                                手动通过描述：
                                                {this.state.datas.ManualText}
                                            </div>
                                        } placement={"top"} color={"geekblue"}>
                                            测试通过(Manual)
                                        </Tooltip> :
                                        this.state.datas.AutoTest === 3 ? "测试失败" : "测试中"}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="固件下载链接" span={3}>
                            {this.state.tasks_datas.AutoOTA === 1 ?
                                <a href={this.state.tasks_datas.FirmwareDownloadAddress}>
                                    <Space>
                                        <DownloadOutlined/> 固件下载
                                    </Space>
                                </a> :
                                this.state.tasks_datas.FirmwareAddress
                            }

                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </div>
        );
    }
}
