import React from "react";
import {Pie} from '@ant-design/plots';
import {Result, Spin} from "antd";

export default class Pie1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas ? props.datas : [],
            textColor: props.textColor
        }
    }


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
                textColor: this.props.textColor
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }

    render() {
        const config = {
            appendPadding: 10,
            style: {marginTop: 20},
            data: typeof this.state.datas !== "undefined" ? this.state.datas : [],
            angleField: 'value',
            colorField: 'type',
            height: 250,
            radius: 0.7,
            legend: false,
            // legend: {
            //     position: 'right',
            //     offsetY: -10,
            //     flipPage: false,
            //     itemName: {
            //         style: {
            //             fill: "#ffffff",
            //             fontSize: 11,
            //             fontWeight: 400,
            //         }
            //     },
            // },
            label: {
                type: 'spider',
                content: `{name} {value} ({percentage})`,
                labelHeight: 25,
                style: {
                    // fontSize: 13,
                    textAlign: 'center',
                    fontWeight: 500,
                    fill: this.state.textColor,
                },
            },
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
            ],
        };
        return <div>
            {this.state.datas.length > 0 ?
                <Pie {...config} /> :
                <div style={{textAlign: "center", height: 270}}>
                    <Result
                        status="success"
                        title={<div style={{color: this.state.textColor}}>
                            暂无数据
                        </div>}
                    />
                </div>
            }
        </div>;
    };
};