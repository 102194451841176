import {G2, Line} from '@ant-design/plots';
import React from "react";
import {Empty, Watermark} from "antd";
import Text from "antd/es/typography/Text";
import moment from 'moment';

export default class MonthLineChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas ? props.datas : [],
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            datas: nextProps.datas ? nextProps.datas : [],
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(this.props.datas === nextProps.datas && this.state.datas === nextState.datas);
    }

    render() {
        G2.registerShape('point', 'custom-point', {
            draw(cfg, container) {
                const point = {x: cfg.x, y: cfg.y};
                const group = container.addGroup();
                const __GetColor = () => {
                    let r = Math.floor(Math.random() * 256);
                    let g = Math.floor(Math.random() * 256);
                    let b = Math.floor(Math.random() * 256);

                    if (r < 16) {//当小于16时，只有一位数，所以给它前面串联一个0
                        r = "0" + r.toString(16);
                    } else {
                        r = r.toString(16);
                    }
                    if (g < 16) {
                        g = "0" + g.toString(16);
                    } else {
                        g = g.toString(16);
                    }
                    if (b < 16) {
                        b = "0" + b.toString(16);
                    } else {
                        b = b.toString(16);
                    }
                    return "#" + r + g + b;
                }
                const decorator1 = group.addShape('circle', {
                    attrs: {
                        x: point.x,
                        y: point.y,
                        r: 5,
                        fill: __GetColor(),
                        opacity: 0.5,
                    },
                });
                const decorator2 = group.addShape('circle', {
                    attrs: {
                        x: point.x,
                        y: point.y,
                        r: 5,
                        fill: __GetColor(),
                        opacity: 0.5,
                    },
                });
                const decorator3 = group.addShape('circle', {
                    attrs: {
                        x: point.x,
                        y: point.y,
                        r: 5,
                        fill: __GetColor(),
                        opacity: 0.5,
                    },
                });
                decorator1.animate(
                    {
                        r: 5,
                        opacity: 0,
                    },
                    {
                        duration: 1800,
                        easing: 'easeLinear',
                        repeat: true,
                    }
                );
                decorator2.animate(
                    {
                        r: 5,
                        opacity: 0,
                    },
                    {
                        duration: 1800,
                        easing: 'easeLinear',
                        repeat: true,
                        delay: 1600,
                    }
                );
                decorator3.animate(
                    {
                        r: 2,
                        opacity: 0,
                    },
                    {
                        duration: 1800,
                        easing: 'easeLinear',
                        repeat: true,
                        delay: 1200,
                    }
                );
                group.addShape('circle', {
                    attrs: {
                        x: point.x,
                        y: point.y,
                        r: 2,
                        fill: cfg.color,
                        opacity: 1.7,
                    },
                });
                group.addShape('circle', {
                    attrs: {
                        x: point.x,
                        y: point.y,
                        r: 2.5,
                        fill: cfg.color,
                    },
                });
                return group;
            },
        });
        const config = {
            data: this.state.datas,
            xField: 'month_str',
            yField: 'avg_rate',
            seriesField: 'type',
            stepType: 'hvh',
            height: 200,
            label: {
                formatter: (datum) => {
                    return datum.avg_rate ? datum.avg_rate.toFixed(3) + "%" : "0%"
                },
            },
            yAxis: {
                max: 110,
                tickCount: 10,
                min: 80
            },
            xAxis: {
                tickCount: 12,
                label: {
                    formatter: (value) => {
                        return value
                    },
                },
            },
            // slider: {
            //     start: 0,
            //     end: 1,
            // },
            point: {
                size: 1,
                shape: 'custom-point',
            },
            // seriesField: 'b',
            tooltip: {
                formatter: (datum) => {
                    return {
                        title: datum.month_str + "月",
                        name: "系统可用性",
                        value: datum.avg_rate.toFixed(3) + "%"
                    };
                },
            },
            lineStyle: {
                lineWidth: 1,
            },
            animation: {
                appear: {
                    animation: 'wave-in',
                    duration: 2000,
                    offset: 20
                },
            },
            color: ['#1979C9', '#FAA219'],
        }
        return this.state.datas.length !== 0 ?
            <Watermark content="公牛Bull">
                <Line {...config} />
                {/*<></>*/}
            </Watermark> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    }

};