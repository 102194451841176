import React from 'react';
import {
    Button,
    Input,
    message,
    Select,
    Space,
    Radio,
    Steps,
    Drawer,
    Col,
    Divider,
    Row,
    Tag,
    InputNumber,
    Progress,
    Typography, List, Card
} from "antd";
import api from '../../api/ApiConfig'
import {getUser, Loginout} from "../Users/AuthRouter";
import {
    CheckCircleTwoTone, CloseCircleOutlined,
    CloseSquareOutlined,
    ContactsOutlined,
    PlusSquareOutlined,
    ScheduleOutlined
} from "@ant-design/icons";
import {CodemirrorZzy} from "./CodemirrorZzy";
import {constant} from "@antv/g2plot/lib/plots/sankey/sankey/helper";
import {data} from "browserslist";
import moment from "moment";

const {Title, Paragraph, Text, Link} = Typography;


export default class AddDeviceTask extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: false,
            current: 0,
            AppType: 1,
            Env: "Test",
            account: null,
            password: null,
            Device: null,
            taskName: null,
            DeviceNumber: 1,
            bind_datas: [],
            DeviceCounts: 0,
            logs: [["INFO", moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'), "开始激活,并绑定设备"]],
            product_list: [],
            BindBtn: false,
            TaskProgress: 0,
            ProgressStatus: null
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getvirtualdevicebindstatus = async (params = {}) => {
        await api.GetVirtualDeviceBindStatus(params).then(r => {
                if (r.msgcode === 20008) {
                    Loginout()
                    this.props.history.push('/login')
                    window.location.reload()
                }
                if (r.msgcode === 20203) {
                    clearInterval(this.timer);
                    this.state.logs.push(["ERROR", moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'), "任务执行异常！！！"])
                    this.setState({
                        logs: this.state.logs,
                        BindBtn: false,
                        ProgressStatus: "exception"
                    }, function () {
                        let scrollContainer = document.getElementById('scrollableDiv');
                        scrollContainer.scrollTop = scrollContainer.scrollHeight
                    })
                    return false
                } else {
                    const logs_raw = JSON.parse(r['datas'].TaskLogs.replace(/'/g, '"'))
                    for (let i = 0; i < logs_raw.length; i++) {
                        const isContained = this.state.logs.some((arr) => arr.toString() === logs_raw[i].toString());
                        // 过滤重复日志
                        if (isContained !== true) {
                            this.state.logs.push(logs_raw[i])
                            this.setState({
                                logs: this.state.logs,
                                TaskProgress: r['datas'].TaskProgress
                            }, function () {
                                let scrollContainer = document.getElementById('scrollableDiv');
                                scrollContainer.scrollTop = scrollContainer.scrollHeight
                            })
                        }
                    }
                }
                if (r['datas'].celeryStatus === "SUCCESS") {
                    clearInterval(this.timer);
                    this.state.logs.push(["INFO", moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'), "任务执行完成,可手动关闭此窗口"])
                    this.setState({
                        logs: this.state.logs
                    }, function () {
                        let scrollContainer = document.getElementById('scrollableDiv');
                        scrollContainer.scrollTop = scrollContainer.scrollHeight
                    })
                }
            }
        )
    }

    getproducts_ypt = async (params = {}) => {
        await api.GetProducts_YPT(params).then(r => {
            if (typeof (r.msgcode) == 'number') {
                if (r.msgcode === 20008) {
                    Loginout()
                    this.props.history.push('/login')
                    window.location.reload()
                } else {
                    this.setState({
                        product_list: r.datas.result
                    })
                }
            }
        })
    }

    getfivetuple = async (params = {}) => {
        await api.GetFiveTuple(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    DeviceCounts: r.datas.counts
                })
            }
        })
    }

    BindDevices = async () => {
        if (this.state.account !== null && this.state.password !== null && this.state.taskName !== null && this.state.bind_datas.length > 0) {
            let values = {}
            const userinfo = getUser()
            values['userid'] = userinfo.UserId
            values['username'] = userinfo.username
            values['account'] = this.state.account
            values['password'] = this.state.password
            values['env'] = this.state.Env
            values['bindDatas'] = JSON.stringify(this.state.bind_datas)
            values['taskName'] = this.state.taskName
            values['AppType'] = this.state.AppType
            await api.CreateTask({values}).then(r => {
                if (r.msgcode === 200) {
                    message.success("开始激活、绑定设备")
                    this.timer = setInterval(() => {
                        this.getvirtualdevicebindstatus({task_id: r.TaskId})
                    }, 1000);
                } else if (r.msgcode === 20008) {
                    Loginout()
                    this.props.history.push('/login')
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                } else {
                    message.error(r.results)
                }
            })
        } else {
            message.error("缺少必要的参数请检查账号、设备配置后重试!")
        }
    }

    handleClose = (dn) => {
        let list = []
        this.state.bind_datas.map(item => {
            //修改被删除的item的数据
            if (item.DeviceName !== dn) {
                list.push(item)
            }
        })
        this.setState({
            bind_datas: list
        })
    }

    color16 = () => {
        let r = Math.floor(Math.random() * 256);
        let g = Math.floor(Math.random() * 256);
        let b = Math.floor(Math.random() * 256);
        r = r < 20 ? r + 30 : r
        g = g < 20 ? g + 30 : g
        b = b < 20 ? b + 30 : b
        return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
    }

    render() {
        const steps = [
            {
                title: '账号配置',
                content: <div>
                    <Space direction={"vertical"} size={20} style={{marginTop: 20}}>
                        <b style={{color: "red"}}>⚠️注意：切记不要使用ta人注册的五元组创建虚拟设备任务,否则会导致设备争抢,频繁上下线！！!</b>
                        <Space size={20}>
                            <div>任务名称</div>
                            <Input placeholder="请填写任务名称"
                                   value={this.state.taskName}
                                   style={{width: 262}} onChange={(e) => {
                                this.setState({
                                    taskName: e.target.value
                                })
                            }}/>
                        </Space>
                        <Space size={20}>
                            <div>登陆平台</div>
                            <Radio.Group style={{width: 262, textAlign: "left"}} value={this.state.AppType}
                                         buttonStyle="solid"
                                         onChange={(e) => {
                                             this.setState({
                                                 AppType: e.target.value
                                             })
                                         }}>
                                <Radio.Button value={1}>公牛智家</Radio.Button>
                                <Radio.Button value={2}>公牛沐光</Radio.Button>
                            </Radio.Group>
                        </Space>
                        <Space size={20}>
                            <div>账号环境</div>
                            <Radio.Group value={this.state.Env} buttonStyle="solid"
                                         onChange={(e) => {
                                             this.setState({
                                                 Env: e.target.value,
                                                 Device: null
                                             }, function () {
                                                 this.getproducts_ypt({"env": this.state.Env}).then(r => {
                                                 })
                                             })
                                         }}>
                                <Radio.Button value="Developer">开发环境</Radio.Button>
                                <Radio.Button value="Test">测试环境</Radio.Button>
                                <Radio.Button value="Online" disabled={true}>生产环境</Radio.Button>
                            </Radio.Group>
                        </Space>
                        <Space size={20}>
                            <div>用户账号</div>
                            <Input placeholder="请填写账号" value={this.state.account} style={{width: 262}}
                                   onChange={(e) => {
                                       this.setState({
                                           account: e.target.value
                                       })

                                   }}/>
                        </Space>
                        <Space size={20}>
                            <div>用户密码</div>
                            <Input.Password
                                placeholder="请填写密码" value={this.state.password} style={{width: 262}}
                                onChange={(e) => {
                                    this.setState({
                                        password: e.target.value
                                    })
                                }}/>
                        </Space>
                    </Space>
                </div>,
            },
            {
                title: '设备选择',
                content: <div>
                    <Space direction={"vertical"} size={10} style={{marginTop: 40}}>
                        <Space size={20}>
                            <div>设备列表</div>
                            <Select
                                style={{
                                    width: '300px',
                                }}
                                showSearch
                                placeholder="请选择设备类型"
                                optionFilterProp="children"
                                onChange={(value) => {
                                    this.getfivetuple({pk: value.split("@")[1]})
                                    for (let i = 0; i < this.state.product_list.length; i++) {
                                        if (this.state.product_list[i].productKey === value.split("@")[1]) {
                                            this.setState({
                                                Device: this.state.product_list[i],
                                            })
                                            break
                                        }
                                    }
                                }}
                                value={this.state.Device ? this.state.Device.productName + "@" + this.state.Device.productId : null}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={this.state.product_list.map((province) => ({
                                    label: province.productName + "@" + province.productId,
                                    value: province.productName + "@" + province.productKey + "@" + province.productId,
                                }))}/>
                            <div style={{fontSize: 20, marginTop: 5}}>*</div>
                            <InputNumber min={0} max={this.state.DeviceCounts} value={this.state.DeviceNumber}
                                         onChange={(value) => {
                                             this.setState({
                                                 DeviceNumber: value
                                             });
                                         }}/>
                            <Button type="primary" disabled={this.state.DeviceCounts === 0} onClick={() => {
                                let res = this.state.bind_datas.find(item => item.DeviceName === this.state.Device.productName.split("@")[0])
                                if (this.state.Device === null) {
                                    message.warning("请选择设备后在点击【添加】按钮").then(r => {
                                    })
                                } else if (this.state.DeviceNumber === 0) {
                                    message.warning("请输入要添加的数量").then(r => {
                                    })

                                } else if (res) {
                                    message.warning("设备【" + this.state.Device.productName.split("@")[0] + "】已添加、请勿重复操作").then(r => {
                                    })
                                } else {
                                    this.state.bind_datas.push({
                                        DeviceName: this.state.Device.productName,
                                        key: this.state.Device.productKey,
                                        Pid: this.state.Device.productId,
                                        Number: this.state.DeviceNumber,
                                        Color: this.color16(),
                                    })
                                    this.setState({
                                        bind_datas: this.state.bind_datas,
                                    })
                                }
                            }
                            }>添加</Button>
                        </Space>
                        <Text style={{
                            color: "#c40808",
                            fontSize: 10
                        }}>{this.state.Device ? "设备「" + this.state.Device.productName.split("@")[0] + "」可用五元组数量为「" + this.state.DeviceCounts + "」" : null}</Text>
                        <Divider dashed={false}
                                 style={{color: "#888888", width: "100vh", fontSize: 13}}>待添加的设备列表</Divider>
                        <div id="scrollableDiv"
                             style={{
                                 height: 120,
                                 overflow: 'auto',
                                 padding: '12px 16px',
                                 // border: "1px dashed rgb(145,159,149)",
                                 marginTop: -20,
                             }}>
                            <Space size={[0, 8]} wrap>
                                {this.state.bind_datas.map((item) => (
                                    <Tag key={item.key} closable icon={<ScheduleOutlined/>} onClose={(e) => {
                                        e.preventDefault();
                                        this.handleClose(item.DeviceName)
                                    }}
                                         color={item.Color}>
                                        {item.DeviceName}@{item.Pid}
                                        <b style={{color: "#ffffff"}}> x {item.Number} Pcs</b>
                                    </Tag>
                                ))}
                            </Space>
                        </div>
                    </Space>
                </div>,
            },
            {
                title: '设备激活、绑定',
                content: <div>
                    <Space direction={"vertical"} size={2}>
                        <Divider dashed={false}
                                 style={{color: "#888888", fontSize: 13}}>设备绑定进度</Divider>
                        <Space size={[50]} wrap style={{
                            marginLeft: 10,
                        }}>
                            <Progress
                                type="circle"
                                percent={this.state.TaskProgress}
                                status={this.state.ProgressStatus}
                                size={170}
                                strokeColor={{
                                    '0%': '#108ee9',
                                    '100%': '#87d068',
                                }}
                            />
                            <div id="scrollableDiv"
                                 style={{
                                     height: 200,
                                     overflow: 'auto',
                                     padding: '0 16px',
                                     // border: "1px dashed rgb(145,159,149)",
                                     background: "#0c0c0c",
                                     BoxShadow: "5px 5px 5px rgba(0,0,0,.5)",
                                     MozBoxShadow: "5px 5px 5px rgba(0,0,0,.5)",
                                     WebkitBoxShadow: "5px 5px 5px rgba(0,0,0,.5)"
                                 }}>
                                <Space
                                    size={0.5}
                                    direction="vertical"
                                    style={{
                                        minWidth: 900,
                                        maxWidth: "70vh",
                                        textAlign: "left",
                                        margin: '5px',
                                    }}
                                >
                                    {this.state.logs.map((item, index) => {
                                            return (
                                                <Text key={index}
                                                      type={item[0] === "INFO" ? "success" : item[0] === "ERROR" ? "danger" : "warning"}
                                                >
                                                    {"[" + item[1] + "] " + " [" + item[0] + "] " + item[2]}
                                                </Text>
                                            )
                                        }
                                    )}
                                </Space>
                            </div>
                        </Space>
                    </Space>
                </div>,
            },];
        const items = steps.map((item) => ({
            key: item.title,
            title: item.title,
        }));

        return <div>
            <Button type={'link'} style={{width: 100, color: '#707272'}}
                    icon={<PlusSquareOutlined/>}
                    onClick={() => {
                        this.getproducts_ypt({"env": this.state.Env}).then(r => {
                        })
                        this.setState({
                            isDrawerOpen: true
                        });
                    }}
            >
                创建任务
            </Button>
            <Drawer
                maskClosable={false}
                keyboard={false}
                title="创建虚拟设备任务"
                placement={'bottom'}
                height={600}
                onClose={() => {
                    this.setState({
                        isDrawerOpen: false,
                        current: 0,
                        AppType: 1,
                        Env: "Test",
                        account: null,
                        password: null,
                        Device: null,
                        taskName: null,
                        DeviceNumber: 1,
                        bind_datas: [],
                        DeviceCounts: 0,
                        logs: [["INFO", moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'), "开始激活,并绑定设备"]],
                        product_list: [],
                        BindBtn: false,
                        TaskProgress: 0,
                        ProgressStatus: null
                    }, function () {
                        clearInterval(this.timer);
                        this.props.fun()
                    });
                }}
                open={this.state.isDrawerOpen}
            >
                <div style={{marginTop: 40, marginLeft: 100, marginRight: 100}}>
                    <Steps current={this.state.current} items={items}/>
                    <div style={{
                        height: '350px',
                        textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 16,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <div>
                            {steps[this.state.current].content}
                        </div>
                    </div>
                    <div
                        style={{
                            marginTop: 24,
                            textAlign: 'center',
                        }}
                    >
                        {this.state.current > 0 && (
                            <Button
                                style={{
                                    margin: '0 8px',
                                }}
                                onClick={() => {
                                    this.setState({
                                        current: this.state.current - 1
                                    })
                                }}
                            >
                                上一步
                            </Button>
                        )}
                        {this.state.current < steps.length - 1 && (
                            <Button type="primary" onClick={() => {
                                if (this.state.current === 0) {
                                    if (this.state.account !== null && this.state.password !== null && this.state.taskName !== null) {
                                        this.setState({
                                            current: this.state.current + 1
                                        })
                                    } else {
                                        message.error("任务名称、账号、密码不可为空").then(r => {
                                            return false
                                        })
                                    }
                                }
                                if (this.state.current === 1) {
                                    if (this.state.bind_datas.length > 0) {
                                        this.setState({
                                            current: this.state.current + 1
                                        })
                                    } else {
                                        message.error("待添加的设备列表为空，请添加设备后继续").then(r => {
                                            return false
                                        })
                                    }
                                }
                            }
                            }>
                                下一步
                            </Button>
                        )}
                        {this.state.current === steps.length - 1 && (
                            <Button type="primary" disabled={this.state.BindBtn} onClick={() => {
                                this.setState({
                                    BindBtn: true,
                                    logs: [["INFO", moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'), "开始激活,并绑定设备"]],
                                    TaskProgress: 0,
                                    ProgressStatus: null
                                }, function () {
                                    this.BindDevices()
                                })
                            }}>
                                开始绑定
                            </Button>
                        )}
                    </div>
                </div>
            </Drawer>
        </div>
    }
}
