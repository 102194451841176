import React, {Component} from 'react';
import '@ant-design/pro-components/dist/components.css';
import {PageHeader} from '@ant-design/pro-layout';
import {
    Affix,
    Button,
    Divider,
    Input,
    Badge,
    Space,
    Table,
    Tag,
    Select,
    message,
    Popconfirm, Drawer, Spin
} from 'antd';
import api from "../../api/ApiConfig";
import {getUser, Loginout} from "../Users/AuthRouter";

import {
    DeleteOutlined,
    DisconnectOutlined,
    QuestionCircleOutlined,
    RocketOutlined,
    StopOutlined, TeamOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import Card from "antd/es/card/Card";
import Avatar from "antd/es/avatar/avatar";
import AddDeviceTask from "./AddDeviceTask";


export class DeviceTask extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            env: "all",
            ph: "all",
            isDrawerOpen: false,
            userid: null,
            TaskListLoading: false, //任务列表
            FiveListLoading: false, //五元组列表
        }
    }

    componentDidMount() {
        this.gettasks()
    }

    columns_style = {fontSize: 11, color: '#707272'}

    startvirtualdevices = async (TaskId) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['TaskId'] = TaskId
        await api.StartVirtualDevices({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("虚拟设备启动成功")
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.gettasks()
        })
    }

    stopvirtualdevices = async (TaskId) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['TaskId'] = TaskId
        await api.StopVirtualDevices({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("虚拟设备停止成功")
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.gettasks()
        })
    }
    unbindvirtualdevices = async (TaskId) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['TaskId'] = TaskId
        await api.UnBindVirtualDevices({values}).then(r => {
            if (r.msgcode === 200) {
                // message.success("解绑设备指令发送成功,后台正在为您执行解绑操作,持续时间由设备数量决定请稍后自行查看")
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.gettasks()
        })
    }
    gettasks = async (params = {}) => {
        this.setState({
            TaskListLoading: true
        })
        let dataSourcelist = []
        params['env'] = this.state.env
        params['ph'] = this.state.ph
        if (this.state.userid != null) {
            params['userid'] = this.state.userid
        }
        await api.GetTasks(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (r.length !== 0) {
                    for (let i = 0; i < r['datas'].length; i++) {
                        dataSourcelist.push(
                            r['datas'][i].fields
                        )
                        dataSourcelist[i]['key'] = r['datas'][i].pk
                    }
                }
                this.setState({
                    dataSource: dataSourcelist
                })
            }
            this.setState({
                TaskListLoading: false
            })
        })
    }

    deltasks = async (TaskId) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['TaskId'] = TaskId
        await api.DelTasks({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("虚拟设备任务删除成功")
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.gettasks()
        })
    }

    getdevicelist = async (params = {}) => {
        this.setState({
            FiveListLoading: true,
        })
        let dataSourcelist = []
        await api.GetDeviceList(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (r.length !== 0) {
                    for (let i = 0; i < r['datas'].length; i++) {
                        dataSourcelist.push(
                            r['datas'][i].fields
                        )
                        dataSourcelist[i]['key'] = r['datas'][i].pk
                    }
                }
                this.setState({
                    dataSource1: dataSourcelist,
                    spinning: false
                })
                this.setState({
                    FiveListLoading: false,
                })
            }
        })
    }

    render() {
        const columns = [
            {
                title: 'No.',
                key: '1',
                fixed: 'left',
                width: 60,
                align: 'center',
                render: (record, text, index) => {
                    return (
                        index + 1
                    )
                }
            },
            {
                title: '任务名称',
                dataIndex: 'taskName',
                align: 'center',
                key: 'taskName',
            },
            {
                title: 'APP类型',
                width: 90,
                dataIndex: 'AppType',
                align: 'center',
                key: 'AppType',
                render: (text, re) => (
                    re.AppType === "1" ? <Tag color={"#0a4793"}>公牛智家</Tag> :
                        re.AppType === "2" ? <Tag color={"rgb(152,106,0)"}>公牛沐光</Tag> : null
                ),
            },
            {
                title: '账号环境',
                width: 110,
                dataIndex: 'env',
                key: 'env',
                align: 'center',
                render: (text, re) => (
                    re.env === "Developer" ? <Tag color={"#d2a616"}>开发环境 </Tag> :
                        re.env === "Test" ? <Tag color={"#2db7f5"}>测试环境 </Tag> :
                            re.env === "Online" ? <Tag color={"#9b1313"}>生产环境 </Tag> : null
                ),
                sorter: (a, b) => a.env !== undefined ? (a.env.length - b.env.length) : null
            },
            {
                title: '任务状态',
                width: 110,
                dataIndex: 'TaskStartStatus',
                key: 'TaskStartStatus',
                align: 'center',
                render: (text, re) => (
                    re.TaskStartStatus === "1" ? <Tag color={"#0765ea"}>已启动 </Tag> :
                        re.TaskStartStatus === "2" ? <Tag color={"#de1147"}>已停止 </Tag> :
                            <Tag color={"#d2a616"}>未知 </Tag>
                ),
                sorter: (a, b) => a.env !== undefined ? (a.env.length - b.env.length) : null
            },
            {
                title: '登陆账号',
                width: 130,
                dataIndex: 'account',
                align: 'center',
                key: 'account',
            },
            {
                title: '登陆密码',
                width: 180,
                dataIndex: 'password',
                align: 'center',
                key: 'password',
                render: ((text, re) => (
                    <div style={{
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}>{re.password}</div>
                ))
            },
            {
                title: '添加、更新人',
                width: 180,
                render: (text, re) => (
                    <Space>
                        <Avatar
                            size={30}
                            style={{
                                backgroundColor: "#141414",
                                verticalAlign: 'middle',
                            }}
                        >U</Avatar>
                        <div style={this.columns_style}>{re.username}({re.userid})</div>
                    </Space>
                )
            },
            {
                title: '创建时间',
                dataIndex: 'CreatedTime',
                key: 'CreatedTime',
                align: 'center',
            },
            {
                title: '操作',
                dataIndex: 'operate',
                key: 'operate',
                fixed: 'right',
                width: 300,
                align: 'center',
                render: (text, re) => (
                    <>
                        {/*<NavLink to={"/DeviceDetails/" + re.key}>*/}
                        {/*<Button type={'link'} icon={<ApartmentOutlined/>}*/}
                        {/*        style={{fontSize: 12, marginLeft: -10}}*/}
                        {/*        onClick={() => {*/}
                        {/*            window.open("https://debug.iotbull.com/Devices/" + re.account + "/" + re.env)*/}
                        {/*        }}*/}
                        {/*>*/}
                        {/*    设备详情*/}
                        {/*</Button>*/}
                        {/*</NavLink>*/}
                        {/*<NavLink to={"/DeviceDetails/" + re.key}>*/}
                        {/*    <Button type={'link'} icon={<ApartmentOutlined/>}*/}
                        {/*            style={{fontSize: 12, marginLeft: -10}}*/}
                        {/*    >*/}
                        {/*        设备详情*/}
                        {/*    </Button>*/}
                        {/*</NavLink>*/}
                        <Space size={1}>
                            <Button type={'link'} size={"small"} disabled={re.TaskStartStatus === "1"}
                                    icon={<RocketOutlined/>}
                                    style={{fontSize: 13, width: 70}}
                                    onClick={() => {
                                        this.startvirtualdevices(re.key)
                                    }}
                            >
                                启动设备
                            </Button>
                            <Divider type="vertical"/>
                            <Button size={"small"} disabled={re.TaskStartStatus === "2"}
                                    type={'link'} icon={<StopOutlined/>}
                                    style={{fontSize: 13, width: 70}}
                                    onClick={() => {
                                        this.stopvirtualdevices(re.key)
                                    }}>
                                停止设备
                            </Button>
                            <Divider type="vertical"/>
                            <Popconfirm
                                style={{fontSize: 13}}
                                placement="left"
                                title="删除任务"
                                description={<div style={{textAlign: 'left', width: 200}}>
                                    <div style={{fontSize: 10, color: "#555555"}}>
                                        <br/>
                                        <b style={{color: "red"}}> &nbsp;1.释放当前绑定的五元组</b>
                                        <br/>
                                        <b style={{color: "red"}}> &nbsp;2.移除账号下所有设备</b>
                                        <br/>
                                        <br/>
                                        你确定要删除任务吗？
                                    </div>
                                </div>}
                                icon={
                                    <QuestionCircleOutlined
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                } onConfirm={() => {
                                this.unbindvirtualdevices(re.key).then(r => {
                                    this.deltasks(re.key).then(r => {
                                    })
                                })

                            }}
                            >
                                <Button
                                    disabled={re.TaskStartStatus === "1"}
                                    type="link" danger
                                    size={"small"}
                                    icon={<DeleteOutlined/>}
                                    style={{fontSize: 13, width: 70}}
                                >
                                    删除任务
                                </Button>
                            </Popconfirm>
                        </Space>
                    </>
                )
            },
        ];
        const columns1 = [
            {
                title: 'No.',
                key: '1',
                fixed: 'left',
                width: 25,
                align: 'center',
                render: (record, text, index) => {
                    return (index + 1)
                }
            },
            {
                title: 'DeviceName',
                width: 40,
                dataIndex: 'DeviceName',
                align: 'center',
                key: 'DeviceName',
            },
            {
                title: 'PID',
                width: 30,
                dataIndex: 'PID',
                align: 'center',
                key: 'PID',
            },
            {
                title: 'ProductKey',
                width: 40,
                dataIndex: 'ProductKey',
                align: 'center',
                key: 'ProductKey',
            },
            {
                title: '设备状态',
                width: 30,
                align: 'center',
                render: (text, re) => {
                    return (
                        <>
                            <div style={{
                                textAlign: 'center',
                            }}>
                                <Space>
                                    <div style={{
                                        position: "relative",
                                        display: "inline - block",
                                        width: "6px",
                                        height: "6px",
                                        verticalAlign: "middle",
                                        borderRadius: "50%",
                                        backgroundColor: re.DeviceStatus === "1" ? "#51be1e" : re.DeviceStatus === "0" ? "#f64d4e" : "#f6d853",
                                    }}/>
                                    {re.DeviceStatus === "0" ? "离线" : re.DeviceStatus === "1" ? "在线" : "未知"}
                                </Space>
                            </div>
                        </>
                    )
                }
            },
            {
                title: 'ProductSecret',
                width: 50,
                dataIndex: 'ProductSecret',
                align: 'center',
                key: 'ProductSecret',
            },
            {
                title: 'DeviceSecret',
                width: 80,
                dataIndex: 'DeviceSecret',
                align: 'center',
                key: 'DeviceSecret',
            },

            {
                title: '创建时间',
                dataIndex: 'CreatedTime',
                key: 'CreatedTime',
                width: 60,
                align: 'center',
            },
        ];

        const expandedRowRender = (r, index) => {
            const bd = JSON.parse(r.bindDatas)
            const columns = [
                {
                    title: 'No.',
                    key: Math.random() + index,
                    fixed: 'left',
                    width: 70,
                    align: 'center',
                    render: (record, text, index) => {
                        return (
                            index + 1
                        )
                    }

                },
                {
                    title: '设备名称',
                    dataIndex: 'DeviceName',
                    key: 'DeviceName',
                    align: 'center',
                },
                {
                    title: '设备PID',
                    dataIndex: 'Pid',
                    key: 'Pid',
                    align: 'center',
                },
                {
                    title: '设备数量',
                    dataIndex: 'Number',
                    key: 'Number',
                    align: 'center',
                },
                {
                    title: '设备PK',
                    dataIndex: 'key',
                    key: 'key',
                    align: 'center',
                },
                {
                    title: '设备详情',
                    dataIndex: 'key',
                    key: 'key',
                    align: 'center',
                    render: (text, re) => (
                        <Button
                            type="link"
                            icon={<DeleteOutlined/>}
                            style={{fontSize: 12, marginLeft: -20}}
                            onClick={() => {
                                this.getdevicelist({"task_id": r.key, "pk": re.key}).then(r => {
                                    this.setState({
                                        isDrawerOpen: true,
                                    })
                                })
                            }}
                        >
                            设备列表
                        </Button>
                    )
                }
            ];
            return <Badge.Ribbon key={Math.random()} placement={"start"} text="设备概览" color="blue">
                <Card bordered={true} key={Math.random()}>
                    <Table columns={columns}
                           key={Math.random()}
                           style={{marginLeft: 5, marginTop: 5}}
                           dataSource={bd}
                           size={"small"}
                           pagination={false}
                           bordered
                           scroll={{
                               y: '20vh',
                           }}
                    />
                </Card>
            </Badge.Ribbon>
        }
        return (
            <>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="任务列表"
                            subTitle="虚拟设备任务管理"
                            extra={
                                <Space size={[0]}>
                                    <Button icon={<UserOutlined/>} type={'link'} style={{width: 100, color: '#707272'}}
                                            onClick={() => {
                                                const userinfo = getUser()
                                                this.setState({
                                                    userid: userinfo.UserId
                                                }, function () {
                                                    this.gettasks()
                                                })
                                            }}
                                    >
                                        我的任务
                                    </Button>
                                    <Divider type="vertical"/>
                                    <Button icon={<TeamOutlined/>} type={'link'} style={{width: 100, color: '#707272'}}
                                            onClick={() => {
                                                this.setState({
                                                    userid: null
                                                }, function () {
                                                    this.gettasks()
                                                })
                                            }}
                                    >
                                        全部任务
                                    </Button>
                                    <Divider type="vertical"/>
                                    <AddDeviceTask fun={this.gettasks}/>
                                    <Divider type="vertical"/>
                                </Space>
                            }
                        >
                            <Content/>
                        </PageHeader>
                    </div>
                </Affix>
                <Drawer
                    title="五元组列表"
                    placement={'bottom'}
                    height={"60vh"}
                    onClose={() => {
                        this.setState({
                            isDrawerOpen: false
                        })
                    }}
                    open={this.state.isDrawerOpen}>
                    <div>
                        <Spin tip="设备列表拉取中,请稍等片刻....." spinning={this.state.spinning}>
                            <Card bordered={true}>
                                <Table
                                    size={"small"}
                                    pagination={
                                        {
                                            showTotal: () => `共 ${this.state.dataSource1 ? this.state.dataSource1.length : 0} 条 `,
                                            defaultPageSize: 1000
                                        }
                                    }
                                    scroll={{
                                        y: '30vh',
                                    }}
                                    loading={this.state.FiveListLoading}
                                    columns={columns1}
                                    dataSource={this.state.dataSource1}
                                    bordered/>
                            </Card>
                        </Spin>
                    </div>
                </Drawer>
                <div style={{marginTop: 3, background: '#ffffff', minHeight: 743}}>
                    <Card bordered={true}>
                        <Table
                            title={() =>
                                <Card bordered={true}>
                                    <Space>
                                        <Select
                                            defaultValue={this.state.env}
                                            value={this.state.env}
                                            onChange={(value) => {
                                                this.setState({
                                                    env: value
                                                })
                                            }}
                                            style={{
                                                width: 120,
                                            }}
                                            options={[
                                                {
                                                    value: 'all',
                                                    label: '所有环境',
                                                },
                                                {
                                                    value: 'Developer',
                                                    label: '开发环境',
                                                },
                                                {
                                                    value: 'Test',
                                                    label: '测试环境',
                                                },
                                                {
                                                    value: 'Online',
                                                    label: '生产环境',
                                                },
                                            ]}
                                        />
                                        <Input placeholder={"请输入手机号(模糊查询)"}
                                               value={this.state.ph === "all" ? null : this.state.ph}
                                               onChange={(e) => {
                                                   this.setState({
                                                       ph: e.target.value
                                                   })
                                               }}/>
                                        <Button type="primary" ghost onClick={() => {
                                            this.gettasks()
                                        }}>查询</Button>
                                        <Button type="primary" ghost onClick={() => {
                                            this.setState({
                                                ph: "all",
                                                env: "all"
                                            }, function () {
                                            })

                                        }}>重置</Button>
                                    </Space>
                                </Card>
                            }
                            pagination={
                                {
                                    showTotal: () => `共 ${this.state.dataSource ? this.state.dataSource.length : 0} 条 `,
                                    defaultPageSize: 20
                                }
                            }
                            loading={this.state.TaskListLoading}
                            columns={columns}
                            dataSource={this.state.dataSource}
                            scroll={{
                                x: 1700,
                            }}
                            bordered
                            expandable={
                                {
                                    expandedRowRender: (record, index) => {
                                        return expandedRowRender(record, index)
                                    },
                                    defaultExpandedRowKeys: [this.state.dataSource.length > 0 ? this.state.dataSource[0].key : null],
                                    // defaultExpandAllRows: true,
                                    rowExpandable: (record) => record.bindDatas.length !== 0,

                                }}
                        />
                    </Card>
                </div>
            </>
        )
    }

}