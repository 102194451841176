import React from "react";
import {Button, Result} from "antd";
import {withRouter} from 'react-router-dom'
class NotAuthorized extends React.Component {

    back_home = ()=>{
        this.props.history.push('/')
        window.location.reload()
    }
    render() {
        return(
            <Result
                status="403"
                title="403"
                subTitle="对不起,您没有权限访问这个页面"
                extra={<Button type="primary" onClick={this.back_home}>Back Home</Button>}
            />
            )
    }
}
export default withRouter(NotAuthorized)