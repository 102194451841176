import React from "react";
import {DualAxes, G2} from '@ant-design/plots';
import {Empty, Result, Spin} from "antd";

export default class DualAxesDp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas,
            textColor: props.textColor,
            height: props.height

        }
    }

    componentDidMount() {

    }


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
                textColor: this.props.textColor
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }

    render() {
        const {registerTheme} = G2;
        registerTheme('custom-theme', {
            colors10: ['#FACDAA', '#F4A49E', '#EE7B91', '#E85285', '#BE408C', '#BE408C'],

            /** 20色板 */
            colors20: ['#FACDAA', '#F4A49E', '#EE7B91', '#E85285', '#BE408C', '#BE408C', '#942D93'],
        });
        const uvBillData = this.state.datas.uvBillData;
        const transformData = this.state.datas.transformData;

        const config = {
            data: [uvBillData, transformData],
            xField: 'time',
            height: this.state.height,
            yField: ['value', 'value'],
            appendPadding: [30, 10, 20, 10],
            scrollbar: {},
            geometryOptions: [
                {
                    geometry: 'column',
                    // isStack: true,
                    isGroup: true,
                    seriesField: 'type',
                    columnStyle: {
                        radius: [20, 20, 0, 0],
                    },
                    dodgePadding: 1,
                    // intervalPadding: 20,
                    // columnWidthRatio: 1,
                    // label: {
                    //     // layout: [
                    //     //     {
                    //     //         type: 'hide-overlap',
                    //     //     },
                    //     // ],
                    //     style: {
                    //         fill: '#333538',
                    //         fontSize: 10,
                    //         // textAlign: 'center',
                    //         // textBaseline: 'middle',
                    //     },
                    // }
                },
                {
                    geometry: 'line',
                    seriesField: 'type',
                    smooth: true,
                    // stepType: 'vh',
                    lineStyle: {
                        lineWidth: 2,
                    },
                    color: ['#E8684A', '#a416d2', '#FF9D4D', '#FF99C3'],
                    position: 'center',
                    label: {
                        layout: [
                            {
                                type: 'hide-overlap',
                            },
                        ],
                        formatter: (datum) => {
                            return datum.value ? datum.value.toFixed(2) + "%" : "0%"
                        },
                        style: {
                            fill: this.state.textColor,
                            textAlign: 'right',
                            fontSize: 12,
                            fontWeight: 500
                            // textBaseline: 'middle',
                        },
                    },
                },
            ],
            // yAxis: [
            //     {min: 0, max: 600, tickCount: 10},
            //     {min: 0, max: 110, tickCount: 10}
            // ],
            legend: {
                layout: 'horizontal',
                // flipPage: false,
                position: 'top',
                // minRow: 1,
                itemName: {
                    style: {
                        fill: this.state.textColor,
                        fontWeight: 500,
                    }
                },
            },
            interactions: [
                {
                    type: 'element-highlight',
                },
                {
                    type: 'active-region',
                },
            ],
            smooth: true,
        };
        return <div>
            {this.state.datas.uvBillData.length > 0 && this.state.datas.transformData.length > 0 ?
                <DualAxes {...config} /> :
                // <div style={{textAlign: "center"}}>
                //     <Spin tip={"数据加载中....."}/>
                // </div>
                <Result
                    status="success"
                    title={<div style={{color: "#fff"}}>
                        暂无数据
                    </div>}
                />
            }
        </div>;
    };
};