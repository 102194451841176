import React, {Component} from 'react'
import {PageHeader} from '@ant-design/pro-layout';
import {Affix, Button, Modal, Card, Divider, FloatButton, Input, Space, Table, Tag, Tooltip} from 'antd';
import {
    CloseOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import api from "../../api/ApiConfig";
import {Loginout} from "../Users/AuthRouter";
import Avatar from "antd/es/avatar/avatar";

export class UserConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            display_button: false,
            datas: [],
            isModalOpen: false,
            user_id: null,
            user_id_Gn: null,
            email_Gn: null,
            email: null,
        }
    }


    componentDidMount() {
        this.getuserlist_atmp()
    }

    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    datas: r['datas']
                })
            }
        })
    }
    postuserlist_atmp = async () => {
        let datas = {"values": {}}
        if (this.state.user_id !== null) {
            datas['values']['user_id'] = this.state.user_id
        }
        if (this.state.user_id_Gn !== null) {
            datas['values']['user_id_Gn'] = this.state.user_id_Gn
        }
        if (this.state.email_Gn !== null) {
            datas['values']['email_Gn'] = this.state.email_Gn
        }
        if (this.state.email !== null) {
            datas['values']['email'] = this.state.email
        }
        await api.PostUserList_ATMP(datas).then((r) => {
            if (r.msgcode === 20008) {
                this.props.history.push('/login')
            } else {
                this.getuserlist_atmp()
            }

        })


    }
    handleOk = () => {
        console.log("获取需要修改的参数", this.state.email, this.state.email_Gn)
        this.postuserlist_atmp()
        this.setState({
            user_id: null,
            email_Gn: null,
            email: null,
            user_id_Gn: null,
            isModalOpen: false,
        })
    }
    handleCancel = () => {
        this.setState({
            user_id: null,
            email_Gn: null,
            email: null,
            user_id_Gn: null,
            isModalOpen: false,
        })
    }

    render() {
        const columns = [
            {
                title: '头像',
                dataIndex: 'name',
                // width:40,
                align: "center",
                key: "user_id",
                render: (_, record) => (
                    <Avatar size={40} shape="square" src={record.avatar_url}/>
                )
            },
            {
                title: '姓名',
                dataIndex: 'name',
                align: "center",
                key: "user_id",
                render: (_, record) => (
                    <div>{record.name}</div>
                )
            },
            {
                title: '飞书用户ID',
                dataIndex: 'user_id',
                key: "user_id",
                align: "center",
            },
            {
                title: '公牛工号',
                dataIndex: 'user_id_Gn',
                key: "user_id",
                align: "center",
            },
            {
                title: '公牛邮箱',
                dataIndex: 'email_Gn',
                key: "user_id",
                align: "center",
            },
            {
                title: '飞书邮箱',
                dataIndex: 'email',
                key: "user_id",
                align: "center",
            },
            {
                title: '操作',
                key: "user_id",
                align: "center",
                render: (_, record) => (
                    <Space size="middle" style={{fontSize: 12, fontWeight: 700}}>
                        <Button type={"link"} onClick={() => {
                            this.setState({
                                isModalOpen: true,
                                user_id: record.user_id,
                                user_id_Gn: record.user_id_Gn,
                                email_Gn: record.email_Gn,
                                email: record.email,
                            })
                        }}>编辑</Button>
                    </Space>
                ),
            },
        ];
        return (<>
            <div>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        // height: 150
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="用户管理"
                            subTitle="用户配置"
                            onBack={() => window.history.back()}
                            extra={
                                <Space size={[0]}>
                                    {this.state.display_button ?
                                        <Input.Group>
                                            <Input
                                                style={{width: 300}}
                                                placeholder="搜索关键字"
                                                onPressEnter={(e) => {
                                                    console.log()
                                                }}
                                                prefix={<SearchOutlined/>}
                                                suffix={<CloseOutlined onClick={() => {
                                                    this.setState({display_button: false})
                                                    console.log()
                                                }}/>}
                                            />
                                        </Input.Group> :
                                        <Button type={'link'} style={{width: 100, color: '#707272'}}
                                                onClick={() => {
                                                    this.setState({display_button: true})
                                                }} icon={<SearchOutlined/>}>搜索</Button>
                                    }
                                    {/*<Button type={'link'} icon={<UserOutlined />} style={{width:100,color:'#707272'}}>我的项目</Button>*/}
                                    <Divider type="vertical"/>
                                </Space>
                            }
                        >
                        </PageHeader>
                    </div>
                </Affix>
                <FloatButton.BackTop visibilityHeight={100}/>
            </div>
            <div style={{margin: 5, minHeight: '80vh'}}>
                <Modal title={<div style={{textAlign: "left"}}>编辑用户</div>} style={{textAlign: "center"}}
                       open={this.state.isModalOpen} onOk={this.handleOk} onCancel={this.handleCancel}>
                    <Space direction={"vertical"} style={{fontSize: 13, fontWeight: 700}}>
                        <Space>
                            公牛工号: <Input value={this.state.user_id_Gn} style={{width: 300}} onChange={(v) => {
                            this.setState({
                                user_id_Gn: v.target.value,
                            })
                        }}/>
                        </Space>
                        <Space>
                            飞书邮箱: <Input value={this.state.email} style={{width: 300}} onChange={(v) => {
                            this.setState({
                                email: v.target.value
                            })
                        }}/>
                        </Space>
                        <Space>
                            公牛邮箱: <Input value={this.state.email_Gn} style={{width: 300}} onChange={(v) => {
                            this.setState({
                                email_Gn: v.target.value
                            })
                        }}/>
                        </Space>
                    </Space>
                </Modal>
                <Card>
                    <Table  pagination={false} bordered={true} columns={columns} dataSource={this.state.datas}/>
                </Card>
            </div>
        </>);
    }
}
