import React from "react";
import {Pie, measureTextWidth} from '@ant-design/plots';
import {Result, Spin} from "antd";

export default class PieHt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas ? props.datas : [],
        }
    }


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }


    render() {
        function renderStatistic(containerWidth, text, style) {
            const {width: textWidth, height: textHeight} = measureTextWidth(text, style);
            const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

            let scale = 1;

            if (containerWidth < textWidth) {
                scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
            }
            const textStyleStr = `width:${containerWidth}px;`;
            return `<div style="${textStyleStr};color:wheat;font-size:17px;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
        }

        const config = {
            appendPadding: 10,
            data: typeof this.state.datas !== "undefined" ? this.state.datas : [],
            angleField: 'value',
            colorField: 'type',
            height: 270,
            radius: 1,
            innerRadius: 0.64,
            legend: {
                position: 'right',
                offsetY: -10,
                flipPage: false,
                itemName: {
                    style: {
                        fill: "#ffffff",
                        // fontSize: 11,
                        fontWeight: 500,
                    }
                },
            },
            meta: {
                value: {
                    formatter: (v) => `${v} ¥`,
                },
            },
            label: {
                type: 'inner',
                offset: '-50%',
                style: {
                    textAlign: 'center',
                },
                autoRotate: false,
                content: `{value}`,
            },
            statistic: {
                title: {
                    offsetY: -4,
                    customHtml: (container, view, datum) => {
                        const {width, height} = container.getBoundingClientRect();
                        const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                        const text = datum ? datum.type : '总计';
                        return renderStatistic(d, text, {
                            fontSize: 13,
                            color: "#fff"
                        });
                    },
                },
                content: {
                    offsetY: 4,
                    style: {
                        fontSize: '32px',
                    },
                    customHtml: (container, view, datum, data) => {
                        const {width} = container.getBoundingClientRect();
                        const text = datum ? `${datum.value}` : `${data.reduce((r, d) => r + d.value, 0)}`;
                        return renderStatistic(width, text, {
                            fontSize: 13,
                            fill: "#fff"
                        });
                    },
                },
            },
            // 添加 中心统计文本 交互
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
                {
                    type: 'pie-statistic-active',
                },
            ],
        };
        return <div>{this.state.datas.length > 0 ? <Pie {...config} /> :
            <div style={{textAlign: "center", height: 270}}>
                <Result
                    status="success"
                    title={<div style={{color: "#fff"}}>
                        暂无数据
                    </div>}
                />
            </div>
        }
        </div>;
    };
};