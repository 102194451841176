import React, {Component} from 'react';
import '@ant-design/pro-components/dist/components.css';
import {PageHeader} from '@ant-design/pro-layout';
import {Affix, Button, Descriptions, Divider, Input, Radio, Space, Table, Tag, Select, message, Popconfirm} from 'antd';
import api from "../../api/ApiConfig";
import {getUser, Loginout} from "../Users/AuthRouter";
import moment from "moment";

import {NavLink} from "react-router-dom";
import {
    ApartmentOutlined, DeleteOutlined, DisconnectOutlined, QuestionCircleOutlined, RocketOutlined, StopOutlined,
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import Card from "antd/es/card/Card";
import Avatar from "antd/es/avatar/avatar";
import AddDeviceTask from "./AddDeviceTask";


export class DeviceDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {

    }

    startvirtualdevices = async (TaskId) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['TaskId'] = TaskId
        await api.StartVirtualDevices({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("虚拟设备任务成功")
                this.gettasks()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    stopvirtualdevices = async (TaskId) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['TaskId'] = TaskId
        await api.StopVirtualDevices({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("虚拟设备停止成功")
                this.gettasks()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        return (
            <>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="设备详情"
                            subTitle="虚拟设备详情"
                            onBack={() => window.history.back()}
                            extra={
                                <Space size={1}>
                                    <Button type={'link'} icon={<RocketOutlined/>}
                                            style={{fontSize: 13, width: 90}}
                                            onClick={() => {
                                                this.startvirtualdevices(this.props.match.params.id)
                                            }}
                                    >
                                        启动设备
                                    </Button>
                                    <Divider type="vertical"/>
                                    <Button type={'link'} icon={<StopOutlined/>}
                                            style={{fontSize: 13, width: 90, marginLeft: 0}}
                                            onClick={() => {
                                                this.stopvirtualdevices(this.props.match.params.id)
                                            }}>
                                        停止设备
                                    </Button>
                                    <Divider type="vertical"/>
                                    <Button type={'link'} icon={<DisconnectOutlined/>}
                                            style={{fontSize: 13, width: 90, marginLeft: 0}}>
                                        解绑设备
                                    </Button>
                                </Space>
                            }
                        >
                            <Content/>
                        </PageHeader>
                    </div>
                </Affix>
                <div style={{marginTop: 3, background: '#ffffff', minHeight: 733}}>
                    阿莱克斯大家阿斯利康大家
                </div>
            </>
        )
    }

}