import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import MenuPage from '../components/Menu/MenuPage';
import {AuthRouter} from "../components/Users/AuthRouter";
import NotFound from "../components/Exceptions/NotFound"
import {LOGIN} from "../components/Users/login";
import WXLogin from "../components/Users";
import {TaskList} from "../components/AutoTest/TestProjectManagement/TaskList";
import ScrollToTop from 'react-router-scroll-top';
import {BugDashboardFullScreenHome} from "../components/BugDashboard/BugDashboardFullScreenHome";
import {UserConfig} from "../components/SystemConfig/UserConfig";
import {VoiceTestDetails} from "../components/VoiceTest/VoiceTestDetails";

class AppRouter extends Component {
    render() {
        return (
            <BrowserRouter>
                <ScrollToTop/>
                <Switch>
                    <AuthRouter strict exact path='/' component={MenuPage}/>
                    <AuthRouter exact needLogin={false} path="/login" component={WXLogin}/>
                    <AuthRouter exact path='/login/fs' component={MenuPage}/>
                    <AuthRouter exact path='/home' component={LOGIN}/>
                    <AuthRouter exact path='/DeviceManagement' component={MenuPage}/>
                    <AuthRouter exact path='/TupleManagement' component={MenuPage}/>
                    <AuthRouter exact path='/PerformanceTest' component={MenuPage}/>
                    <AuthRouter exact path='/DeviceDetails/:id' component={MenuPage}/>
                    <AuthRouter exact path='/PerformanceTestDetails/:id' component={MenuPage}/>
                    <AuthRouter exact path='/ServiceAvailability' component={MenuPage}/>
                    <AuthRouter exact path='/ServiceDetail/:apptype/:cycle' component={MenuPage}/>
                    <AuthRouter exact path='/DevicesDetail/:apptype/:cycle' component={MenuPage}/>
                    <AuthRouter exact path='/Repository/' component={MenuPage}/>
                    <AuthRouter exact path='/TestProject/' component={MenuPage}/>
                    <AuthRouter exact path='/TaskList/:id/:name' component={MenuPage}/>
                    <AuthRouter exact path='/TestTaskHome' component={MenuPage}/>
                    <AuthRouter exact path='/ProjectTesting' component={MenuPage}/>
                    <AuthRouter exact path='/ProjectTaskDetails/:id' component={MenuPage}/>
                    <AuthRouter exact path='/ProjectTestConfig' component={MenuPage}/>
                    <AuthRouter exact path='/UserConfig' component={MenuPage}/>
                    <AuthRouter exact path='/AppProjectDetails/:id' component={MenuPage}/>
                    <AuthRouter exact path='/BugDashboard' component={MenuPage}/>
                    <AuthRouter exact path='/FirmwareTaskDetails/:id' component={MenuPage}/>
                    <AuthRouter exact path='/VoiceTest' component={MenuPage}/>
                    <AuthRouter exact path='/VoiceTestDetails/:id' component={MenuPage}/>
                    <AuthRouter exact needLogin={false} path='/BugDashboardTv' component={BugDashboardFullScreenHome}/>
                    <AuthRouter component={NotFound}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default AppRouter