import React, {Component} from 'react';
import {Pie, Bar} from '@ant-design/plots';
import moment from "moment";
import insertCss from "insert-css";
import {Empty, Watermark} from "antd";

export class DevicesRoseChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas ? props.datas : [],
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }

    render() {
        this.state.datas.sort(function (a, b) {
            return b.OffLineNumber - a.OffLineNumber;
        });
        // const config = {
        //     appendPadding: 10,
        //     data: this.state.datas,
        //     angleField: 'StatusChanges',
        //     colorField: 'purposeName',
        //     radius: 1,
        //     // 设置圆弧起始角度
        //     startAngle: Math.PI,
        //     endAngle: Math.PI * 2,
        //     label: {
        //         type: 'inner',
        //         offset: '-8%',
        //         // content: '{name} {percentage}',
        //         style: {
        //             fill: 'rgba(0, 0, 0, 0.65)',
        //             fontSize: 13,
        //             fontWeight:700,
        //         },
        //         formatter: (value) => {
        //             return value.globalProductId +"_"+ value.purposeName + "(" + value.StatusChanges + "次)"
        //         },
        //     },
        //     interactions: [
        //         {
        //             type: 'element-active',
        //         },
        //     ],
        //     pieStyle: {
        //         lineWidth: 2,
        //     },
        // };
        const config = {
            data: this.state.datas,
            xField: 'OffLineNumber',
            yField: 'purposeName',
            seriesField: 'purposeName',
            // isStack: true,
            height: 25 * this.state.datas.length,
            label: {
                // 可手动配置 label 数据标签位置
                position: 'middle',
                // 'left', 'middle', 'right'
                // 可配置附加的布局方法
                layout: [
                    // 柱形图数据标签位置自动调整
                    {
                        type: 'interval-adjust-position',
                    }, // 数据标签防遮挡
                    {
                        type: 'interval-hide-overlap',
                    }, // 数据标签文颜色自动调整
                    {
                        type: 'adjust-color',
                    },
                ],
                style: {
                    fill: 'rgb(238,238,235)',
                    fontSize: 14,
                },
                formatter: (value) => {
                    if (value.OffLineNumber !== 0) {
                        return value.OffLineNumber + "次"
                    }

                },

            },
        };
        return <div style={{textAlign: "left", marginTop: 10}}> {this.state.datas.length !== 0 ?
            <Bar {...config}/> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </div>
    }
}