import React, {Component} from 'react';
import {Empty} from "antd";
import {DualAxes, G2} from '@ant-design/plots';

export class DualAxesCharts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }

    render() {
        const {datas} = this.state
        const l1 = datas.l1
        const l2 = datas.l2
        const {registerTheme} = G2;
        registerTheme('custom-theme', {
            colors10: ['#FACDAA', '#F4A49E', '#EE7B91', '#E85285', '#BE408C', '#BE408C'],
            /** 20色板 */
            colors20: ['#FACDAA', '#F4A49E', '#EE7B91', '#E85285', '#BE408C', '#BE408C', '#942D93'],
        });
        const config = {
            data: [l1, l2],
            xField: 'ProjectName',
            height: 300,
            yField: ['Value', 'CoverageRate', 'Type'],
            meta: {
                Type: {
                    alias: '产出耗时',
                    formatter: (v) => {
                        if (v === "TestCaseBase") {
                            return "用例基数";
                        }
                        if (v === "completed") {
                            return "已完成用例"
                        }
                    },
                },
                CoverageRate: {
                    alias: '用例覆盖率',
                    formatter: (v) => {
                        return v + "%";
                    },
                }
            },
            yAxis: [
                {},  // 设置第一条折线图和柱状图的 Y 轴最大值为 1500
                {min: 0, max: 100, tickCount: 5}             // 第二条折线图默认不设置最大值
            ],
            geometryOptions: [
                {
                    geometry: 'column',
                    isGroup: true,
                    seriesField: 'Type',
                    // columnWidthRatio: 0.3,
                    label: {},
                },
                {
                    geometry: 'line',
                    smooth: true,
                    // stepType: 'vh',
                    lineStyle: {
                        lineWidth: 2,
                    },
                    label: {
                        formatter: (datum) => {
                            return datum.CoverageRate ? datum.CoverageRate.toFixed(2) + "%" : "0%"
                        },
                    },
                }
            ],
            legend: {
                // layout: 'vertical',
                position: "top",
                itemName: {
                    formatter: (val) => `${val}`,
                    style: {
                        fill: '#333',
                    },
                },
            },
            // interactions: [
            //     {
            //         type: 'element-highlight',
            //     },
            //     {
            //         type: 'active-region',
            //     },
            // ],
            animation: true,
            theme: 'custom-theme',
        };
        return <> {Object.keys(this.state.datas).length > 0 ?
            <DualAxes {...config} /> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </>
    }
}