import React, {Component} from 'react';
import {
    FileExcelTwoTone,
    FilePdfTwoTone,
    FileWordTwoTone,
    FileZipTwoTone,
    LoadingOutlined,
    PaperClipOutlined,
    PictureTwoTone,
    UploadOutlined
} from '@ant-design/icons';
import {Button, Form, message, Upload} from 'antd';
import axios from "axios";
import api from "../../../api/ApiConfig";
import {Loginout} from "../../Users/AuthRouter";

class AliYunOssUploader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            datas: props.datas,
            OssUrl: props.OssUrl,
            fun: props.fun,
            TaskId: props.TaskId
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas) ||
            JSON.stringify(this.props.OssUrl) !== JSON.stringify(prevProps.OssUrl) ||
            JSON.stringify(this.props.TaskId) !== JSON.stringify(prevProps.TaskId)
        ) {
            this.setState({
                datas: this.props.datas,
                OssUrl: this.props.OssUrl,
                TaskId: this.props.TaskId,
            })
        }
    }


    beforeUpload = (file) => {
        const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/zip';
        if (!isXlsx) {
            message.error('只能上传xlsx和zip类文件!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2000;
        if (!isLt2M) {
            message.error('文件大小不得大于2000MB!');
        }
        return isXlsx && isLt2M || Upload.LIST_IGNORE;
    }

    handleIconRender = (file, listType) => {
        const fileSufIconList = [
            {
                type: <FilePdfTwoTone/>,
                suf: ['.pdf'],
            },
            {
                type: <FileExcelTwoTone twoToneColor={"#52c41a"}/>,
                suf: ['.xlsx', '.xls', '.csv'],
            },
            {
                type: <FileWordTwoTone/>,
                suf: ['.doc', '.docx'],
            },
            {
                type: <FileZipTwoTone twoToneColor={"#a11010"}/>,
                suf: ['.zip', '.rar', '.7z'],
            },
            {
                type: <PictureTwoTone/>,
                suf: ['.webp', '.svg', '.png', '.gif', '.jpg', '.jpeg', '.jfif', '.bmp', '.dpg'],
            },
        ];
        // console.log(1, file, listType);
        let icon = file.status === 'uploading' ? <LoadingOutlined/> : <PaperClipOutlined/>;
        if (listType === 'text' || listType === 'picture-card') {
            if (listType === 'picture-card' && file.status === 'uploading') {
                icon = <LoadingOutlined/>; // or icon = 'uploading...';
            } else {
                fileSufIconList.forEach((item) => {
                    if (item.suf.includes(file.name.slice(file.name.lastIndexOf('.')))) {
                        icon = item.type;
                    }
                });
            }
        }
        return icon;
    };

    customRequest = (options) => {
        const {onSuccess, onError, file, onProgress} = options;
        const formData = new FormData();
        formData.append('file', file);
        // 先获取oss临时签名链接
        const filename = this.state.TaskId + "_" + this.state.datas.name + file.name.slice(file.name.lastIndexOf('.'))
        this.state.fun(filename, file.type).then(() => {
            console.log("任务ID", this.state.TaskId, "文件类型：", file.type, "文件名称：", file.name, this, "上传链接：", this.state.OssUrl)
            axios.put(this.state.OssUrl, file, {
                method: 'PUT',
                body: file,
                headers: {
                    "Content-Type": file.type
                },
                onUploadProgress: ({total, loaded}) => {
                    onProgress({percent: Math.round((loaded / total) * 100).toFixed(2)});
                },
            }).then(response => {
                console.log('上传结果', response)
                if (response.status === 200) {
                    onSuccess(response);
                    return response
                }
            }).catch(error => {
                onError(error);
                console.log('上传结果', error)
            });
        })
    }


    render() {
        return (
            <Form.Item
                style={{marginLeft: 10}}
                name={this.state.datas.name}
                label={this.state.datas.label}
                rules={[
                    {
                        required: false,
                    }
                ]}
            >
                <Upload
                    maxCount={1}
                    name="file"
                    customRequest={this.customRequest}
                    beforeUpload={this.beforeUpload}
                    iconRender={this.handleIconRender}
                    onChange={(info) => {
                        if (info.file.status !== 'uploading') {
                            console.log("上传成功的文件列表:", info.fileList);
                        }
                        if (info.file.status === 'done') {
                            message.success(`${info.file.name} 文件上传成功！`);
                        } else if (info.file.status === 'error') {
                            message.error(`${info.file.name} 文件上传失败！`);
                        }
                    }}
                >
                    <Button icon={<UploadOutlined/>} style={{fontSize: 13, fontWeight: 600}}>点击上传</Button>
                </Upload>
            </Form.Item>
        );
    }
}

export default AliYunOssUploader;