import React, {Component} from 'react';
import {Button, Divider, Progress, Space, Spin} from 'antd';
import {PauseCircleOutlined, PlayCircleOutlined} from "@ant-design/icons";
import WaveSurfer from 'wavesurfer.js';


export class AudioPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            currentTime: 0,
            duration: 0,
            audioSrc: props.audioSrc,
            audioKey: props.audioKey,
            spinning: true
        };
        this.wavesurfer = null; // 用于存储 WaveSurfer 实例
    }

    componentDidMount() {
        this.initWaveSurfer();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.playing !== this.state.playing) {
            if (this.state.playing) {
                this.wavesurfer.play();
            } else {
                this.wavesurfer.pause();
            }
        }
    }

    componentWillUnmount() {
        this.wavesurfer.destroy(); // 销毁 WaveSurfer 实例
    }

    initWaveSurfer = () => {
        const container = document.getElementById(this.state.audioKey);
        if (container) {
            this.wavesurfer = WaveSurfer.create({
                container: container,
                waveColor: "#bae0ff",
                progressColor: "#1677ff",
                responsive: true,
                height: 30,
                width: 200,
            });

            this.wavesurfer.on('seeking', (time) => {
                const currentTime = time;
                const duration = this.wavesurfer.getDuration();
                this.setState({currentTime, duration});
            });
            this.wavesurfer.on('ready', () => {
                const duration = this.wavesurfer.getDuration();
                this.setState({duration: duration, spinning: false});
            });
            this.wavesurfer.on('audioprocess', () => {
                const currentTime = this.wavesurfer.getCurrentTime();
                const duration = this.wavesurfer.getDuration();
                this.setState({currentTime, duration});
            });

            this.wavesurfer.on('finish', () => {
                this.setState({
                    playing: false
                })
                // 在这里添加播放完成后的回调逻辑
            });
            // this.wavesurfer.load("https://img.houzi8.com/original_music/preview/2024/07/11/c4914b92dc23acc0b66a3a94e95d7cf1.mp3?sign=6cfd43cae1a147ab4ec7cbe95f278785&t=66fd56ca&e=1727878678");
            this.wavesurfer.load(this.state.audioSrc);
        }
    };

    handlePlayPause = () => {
        this.setState(prevState => ({playing: !prevState.playing}));
    };

    formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    render() {
        const {playing, currentTime, duration, audioKey} = this.state;
        const formattedCurrentTime = this.formatTime(currentTime);
        const formattedDuration = this.formatTime(duration);
        return (
            <Spin spinning={this.state.spinning} tip="音频数据加载中...">
                <Space direction={"vertical"} size={10}>
                    <div id={audioKey} className={"waveform"}/>
                    <Space size={5}>
                        <Button type={"link"} size={"small"} onClick={this.handlePlayPause}>
                            <Space size={3} style={{fontSize: 12}}>
                                {playing ? <PauseCircleOutlined/> : <PlayCircleOutlined/>}
                                {playing ? '暂停' : '播放'}
                            </Space>
                        </Button>
                        <Space style={{fontSize: 12}}>{formattedCurrentTime} / {formattedDuration}</Space>
                    </Space>
                </Space>
            </Spin>
        );
    }
}
