import React, {Component, PureComponent} from 'react'
import {Card, Spin, Tabs, message, Space} from 'antd'
import loadScriptOnce from 'load-script-once'
import {CorPid, AgentId, redirect_uri_wx} from '../../api/UrlConfig'
import axios from 'axios';

class WXLogin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            fsisLoading: true,
            activeKey: "fsdl",
        }
    }

    async componentDidMount() {
        this.initScript()
    }


    initScript = () => {
        const WXLOGINJS_URL = 'https://wwcdn.weixin.qq.com/node/wework/wwopen/js/wwLogin-1.2.7.js'
        loadScriptOnce(
            WXLOGINJS_URL,
        )
        this.onOpenLogin()
        this.onOpenLogin1()
    }


    onOpenLogin = () => {
        const timer = setTimeout(() => {
            this.initWX()
            clearTimeout(timer)
        }, 1000)
    }

    onOpenLogin1 = () => {
        const timer = setTimeout(() => {
            this.initFS()
            clearTimeout(timer)
        }, 1000)
    }

    initWX() {
        try {
            const url = `${redirect_uri_wx}wx/`
            new window.WwLogin({
                id: "login_container",
                appid: CorPid,
                agentid: AgentId,
                redirect_uri: encodeURI(url),
                scope: "snsapi_privateinfo",
                lang: "zh",
                href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogdHVyZTt9DQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDIwMHB4O30NCi5zdGF0dXNfaWNvbiB7ZGlzcGxheTogdHVyZX0NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9"
            })
            this.setState({isLoading: false})
        } catch (e) {
            console.log("企业微信登陆异常", e)
        }

    }

    initFS() {
        try {
            const appId = "cli_a609c6a9b2ae500b";
            const redirect_uri = `${redirect_uri_wx}`;
            this.setState({fsisLoading: false})
            var gotoUrl = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${appId}&redirect_uri=${encodeURI(redirect_uri)}&response_type=code&state=success_login`;
            var QRLoginObj = window.QRLogin({
                id: "login_container1",
                goto: `${gotoUrl}`,
                style: "width: 300px; height: 300px; border:0;padding:10px;",
            });
            var handleMessage = function (event) {
                // 使用 matchOrigin 和 matchData 方法来判断 message 和来自的页面 url 是否合法
                if (QRLoginObj.matchOrigin(event.origin) && QRLoginObj.matchData(event.data)) {
                    var loginTmpCode = event.data.tmp_code;
                    // 在授权页面地址上拼接上参数 tmp_code，并跳转
                    window.location.href = `${gotoUrl}&tmp_code=${loginTmpCode}`;
                }
            };
            if (typeof window.addEventListener != 'undefined') {
                window.addEventListener('message', handleMessage, false);
            } else if (typeof window.attachEvent != 'undefined') {
                window.attachEvent('onmessage', handleMessage);
            }
        } catch (e) {
            console.log("飞书登陆异常", e)
        }
    }


    render(params) {
        return (
            <div>
                <video style={{
                    position: 'absolute',
                    overflow: "hidden",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    pointerEvents: "none",
                    objectFit: 'cover',
                }} autoPlay loop muted>
                    <source
                        src="https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr"
                        type="video/mp4"/>
                </video>
                <div style={{
                    overflow: "hidden",
                    borderRadius: '6px',
                    textAlign: 'center',
                    width: "450px",
                    height: "650px",
                    position: 'absolute', left: '50%', top: '40%',
                    margin: '-300px 0 0 -200px',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(10px)',
                    padding: '32px',
                    backgroundImage: 'radial-gradient(circle at 93% 1e+02%, rgba(22,119,255,0.17) 0%, rgba(255,255,255,0.05) 23%, rgba(255,255,255,0.03) 87%, rgba(22,119,255,0.12) 109%)'
                    // :"https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr"
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "44px",
                        lineHeight: "44px"
                    }}>
                        <div style={{
                            position: 'relative',
                            tinsetBlockStartop: '12px',
                            fontWeight: 600,
                            fontSize: '33px',
                        }}>
                            综合测试平台
                        </div>
                    </div>
                    <div style={{
                        marginBlockStart: '12px',
                        marginBlockEnd: '40px',
                        color: 'rgba(0, 0, 0, 0.65)',
                        fontSize: '14px'
                    }}>
                        接口、UI、设备、性能、系统巡检自动化相关任务
                    </div>
                    <Tabs
                        centered
                        activeKey={this.state.activeKey}
                        onChange={(activeKey) => {
                            this.setState({
                                activeKey: activeKey
                            }, function () {
                                if (activeKey === "qywx") {
                                    this.onOpenLogin()
                                }
                            })
                        }}
                    >
                        <Tabs.TabPane key={'fsdl'} tab={'飞书登陆'}>
                            <div style={{textAlign: "center"}}>
                                {this.state.fsisLoading ?
                                    <Spin spinning={this.state.fsisLoading}
                                          style={{marginTop: "15%"}}
                                          tip="正在为您获取登陆二维码,请稍后...."
                                    /> :
                                    <Space direction="vertical" size={5}>
                                        <div id={"login_container1"}/>
                                        <div style={{fontSize: 12, color: "#707272"}}>
                                            <Space>请使用飞书应用扫描二维码登录</Space>
                                            <br/>
                                            <Space>系统自动化测试平台</Space>
                                        </div>
                                    </Space>
                                }
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane disabled={true} key={'qywx'} tab={'企业微信'}>
                            <div id="login_container"
                                 style={{marginTop: "15%"}}
                                 onClick={this.onOpenLogin}
                            >
                                <Spin spinning={this.state.isLoading}
                                      tip="正在为您获取登陆二维码,请稍后...."
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane disabled={true} key={'phone'} tab={'账号密码'}/>
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default WXLogin
