import React, {Component} from 'react';
import {Button, Row, Col, Form, Card, Input, Select, message, Space} from "antd";
import Modal from "antd/es/modal/Modal";
import {getUser, Loginout} from "../../Users/AuthRouter";
import api from "../../../api/ApiConfig";
import {PlusCircleOutlined} from "@ant-design/icons";

const {TextArea} = Input;

export class CreateTT extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas,
            fun1: props.fun1,
            btn_type: props.btn_type
        }
        this.form = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
                fun1: this.props.fun1,
            })
        }
    }

    userinfo = getUser()
    posttesttask = async (values) => {
        values['ProjectId'] = this.state.datas.ProjectId
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['Markers'] = JSON.stringify(["BLOCKER"])
        values['Users'] = JSON.stringify([this.userinfo.username + "(" + this.userinfo.UserId + ")"])
        values['TaskConfig'] = JSON.stringify({})
        values['ProjectTest'] = 2
        values['TaskTimePlan'] = ""
        await api.PostTestTask({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("任务创建成功")
                this.setState({
                    isModalOpen: false,
                }, function () {
                    this.state.fun1()
                    this.form.current.resetFields();
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const showModal = () => {
            this.setState({
                isModalOpen: true
            })
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = (values) => {
            console.log("创建测试任务", values)
            this.posttesttask(values)
        }
        return <>
            {
                this.state.btn_type ?
                    <Button size={"small"} disabled={false} onClick={showModal}
                            type={"link"} style={{width: 70, color: '#707272'}}>
                        <Space size={5}>
                            <PlusCircleOutlined/>
                            <div style={{fontWeight: 800, fontSize: 12}}>创建任务</div>
                        </Space>
                    </Button> :
                    <Button size={"middle"} type={"primary"} style={{fontWeight: 800}} disabled={false}
                            onClick={showModal}
                            icon={<PlusCircleOutlined/>}>
                        创建测试任务
                    </Button>
            }
            <Modal title="创建测试任务"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{marginTop: 100, minWidth: 700}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 450,
                                marginTop: 30,
                                marginLeft: 50
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="TaskName"
                                        label="任务名称"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入任务名称",
                                            }
                                        ]}
                                    >
                                        <Input showCount maxLength={40} minLength={1} placeholder={"请输入任务名称"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="TaskType"
                                        label="任务类型"
                                        initialValue={1}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择任务类型",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{width: 140, textAlign: "center"}}
                                            placeholder="请选择任务类型"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            // onChange={(value) => {
                                            //     this.form.current.setFieldsValue({ProjectImageUrl: this.ProjectImageUrl[value]})
                                            // }}
                                            options={[
                                                {
                                                    value: 1,
                                                    label: '设备自动化',
                                                },
                                                {
                                                    value: 2,
                                                    label: '接口自动化',
                                                },
                                                {
                                                    value: 3,
                                                    label: 'App自动化',
                                                },
                                                {
                                                    value: 4,
                                                    label: 'Web自动化',
                                                },
                                                {
                                                    value: 5,
                                                    label: '自定义任务',
                                                },
                                                {
                                                    value: 6,
                                                    label: '稳定性任务',
                                                },
                                                {
                                                    value: 7,
                                                    label: '本地化任务',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="TaskNote"
                                        label="任务描述"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入任务描述",
                                            }
                                        ]}
                                    >
                                        <TextArea showCount rows={3} placeholder="请输入任务描述" maxLength={50}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item style={{marginTop: 30, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit">
                                    确认创建
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}