import {PageHeader} from '@ant-design/pro-layout';
import {Dropdown, Row, Col, Avatar, Space, Badge, Menu, Tag} from 'antd';
import React from "react";
import {withRouter} from 'react-router-dom'
import {getUser, Loginout} from "./AuthRouter";
import {
    LogoutOutlined,
    UserOutlined
} from "@ant-design/icons";
import Meta from "antd/es/card/Meta";


class UserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null,
            userid: null,
            position: null,
            avatar: null
        };
    }

    componentDidMount() {
        const datas = getUser()
        this.setState({
            username: datas.username,
            userid: datas.UserId,
            avatar_url: datas.avatar_url
        })
    }

    Loginout = () => {
        Loginout()
        this.props.history.push('/')
        window.location.reload()
    }

    render() {
        const items = [
            {
                key: '1',
                label: (
                    <a onClick={e => {
                        this.Loginout()
                    }}>
                        退出登陆
                    </a>
                ),
            },
        ];
        return (
            <PageHeader style={{marginLeft: 5}}
                        title={
                            <div className="logo" style={{marginTop: 5}}>
                                <Space>
                                    <img
                                        alt="pro-logo"
                                        src="https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/favicon.ico"
                                        // src="favicon.ico"
                                        style={{
                                            width: 32,
                                            height: 32,
                                            marginTop: -10,
                                        }}
                                    />
                                    <a
                                        style={{
                                            lineHeight: '48rpx',
                                            display: 'flex',
                                            height: 48,
                                            color: 'rgb(246,245,248)',
                                            alignItems: 'center',
                                            marginTop: -10,
                                            fontSize: 16
                                        }}
                                        href="/"
                                        // target="_blank"
                                        rel="noreferrer"
                                    >
                                        BULL 公牛
                                    </a>
                                </Space>
                            </div>
                        }
                        extra={
                            <Space size={5} style={{marginTop:5}}>
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="bottomLeft"
                                    arrow
                                >
                                    <Avatar shape="square"
                                            size={37}
                                            src={this.state.avatar_url}
                                    />
                                </Dropdown>
                                <Space direction={"vertical"} size={0}>
                                    <Tag color="black" style={{marginLeft: 2, fontSize: 13, fontWeight: 700}}>
                                        {this.state.userid ? this.state.username + '(' + this.state.userid + ')' : '系统用户'}
                                    </Tag>
                                </Space>
                            </Space>
                        }

            >
            </PageHeader>
        )
    }
}

export default withRouter(UserInfo)