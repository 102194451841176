import React, {Component} from "react";
import {Button, Card, Col, Descriptions, Divider, Input, message, Row, Select, Space, Spin, Tag, Tooltip,} from "antd";

import {AppstoreAddOutlined} from "@ant-design/icons";
import api from "../../api/ApiConfig";
import Modal from "antd/es/modal/Modal";
import {getUser, Loginout} from "../Users/AuthRouter";
import Avatar from "antd/es/avatar/avatar";
import Text from "antd/es/typography/Text";

export class Approval extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TaskID: props.TaskID,
            APPROVALDATAS: {},
            fun: props.fun,
            DeploymentNotes: null,
            Spinning: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.TaskID !== prevProps.TaskID) {
            this.setState({
                TaskID: this.props.TaskID,
            });
        }
    }

    componentDidMount() {
        this.getuserlist_atmp()
    }

    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <div>
                            <Tooltip title={<div>{value.name}({value.user_id})</div>} color={"#108ee9"} key={key}>
                                <Avatar key={key} size={30} src={value.avatar_url}/>
                            </Tooltip>
                        </div>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }


    getapprovaldatas = async () => {
        this.setState({
            Spinning: true
        })
        let params = {}
        params['TaskID'] = this.state.TaskID
        await api.GetApprovalDatas(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: Object.keys(r.datas).length > 0,
                    APPROVALDATAS: r.datas,
                })
            } else {
                message.error(r.results)
            }
            setTimeout(() => {
                this.setState({
                    Spinning: false
                })
            }, 100)

        })
    }

    userinfo = getUser()

    commitapproval = async (values = {}) => {
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['TaskID'] = this.state.TaskID
        if (this.state.DeploymentNotes !== null && this.state.DeploymentNotes !== "") {
            values['DeploymentNotes'] = this.state.DeploymentNotes
        }
        console.log("提交审批", values)
        await api.CommitApproval({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    message.success("提交审批成功")
                    this.state.fun()
                })

            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            setTimeout(() => {
                this.setState({
                    Spinning: false
                })
            }, 1000)
        })
    }

    render() {
        const showModal = () => {
            this.getapprovaldatas()
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = () => {
            console.log("发起审批确认", this.state.TaskID)
            this.setState({
                Spinning: true
            })
            this.commitapproval()
        }
        const record = Object.keys(this.state.APPROVALDATAS).length > 0 ? this.state.APPROVALDATAS.ServicesList : "[]"

        let ServicesListObj = []
        try {
            // 将单引号替换为双引号
            // 解析 JSON 字符串为 JavaScript 对象
            ServicesListObj = JSON.parse(record);
        } catch (e) {
            console.log("服务列表解析异常", e, record)
            try {
                // 将单引号替换为双引号
                const jsonStringWithDoubleQuotes = record.replace(/'/g, '"');
                // 解析 JSON 字符串为 JavaScript 对象
                ServicesListObj = JSON.parse(jsonStringWithDoubleQuotes);
            } catch (e) {

            }
        }
        return <>
            <Button style={{width: 80}} size={"small"} disabled={false}
                    type={"link"} onClick={showModal}>
                <Space size={5} style={{fontWeight: 800, fontSize: 13}}>
                    <AppstoreAddOutlined/> 发起审批
                </Space>
            </Button>
            <Modal title="【发起审批】信息确认"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{minWidth: 900}}
                   footer={null}
                   maskClosable={false}
            >
                <Spin spinning={this.state.Spinning} tip={"审批流创建中,请稍后...."}>
                    <Card style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 30,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)",
                    }}>
                        <div style={{maxHeight: "60vh", overflowY: "auto"}}>
                            <Descriptions title="审批信息确认" bordered column={2}
                                          labelStyle={{width: 110, fontSize: 12, fontWeight: 700}}
                                          contentStyle={{fontSize: 12, fontWeight: 700}}
                            >
                                <Descriptions.Item label="服务名称"
                                                   style={{fontSize: 13, fontWeight: 800}}
                                                   span={1}>
                                    {this.state.APPROVALDATAS.ServiceName}
                                </Descriptions.Item>
                                <Descriptions.Item label="服务版本"
                                                   style={{fontSize: 13, fontWeight: 800}}
                                                   span={1}>
                                    {this.state.APPROVALDATAS.ServiceVersion}
                                </Descriptions.Item>
                                <Descriptions.Item label="服务描述"
                                                   style={{fontSize: 13, fontWeight: 800}}
                                                   span={2}>
                                    {this.state.APPROVALDATAS.ServiceNotes}
                                </Descriptions.Item>
                                <Descriptions.Item label="服务环境" span={1}>
                                    <Tag
                                        color={
                                            this.state.APPROVALDATAS.ServiceEnv === "TEST" ? "#5b8c00" :
                                                this.state.APPROVALDATAS.ServiceEnv === "RELEASE" ? "#8d062c" :
                                                    this.state.APPROVALDATAS.ServiceEnv === "PRE" ? "#d05715" : "#9254de"
                                        }
                                        style={{
                                            marginRight: 5,
                                            textAlign: "center",
                                            fontSize: 12,
                                            fontWeight: 700,
                                            width: 70,
                                        }}
                                    >
                                        {this.state.APPROVALDATAS.ServiceEnv === "TEST" ? "测试环境" :
                                            this.state.APPROVALDATAS.ServiceEnv === "RELEASE" ? "生产环境" :
                                                this.state.APPROVALDATAS.ServiceEnv === "PRE" ? "预发布" : "未知环境"}
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="服务类型" span={1}>
                                    <Tag
                                        style={{
                                            marginRight: 5,
                                            textAlign: "center",
                                            fontSize: 12,
                                            fontWeight: 700,
                                            width: 85,
                                        }}
                                        color={
                                            this.state.APPROVALDATAS.ServiceType === 1 ? "#1677ff" :
                                                this.state.APPROVALDATAS.ServiceType === 2 ? "#13c2c2" :
                                                    this.state.APPROVALDATAS.ServiceType === 3 ? "#d05715" :
                                                        this.state.APPROVALDATAS.ServiceType === 4 ? "#9254de" : "#9254de"
                                        }>
                                        {
                                            this.state.APPROVALDATAS.ServiceType === 1 ? "云服务" :
                                                this.state.APPROVALDATAS.ServiceType === 2 ? "App" :
                                                    this.state.APPROVALDATAS.ServiceType === 3 ? "固件" :
                                                        this.state.APPROVALDATAS.ServiceType === 4 ? "Web" : "未知类型"
                                        }
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="备注信息" span={2}>
                                    <Input.TextArea
                                        placeholder={
                                            "此处的备注信息会在审批通过、部署之前同步到运维(" +
                                            (this.state.APPROVALDATAS.OperationsList !== undefined ?
                                                JSON.parse(this.state.APPROVALDATAS.OperationsList) : "").toString() + ")"
                                        }
                                        value={this.state.DeploymentNotes}
                                        onChange={(e) => {
                                            this.setState({
                                                DeploymentNotes: e.target.value
                                            })
                                        }}
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item label="自动化测试" span={2}>
                                    <Space direction={"vertical"} size={2}>
                                        <Space size={0} style={{fontSize: 12, fontWeight: 700}}>
                                            自动化测试项目:【 {this.state.APPROVALDATAS.AutoTestProjectInfo ? this.state.APPROVALDATAS.AutoTestProjectInfo.split("@")[0] : null} 】
                                        </Space>
                                        <Space size={0} style={{fontSize: 12, fontWeight: 700}}>
                                            自动化测试任务:【 {this.state.APPROVALDATAS.AutoTestTaskInfo ? this.state.APPROVALDATAS.AutoTestTaskInfo.split("@")[0] : null} 】
                                        </Space>
                                    </Space>
                                </Descriptions.Item>
                                <Descriptions.Item label="服务列表" span={2}>
                                    {/*<div style={{height: 150, overflow: 'auto'}}>*/}
                                    <div>
                                        {ServicesListObj.map((value, index, array) => {
                                            return <Row style={{fontSize: 12, fontWeight: 700}}>
                                                <Divider orientation={"left"}
                                                         style={{fontSize: 12}}>No.{index + 1}</Divider>
                                                <Col span={24}>
                                                    <Space style={{fontSize: 12}}> 服务名称: {value.serviceName}</Space>
                                                </Col>
                                                <Col span={12}>
                                                    <Space style={{fontSize: 12}}> 服务版本: {value.version}</Space>
                                                </Col>
                                                <Col span={12}>
                                                    <Space
                                                        style={{fontSize: 12}}> 提交分支: {value.gitBranch}</Space>
                                                </Col>
                                                <Col span={12}>
                                                    <Space style={{fontSize: 12}}> 提交人员: {value.commitor}</Space>
                                                </Col>
                                                <Col span={12}>
                                                    <Space style={{fontSize: 12}}> 提交时间: {value.commiteTime}</Space>
                                                </Col>
                                                <Col span={24}>
                                                    <div style={{fontSize: 12}}>
                                                        提交消息: {value.msg}
                                                    </div>
                                                </Col>
                                            </Row>
                                        })}
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="发起审批" span={1}>
                                    <Avatar.Group
                                        maxCount={10}
                                        size={"default"}
                                        maxStyle={{
                                            color: '#6d45c2',
                                            backgroundColor: '#fde3cf',
                                        }}
                                    >
                                        {(this.state.APPROVALDATAS.InitiateApproverList !== undefined ?
                                            JSON.parse(this.state.APPROVALDATAS.InitiateApproverList) :
                                            []).map((value, index) => {
                                            for (let i = 0; i < this.state.userList.length; i++) {
                                                if (value === this.state.userList[i].value) {
                                                    return this.state.userList[i].label
                                                }
                                            }
                                        })}
                                    </Avatar.Group>
                                </Descriptions.Item>
                                <Descriptions.Item label="审批人员" span={1}>
                                    <Avatar.Group
                                        maxCount={10}
                                        size={"default"}
                                        maxStyle={{
                                            color: '#6d45c2',
                                            backgroundColor: '#fde3cf',
                                        }}
                                    >
                                        {(this.state.APPROVALDATAS.ApproversList !== undefined ?
                                            JSON.parse(this.state.APPROVALDATAS.ApproversList) :
                                            []).map((value, index) => {
                                            for (let i = 0; i < this.state.userList.length; i++) {
                                                if (value === this.state.userList[i].value) {
                                                    return this.state.userList[i].label
                                                }
                                            }
                                        })}
                                    </Avatar.Group>
                                </Descriptions.Item>
                                <Descriptions.Item label="抄送人员" span={1}>
                                    <Avatar.Group
                                        maxCount={10}
                                        size={"default"}
                                        maxStyle={{
                                            color: '#6d45c2',
                                            backgroundColor: '#fde3cf',
                                        }}
                                    >
                                        {(this.state.APPROVALDATAS.CCList !== undefined ?
                                            JSON.parse(this.state.APPROVALDATAS.CCList) :
                                            []).map((value, index) => {
                                            for (let i = 0; i < this.state.userList.length; i++) {
                                                if (value === this.state.userList[i].value) {
                                                    return this.state.userList[i].label
                                                }
                                            }
                                        })}
                                    </Avatar.Group>
                                </Descriptions.Item>
                                <Descriptions.Item label="运维人员" span={1}>
                                    <Avatar.Group
                                        maxCount={10}
                                        size={"default"}
                                        maxStyle={{
                                            color: '#6d45c2',
                                            backgroundColor: '#fde3cf',
                                        }}
                                    >
                                        {(this.state.APPROVALDATAS.OperationsList !== undefined ?
                                            JSON.parse(this.state.APPROVALDATAS.OperationsList) :
                                            []).map((value, index) => {
                                            for (let i = 0; i < this.state.userList.length; i++) {
                                                if (value === this.state.userList[i].value) {
                                                    return this.state.userList[i].label
                                                }
                                            }
                                        })}
                                    </Avatar.Group>
                                </Descriptions.Item>
                                <Descriptions.Item label="开发人员" span={1}>
                                    <Avatar.Group
                                        maxCount={10}
                                        size={"default"}
                                        maxStyle={{
                                            color: '#6d45c2',
                                            backgroundColor: '#fde3cf',
                                        }}
                                    >
                                        {(this.state.APPROVALDATAS.DeveloperList !== undefined ?
                                            JSON.parse(this.state.APPROVALDATAS.DeveloperList) :
                                            []).map((value, index) => {
                                            for (let i = 0; i < this.state.userList.length; i++) {
                                                if (value === this.state.userList[i].value) {
                                                    return this.state.userList[i].label
                                                }
                                            }
                                        })}
                                    </Avatar.Group>
                                </Descriptions.Item>
                                <Descriptions.Item label="测试人员" span={1}>
                                    <Avatar.Group
                                        maxCount={10}
                                        size={"default"}
                                        maxStyle={{
                                            color: '#6d45c2',
                                            backgroundColor: '#fde3cf',
                                        }}
                                    >
                                        {(this.state.APPROVALDATAS.TesterList !== undefined ?
                                            JSON.parse(this.state.APPROVALDATAS.TesterList) :
                                            []).map((value, index) => {
                                            for (let i = 0; i < this.state.userList.length; i++) {
                                                if (value === this.state.userList[i].value) {
                                                    return this.state.userList[i].label
                                                }
                                            }
                                        })}
                                    </Avatar.Group>
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <Button type={"primary"} onClick={onFinish}
                                    style={{fontSize: 13, fontWeight: 800, marginTop: 20}}>
                                提交审批
                            </Button>
                        </div>
                    </Card>
                </Spin>

            </Modal>
        </>
    }
}
