import React, {Component} from 'react'
import {Button, Col, Descriptions, Row, Space, Tag} from "antd";
import Avatar from "antd/es/avatar/avatar";
import api from "../../../api/ApiConfig";
import {Loginout} from "../../Users/AuthRouter";
import Card from "antd/es/card/Card";
import {LinkOutlined} from "@ant-design/icons";

export class Step2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userList: props.userList,
            datas: props.datas,
            taskId: props.taskId,
            approval_datas: props.approval_datas,
        }
        console.log("初始化时", this.state.approval_datas)

    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.userList) !== JSON.stringify(prevProps.userList) ||
            JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas) ||
            JSON.stringify(this.props.approval_datas) !== JSON.stringify(prevProps.approval_datas)) {
            this.setState({
                userList: this.props.userList,
                datas: this.props.datas,
                approval_datas: this.props.approval_datas,
            }, function () {
                console.log("状态变更时", this.props.approval_datas)
            })

        }
    }

    componentDidMount() {
        this.getproject_tasks_firmware_status()
    }


    getproject_tasks_firmware_status = async (params = {}) => {
        params['key'] = this.state.taskId
        await api.GetProjectTasksFirmwareStatus(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    datas: r.datas
                })
            }

        })
    }

    render() {
        const getUserLabel = (userId = "", type = 1) => {
            if (userId.length > 0) {
                for (let i = 0; i < this.state.userList.length; i++) {
                    if (this.state.userList[i].value.includes(userId)) {
                        return this.state.userList[i].label
                    }
                }
            } else {
                if (type === 1) {
                    let ApproverList1 = this.state.approval_datas.ApproverList1 !== undefined ? JSON.parse(this.state.approval_datas.ApproverList1) : []
                    console.log("ApproverList1", ApproverList1)
                    return (
                        <Avatar.Group maxCount={10} size={"default"}
                                      maxStyle={{color: '#6d45c2', backgroundColor: '#fde3cf'}}>
                            {ApproverList1.map((value, index) => {
                                for (let i = 0; i < this.state.userList.length; i++) {
                                    if (value === this.state.userList[i].value) {
                                        return this.state.userList[i].label1
                                    }
                                }
                            })}
                        </Avatar.Group>
                    )
                }
                if (type === 2) {
                    let ApproverList2 = this.state.approval_datas.ApproverList2 !== undefined ? JSON.parse(this.state.approval_datas.ApproverList2) : []
                    console.log("ApproverList2", ApproverList2)
                    return (
                        <Avatar.Group maxCount={10} size={"default"}
                                      maxStyle={{color: '#6d45c2', backgroundColor: '#fde3cf'}}>
                            {ApproverList2.map((value, index) => {
                                for (let i = 0; i < this.state.userList.length; i++) {
                                    if (value === this.state.userList[i].value) {
                                        return this.state.userList[i].label1
                                    }
                                }
                            })}
                        </Avatar.Group>
                    )
                }
                if (type === 3) {
                    let ApproverList3 = this.state.approval_datas.ApproverList3 !== undefined ? JSON.parse(this.state.approval_datas.ApproverList3) : []
                    console.log("ApproverList3", ApproverList3)
                    return (
                        <Avatar.Group maxCount={10} size={"default"}
                                      maxStyle={{color: '#6d45c2', backgroundColor: '#fde3cf'}}>
                            {ApproverList3.map((value, index) => {
                                for (let i = 0; i < this.state.userList.length; i++) {
                                    if (value === this.state.userList[i].value) {
                                        return this.state.userList[i].label1
                                    }
                                }
                            })}
                        </Avatar.Group>
                    )
                }
            }
        }
        console.log("视图中", this.state.approval_datas)

        return (
            <div style={{margin: "20px 60px 0px 60px"}}>
                <Card style={{minHeight: "50vh"}}>
                    <Descriptions bordered={true}
                                  title={<Space size={5}>
                                      <svg d="1719652152975" className="icon" viewBox="0 0 1024 1024" version="1.1"
                                           xmlns="http://www.w3.org/2000/svg" p-id="5417" width="25" height="25">
                                          <path
                                              d="M634.825 142.875l10.585 6.928c13.029 8.529 26.576 22.982 38.379 37.507 12.619 15.53 26.083 34.706 38.644 55.731 15.786 26.425 31.466 57.971 41.654 90.436 54.884-9.621 118.466-4.248 190.139 27.292 30.367 13.364 36.232 54.071 10.769 75.436-7.114 5.969-25.61 24.499-40.74 57.28-1.762 3.819-3.706 8.141-5.838 12.88-11.273 25.066-27.777 61.761-50.256 96.935-65.104 119.198-206.186 272.371-457.796 278.116-72.046 1.645-142.937-15.42-199.215-37.094-28.28-10.891-53.596-23.222-74.207-35.555-19.662-11.765-38.18-25.372-49.934-39.791C79.804 760.138 77 749.888 77 740.79V372.895c0-38.27 49.429-63.131 78.603-29.304 36.635 42.478 106.051 109.181 195.939 168.337 27.62 18.177 56.936 35.486 87.611 51.113 8.002-4.991 19.791-13.227 34.919-25.418a658.577 658.577 0 0 0 19.777-16.614C396.053 355.214 286.08 273.578 221.374 225.77c-34.502-25.492-16.83-80.495 26.338-80.735l387.113-2.16z m-78.978 318.264l1.594-1.683c23.669-24.996 66.196-69.91 126.849-99.733-7.263-23.524-19.415-49.239-33.969-73.602-10.65-17.826-21.829-33.663-31.722-45.837-4.345-5.347-8.273-9.779-11.634-13.252l-246.926 1.377c59.468 51.879 129.922 125.497 195.808 232.73zM344.609 606.705a1043.927 1043.927 0 0 1-39.245-24.609c-56.193-36.981-105.114-76.752-144.364-113v254.79c4.534 3.503 10.799 7.848 19.072 12.799 16.024 9.587 36.94 19.881 61.267 29.25 48.933 18.845 108.747 32.835 167.109 31.503 104.728-2.391 186.493-34.617 249.157-78.915a99.11 99.11 0 0 1-4.599-0.941 68.711 68.711 0 0 1-3.377-0.87c-107.319-12.745-207.078-53.408-292.331-102.566l-15.009-6.395 2.32-1.046z m454.724-51.711c17.658-28.163 30.829-57.336 41.877-81.804 2.347-5.2 4.598-10.186 6.777-14.906 6.673-14.458 13.935-27.179 21.239-38.223-58.481-14.239-105.366-4.663-142.795 12.622-49.513 22.864-84.986 60.269-109.71 86.339l-0.676 0.713c-30.147 31.785-58.212 57.667-82.261 77.572 41.693 16.281 85.174 30.371 130.525 36.535 24.614 2.58 49.457-5.92 74.07-22.255 24.223-16.076 45.214-37.81 60.954-56.593z"
                                              p-id="5418"/>
                                      </svg>
                                      <div style={{marginTop: -6}}>审批信息</div>
                                  </Space>}
                                  style={{display: "block", width: "100%", margin: "0 auto"}}
                                  labelStyle={{fontSize: 12, textAlign: "left", width: "18%", fontWeight: 600}}
                                  contentStyle={{
                                      textAlign: "left",
                                      fontSize: 12,
                                      fontWeight: 600,
                                      width: "35%"
                                  }}
                    >
                        <Descriptions.Item label="提测发起人" span={3}>
                            {this.state.userList.map((value, index) => {
                                for (let i = 0; i < this.state.userList.length; i++) {
                                    if (value.value === this.state.datas.UserName + "(" + this.state.datas.UserId + ")") {
                                        return value.label
                                    }
                                }
                            })}
                        </Descriptions.Item>
                        <Descriptions.Item label="提测审批单号" span={3}>
                            <Space style={{
                                fontSize: 12,
                                fontWeight: 600
                            }}>
                                {this.state.datas.ApprovalNumber}
                                <a target={"_blank"}
                                   href={"https://applink.feishu.cn/client/mini_program/open?appId=cli_9cb844403dbb9108&mode=appCenter&path=pc/pages/in-process/index?enableTrusteeship=true&instanceId="
                                   + this.state.datas.InstanceCode + "&source=approval_bot&relaunch=true"}>审批详情 <LinkOutlined/>
                                </a>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="审批实例编号" span={3}>
                            {this.state.datas.InstanceCode}
                        </Descriptions.Item>
                        <Descriptions.Item label="嵌入式负责人(审批人)" span={1.5}>
                            {getUserLabel(this.state.datas.ApprovalUser1, 1)}
                        </Descriptions.Item>
                        <Descriptions.Item label="审批结果" span={1.5}>
                            <Tag color={
                                this.state.datas.ApprovalStatus1 === 2 ? "green-inverse" :
                                    this.state.datas.ApprovalStatus1 === 3 ? "red-inverse" :
                                        this.state.datas.ApprovalStatus1 === 1 ? "gold-inverse" : "red-inverse"
                            }>
                                {this.state.datas.ApprovalStatus1 === 2 ? "审批通过" :
                                    this.state.datas.ApprovalStatus1 === 3 ? "审批驳回" :
                                        this.state.datas.ApprovalStatus1 === 1 ? "等待审批" : "未知状态"}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="产品/项目/组长(审批人)" span={1.5}>
                            {getUserLabel(this.state.datas.ApprovalUser2, 2)}
                        </Descriptions.Item>
                        <Descriptions.Item label="审批结果" span={1.5}>
                            <Tag color={
                                this.state.datas.ApprovalStatus2 === 2 ? "green-inverse" :
                                    this.state.datas.ApprovalStatus2 === 3 ? "red-inverse" :
                                        this.state.datas.ApprovalStatus2 === 1 ? "gold-inverse" : "red-inverse"
                            }>

                                {this.state.datas.ApprovalStatus2 === 2 ? "审批通过" :
                                    this.state.datas.ApprovalStatus2 === 3 ? "审批驳回" :
                                        this.state.datas.ApprovalStatus2 === 1 ? "等待审批" : "未知状态"}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="测试负责人(审批人)" span={1.5}>
                            {getUserLabel(this.state.datas.ApprovalUser3, 3)}
                        </Descriptions.Item>
                        <Descriptions.Item label="审批结果" span={1.5}>
                            <Tag color={
                                this.state.datas.ApprovalStatus3 === 2 ? "green-inverse" :
                                    this.state.datas.ApprovalStatus3 === 3 ? "red-inverse" :
                                        this.state.datas.ApprovalStatus3 === 1 ? "gold-inverse" : "red-inverse"
                            }>

                                {this.state.datas.ApprovalStatus3 === 2 ? "审批通过" :
                                    this.state.datas.ApprovalStatus3 === 3 ? "审批驳回" :
                                        this.state.datas.ApprovalStatus3 === 1 ? "等待审批" : "未知状态"}
                            </Tag>
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </div>
        );
    }
}
