import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import NotAuthorized from '../Exceptions/NotAuthorized'

const DATA = {
    UserId: localStorage.getItem("UserId"),
    token: localStorage.getItem('token'),
    username: localStorage.getItem('username'),
    avatar_url: localStorage.getItem('avatar_url'),

}
const islogin = () => {
    return (DATA.token !== null && DATA.token !== undefined)
        && (DATA.UserId !== null && DATA.UserId !== undefined)
        && (DATA.username !== null && DATA.username !== undefined)
        && (DATA.avatar_url !== null && DATA.avatar_url !== undefined)
}

const AuthRouter = (props) => {
    const {component: Component, needLogin} = props;
    return (<Route  {...props} component={Component}/>);
}


const Login = (UserId, token, username, avatar_url) => {
    DATA.UserId = UserId;
    DATA.token = token;
    DATA.username = username;
    DATA.avatar_url = avatar_url
    localStorage.setItem("UserId", UserId)
    localStorage.setItem("token", token)
    localStorage.setItem("username", username)
    localStorage.setItem("avatar_url", avatar_url)
}

const Loginout = () => {
    DATA.UserId = null
    DATA.token = null
    DATA.username = null;
    localStorage.clear("UserId")
    localStorage.clear("token")
    localStorage.clear("username")
    localStorage.clear("avatar_url")
}

const getUser = () => {
    return DATA || {};
}

export {getUser, AuthRouter, Login, islogin, Loginout}