import React, {Component} from 'react';
import {Button, Row, Col, Form, Card, Input, Select, message, Space} from "antd";
import Modal from "antd/es/modal/Modal";
import {getUser, Loginout} from "../Users/AuthRouter";
import {AppstoreAddOutlined, EditOutlined, SisternodeOutlined} from "@ant-design/icons";
import api from "../../api/ApiConfig";

const {TextArea} = Input;

export class EditVoiceLibrary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas,
            fun: props.fun
        }
        this.form = React.createRef();
    }

    userinfo = getUser()

    post_voice_library = async (values) => {
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        await api.PostVoiceLibrary({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({}, function () {
                    message.success("语音库编辑成功")
                    this.state.fun()
                    this.setState({
                        isModalOpen: false
                    })
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const showModal = () => {
            this.setState({
                isModalOpen: true
            })
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = (values) => {
            values['key'] = this.state.datas.key
            this.post_voice_library(values)
        }
        return <>
            <Button style={{width: 60, fontWeight: 700, fontSize: 12}}
                    size={"small"}
                    onClick={() => {
                        showModal()
                    }}
                    disabled={false} type={"link"} icon={<EditOutlined/>}>
                编辑
            </Button>
            <Modal title="编辑语音库"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{marginTop: 100, minWidth: 700}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 450,
                                marginTop: 60,
                                marginLeft: 50
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="ChatId"
                                        label="飞书群聊ID"
                                        initialValue={this.state.datas.ChatId}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入飞书群聊ID",
                                            }
                                        ]}
                                    >
                                        <Input showCount maxLength={100} placeholder={"飞书群聊ID(需开发人员配置，请勿修改！)"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="VoiceKeyWords"
                                        label="语音关键词"
                                        initialValue={this.state.datas.VoiceKeyWords}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入语音关键词",
                                            }
                                        ]}
                                    >
                                        <Input  disabled={true} showCount maxLength={30} placeholder={"请输入语音关键词(如:小牛、小牛)"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="VoiceLibraryNotes"
                                        label="语音库说明"
                                        initialValue={this.state.datas.VoiceLibraryNotes}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入语音库说明",
                                            }
                                        ]}
                                    >
                                        <Input.TextArea showCount rows={3} maxLength={300}
                                                        placeholder={"请输入语音库说明(如：语音规则、语言类型等..)"}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item style={{marginTop: 30, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit">
                                    确认修改
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}