import React, {Component} from 'react';
import {Button, Row, Col, Form, Card, Input, Select, message, Space} from "antd";
import Modal from "antd/es/modal/Modal";
import {getUser, Loginout} from "../../Users/AuthRouter";
import api from "../../../api/ApiConfig";
import {EditOutlined, StopOutlined} from "@ant-design/icons";

const {TextArea} = Input;

export class EditTP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas,
            isModalOpen: false,
            borderColor: '#b7b7b7',
            borderWidth: 1,
            fun1: props.fun1
        }
        this.form = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
                fun1: this.props.fun1,
            })
        }
    }

    userinfo = getUser()
    ProjectImageUrl = {
        "interface": "https://img1.baidu.com/it/u=183557575,1648010748&fm=253&fmt=auto&app=138&f=JPEG?w=499&h=281",
        "mg": "https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/%E6%B2%90%E5%85%89log.png",
        "ej": "https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/favicon.ico",
        "zj": "https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/%E5%85%AC%E7%89%9B%E6%99%BA%E5%AE%B6log.png",
        "web": "https://console.iotbull.com/static/login_bg.337c3c03.png",
        "gj": "https://saas-product-image.oss-cn-shanghai.aliyuncs.com/app2_0/%E4%BA%A7%E5%93%81%E5%9B%BE%E7%89%87/177.png",
        "mos": "https://gn-ota.oss-cn-shanghai.aliyuncs.com/zhangfan/%E8%B5%84%E6%BA%90/OXScrBDqeM.png"
    }
    posttestproject = async (values) => {
        values['userid'] = this.userinfo.UserId
        values['username'] = this.userinfo.username
        values['TestProjectId'] = this.state.datas.pk
        await api.PostTestProject({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("配置更新成功")
                this.setState({
                    isModalOpen: false,
                }, function () {
                    this.state.fun1()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const {datas} = this.state
        const showModal = () => {
            this.setState({
                isModalOpen: true
            })
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = (values) => {
            console.log("修改测试项目", values)
            this.posttestproject(values)
        }
        return <>
            <Button size={"small"} onClick={showModal}
                    type={'link'}>
                <Space size={5} style={{fontWeight: 800, fontSize: 13}}>
                    <EditOutlined/>
                    <div>编辑项目</div>
                </Space>
            </Button>
            <Modal title="修改测试项目信息"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{marginTop: 100, minWidth: 700}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 450,
                                marginTop: 30,
                                marginLeft: 50
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="ProjectName"
                                        label="项目名称"
                                        initialValue={datas.fields.ProjectName}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入项目名称",
                                            }
                                        ]}
                                    >
                                        <Input showCount maxLength={20} placeholder={"请输入项目名称"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="ProjectType"
                                        label="项目类型"
                                        initialValue={datas.fields.ProjectType}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入项目名称",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{width: 140, textAlign: "center"}}
                                            showSearch
                                            placeholder="请选择项目类型"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            onChange={(value) => {
                                                this.form.current.setFieldsValue({ProjectImageUrl: this.ProjectImageUrl[value]})
                                            }}
                                            options={[
                                                {
                                                    value: 'mg',
                                                    label: 'App-沐光',
                                                },
                                                {
                                                    value: 'ej',
                                                    label: 'App-调试',
                                                },
                                                {
                                                    value: "zj",
                                                    label: 'App-智家',
                                                },
                                                {
                                                    value: 'gj',
                                                    label: 'Mos-固件',
                                                },
                                                {
                                                    value: "mos",
                                                    label: 'App-Mos',
                                                },
                                                {
                                                    value: 'web',
                                                    label: 'Mos-Web',
                                                },
                                                {
                                                    value: 'interface',
                                                    label: 'Mos-接口',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="ProjectImageUrl"
                                        label="项目图标"
                                        initialValue={datas.fields.ProjectImageUrl}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入图片Url",
                                            }
                                        ]}
                                    >
                                        <Input placeholder="请输入图片Url"/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="ProjectNote"
                                        label="项目描述"
                                        initialValue={datas.fields.ProjectNote}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入项目描述",
                                            }
                                        ]}
                                    >
                                        <TextArea showCount rows={3} placeholder="请输入项目描述" maxLength={30}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item style={{marginTop: 30, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit">
                                    确认修改
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}