import React, {Component} from 'react';
import '@ant-design/pro-components/dist/components.css';
import {PageHeader} from '@ant-design/pro-layout';
import {
    Affix,
    Button,
    Descriptions,
    Divider,
    Input,
    Radio,
    Space,
    Table,
    Tag,
    Select,
    message,
    Popconfirm,
    Drawer, Spin
} from 'antd';

import {NavLink} from "react-router-dom";
import {
    ApartmentOutlined,
    DeleteOutlined,
    QuestionCircleOutlined, TeamOutlined, UserOutlined,
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import Card from "antd/es/card/Card";
import Avatar from "antd/es/avatar/avatar";
import AddFiveTuples from "./AddFiveTuples";
import api from "../../api/ApiConfig";
import {getUser, Loginout} from "../Users/AuthRouter";


export class FiveTuplesHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            dataSource1: [],
            loading: false,
            qs: null,
            env: "all",
            spinning: false,
            task_info: null,
            userid: null
        }
    }

    componentDidMount() {
        this.gettupleapply().then(r => {
        })
    }

    columns_style = {fontSize: 11, color: '#707272'}

    deltupleapply = async (TupleApplyId) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['TupleApplyId'] = TupleApplyId
        await api.DelTupleApply({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("五元组删除成功")
                this.gettupleapply()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    gettupleapply = async (params = {}) => {
        this.setState({
            loading: true
        })
        let dataSourcelist = []
        params['env'] = this.state.env
        if (this.state.userid != null) {
            params['userid'] = this.state.userid
        }
        params['qs'] = this.state.qs ? this.state.qs : "all"
        await api.GetTupleApply(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (r.length !== 0) {
                    for (let i = 0; i < r['datas'].length; i++) {
                        dataSourcelist.push(
                            r['datas'][i].fields
                        )
                        dataSourcelist[i]['key'] = r['datas'][i].pk
                    }
                }
                this.setState({
                    dataSource: dataSourcelist,
                    loading: false
                })

            }
        })
    }

    getfivetuple = async (params = {}) => {
        let dataSourcelist = []
        this.setState({
            spinning: true
        })
        await api.GetFiveTuple(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (r.length !== 0) {
                    for (let i = 0; i < r['datas'].length; i++) {
                        dataSourcelist.push(
                            r['datas'][i].fields
                        )
                        dataSourcelist[i]['key'] = r['datas'][i].pk
                    }
                }
                this.setState({
                    dataSource1: dataSourcelist,
                    spinning: false
                })

            }
        })
    }

    gettasks = async (params = {}) => {
        await api.GetTasks(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    task_info: r['datas'][0].fields
                })
            }
        })
    }

    render() {
        const columns = [
            {
                title: 'No.',
                key: '1',
                fixed: 'left',
                width: 60,
                align: 'center',
                render: (record, text, index) => {
                    return (index + 1)
                }
            },
            {
                title: '设备名称',
                width: 150,
                dataIndex: 'ProductName',
                // align: 'center',
                key: 'ProductName',
            },
            {
                title: 'PID',
                width: 40,
                align: 'center',
                dataIndex: 'productId',
                key: 'productId',
                render: (text, re) => (
                    <Tag color={"#342367"}>{re.productId}</Tag>
                )
            },
            {
                title: '环境',
                width: 50,
                align: 'center',
                render: (text, re) => (
                    re.env === "Developer" ? <Tag color={"#d2a616"}>开发环境 </Tag> :
                        re.env === "Test" ? <Tag color={"#2db7f5"}>测试环境 </Tag> :
                            re.env === "Online" ? <Tag color={"#9b1313"}>生产环境 </Tag> : null
                ),
                sorter: (a, b) => a.env !== undefined ? (a.env.length - b.env.length) : null
            },
            {
                title: '元组数量',
                width: 50,
                dataIndex: 'fiveNumber',
                align: 'center',
                key: 'fiveNumber',
            },
            {
                title: '批次编号',
                width: 80,
                dataIndex: 'applyId',
                align: 'center',
                key: 'applyId',
            },
            {
                title: '添加、更新人',
                width: 100,
                render: (text, re) => (
                    <Space>
                        <Avatar
                            size={30}
                            style={{
                                backgroundColor: "#141414",
                                verticalAlign: 'middle',
                            }}
                        >U</Avatar>
                        <div style={this.columns_style}>{re.username}({re.userid})</div>
                    </Space>
                )
            },
            {
                title: '描述信息',
                width: 120,
                dataIndex: 'applyNote',
                key: 'applyNote',
            },
            {
                title: '创建时间',
                dataIndex: 'CreatedTime',
                key: 'CreatedTime',
                width: 120,
                align: 'center',
            },
            {
                title: '操作',
                dataIndex: 'operate',
                key: 'operate',
                fixed: 'right',
                width: 120,
                align: 'center',
                render: (text, re) => (
                    <Space size={[1]}>
                        <Button size={'small'} type={'link'} icon={<ApartmentOutlined/>}
                                style={{fontSize: 13}}
                                onClick={() => {
                                    this.setState({
                                        isDrawerOpen: true
                                    })
                                    this.getfivetuple({"TupleApplyId": re.key})
                                }}>
                            元组列表
                        </Button>
                        <Divider type="vertical"/>
                        <Popconfirm
                            title="删除批次"
                            description="你确定要删除该批次中的所有五元组吗?"
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            } onConfirm={() => {
                            this.deltupleapply(re.key)
                        }}
                        >
                            <Button
                                type="link" danger
                                icon={<DeleteOutlined/>}
                                style={{fontSize: 13}}>
                                删除批次
                            </Button>
                        </Popconfirm>
                    </Space>
                )
            },
        ];
        const columns1 = [
            {
                title: 'No.',
                key: '1',
                fixed: 'left',
                width: 25,
                align: 'center',
                render: (record, text, index) => {
                    return (index + 1)
                }
            },
            {
                title: 'DeviceName',
                width: 40,
                dataIndex: 'DeviceName',
                align: 'center',
                key: 'DeviceName',
            },
            {
                title: 'PID',
                width: 30,
                dataIndex: 'PID',
                align: 'center',
                key: 'PID',
            },
            {
                title: 'ProductKey',
                width: 40,
                dataIndex: 'ProductKey',
                align: 'center',
                key: 'ProductKey',
            },
            {
                title: '占用状态',
                width: 30,
                align: 'center',
                render: (text, re) => {
                    return (
                        <>
                            <div style={{
                                textAlign: 'center',
                            }}>
                                <Space>
                                    <div style={{
                                        position: "relative",
                                        display: "inline - block",
                                        width: "6px",
                                        height: "6px",
                                        verticalAlign: "middle",
                                        borderRadius: "50%",
                                        backgroundColor: re.FiveTupleStatus === "0" ? "#51be1e" : re.FiveTupleStatus === "1" ? "#f64d4e" : "#f6d853",
                                    }}/>
                                    {re.FiveTupleStatus === "0" ? "空闲" : re.FiveTupleStatus === "1" ? "占用" : "未知"}
                                </Space>
                            </div>
                        </>
                    )
                }
            },
            {
                title: 'ProductSecret',
                width: 50,
                dataIndex: 'ProductSecret',
                align: 'center',
                key: 'ProductSecret',
            },
            {
                title: 'DeviceSecret',
                width: 80,
                dataIndex: 'DeviceSecret',
                align: 'center',
                key: 'DeviceSecret',
            },

            {
                title: '创建时间',
                dataIndex: 'CreatedTime',
                key: 'CreatedTime',
                width: 60,
                align: 'center',
            },
            {
                title: '任务占用',
                width: 40,
                dataIndex: 'OccupiedByTaskID',
                align: 'center',
                key: 'OccupiedByTaskID',
                render: (record, text, index) => {
                    return (<Button disabled={text.OccupiedByTaskID === "-1"} type={"link"} onClick={() => {
                        this.gettasks({task_id: text.OccupiedByTaskID}).then(r => {
                            if (this.state.task_info !== null) {
                                message.success("此五元组被【" + this.state.task_info.username + "】创建的任务【" + this.state.task_info.taskName + "】占用")
                            } else {
                                message.warning("暂时无法查询五元组占用信息,请稍后再试！")
                            }
                        })
                    }
                    }>查看占用</Button>)
                }
            },

        ];

        return (
            <>
                <Drawer
                    title="五元组列表"
                    placement={'bottom'}
                    height={"60vh"}
                    onClose={() => {
                        this.setState({
                            isDrawerOpen: false
                        })
                    }}
                    open={this.state.isDrawerOpen}>
                    <div>
                        <Spin tip="五元组拉取中,请稍等片刻....." spinning={this.state.spinning}>
                            <Card bordered={true}>
                                <Table
                                    size={"small"}
                                    pagination={
                                        {
                                            showTotal: () => `共 ${this.state.dataSource1 ? this.state.dataSource1.length : 0} 条 `,
                                            defaultPageSize: 1000
                                        }
                                    }
                                    scroll={{
                                        y: '30vh',
                                    }}
                                    columns={columns1}
                                    dataSource={this.state.dataSource1}
                                    bordered/>
                            </Card>
                        </Spin>
                    </div>
                </Drawer>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="五元组管理"
                            subTitle="五元组批次管理"
                            extra={
                                <Space size={[0]}>
                                    <Button icon={<UserOutlined/>} type={'link'} style={{width: 100, color: '#707272'}}
                                            onClick={() => {
                                                const userinfo = getUser()
                                                this.setState({
                                                    userid: userinfo.UserId
                                                }, function () {
                                                    this.gettupleapply()
                                                })
                                            }}
                                    >
                                        我的五元组
                                    </Button>
                                    <Divider type="vertical"/>
                                    <Button icon={<TeamOutlined/>} type={'link'} style={{width: 100, color: '#707272'}}
                                            onClick={() => {
                                                this.setState({
                                                    userid: null
                                                }, function () {
                                                    this.gettupleapply()
                                                })
                                            }}
                                    >
                                        全部五元组
                                    </Button>
                                    <Divider type="vertical"/>
                                    <AddFiveTuples/>
                                    <Divider type="vertical"/>
                                </Space>
                            }
                        >
                            <Content/>
                        </PageHeader>
                    </div>
                </Affix>
                <div style={{marginTop: 3, background: '#ffffff', minHeight: 743}}>
                    <Card bordered={true}>
                        <Table
                            title={() =>
                                <Card bordered={true}>
                                    <Space>
                                        <Select
                                            defaultValue={this.state.env}
                                            value={this.state.env}
                                            onChange={(value) => {
                                                this.setState({
                                                    env: value
                                                })
                                            }}
                                            style={{
                                                width: 120,
                                            }}
                                            options={[
                                                {
                                                    value: 'all',
                                                    label: '所有环境',
                                                },
                                                {
                                                    value: 'Developer',
                                                    label: '开发环境',
                                                },
                                                {
                                                    value: 'Test',
                                                    label: '测试环境',
                                                },
                                                {
                                                    value: 'Online',
                                                    label: '生产环境',
                                                },
                                            ]}
                                        />
                                        <Input style={{width: 200}} placeholder={"请输入PID（模糊查询）"}
                                               value={this.state.qs === "all" ? null : this.state.qs}
                                               onChange={(e) => {
                                                   this.setState({
                                                       qs: e.target.value
                                                   })
                                               }}/>
                                        <Button type="primary" ghost onClick={() => {
                                            this.gettupleapply()
                                        }}>查询</Button>
                                        <Button type="primary" ghost onClick={() => {
                                            this.setState({qs: "all"}, function () {
                                                this.gettupleapply()
                                            })
                                        }}>重置</Button>
                                    </Space>
                                </Card>
                            }
                            pagination={
                                {
                                    showTotal: () => `共 ${this.state.dataSource ? this.state.dataSource.length : 0} 条 `,
                                    defaultPageSize: 100
                                }

                            }
                            loading={this.state.loading}
                            columns={columns}
                            dataSource={this.state.dataSource}
                            scroll={{
                                x: 1800,
                            }}
                            bordered/>
                    </Card>
                </div>
            </>
        )
    }

}