import React, {Component} from "react";
import {
    Affix,
    Button,
    Card,
    Col,
    Row,
    Divider,
    Select,
    DatePicker,
    Space,
    Avatar,
    Pagination, Spin, Input, message, Popconfirm, Tag, Watermark, Statistic,
} from "antd";
import {PageHeader} from "@ant-design/pro-layout";
import {
    AppstoreAddOutlined,
    AreaChartOutlined, BarChartOutlined, ClearOutlined,
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    LineChartOutlined,
    OrderedListOutlined, PieChartOutlined,
    ProjectOutlined,
    RedoOutlined, SearchOutlined,
    SettingOutlined, StockOutlined, UserOutlined,
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import {getUser, Loginout} from "../../Users/AuthRouter";
import {CreateTP} from "./CreateTP";
import Meta from "antd/es/card/Meta";
import dayjs from "dayjs";
import api from "../../../api/ApiConfig";
import {EditTP} from "./EditTP";
import {NavLink} from "react-router-dom";
import Text from "antd/es/typography/Text";

const {RangePicker} = DatePicker;

export class TestProject extends Component {
    userinfo = getUser()

    constructor(props) {
        super(props)
        this.state = {
            spinning: false,
            page: 1,
            pageSize: 11,
            TestProjectDatas: [],
            Total: 0,
            pname: null,
            // UserValue: `${this.userinfo.username}(${this.userinfo.UserId})`,
            UserValue: null,
            qText: null,
            UserList: [],
            ptime: [dayjs().subtract(24, 'month'), dayjs()],
            TestDatasStatistics: {}
        }
    }

    componentDidMount() {
        this.gettestproject()
        this.getuserlist_atmp()
        this.testdatasstatistics()
    }


    testdatasstatistics = async () => {
        this.setState({
            spinning: true
        })
        await api.TestDatasStatistics({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    TestDatasStatistics: r['datas'],
                })
            }
            this.setState({
                spinning: false
            })
        })
    }

    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <Space size={5}>
                            <Avatar key={key} size={20} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 11, fontWeight: 600}}>{value.name}({value.user_id})</Text>
                        </Space>
                    })
                )
                this.setState({
                    UserList: userList
                })
            }
        })
    }

    gettestproject = async (params = {}) => {
        this.setState({
            spinning: true
        })
        params['userid'] = this.userinfo.UserId
        if (this.state.pname !== null) {
            params['pname'] = this.state.pname
        }
        if (this.state.UserValue !== null) {
            params['puserid'] = this.state.UserValue
        }
        if (this.state.qText !== null) {
            params['qText'] = this.state.qText
        }
        params['page'] = this.state.page
        params['pageSize'] = this.state.pageSize
        params['stime'] = this.state.ptime[0].format("YYYY-MM-DD 00:00:00")
        params['etime'] = this.state.ptime[1].format("YYYY-MM-DD 23:59:59")
        await api.GetTestProject(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    TestProjectDatas: r.datas.length > 0 ? r.datas : [],
                    Total: r.Total
                })
            }
            this.setState({
                spinning: false
            })
        })
    }

    deltestproject = async (TestProjectId) => {
        let values = {}
        values['userid'] = this.userinfo.UserId
        values['TestProjectId'] = TestProjectId
        await api.DelTestProject({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("项目删除成功")
                this.setState({
                    isModalOpen: false,
                }, function () {
                    this.gettestproject()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        return <div style={{background: 'rgb(240, 242, 245)'}}>
            <Affix offsetTop={60}>
                <div style={{
                    background: '#ffffff',
                    textAlign: 'center',
                }}>
                    <PageHeader
                        className="site-page-header-responsive"
                        title="测试项目管理"
                        subTitle={"测试项目"}
                        onBack={() => window.history.back()}
                        extra={<>
                            <Divider type="vertical"/>
                            <Button type={'link'} style={{width: 100, color: '#707272'}}
                                    icon={<RedoOutlined/>}
                                    onClick={() => {
                                        this.gettestproject()
                                        this.getuserlist_atmp()
                                        this.testdatasstatistics()
                                    }}>
                                刷新数据
                            </Button>
                            <Divider type="vertical"/>
                        </>
                        }
                    >
                        <Content/>
                    </PageHeader>
                </div>
            </Affix>
            <div style={{marginTop: 10, minHeight: 763}}>
                <Watermark content="公牛Bull">
                    <Spin spinning={this.state.spinning} tip="数据加载中...">
                        <div>
                            <div style={{margin: 20}}>
                                <Row gutter={[10, 10]}>
                                    <Col span={4}>
                                        <Card style={{height: 120}}>
                                            <Statistic
                                                title="测试项目总数"
                                                value={Object.keys(this.state.TestDatasStatistics).length > 0 ? this.state.TestDatasStatistics.tpCount : 0}
                                                valueStyle={{
                                                    color: '#63296b',
                                                    marginLeft: 5,
                                                }}
                                                prefix={<div style={{marginLeft: 5}}><LineChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card style={{height: 120}}>
                                            <Statistic
                                                title="测试任务总数"
                                                value={Object.keys(this.state.TestDatasStatistics).length > 0 ? this.state.TestDatasStatistics.ttCount : 0}
                                                valueStyle={{
                                                    color: '#13a4a9',
                                                    marginLeft: 5,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><PieChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card style={{height: 120}}>
                                            <Statistic
                                                title="构建任务数"
                                                value={Object.keys(this.state.TestDatasStatistics).length > 0 ? this.state.TestDatasStatistics.ttsCount : 0}
                                                valueStyle={{
                                                    color: '#709f17',
                                                    marginLeft: 5,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><StockOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card style={{height: 120}}>
                                            <Statistic
                                                title="配置用例数"
                                                value={Object.keys(this.state.TestDatasStatistics).length > 0 ? this.state.TestDatasStatistics.ttclCount : 0}
                                                valueStyle={{
                                                    color: '#1c4fce',
                                                    marginLeft: 5,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><AreaChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card style={{height: 120}}>
                                            <Statistic
                                                title="项目参与人"
                                                value={Object.keys(this.state.TestDatasStatistics).length > 0 ? this.state.TestDatasStatistics.UsersCount : 0}
                                                valueStyle={{
                                                    color: '#036247',
                                                    marginLeft: 5,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><UserOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card style={{height: 120}}>
                                            <Statistic
                                                title="提测任务数"
                                                value={Object.keys(this.state.TestDatasStatistics).length > 0 ? this.state.TestDatasStatistics.tttCount : 0}
                                                valueStyle={{
                                                    color: '#0c0c0c',
                                                    marginLeft: 5,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><BarChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{background: 'rgb(259,259,259)', marginTop: 30, minHeight: "100vh"}}>
                                <div style={{
                                    position: "absolute",
                                    marginTop: "-14px",
                                    marginLeft: "20px",
                                    background: "#ffffff",
                                    borderRadius: "6px 6px 0 0",
                                    fontSize: 14,
                                    textAlign: 'center',
                                    padding: "3px 10px  0px 10px",
                                }}>
                                    <Space size={2}>
                                        <AreaChartOutlined/>
                                        <b>
                                            数据概览
                                        </b>
                                    </Space>
                                </div>
                                <div style={{height: 20}}/>
                                <div>
                                    <Card style={{marginLeft: 30, marginRight: 30, marginTop: 30, borderRadius: 20}}>
                                        <div style={{textAlign: "center"}}>
                                            <Card style={{borderRadius: 20, marginTop: 30}}>
                                                <Space size={20}>
                                                    <Select
                                                        style={{width: 140, textAlign: "center"}}
                                                        showSearch
                                                        placeholder="项目类型查询"
                                                        optionFilterProp="children"
                                                        onChange={(value) => {
                                                            this.setState({
                                                                pname: value
                                                            })
                                                        }}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                        // value={this.state.CaseValue}
                                                        value={this.state.pname}
                                                        options={[
                                                            {
                                                                value: 'mg',
                                                                label: 'App-沐光',
                                                            },
                                                            {
                                                                value: 'ej',
                                                                label: 'App-调试',
                                                            },
                                                            {
                                                                value: "zj",
                                                                label: 'App-智家',
                                                            },
                                                            {
                                                                value: 'gj',
                                                                label: 'Mos-固件',
                                                            },
                                                            {
                                                                value: "mos",
                                                                label: 'App-Mos',
                                                            },
                                                            {
                                                                value: 'web',
                                                                label: 'Mos-Web',
                                                            },
                                                            {
                                                                value: 'interface',
                                                                label: 'Mos-接口',
                                                            },
                                                        ]}
                                                    />
                                                    <Select
                                                        style={{width: 190}}
                                                        showSearch
                                                        placeholder="创建人查询"
                                                        value={this.state.UserValue}
                                                        options={this.state.UserList}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                UserValue: value
                                                            }, function () {
                                                            })
                                                        }}
                                                    />
                                                    <Input width={200} value={this.state.qText} onChange={(data) => {
                                                        this.setState({
                                                            qText: data.target.value,
                                                        })
                                                    }} placeholder={"项目名称、描述模糊查询"}/>
                                                    <RangePicker style={{textAlign: "center"}} allowClear={false}
                                                                 onChange={(date, dateString) => {
                                                                     this.setState({
                                                                         ptime: date,
                                                                     })
                                                                 }} value={this.state.ptime}/>
                                                    <Button type={"primary"} onClick={() => {
                                                        this.gettestproject()
                                                    }}>
                                                        <Space size={5} style={{fontSize: 13, fontWeight: 800}}>
                                                            <SearchOutlined/> 查询
                                                        </Space>
                                                    </Button>
                                                    <Button type={"primary"} onClick={() => {
                                                        this.setState({
                                                            pname: null,
                                                            UserValue: null,
                                                            qText: null,
                                                            ptime: [dayjs().subtract(24, 'month'), dayjs()],
                                                        }, function () {
                                                            this.gettestproject()
                                                        })
                                                    }}>
                                                        <Space size={5} style={{fontSize: 13, fontWeight: 800}}>
                                                            <ClearOutlined/> 重置
                                                        </Space>
                                                    </Button>
                                                </Space>
                                            </Card>
                                            {/*<Divider orientation={"center"}><ProjectOutlined/> 项目列表</Divider>*/}
                                        </div>
                                        <Row gutter={[10, 10]} wrap={true}
                                             style={{marginLeft: 10, marginRight: 10, marginTop: 30}}>
                                            <Col span={24}/>
                                            <Col span={6}>
                                                <CreateTP fun1={this.gettestproject}/>
                                            </Col>
                                            {this.state.TestProjectDatas.map(item => (
                                                <Col span={6} key={item.pk}>
                                                    <Card
                                                        key={item.pk}
                                                        hoverable={true}
                                                        bordered={true}
                                                        style={{
                                                            minHeight: 246,
                                                            maxHeight: 246,
                                                            borderColor: "#8caee7",
                                                            borderRadius: 10
                                                        }}
                                                        title={
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        fontSize: 12,
                                                                        fontWeight: 800
                                                                    }}>{item.fields.ProjectName}</div>
                                                            </div>
                                                        }
                                                        extra={<Space size={5} style={{
                                                            fontSize: 11,
                                                            fontWeight: 600,
                                                            marginRight: -10
                                                        }}>
                                                            {/*<Avatar style={{*/}
                                                            {/*    backgroundColor: '#0c0c0c',*/}
                                                            {/*    color: '#ffffff'*/}
                                                            {/*}} size={25}>*/}
                                                            {/*    U*/}
                                                            {/*</Avatar>*/}
                                                            {/*<div*/}
                                                            {/*    style={{color: "#042121"}}>{item.fields.UserName}({item.fields.UserId})*/}
                                                            {/*</div>*/}
                                                            {([`${item.fields.UserName}(${item.fields.UserId})`]).map((value, index) => {
                                                                for (let i = 0; i < this.state.UserList.length; i++) {
                                                                    if (value === this.state.UserList[i].value) {
                                                                        return this.state.UserList[i].label
                                                                    }
                                                                }
                                                            })}
                                                        </Space>}
                                                        actions={[
                                                            <NavLink
                                                                to={'/TaskList/' + item.pk + "/" + item.fields.ProjectName}>
                                                                <Button size={"small"} type={'link'}>
                                                                    <Space size={5}
                                                                           style={{fontWeight: 800, fontSize: 13}}>
                                                                        <OrderedListOutlined/>
                                                                        <div>任务列表</div>
                                                                    </Space>
                                                                </Button>
                                                            </NavLink>,
                                                            <EditTP datas={item} fun1={this.gettestproject}/>,
                                                            <Popconfirm
                                                                title="删除测试项目"
                                                                description={<div>确定要删除这个测试项目吗?
                                                                    <div style={{
                                                                        fontSize: 12,
                                                                        color: "red"
                                                                    }}>(该项目下的测试任务数据也将被同时删除)
                                                                    </div>
                                                                </div>}
                                                                onConfirm={() => {
                                                                    this.deltestproject(item.pk)
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button size={"small"} type={'link'} danger>
                                                                    <Space size={5}
                                                                           style={{fontWeight: 800, fontSize: 13}}>
                                                                        <DeleteOutlined/>
                                                                        <div>删除项目</div>
                                                                    </Space>
                                                                </Button>
                                                            </Popconfirm>
                                                        ]}
                                                    >
                                                        <Meta style={{
                                                            minHeight: 100,
                                                            maxHeight: 100,
                                                            overflow: 'auto',
                                                        }}
                                                              avatar={
                                                                  <Avatar size={90}
                                                                          src={item.fields.ProjectImageUrl}
                                                                          shape="square"/>
                                                              }
                                                              title={<>
                                                                  {/*<div style={{fontSize: 13}}>项目信息</div>*/}
                                                              </>}
                                                              description={
                                                                  <>
                                                                      <div style={{fontSize: 12, marginTop: -5}}>
                                                                          <div style={{
                                                                              marginBottom: 4,
                                                                              marginTop: 0,
                                                                              fontWeight: 700,
                                                                          }}>
                                                                              类型: &nbsp; {
                                                                              item.fields.ProjectType === "interface" ?
                                                                                  <Tag
                                                                                      color="#002c8c"> Mos-接口 </Tag> :
                                                                                  item.fields.ProjectType === "mg" ?
                                                                                      <Tag
                                                                                          color="#d48806">App-沐光</Tag> :
                                                                                      item.fields.ProjectType === "zj" ?
                                                                                          <Tag
                                                                                              color="#04bf8b">App-智家</Tag> :
                                                                                          item.fields.ProjectType === "mos" ?
                                                                                              <Tag
                                                                                                  color="#ff9966">App-MosHome</Tag> :
                                                                                              item.fields.ProjectType === "ej" ?
                                                                                                  <Tag
                                                                                                      color="#871400">App-调试</Tag> :
                                                                                                  item.fields.ProjectType === "web" ?
                                                                                                      <Tag
                                                                                                          color="#262626">Mos-Web</Tag> :
                                                                                                      item.fields.ProjectType === "gj" ?
                                                                                                          <Tag
                                                                                                              color="#135200">Mos-固件</Tag> :
                                                                                                          <Tag
                                                                                                              color="#820014">未知</Tag>
                                                                          }
                                                                          </div>
                                                                          <div>
                                                                              时间：{dayjs(item.fields.DatasDate).format("YYYY-MM-DD HH:mm:ss")}
                                                                          </div>
                                                                          <div style={{marginTop: 4}}>
                                                                              描述: &nbsp;{item.fields.ProjectNote}
                                                                          </div>
                                                                      </div>
                                                                  </>
                                                              }
                                                        />
                                                    </Card>
                                                </Col>
                                            ))
                                            }
                                            <Col span={24}>
                                                <Pagination
                                                    style={{marginTop: 50, height: 100, textAlign: "center"}}
                                                    pageSizeOptions={[11, 23, 49]}
                                                    position={["bottomCenter"]}
                                                    size={"default"}
                                                    showSizeChanger={true}
                                                    showTotal={(total, range) => {
                                                        return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                                                    }}
                                                    total={this.state.Total}
                                                    defaultPageSize={this.state.pageSize}
                                                    defaultCurrent={this.state.page}
                                                    current={this.state.page}
                                                    pageSize={this.state.pageSize}
                                                    onShowSizeChange={(current, size) => {
                                                        this.setState({
                                                            page: current,
                                                            pageSize: size
                                                        }, function () {
                                                            this.gettestproject()
                                                        })
                                                    }}
                                                    onChange={(page, pageSize) => {
                                                        this.setState({
                                                            page: page,
                                                            pageSize: pageSize
                                                        }, function () {
                                                            this.gettestproject()
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                                <div style={{height: 30}}/>
                            </div>
                        </div>
                    </Spin>
                </Watermark>
            </div>
        </div>
    }
}
