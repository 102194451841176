import React, {Component} from "react";
import {
    Affix,
    Row, Button,
    Card, Col, Divider,
    Spin, Statistic,Watermark,
} from "antd";
import {AreaChartOutlined,RedoOutlined} from "@ant-design/icons";
import {PageHeader} from "@ant-design/pro-layout";
import {Content} from "antd/es/layout/layout";

export class ProjectTestDatas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            spinning: false
        }
    }

    render() {
        return <>
            <Affix offsetTop={60}>
                <div style={{
                    background: '#ffffff',
                    textAlign: 'center',
                }}>
                    <PageHeader
                        className="site-page-header-responsive"
                        title="提测数据"
                        subTitle={"提测数据"}
                        onBack={() => {
                            if (window.history.length <= 1) {
                                window.location.href = "/ProjectTesting"
                            } else {
                                window.history.back();
                            }
                        }}
                        extra={<>
                            <Divider type="vertical"/>
                            <Button type={'link'} style={{width: 100, color: '#707272'}}
                                    icon={<RedoOutlined/>}
                                    onClick={() => {
                                    }}>
                                刷新数据
                            </Button>
                            <Divider type="vertical"/>
                        </>
                        }
                    >
                        <Content/>
                    </PageHeader>
                </div>
            </Affix>
            <div style={{background: 'rgb(240, 242, 245)', minHeight: 763}}>
                <Watermark content="公牛Bull">
                    <Spin spinning={this.state.spinning} tip="数据加载中...">
                        <div>
                            <div style={{margin: 20}}>
                                <Row gutter={[10, 10]}>
                                    <Col span={6}>
                                        <Card bordered={false}>
                                            <Statistic
                                                title="版本提测数"
                                                value={0}
                                                valueStyle={{
                                                    color: '#3f8600',
                                                    marginLeft: 10,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><AreaChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card bordered={false}>
                                            <Statistic
                                                title="提测通过率"
                                                value={0}
                                                valueStyle={{
                                                    color: '#3f8600',
                                                    marginLeft: 10,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><AreaChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card bordered={false}>
                                            <Statistic
                                                title="提测通过数"
                                                value={0}
                                                valueStyle={{
                                                    color: '#3f8600',
                                                    marginLeft: 10,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><AreaChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card bordered={false}>
                                            <Statistic
                                                title="提测失败数"
                                                value={0}
                                                valueStyle={{
                                                    color: '#3f8600',
                                                    marginLeft: 10,
                                                }}
                                                prefix={<div style={{marginRight: 5}}><AreaChartOutlined/></div>}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Spin>
                </Watermark>
            </div>
        </>
    }
}
