import React, {Component} from 'react';
import '@ant-design/pro-components/dist/components.css';
import {PageHeader} from '@ant-design/pro-layout';
import {
    Affix,
    Button,
    Card,
    DatePicker,
    Divider,
    Row,
    Col,
    Statistic,
    List,
    Progress,
    Spin,
    Space,
    Badge,
    Watermark,
    message,
    Radio, FloatButton, TimePicker, Popconfirm
} from 'antd'
import {Content} from "antd/es/layout/layout";
import dayjs from "dayjs";
import {InterfaceTable} from "./InterfaceTable";
import {LineChart} from "./LineChart";
import {getUser, Loginout} from "../Users/AuthRouter";
import api from "../../api/ApiConfig";
import html2canvas from 'html2canvas';

import {
    AlertOutlined,
    AppstoreOutlined,
    ArrowDownOutlined,
    ArrowRightOutlined,
    ArrowUpOutlined, CameraOutlined, RedoOutlined, RocketOutlined,
    SendOutlined,
    SettingOutlined,
    StopOutlined
} from "@ant-design/icons";
import {GaugeChart} from "./GaugeChart";
import Text from "antd/es/typography/Text";
import {PatrolConfig} from "./PatrolConfig";
import {object} from "bfj/src/events";
import {CoveredInterfacesTable} from "./CoveredInterfacesTable";
import {RadialBarChart} from "./RadialBarChart";
import {values} from "@antv/util";
import {MonthServiceAvailabilityLineChart} from "./MonthServiceAvailabilityLineChart";
import moment from "moment";
import {AlertConfig} from "./AlertConfig";


const {Meta} = Card;

export class ServiceDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: [],
            datas1: [],
            DatasDate: dayjs().format("YYYY-MM-DD"),
            timeObj: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')],
            timeString: ['00:00:00', '23:59:59'],
            ranking: [],
            total: 0,
            spinning: false,
            avg_interface_time: 0,
            max_interface_time: 0,
            min_interface_time: 0,
            ServiceAvailability: 0,
            PatrolTaskStatus: -1,
            CoveredInterfaces: [],
            Top10: 1,
            delinterfacedatasloading: false
        }
    }

    componentDidMount() {
        if (this.props.match.params.cycle === "day") {
            this.getdatepickerconfig()
            this.GetInterfaceDatas({DatasType: 1})
            this.GetInterfaceDatas({DatasType: 2})
            this.getinterfacestatistic()
            this.interval = setInterval(() => {
                this.GetInterfaceDatas({DatasType: 1})
                this.getinterfacestatistic()
            }, 200000);
        }
        if (this.props.match.params.cycle === "month") {
            this.getinterfacestatisticmonth()
            this.getinterfacedatasmonth()
            this.getcoveredinterfaces()
        }
        this.getpatroltaskstatus()
    }

    componentWillUnmount() {
        if (this.props.match.params.cycle === "day") {
            clearInterval(this.interval);
        }
    }

    getdatepickerconfig = async (params = {}) => {
        this.setState({
            spinning: true
        })
        params['AppType'] = this.props.match.params.apptype
        await api.GetDatePickerConfig(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                        DATEPICKERCONFIG: Object.keys(r.datas).length > 0 ? r.datas : {}
                    }, function () {
                    }
                )
            }
        })
        this.setState({
            spinning: false
        })
    }

    getcoveredinterfaces = async (params = {}) => {
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['AppType'] = this.props.match.params.apptype
        await api.GetCoveredInterfaces(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (Object.keys(r.datas).length > 0) {
                    const dataSourcelist = []
                    for (let i = 0; i < Object.keys(r.datas).length; i++) {
                        dataSourcelist.push(
                            r['datas'][i].fields
                        )
                        dataSourcelist[i]['pk'] = r['datas'][i].pk
                        dataSourcelist[i]['key'] = i
                    }
                    this.setState({
                        CoveredInterfaces: dataSourcelist
                    }, function () {
                        console.log("覆盖的接口列表", r.datas)
                    })
                }
            }
        })
    }

    getinterfacestatisticmonth = async (params = {}) => {
        this.setState({
            spinning: true
        })
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['DatasDate'] = this.state.DatasDate
        params['AppType'] = this.props.match.params.apptype
        await api.GetInterfaceStatisticMonth(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    ranking: r.datas.ranking.length > 0 ? r.datas.ranking : [],
                    total: r.datas.total,
                    ServiceAvailability: r.datas.ServiceAvailability,
                    avg_interface_time: r.datas.avg_interface_time,
                    max_interface_time: r.datas.max_interface_time,
                    min_interface_time: r.datas.min_interface_time,
                })
            }
        })
        this.setState({
            spinning: false
        })
    }

    getinterfacestatistic = async (params = {}) => {
        this.setState({
            spinning: true
        })
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['DatasDate'] = this.state.DatasDate
        params['AppType'] = this.props.match.params.apptype
        await api.GetInterfaceStatistic(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    ranking: r.datas.ranking.length > 0 ? r.datas.ranking : [],
                    total: r.datas.total,
                    ServiceAvailability: r.datas.ServiceAvailability,
                    avg_interface_time: r.datas.avg_interface_time,
                    max_interface_time: r.datas.max_interface_time,
                    min_interface_time: r.datas.min_interface_time,
                })
            }
        })
        this.setState({
            spinning: false
        })
    }

    getinterfacedatasmonth = async (params = {}) => {
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['DatasDate'] = this.state.DatasDate
        params['AppType'] = this.props.match.params.apptype
        await api.GetInterfaceDatasMonth(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    datas: r.datas
                })
            }
        })
        this.setState({
            spinning: false
        })
    }
    GetInterfaceDatas = async (params = {}) => {
        this.setState({
            spinning: true
        })
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['DatasDate'] = this.state.DatasDate
        params['AppType'] = this.props.match.params.apptype
        await api.InterfaceDatas(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (params['DatasType'] === 1) {
                    this.setState({
                        datas: r.datas
                    }, function () {
                    })
                }
                if (params['DatasType'] === 2) {
                    this.setState({
                        datas1: r.datas
                    }, function () {
                    })
                }
            }
        })
        this.setState({
            spinning: false
        })
    }

    delinterfacedatas = async (ServiceAvailabilityId, s_time, e_time) => {
        this.setState({
            delinterfacedatasloading: true
        })
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['ServiceAvailabilityId'] = ServiceAvailabilityId
        values['s_time'] = s_time
        values['e_time'] = e_time
        await api.delInterfaceDatas({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("删除成功!")
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.getdatepickerconfig()
            this.GetInterfaceDatas({DatasType: 1})
            this.GetInterfaceDatas({DatasType: 2})
            this.getinterfacestatistic()
            this.setState({
                delinterfacedatasloading: false
            })
        })
    }
    screenshotRef = React.createRef();

    handleScreenshot = (image_type = 0, scale = 1) => {
        html2canvas(this.screenshotRef.current, {
            scale: scale, ignoreElements: function (element) {
                // 返回 true 表示忽略该元素，返回 false 表示不忽略
                return element.classList.contains('ignore-class');
            }
        }).then(async canvas => {
            const image = canvas.toDataURL();
            const link = document.createElement('a');
            link.href = image;
            if (image_type === 1) {
                let values = {}
                const userinfo = getUser()
                values['userid'] = userinfo.UserId
                if (this.props.match.params.cycle === "day") {
                    values['ReportType'] = 2
                    values['DatasDate'] = dayjs(this.state.DatasDate).format("YYYY年MM月DD日")
                } else {
                    values['ReportType'] = 4
                    values['DatasDate'] = dayjs(this.state.DatasDate).format("YYYY年MM月")
                }
                values['image'] = image
                values['AppType'] = this.props.match.params.apptype
                await api.uploadImage({values}).then(r => {
                    if (r.msgcode === 20008) {
                        Loginout()
                        this.props.history.push('/login')
                        window.location.reload()
                    }
                    if (r.msgcode === 200) {
                        message.success("报告发送成功")
                    } else {
                        message.error(r.results)
                    }
                    this.setState({
                        ReportLoading: false
                    })
                })
            } else {
                if (this.props.match.params.cycle === "day") {
                    link.download = this.props.match.params.apptype + '_服务可用性日度数据_' + dayjs(this.state.DatasDate).format("YYYY年MM月DD日") + '.png';
                } else {
                    link.download = this.props.match.params.apptype + '_服务可用性月度数据_' + dayjs(this.state.DatasDate).format("YYYY年MM月") + '.png';
                }
                link.click();
            }
        });
    };

    getpatroltaskstatus = async (params = {}) => {
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['AppType'] = this.props.match.params.apptype
        params['TaskType'] = 1
        await api.GetPatrolTaskStatus(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (Object.keys(r.datas).length > 0) {
                    this.setState({
                        PatrolTaskStatus: r.datas.TaskStatus
                    }, function () {
                        console.log("当前巡检任务状态", this.state.PatrolTaskStatus)
                    })
                } else {
                    message.warning("暂时无法获取任务状态!")
                }

            }
        })
    }


    starttask = async () => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['AppType'] = this.props.match.params.apptype
        values['TaskType'] = 1
        await api.StartTask({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("开始巡检指令已下发,请关注任务状态!", 1)
                this.getpatroltaskstatus()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    stoptask = async () => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['AppType'] = this.props.match.params.apptype
        values['TaskType'] = 1
        await api.StopTask({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("停止巡检指令已下发,请关注任务状态!", 1)
                this.getpatroltaskstatus()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }


    render() {
        return (
            <>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="云服务可用性"
                            subTitle={this.props.match.params.cycle === "day" ? "日度数据详情" : "月度数据详情"}
                            onBack={() => window.history.back()}
                            extra={<>
                                {this.props.match.params.cycle === "day" ?
                                    <>
                                        < Divider type="vertical"/>
                                        <DatePicker
                                            allowClear={false}
                                            dateRender={(current) => {
                                                const style = {};
                                                const current_date = moment(current.toDate()).format("YYYY-MM-DD")
                                                const c_list = Object.keys(this.state.DATEPICKERCONFIG).length > 0 ?
                                                    this.state.DATEPICKERCONFIG.ServiceAvailabilityDateList : []
                                                if (c_list.includes(current_date)) {
                                                    style.border = '0px solid #1890ff';
                                                    style.borderRadius = '20%';
                                                    style.backgroundColor = '#9b1313'
                                                    style.color = '#ffffff'
                                                }
                                                return (
                                                    <div className="ant-picker-cell-inner" style={style}>
                                                        {current.date()}
                                                    </div>
                                                );
                                            }}
                                            placement={"bottomRight"}
                                            picker={'date'}
                                            defaultValue={dayjs()}
                                            onChange={(date, dateString) => {
                                                this.setState({
                                                    DatasDate: dateString,
                                                    datas: [],
                                                    datas1: []
                                                }, function () {
                                                    this.GetInterfaceDatas({DatasType: 1})
                                                    this.GetInterfaceDatas({DatasType: 2})
                                                    this.getinterfacestatistic()
                                                })
                                            }}/>
                                        <Divider type="vertical"/>
                                        <Button disabled={this.state.PatrolTaskStatus === 1}
                                                icon={<RocketOutlined/>} type={'link'}
                                                style={{width: 100, color: '#13a4a9'}}
                                                onClick={() => {
                                                    this.setState({}, function () {
                                                        this.starttask()
                                                    })
                                                }}
                                        >
                                            开始巡检
                                        </Button>
                                        <Divider type="vertical"/>
                                        <Button
                                            disabled={this.state.PatrolTaskStatus === 0}
                                            icon={<StopOutlined/>} type={'link'}
                                            style={{width: 100, color: '#6d45c2'}}
                                            onClick={() => {
                                                this.setState({}, function () {
                                                    this.stoptask()
                                                })
                                            }}
                                        >
                                            停止巡检
                                        </Button>
                                        <Divider type="vertical"/>
                                        {/*<PatrolConfig AppType={this.props.match.params.apptype} TaskType={1}/>*/}
                                    </>
                                    : <DatePicker
                                        allowClear={false}
                                        placement={"bottomRight"}
                                        picker={'month'}
                                        defaultValue={dayjs()}
                                        onChange={(date, dateString) => {
                                            this.setState({
                                                DatasDate: dateString + "-" + "1",
                                            }, function () {
                                                this.getinterfacestatisticmonth()
                                                this.getinterfacedatasmonth()
                                            })
                                        }}/>
                                }
                                {/*<Divider type="vertical"/>*/}
                                {/*<Button type={'link'} style={{width: 100, color: '#707272'}}*/}
                                {/*        icon={<CameraOutlined/>}*/}
                                {/*        onClick={() => {*/}
                                {/*            message.success("即将为您生成截图数据请稍后...", 3)*/}
                                {/*            setTimeout(() => {*/}
                                {/*                this.handleScreenshot()*/}
                                {/*            }, 500); // 延迟 500 毫秒执行*/}
                                {/*        }}>*/}
                                {/*    数据截图*/}
                                {/*</Button>*/}
                                {/*<Divider type="vertical"/>*/}
                                <Button type={'link'} style={{width: 100, color: '#707272'}}
                                        icon={<RedoOutlined/>}
                                        onClick={() => {
                                            this.getdatepickerconfig()
                                            if (this.props.match.params.cycle === "day") {
                                                this.GetInterfaceDatas({DatasType: 1})
                                                this.GetInterfaceDatas({DatasType: 2})
                                                this.getinterfacestatistic()
                                                this.setState({
                                                    timeObj: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')],
                                                    timeString: ['00:00:00', '23:59:59']
                                                })
                                            }
                                            if (this.props.match.params.cycle === "month") {
                                                this.getinterfacestatisticmonth()
                                                this.getinterfacedatasmonth()
                                                this.getcoveredinterfaces()
                                            }
                                        }}>
                                    刷新数据
                                </Button>
                                <Divider type="vertical"/>
                                {/*<Button icon={<SendOutlined/>} type={'link'} style={{width: 100, color: '#707272'}}*/}
                                {/*        onClick={() => {*/}
                                {/*            this.setState({*/}
                                {/*                ReportLoading: true*/}
                                {/*            })*/}
                                {/*            message.success("正在为您生成测试报告请稍后...")*/}
                                {/*            setTimeout(() => {*/}
                                {/*                this.handleScreenshot(1, 1)*/}
                                {/*            }, 500);*/}
                                {/*        }}*/}
                                {/*        loading={this.state.ReportLoading}*/}
                                {/*>*/}
                                {/*    发送报告*/}
                                {/*</Button>*/}
                            </>
                            }
                        >
                            <Content/>
                        </PageHeader>
                    </div>
                </Affix>
                <FloatButton.Group
                    trigger="hover"
                    type="primary"
                    style={{
                        right: 30,
                        color: "#0c0c0c"
                    }}
                    icon={<AppstoreOutlined/>}
                >
                    {this.props.match.params.cycle === "day" ?
                        <>
                            <PatrolConfig AppType={this.props.match.params.apptype} TaskType={1}/>
                            <AlertConfig AppType={this.props.match.params.apptype} TaskType={1}/>
                        </>
                        : null
                    }
                    <FloatButton
                        tooltip={<div>数据截图</div>}
                        icon={<CameraOutlined/>}
                        onClick={() => {
                            message.success("即将为您生成截图数据请稍后...", 3)
                            setTimeout(() => {
                                this.handleScreenshot()
                            }, 500); // 延迟 500 毫秒执行
                        }}/>
                    <FloatButton
                        tooltip={<div>发送报告</div>}
                        icon={<SendOutlined/>}
                        onClick={() => {
                            this.setState({
                                ReportLoading: true
                            })
                            message.success("正在为您生成测试报告请稍后...", 3)
                            setTimeout(() => {
                                this.handleScreenshot(1, 1)
                            }, 500);
                        }}
                        loading={this.state.ReportLoading}/>
                </FloatButton.Group>
                <div style={{marginTop: 3, background: 'rgb(240, 242, 245)', minHeight: 743}}
                     ref={this.screenshotRef}>
                    <Spin tip="请稍后,数据加载中..." spinning={this.state.spinning}>
                        <Watermark content="公牛Bull">
                            <div style={{width: "100%", marginLeft: 1, marginTop: 1, textAlign: 'center'}}>
                                <Row gutter={[5, 1]} wrap={true} style={{maxWidth: '100%'}}>
                                    <Col span={4}>
                                        <Card style={{
                                            backgroundColor: 'rgb(255,255,255)',
                                            borderRadius: '10px',
                                            height: 230,
                                        }}>
                                            <Statistic
                                                style={{marginTop: '40%', transform: 'translateY(-40%)'}}
                                                title="巡检任务(10s/次)"
                                                value={" "}
                                                prefix={
                                                    <div>
                                                        <Space style={{
                                                            marginLeft: 20
                                                        }}>
                                                            <Progress
                                                                size="small"
                                                                style={{marginLeft: 0, width: 100}}
                                                                status={this.state.PatrolTaskStatus === 1 ? "active" : this.state.PatrolTaskStatus === 0 ? "exception" : "exception"}
                                                                percent={90} format={() => <div
                                                                style={{
                                                                    fontSize: 12,
                                                                    fontWeight: "bold"
                                                                }}>{this.state.PatrolTaskStatus === 0 ? "已停止" :
                                                                this.state.PatrolTaskStatus === 1 ? "巡检中" : "状态异常"}
                                                            </div>}
                                                            />

                                                        </Space>
                                                    </div>

                                                }
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card style={{
                                            backgroundColor: 'rgb(255,255,255)',
                                            borderRadius: '10px',
                                            height: 230,
                                        }}>
                                            <Statistic
                                                style={{marginTop: '40%', transform: 'translateY(-50%)'}}
                                                title="请求总数"
                                                value={this.state.total}
                                                precision={0}
                                                valueStyle={{
                                                    color: '#3f8600',
                                                    fontSize: 20
                                                }}
                                                suffix="次"
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card style={{
                                            backgroundColor: 'rgb(255,255,255)',
                                            borderRadius: '10px',
                                            height: 230,
                                        }}>

                                            <div style={{
                                                marginTop: '40%', transform: 'translateY(-50%)'
                                            }}>
                                                <GaugeChart datas={this.state.ServiceAvailability}
                                                            content={"服务可用性"}/>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card style={{
                                            backgroundColor: 'rgb(255,255,255)',
                                            borderRadius: '10px',
                                            height: 230,
                                        }}>
                                            <Statistic
                                                style={{marginTop: '40%', transform: 'translateY(-50%)'}}
                                                title="平均耗时"
                                                value={this.state.avg_interface_time}
                                                precision={0}
                                                valueStyle={{
                                                    color: '#1f3cb9',
                                                    fontSize: 20
                                                }}
                                                suffix="ms"
                                                prefix={<ArrowRightOutlined/>}
                                            /></Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card style={{
                                            backgroundColor: 'rgb(255,255,255)',
                                            borderRadius: '10px',
                                            height: 230,
                                        }}>
                                            <Statistic
                                                style={{marginTop: '40%', transform: 'translateY(-50%)'}}
                                                title="最小耗时"
                                                value={this.state.min_interface_time}
                                                precision={0}
                                                valueStyle={{
                                                    color: '#3f8600',
                                                    fontSize: 20
                                                }}
                                                suffix="ms"
                                                prefix={<ArrowDownOutlined/>}

                                            />
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card style={{
                                            backgroundColor: 'rgb(255,255,255)',
                                            borderRadius: '10px',
                                            height: 230,
                                        }}>
                                            <Statistic
                                                style={{marginTop: '40%', transform: 'translateY(-50%)'}}
                                                title="最大耗时"
                                                value={this.state.max_interface_time}
                                                precision={0}
                                                valueStyle={{
                                                    color: '#cf1322',
                                                    fontSize: 20
                                                }}
                                                suffix="ms"
                                                prefix={<ArrowUpOutlined/>}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Badge.Ribbon text="实时数据" color="purple">
                                            <Card title={<div style={{textAlign: "left"}}>服务可用性数据</div>}
                                                  style={{height: 600}}>
                                                {this.props.match.params.cycle === "day" ?
                                                    <LineChart datas={this.state.datas}/> :
                                                    <MonthServiceAvailabilityLineChart datas={this.state.datas}/>}
                                            </Card>
                                        </Badge.Ribbon>
                                    </Col>
                                    <Col span={12}>
                                        <Badge.Ribbon text="实时数据" color="purple">

                                            <Card title={<div>接口耗时排行Top10</div>} extra={
                                                <Radio.Group value={this.state.Top10}
                                                             style={{marginRight: 70}}
                                                             onChange={(e) => {
                                                                 this.setState({
                                                                     Top10: e.target.value,
                                                                 })
                                                             }
                                                             }>
                                                    <Radio.Button value={1}>玉珏图</Radio.Button>
                                                    <Radio.Button value={2}>列表图</Radio.Button>
                                                </Radio.Group>
                                            } style={{height: 600, textAlign: "left"}}>
                                                {this.state.Top10 === 1 ?
                                                    <RadialBarChart datas={this.state.ranking}/> :
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={this.state.ranking}
                                                        renderItem={(item, index) => (
                                                            <List.Item>
                                                                <List.Item.Meta
                                                                    avatar={
                                                                        <div style={{
                                                                            width: 30,
                                                                            height: 20,
                                                                            backgroundColor: "#0c0c0c",
                                                                            borderRadius: 20,
                                                                            color: '#ffffff',
                                                                            fontWeight: 800,
                                                                            fontSize: 13,
                                                                            textAlign: "center",

                                                                        }}>
                                                                            {index + 1}
                                                                        </div>
                                                                    }
                                                                    description={<div style={{
                                                                        maxWidth: "90%",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        whiteSpace: "pre"
                                                                    }}>
                                                                        {item.Method.padEnd(9, " ")} {item.Path}
                                                                    </div>}
                                                                />
                                                                <div>{item.InterfaceTime} ms</div>
                                                            </List.Item>
                                                        )}
                                                    />}
                                            </Card>
                                        </Badge.Ribbon>
                                    </Col>
                                    {this.props.match.params.cycle === "day" ?
                                        <Col span={24}>
                                            <Badge.Ribbon text="实时数据" color="purple">

                                                <Card
                                                    bordered={true}
                                                    title={<div style={{"textAlign": "left"}}>请求异常数据</div>}
                                                    extra={<div className={'ignore-class'}
                                                                style={{"textAlign": "left", marginRight: 50}}><Space
                                                        size={5}>
                                                        <TimePicker.RangePicker
                                                            defaultValue={[dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]}
                                                            // defaultValue={[dayjs(this.state.timeString[0], 'HH:mm:ss'), dayjs(this.state.timeString[1], 'HH:mm:ss')]}
                                                            value={this.state.timeObj}
                                                            onChange={(time, timeString) => {
                                                                this.setState({
                                                                    timeString: timeString,
                                                                    timeObj: time,
                                                                })
                                                            }
                                                            }
                                                        />
                                                        <Button onClick={() => {
                                                            this.GetInterfaceDatas({
                                                                DatasType: 2,
                                                                s_time: this.state.DatasDate + " " + this.state.timeString[0],
                                                                e_time: this.state.DatasDate + " " + this.state.timeString[1]
                                                            })

                                                        }
                                                        }>查询</Button>
                                                        <Button onClick={() => {
                                                            this.setState({
                                                                timeObj: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')],
                                                                timeString: ['00:00:00', '23:59:59']
                                                            })
                                                            this.GetInterfaceDatas({
                                                                DatasType: 2
                                                            })
                                                        }}>重置</Button>
                                                        <Popconfirm
                                                            title="删除数据"
                                                            description="您确定要删除指定时间内的所有接口异常数据吗?"
                                                            onConfirm={() => {
                                                                let ServiceAvailabilityId = -1
                                                                if (this.state.datas1.length > 0) {
                                                                    const s_time = this.state.DatasDate + " " + this.state.timeString[0]
                                                                    const e_time = this.state.DatasDate + " " + this.state.timeString[1]
                                                                    ServiceAvailabilityId = this.state.datas1[0]['ServiceAvailability_id']
                                                                    this.delinterfacedatas(ServiceAvailabilityId, s_time, e_time)
                                                                } else {
                                                                    message.error("暂无可删除的数据～")
                                                                }
                                                            }}
                                                            okText="确认"
                                                            cancelText="取消"
                                                            okButtonProps={{
                                                                loading: this.state.delinterfacedatasloading,
                                                            }}
                                                        >
                                                            <Button danger>删除</Button>
                                                        </Popconfirm>
                                                    </Space></div>}
                                                >
                                                    <Meta
                                                        description={<InterfaceTable datas={this.state.datas1}
                                                                                     DatasDate={this.state.DatasDate}
                                                                                     fun={this.GetInterfaceDatas}
                                                                                     fun1={this.getinterfacestatistic}
                                                                                     fun2={this.getdatepickerconfig}
                                                        />}
                                                    />
                                                </Card>
                                            </Badge.Ribbon>
                                        </Col>
                                        :
                                        <Col span={24}>
                                            <Card
                                                bordered={true}
                                                title={<div style={{textAlign: "left"}}><Space>已覆盖的接口 <div style={{
                                                    width: 10,
                                                    fontSize: 13,
                                                    color: '#6d45c2',
                                                }}>(共计:{this.state.CoveredInterfaces.length})</div></Space></div>}
                                            >
                                                <Meta
                                                    description={<CoveredInterfacesTable
                                                        datas={this.state.CoveredInterfaces}/>}
                                                />
                                            </Card>
                                        </Col>}
                                </Row>
                            </div>
                        </Watermark>
                    </Spin>
                </div>
            </>
        )
    }

}