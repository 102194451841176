import React, {Component} from "react";
import {
    Affix,
    Badge, Button,
    Card, Divider, Input, Mentions, message, Popconfirm, Select, Space,
    Spin, Table, Tag, Tooltip, Watermark,
} from "antd";
import {getUser, Loginout} from "../Users/AuthRouter";
import {CreatePConfig} from "./CreatePConfig";
import api from "../../api/ApiConfig";
import {PageHeader} from "@ant-design/pro-layout";
import {
    AndroidOutlined, ChromeOutlined,
    ClearOutlined,
    CloudServerOutlined, DeleteOutlined, FormOutlined,
    GatewayOutlined, QuestionCircleOutlined,
    RedoOutlined,
    SearchOutlined, SettingOutlined
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import {UpdatePConfig} from "./UpdatePConfig";
import dayjs from "dayjs";
import {SpecialConfiguration} from "./SpecialConfiguration";
import Avatar from "antd/es/avatar/avatar";

export class CsProjectConfig extends Component {
    // 云服务配置列表
    constructor(props) {
        super(props)
        this.state = {
            GetProjectTaskConfigList: [],
            spinning: false,
            ServiceEnv: -1,
            ServiceName: null,
            ServiceType: 1,
            pageSize: 10,
            page: 1,
            Total: 0,
            userList: []
        }
    }

    componentDidMount() {
        this.getprojecttaskconfiginfo()
        this.getuserlist_atmp()
    }

    userinfo = getUser()

    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <div>
                            <Tooltip title={<div>{value.name}({value.user_id})</div>} color={"#108ee9"} key={key}>
                                <Avatar key={key} size={30} src={value.avatar_url}/>
                            </Tooltip>
                        </div>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }

    delprojecttaskconfiginfo = async (configId) => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['configId'] = configId
        console.log("删除提测任务配置", values)
        await api.DelProjectTaskConfigInfo({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    message.success("删除提测任务配置成功")
                    this.getprojecttaskconfiginfo()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    getprojecttaskconfiginfo = async (params = {}) => {
        this.setState({
            spinning: true
        })
        params['pageSize'] = this.state.pageSize
        params['page'] = this.state.page
        if (this.state.ServiceEnv !== -1) {
            params['ServiceEnv'] = this.state.ServiceEnv
        }
        if (this.state.ServiceType !== 1) {
            params['ServiceType'] = this.state.ServiceType
        }
        if (this.state.ServiceName !== null) {
            params['ServiceName'] = this.state.ServiceName
        }
        await api.GetProjectTaskConfigInfo(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    GetProjectTaskConfigList: r.datas,
                    Total: r.Total
                })
            }
            setTimeout(() => {
                this.setState({
                    spinning: false
                })
            }, 100)

        })
    }

    render() {
        const ProjectConfigColumns = [
            {
                title: '编号',
                fixed: "left",
                width: 100,
                align: 'center',
                render: (_, record, index) => {
                    return <div>{index + 1}</div>
                }
            },
            {
                title: '创建、更新时间',
                align: 'center',
                dataIndex: 'DatasDate',
                render: (text, record, index) => {
                    return <div style={{
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: 12
                    }}>{dayjs(text).format("YYYY年MM月DD日 HH:mm:ss")}</div>
                }
            },
            {
                title: '服务名称',
                align: 'center',
                dataIndex: 'ServiceName',
                render: (text, record, index) => {
                    return <div style={{textAlign: "center", fontWeight: 700, fontSize: 13}}>{text}</div>
                }
            },
            {
                title: '提测环境',
                align: 'center',
                width: 120,
                dataIndex: 'ServiceEnv',
                render: (text, record, index) => {
                    return <div style={{textAlign: "center", fontWeight: 700, fontSize: 12}}>
                        <Tag
                            color={
                                text === "TEST" ? "#5b8c00" :
                                    text === "RELEASE" ? "#8d062c" :
                                        text === "PRE" ? "#d05715" : "#9254de"
                            }
                            style={{
                                marginRight: 5,
                                textAlign: "center",
                                fontSize: 12,
                                fontWeight: 700,
                                width: 70,
                            }}
                        >
                            {text === "TEST" ? "测试环境" :
                                text === "RELEASE" ? "生产环境" :
                                    text === "PRE" ? "预发布" : "未知环境"}
                        </Tag></div>
                }
            },
            {
                title: '提测类型',
                align: 'center',
                width: 120,
                dataIndex: 'ServiceType',
                render: (text, record, index) => {
                    return <div style={{textAlign: "center", fontWeight: 700, fontSize: 12}}>
                        <Tag
                            style={{
                                marginRight: 5,
                                textAlign: "center",
                                fontSize: 12,
                                fontWeight: 700,
                                width: 85,
                            }}
                            color={
                                text === 1 ? "#1677ff" :
                                    text === 2 ? "#13c2c2" :
                                        text === 3 ? "#d05715" :
                                            text === 4 ? "#9254de" : "#9254de"
                            }>
                            {
                                text === 1 ? "云服务" :
                                    text === 2 ? "App" :
                                        text === 3 ? "固件" :
                                            text === 4 ? "Web" : "未知类型"
                            }
                        </Tag>
                    </div>
                }
            },
            {
                title: '服务描述',
                align: 'center',
                dataIndex: 'ServiceNotes',
                render: (text, record, index) => {
                    return <div style={{textAlign: "center", fontWeight: 700, fontSize: 12}}>{text}</div>
                }
            },
            {
                title: '测试项目',
                align: 'center',
                dataIndex: 'AutoTestProjectInfo',
                render: (text, record, index) => {
                    return <div style={{textAlign: "center", fontWeight: 700, fontSize: 12}}>{text.split("@")[0]}</div>
                }
            },
            {
                title: '测试任务',
                align: 'center',
                dataIndex: 'AutoTestTaskInfo',
                render: (text, record, index) => {
                    return <div style={{textAlign: "center", fontWeight: 700, fontSize: 12}}>{text.split("@")[0]}</div>
                }
            },
            {
                title: '审批发起人',
                align: 'center',
                dataIndex: 'InitiateApproverList',
                render: (text, record, index) => {
                    let users_l = []
                    try {
                        users_l = JSON.parse(text)
                    } catch (e) {
                        users_l = []
                    }
                    return <Avatar.Group
                        maxCount={10}
                        size={"default"}
                        maxStyle={{
                            color: '#6d45c2',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {users_l.map((value, index) => {
                            for (let i = 0; i < this.state.userList.length; i++) {
                                if (value === this.state.userList[i].value) {
                                    return this.state.userList[i].label
                                }
                            }
                        })}
                    </Avatar.Group>
                    // <div style={{textAlign: "left", fontWeight: 700, fontSize: 12}}>{text}</div>
                }
            },
            {
                title: '审批人',
                align: 'center',
                dataIndex: 'ApproversList',
                render: (text, record, index) => {
                    let users_l = []
                    try {
                        users_l = JSON.parse(text)
                        
                    } catch (e) {
                        users_l = []
                    }
                    return <Avatar.Group
                        maxCount={10}
                        size={"default"}
                        maxStyle={{
                            color: '#6d45c2',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {users_l.map((value, index) => {
                            for (let i = 0; i < this.state.userList.length; i++) {
                                if (value === this.state.userList[i].value) {
                                    return this.state.userList[i].label
                                }
                            }
                        })}
                    </Avatar.Group>
                    // <div style={{textAlign: "left", fontWeight: 700, fontSize: 12}}>{text}</div>
                }
            },
            {
                title: '抄送人',
                align: 'center',
                dataIndex: 'CCList',
                ellipsis: true,
                render: (text, record, index) => {
                    let users_l = []
                    try {
                        users_l = JSON.parse(text)
                    } catch (e) {
                        users_l = []
                    }
                    return <Avatar.Group
                        maxCount={10}
                        size={"default"}
                        maxStyle={{
                            color: '#6d45c2',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {users_l.map((value, index) => {
                            for (let i = 0; i < this.state.userList.length; i++) {
                                if (value === this.state.userList[i].value) {
                                    return this.state.userList[i].label
                                }
                            }
                        })}
                    </Avatar.Group>
                    // <div style={{textAlign: "left", fontWeight: 700, fontSize: 12}}>{text}</div>
                }
            },
            {
                title: '运维人员',
                align: 'center',
                dataIndex: 'OperationsList',
                render: (text, record, index) => {
                    let users_l = []
                    try {
                        users_l = JSON.parse(text)
                        
                    } catch (e) {
                        users_l = []
                    }
                    return <Avatar.Group
                        maxCount={10}
                        size={"default"}
                        maxStyle={{
                            color: '#6d45c2',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {users_l.map((value, index) => {
                            for (let i = 0; i < this.state.userList.length; i++) {
                                if (value === this.state.userList[i].value) {
                                    return this.state.userList[i].label
                                }
                            }
                        })}
                    </Avatar.Group>
                    // <div style={{textAlign: "left", fontWeight: 700, fontSize: 12}}>{text}</div>
                }
            },
            {
                title: '开发人员',
                align: 'center',
                dataIndex: 'DeveloperList',
                render: (text, record, index) => {
                    let users_l = []
                    try {
                        users_l = JSON.parse(text)
                        
                    } catch (e) {
                        users_l = []
                    }
                    return <Avatar.Group
                        maxCount={10}
                        size={"default"}
                        maxStyle={{
                            color: '#6d45c2',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {users_l.map((value, index) => {
                            for (let i = 0; i < this.state.userList.length; i++) {
                                if (value === this.state.userList[i].value) {
                                    return this.state.userList[i].label
                                }
                            }
                        })}
                    </Avatar.Group>
                    // <div style={{textAlign: "left", fontWeight: 700, fontSize: 12}}>{text}</div>
                }
            },
            {
                title: '测试人员',
                align: 'center',
                dataIndex: 'TesterList',
                render: (text, record, index) => {
                    let users_l = []
                    try {
                        users_l = JSON.parse(text)
                        
                    } catch (e) {
                        users_l = []
                    }
                    return <Avatar.Group
                        maxCount={10}
                        size={"default"}
                        maxStyle={{
                            color: '#6d45c2',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {users_l.map((value, index) => {
                            for (let i = 0; i < this.state.userList.length; i++) {
                                if (value === this.state.userList[i].value) {
                                    return this.state.userList[i].label
                                }
                            }
                        })}
                    </Avatar.Group>
                    // <div style={{textAlign: "left", fontWeight: 700, fontSize: 12}}>{text}</div>
                }
            },
            {
                fixed: "right",
                title: '操作',
                align: 'center',
                render: (_, record) => {
                    return <Space size={0}>
                        <UpdatePConfig configId={record.key} fun1={this.getprojecttaskconfiginfo}/>
                        <Divider type={"vertical"}/>
                        <Popconfirm
                            title="删除任务配置"
                            description="确定要删除这个提测任务配置吗?"
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                            onConfirm={() => {
                                this.delprojecttaskconfiginfo(record.key)
                            }}
                        >
                            <Button size={"small"} disabled={false} style={{width: 80}}
                                    type={"link"} danger>
                                <Space size={5} style={{fontWeight: 800, fontSize: 13}}>
                                    <DeleteOutlined/> 删除配置
                                </Space>
                            </Button>
                        </Popconfirm>
                    </Space>
                }
            }
            ,
        ];
        return <>
            <div style={{background: 'rgb(240, 242, 245)'}}>
                <Watermark content="公牛Bull">
                    <Card extra={<Space size={0}>
                        <Divider type="vertical"/>
                        <Button type={'link'} size={"small"} style={{width: 90, color: '#707272'}}
                                icon={<RedoOutlined/>}
                                onClick={() => {
                                    this.getprojecttaskconfiginfo()
                                }}>
                            刷新数据
                        </Button>
                        <Divider type="vertical"/>
                        <CreatePConfig fun1={this.getprojecttaskconfiginfo}/>
                        <Divider type="vertical"/>
                        {/*<SpecialConfiguration/>*/}
                        {/*<Divider type="vertical"/>*/}
                    </Space>
                    }
                          title={
                              <Space size={10} style={{margin: "20px 20px 20px 0px"}}>
                                  <Select
                                      style={{minWidth: 150, textAlign: "center"}}
                                      showSearch={false}
                                      placeholder="请选择环境"
                                      value={this.state.ServiceEnv}
                                      onChange={(value, option) => {
                                          this.setState({
                                              ServiceEnv: value
                                          })
                                      }}
                                      options={[
                                          {
                                              value: -1,
                                              label: '所有环境',
                                          },
                                          {
                                              value: "TEST",
                                              label: '测试环境',
                                          },
                                          {
                                              value: "RELEASE",
                                              label: '生产环境',
                                          },
                                          {
                                              value: "PRE",
                                              label: '预发布环境',
                                          }
                                      ]}
                                  />
                                  <Input style={{minWidth: 200, textAlign: "center"}}
                                         value={this.state.ServiceName}
                                         placeholder={"请输入服务名称"}
                                         onChange={(v) => {
                                             this.setState({
                                                 ServiceName: v.target.value
                                             })
                                         }}
                                  />
                                  <Button size={"middle"} type={"primary"}
                                          style={{fontSize: 13, fontWeight: 700}}
                                          onClick={() => {
                                              this.getprojecttaskconfiginfo()
                                          }}
                                  >
                                      <Space size={5}>
                                          <SearchOutlined/>搜索
                                      </Space>
                                  </Button>
                                  <Button size={"middle"} type={"primary"}
                                          style={{fontSize: 13, fontWeight: 700}}
                                          onClick={() => {
                                              this.setState({
                                                  ServiceEnv: -1,
                                                  ServiceName: null,
                                                  ServiceType: 1,
                                              }, function () {
                                                  this.getprojecttaskconfiginfo()
                                              })
                                          }}
                                  >
                                      <Space size={5}>
                                          <ClearOutlined/>重置
                                      </Space>
                                  </Button>
                              </Space>
                          }>
                        <Table
                            scroll={{
                                x: 3800,
                            }}
                            loading={this.state.spinning}
                            bordered={true}
                            columns={ProjectConfigColumns}
                            dataSource={this.state.GetProjectTaskConfigList}
                            pagination={{
                                position: ['bottomRight'],
                                pageSizeOptions: [10, 20, 30],
                                responsive: true,
                                size: "middle",
                                current: this.state.current,
                                pageSize: this.state.pageSize,
                                total: this.state.Total,
                                showTotal: (total, range) => {
                                    return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                                },
                                onShowSizeChange: (current, size) => {
                                    this.setState({
                                        page: current,
                                        pageSize: size
                                    }, function () {
                                        this.getprojecttaskconfiginfo()
                                    })
                                },
                                onChange: (page, pageSize) => {
                                    this.setState({
                                        page: page,
                                        pageSize: pageSize
                                    }, function () {
                                        this.getprojecttaskconfiginfo()
                                    })
                                }
                            }}
                        />
                    </Card>
                </Watermark>
            </div>
        </>
    }
}
