import React, {Component} from 'react';
import {Button, Modal, message, Space, Form, Input, InputNumber, FloatButton} from "antd";
import {
    ClockCircleOutlined,
    ClusterOutlined,
    CodeOutlined,
    ForkOutlined,
    GitlabOutlined, KeyOutlined,
    SettingOutlined
} from "@ant-design/icons";
import api from "../../api/ApiConfig";
import {getUser, Loginout} from "../Users/AuthRouter";

export class PatrolConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: props.datas ? props.datas : [],
            isModalOpen: false,
            PatrolConfig: {}
        }
    }


    getpatrolconfig = async (params = {}) => {
        const userinfo = getUser()
        params['userid'] = userinfo.UserId
        params['AppType'] = this.props.AppType
        params['TaskType'] = this.props.TaskType
        await api.GetPatrolConfig(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (Object.keys(r.datas).length > 0) {
                    this.setState({
                        PatrolConfig: r.datas,
                    }, function () {
                    })
                }
                else {
                    message.error(r.results)
                    return false
                }
            }
            this.setState({
                isModalOpen: true
            })
        })
    }
    updatepatrolconfig = async (_values) => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['AppType'] = this.props.AppType
        values['TaskType'] = this.props.TaskType
        values['TaskType'] = this.props.TaskType
        values = Object.assign({}, values, _values)
        await api.UpdatePatrolConfig({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("更新或创建成功!", 1)
                this.setState({
                    isModalOpen: false
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            }, function () {
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            }, function () {
            })
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 20,
                    offset: 4,
                },
            },
        };
        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24,
                },
                sm: {
                    span: 4,
                },
            },
            wrapperCol: {
                xs: {
                    span: 24,
                },
                sm: {
                    span: 20,
                },
            },
        };
        const onFinish = (values) => {
            console.log("巡检配置信息", values)
            this.updatepatrolconfig(values)
        }
        return <Space>
            <FloatButton
                tooltip={<div>巡检配置</div>}
                icon={<SettingOutlined/>}
                onClick={() => {
                    this.getpatrolconfig()
                }}
                style={{marginBottom: 13}}
            />
            <Modal title="巡检配置" open={this.state.isModalOpen}
                   footer={null} style={{minWidth: 800}}
                   onCancel={handleCancel} maskClosable={false}>
                <Space>
                    <div style={{marginTop: 40, marginLeft: 20}}>
                        <div style={{
                            // textAlign: 'center',
                            background: "rgba(0, 0, 0, 0.02)",
                            marginTop: 16,
                            marginBottom: 40,
                            borderRadius: "8px",
                            border: "1px dashed rgb(217, 217, 217)"
                        }}>

                            <Form
                                ref={this.formRef}
                                name="dynamic_form_item"
                                {...formItemLayoutWithOutLabel}
                                onFinish={onFinish}
                                style={{
                                    width: 670,
                                    marginTop: 40,
                                    marginLeft: 40
                                }}
                            >
                                <Form.Item
                                    {...formItemLayout}
                                    label="任务配置ID"
                                    name="id"
                                    initialValue={this.state.PatrolConfig.id}
                                >
                                    <Input
                                        addonBefore={<KeyOutlined/>}
                                        style={{
                                            width: '90%',
                                        }}
                                        disabled={true}
                                    />
                                </Form.Item>
                                <Form.Item
                                    initialValue={this.state.PatrolConfig.JenkinsHost}
                                    {...formItemLayout}
                                    label="Jenkins地址"
                                    name="JenkinsHost"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请填写JenkinsHost",
                                        },
                                    ]}
                                >
                                    <Input
                                        addonBefore={<ClusterOutlined/>}
                                        style={{
                                            width: '90%',
                                        }}
                                        placeholder="请填写任务名称"/>
                                </Form.Item>
                                <Form.Item
                                    initialValue={this.state.PatrolConfig.JenkinsAccount}
                                    {...formItemLayout}
                                    label="Jenkins账号"
                                    name="JenkinsAccount"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请填写任务执行命令",
                                        },
                                    ]}
                                >
                                    <Input
                                        addonBefore={<ClusterOutlined/>}
                                        style={{
                                            width: '90%',
                                        }}
                                        placeholder="请填写Jenkins账号"/>
                                </Form.Item>
                                <Form.Item
                                    initialValue={this.state.PatrolConfig.JenkinsPassword}
                                    {...formItemLayout}
                                    label="Jenkins密码"
                                    name="JenkinsPassword"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请填写Jenkins密码",
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        addonBefore={<ClusterOutlined/>}
                                        style={{
                                            width: '90%',
                                        }}
                                        placeholder="请填写Jenkins密码"/>
                                </Form.Item>
                                <Form.Item
                                    initialValue={this.state.PatrolConfig.gitSshUrl}
                                    {...formItemLayout}
                                    label="代码仓库地址"
                                    name="gitSshUrl"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请填写代码仓库地址",
                                        },
                                    ]}
                                >
                                    <Input
                                        addonBefore={<GitlabOutlined/>}
                                        style={{
                                            width: '90%',
                                        }}
                                        placeholder="请填写代码仓库地址"/>
                                </Form.Item>
                                <Form.Item
                                    initialValue={this.state.PatrolConfig.gitBranch}
                                    {...formItemLayout}
                                    label="代码仓库分支"
                                    name="gitBranch"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请选择代码仓库分支",
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{
                                            width: '90%',
                                        }}
                                        addonBefore={<ForkOutlined/>}
                                        placeholder="请选择代码仓库分支"/>
                                </Form.Item>
                                <Form.Item
                                    initialValue={this.state.PatrolConfig.pythonCmd}
                                    {...formItemLayout}
                                    label="任务执行命令"
                                    name="pythonCmd"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请填写任务执行命令",
                                        },
                                    ]}
                                >
                                    <Input
                                        addonBefore={<CodeOutlined/>}
                                        style={{
                                            width: '90%',
                                        }}
                                        placeholder="请填写任务执行命令"/>
                                </Form.Item>
                                <Form.Item
                                    initialValue={this.state.PatrolConfig.TaskCycle}
                                    {...formItemLayout}
                                    label="任务执行周期"
                                    name="TaskCycle"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择任务执行周期",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        addonBefore={<ClockCircleOutlined/>}
                                        min={-1}
                                        max={99}
                                        style={{
                                            // width: '90%',
                                        }}
                                        placeholder="请选择任务执行周期"/>
                                </Form.Item>
                                <Form.Item style={{marginLeft: "30%", marginTop: 50}}>
                                    <Button type="primary" htmlType="submit">
                                        确认修改
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Space>
            </Modal>
        </Space>
    }
}