import React, {Component} from 'react';
import {Bullet, Line} from '@ant-design/plots';
import moment from "moment";
import {Button, Empty, message, Popconfirm, Table, Watermark} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {getUser, Loginout} from "../Users/AuthRouter";
import api from "../../api/ApiConfig";

export class CoveredInterfacesTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: props.datas ? props.datas : [],
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
                fun: this.props.fun,
                fun1: this.props.fun1

            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }

    render() {
        const columns = [
            {
                title: '接口用途',
                dataIndex: 'InterfaceName',
                key: 'InterfaceName',
                width: 400,
            },
            {
                title: '请求方法',
                width: 90,
                dataIndex: 'Method',
                key: 'Method',
                align: 'center',
            },
            {
                title: '接口路径',
                dataIndex: 'Path',
                key: 'Path',
            },

            {
                title: '数据更新时间',
                dataIndex: 'EditedTime',
                key: 'EditedTime',
            },
        ]
        return <>
            {this.state.datas.length !== 0 ?
                <Table dataSource={this.state.datas}
                       columns={columns}
                       pagination={
                           {
                               pageSize: 1000,
                               showTotal: (total) => `共计 ${this.state.datas.length}`,
                               position: ['none']
                           }
                       }
                       bordered
                /> :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </>
    }
}
