import React from "react";
import {Column, G2} from '@ant-design/plots';
import {Result} from "antd";

export default class Column01 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas,
            textColor: props.textColor
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
                textColor: this.props.textColor
            })
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        // 比较新的属性和状态与当前的属性和状态是否相等
        return !(JSON.stringify(this.props.datas) === JSON.stringify(nextProps.datas) &&
            JSON.stringify(this.state.datas) === JSON.stringify(nextState.datas));
    }

    render() {
        const {registerTheme} = G2;
        registerTheme('custom-theme', {
            colors10: ['#6d45c2', '#F4A49E', '#EE7B91', '#E85285', '#BE408C', '#BE408C', '#942D93'],
            /** 20色板 */
            colors20: ['#FACDAA', '#F4A49E', '#EE7B91', '#E85285', '#BE408C', '#BE408C', '#942D93'],
        });

        const config = {
            data: this.state.datas,
            xField: 'xKey',
            yField: 'value',
            isGroup: true,
            isStack: true,
            groupField: 'name',
            seriesField: 'type',
            height: 250,
            slider: {
                x: {
                    values: [0, 1],
                },
            },
            legend: {
                layout: 'horizontal',
                position: 'top',
                offsetX: -10,
                itemName: {
                    style: {
                        fill: this.state.textColor,
                        fontWeight: 500,
                    }
                },
            },
            label: {
                // 可手动配置 label 数据标签位置
                position: 'middle',
                // 'top', 'middle', 'bottom'
                // 可配置附加的布局方法
                layout: [
                    // 柱形图数据标签位置自动调整
                    {
                        type: 'interval-adjust-position',
                    }, // 数据标签防遮挡
                    {
                        type: 'interval-hide-overlap',
                    }, // 数据标签文颜色自动调整
                    {
                        type: 'adjust-color',
                    },
                ],

            },
            // theme: 'custom-theme',
            columnStyle: {
                radius: [0, 0, 0, 0],
            },
            // columnWeight: 10,
            yAxis: {
                label: {
                    style: {
                        fill: this.state.textColor, // 设置Y轴标签文本颜色为红色
                        fontWeight: 700,
                        fontSize: 12,
                    },
                    axisLabel: {
                        show: true,
                    },
                },
            },
            xAxis: {
                label: {
                    // rotate: 70,
                    autoRotate: true,
                    // offsetY: 10,
                    // offsetX: 10,
                    style: {
                        fill: this.state.textColor,
                        fontSize: 12,
                    },
                    autoHide: false, // 禁止Y轴标签自动隐藏
                },
            },
        };
        return <div>{
            this.state.datas.length > 0 ? <Column {...config} />
                : <Result
                    status="success"
                    title={<div style={{color: this.state.textColor}}>
                        暂无数据
                    </div>}
                />
        }</div>
    }
};