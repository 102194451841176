import React, {Component} from 'react';
import '@ant-design/pro-components/dist/components.css';
import {PageHeader} from '@ant-design/pro-layout';
import {ProCard} from '@ant-design/pro-components';
import {
    Affix,
    Button,
    Descriptions,
    Divider,
    Input,
    Radio,
    Space,
    Table,
    Tag,
    List,
    message,
    Popconfirm,
    Drawer, Spin, Tabs, Badge, Col, Row, Result, Skeleton, Select, Watermark
} from 'antd'

import {NavLink} from "react-router-dom";
import {
    AppstoreOutlined, ClearOutlined, DeleteOutlined,
    DownloadOutlined, ExportOutlined, SearchOutlined, ShakeOutlined, ShareAltOutlined, TeamOutlined, UserOutlined,
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import Card from "antd/es/card/Card";
import Avatar from "antd/es/avatar/avatar";
import api from "../../api/ApiConfig";
import {getUser, Loginout} from "../Users/AuthRouter";
import dayjs from "dayjs";
import Text from "antd/es/typography/Text";
import {CreateVoiceLibrary} from "./CreateVoiceLibrary";
import {EditVoiceLibrary} from "./EditVoiceLibrary";


export class VoiceTestHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            spinning: false,
            userList: [],
            datas: [],
            qText: null,
            page: 1,
            pageSize: 10,
            UserId: null,
        }
    }

    componentDidMount() {
        this.getuserlist_atmp()
        this.get_voice_library()
    }

    userinfo = getUser()

    get_voice_library = async () => {
        // params['UserId'] = this.userinfo.UserId
        let params = {}
        params['UserId'] = this.state.UserId
        params['page'] = this.state.page
        params['pageSize'] = this.state.pageSize
        params['qText'] = this.state.qText
        this.setState({
            spinning: true
        })
        await api.GetVoiceLibrary(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    datas: r.datas,
                    Total: r.Total
                })
            }
            this.setState({
                spinning: false
            })
        })
    }

    delete_voice_library = async (key) => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['key'] = key
        values['delete'] = 1
        await api.PostVoiceLibrary({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({}, function () {
                    message.success("语音库删除成功")
                    this.get_voice_library()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }
    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <Space size={2} style={{marginTop: -5}}>
                            <Avatar key={key} style={{marginTop: -5}} size={30} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, fontWeight: 600, color: "#f6f5f8"}}>{value.name}({value.user_id})
                            </Text>
                        </Space>,
                        label1: <Space size={2} style={{marginTop: -5}}>
                            <Avatar key={key} size={20} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, fontWeight: 600, color: "#f6f5f8"}}>{value.name}({value.user_id})
                            </Text>
                        </Space>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }

    render() {
        const columns = [
            {
                title: 'No.',
                width: 30,
                fixed: 'left',
                align: 'center',
                render: (record, text, index) => {
                    return (index + 1)
                }
            },
            {
                title: '创建信息',
                align: 'center',
                width: 350,
                dataIndex: 'age',
                key: 'age',
                render: (text, record, index) => {
                    return <div
                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <Badge.Ribbon
                            placement={"start"}
                            color={"#9254de"}
                            text={<div style={{fontSize: 12, fontWeight: 500, textAlign: "center"}}>
                                {
                                    ([`${record.UserName}(${record.UserId})`]).map((value, index) => {
                                        for (let i = 0; i < this.state.userList.length; i++) {
                                            if (value === this.state.userList[i].value) {
                                                return this.state.userList[i].label
                                            }
                                        }
                                    })
                                }
                            </div>}>
                            <Card bordered={true} style={{
                                fontSize: 12, fontWeight: 500, width: 400, maxHeight: 100, textAlign: "left"
                            }}>
                                <Space direction={"vertical"} size={2}>
                                    <div style={{marginTop: 10, fontSize: 12}}>{text}</div>
                                    <Space direction={"horizontal"} size={5}>
                                        <div style={{
                                            fontSize: 10,
                                            color: "#938f8f"
                                        }}>创建时间 {dayjs(record.sDatasDate).format("YYYY-MM-DD HH:mm:ss")}</div>
                                        <div style={{
                                            fontSize: 10,
                                            color: "#938f8f"
                                        }}>更新时间 {dayjs(record.eDatasDate).format("YYYY-MM-DD HH:mm:ss")}</div>
                                    </Space>
                                </Space>
                            </Card>
                        </Badge.Ribbon>
                    </div>
                }
            },
            {
                title: '语音关键词',
                align: 'center',
                dataIndex: 'VoiceKeyWords',
                key: 'VoiceKeyWords',
                render: (text, record, index) => {
                    return <Tag color={"#52c41a"} style={{fontWeight: 700}}>{text}</Tag>
                }
            },
            {
                title: '语音库描述',
                align: 'center',
                dataIndex: 'VoiceLibraryNotes',
                key: 'VoiceLibraryNotes',
            },
            {
                title: '群聊信息',
                align: 'center',
                dataIndex: 'ChatId',
                key: 'ChatId',
                render: (text, record, index) => {
                    return <Space direction={"vertical"}>
                        <a target={"_blank"} rel={"noreferrer"}
                           href={"https://applink.feishu.cn/client/chat/open?openChatId=" + text}>
                            <Space style={{fontWeight: 700, fontSize: 12}}>跳转至语音收集群<ExportOutlined/></Space>
                        </a>
                        <Space style={{fontWeight: 700, fontSize: 12, color: "gray"}}>{text}</Space>
                    </Space>
                }
            },
            {
                title: '语音操作',
                align: 'center',
                width: 200,
                render: (_, record) => {

                    return <Space size={0} direction={"horizontal"}>
                        <EditVoiceLibrary datas={record} fun={this.get_voice_library}/>
                        <NavLink to={'/VoiceTestDetails/' + record.key}>
                            <Button style={{width: 60, fontWeight: 700, fontSize: 12}}
                                    size={"small"}
                                    disabled={false} type={"link"} icon={<AppstoreOutlined/>}>
                                详情
                            </Button>
                        </NavLink>
                        <Popconfirm
                            title="删除语音库"
                            description={<div style={{fontSize: 12, color: "red"}}>
                                您确定要删除该语音库及语音数据吗？
                            </div>}
                            onConfirm={() => {
                                this.delete_voice_library(record.key)
                            }}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button style={{width: 60, fontWeight: 700, fontSize: 12}}
                                    size={"small"} danger={true}
                                    disabled={false} type={"link"} icon={<DeleteOutlined/>}
                            >
                                删除
                            </Button>
                        </Popconfirm>
                    </Space>
                }
            },
        ];
        return (
            <>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="语音采集"
                            subTitle={"采集真实关键词语音"}
                            onBack={() => window.history.back()}
                            extra={<Space size={0} style={{marginRight: 20}}>
                                <Button icon={<UserOutlined/>} type={'link'}
                                        style={{width: 100, color: '#707272', fontSize: 13, fontWeight: 600}}
                                        onClick={() => {
                                            this.setState({
                                                UserId: this.userinfo.UserId
                                            }, function () {
                                                this.get_voice_library()
                                            })
                                        }}
                                >
                                    我的语音库
                                </Button>
                                <Divider type="vertical"/>
                                <Button icon={<TeamOutlined/>} type={'link'}
                                        style={{width: 100, color: '#707272', fontSize: 13, fontWeight: 600}}
                                        onClick={() => {
                                            this.setState({
                                                UserId: null
                                            }, function () {
                                                this.get_voice_library()
                                            })
                                        }}
                                >
                                    全部语音库
                                </Button>
                                <Divider type="vertical"/>
                                <CreateVoiceLibrary fun={this.get_voice_library}/>
                            </Space>
                            }
                        >
                            <Content/>
                        </PageHeader>
                    </div>
                </Affix>
                <div style={{background: 'rgb(240, 242, 245)', minHeight: '80vh'}}>
                    <Watermark content="公牛Bull">
                        <Spin spinning={this.state.spinning} tip="数据加载中...">
                            <Card style={{minHeight: '80vh', textAlign: "center"}} title={
                                <Space style={{marginTop: 30, marginBottom: 30}}>
                                    <Input style={{width: "20vw"}} placeholder={"可通过语音关键词、语音库描述字段模糊搜索"}
                                           value={this.state.qText}
                                           onChange={(e) => {
                                               this.setState({
                                                   qText: e.target.value
                                               })
                                           }}/>
                                    <Button type={"primary"} style={{fontWeight: 700}}
                                            icon={<SearchOutlined/>}
                                            onClick={() => {
                                                this.get_voice_library()
                                            }
                                            }>查询</Button>
                                    <Button type={"primary"} style={{fontWeight: 700}}
                                            icon={<ClearOutlined/>}
                                            onClick={() => {
                                                this.setState({
                                                    qText: null,
                                                    UserId: null
                                                }, function () {
                                                    this.get_voice_library()
                                                })
                                            }
                                            }>重置</Button>
                                </Space>
                            }>
                                <Table columns={columns} dataSource={this.state.datas} bordered={true}
                                       pagination={{
                                           position: ['bottomRight'],
                                           pageSizeOptions: [10, 20, 30],
                                           responsive: true,
                                           size: "middle",
                                           current: this.state.page,
                                           pageSize: this.state.pageSize,
                                           total: this.state.Total,
                                           showTotal: (total, range) => {
                                               return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                                           },
                                           onShowSizeChange: (current, size) => {
                                               this.setState({
                                                   page: current,
                                                   pageSize: size
                                               }, function () {
                                                   this.get_voice_library()
                                               })
                                           },
                                           onChange: (page, pageSize) => {
                                               this.setState({
                                                   page: page,
                                                   pageSize: pageSize
                                               }, function () {
                                                   this.get_voice_library()
                                               })
                                           }
                                       }}
                                />
                            </Card>
                        </Spin>
                    </Watermark>
                </div>
            </>
        )
    }

}