import React from 'react';
import {
    Button,
    Input,
    message,
    Space,
    Drawer,
    Form,
    Spin, Radio
} from "antd";
import {
    MinusCircleOutlined, PlusOutlined,
    PlusSquareOutlined,
} from "@ant-design/icons";
import {CheckCard} from '@ant-design/pro-components';
import {getUser, Loginout} from "../Users/AuthRouter";
import api from "../../api/ApiConfig";


export default class AddPerformanceTestProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: false,
            spinning: false,
            Env: "Test"
        }
    }

    componentDidMount() {
    }

    CreatePerformanceTestTask = async (values) => {
        await api.CreatePerformanceTestTask({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("性能测试任务创建成功")
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.props.fun()
            this.setState({
                isDrawerOpen: false
            })
        })
    }
    formRef = React.createRef();

    render() {
        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24,
                },
                sm: {
                    span: 4,
                },
            },
            wrapperCol: {
                xs: {
                    span: 24,
                },
                sm: {
                    span: 20,
                },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 20,
                    offset: 4,
                },
            },
        };
        const onFinish = (values) => {
            const userinfo = getUser()
            values['userid'] = userinfo.UserId
            values['username'] = userinfo.username
            values['TestingItems'] = values['TestingItems'].toString()
            this.CreatePerformanceTestTask(values).then(r => {
                this.setState({
                    isDrawerOpen: false
                }, function () {
                    this.formRef.current.resetFields();
                })
            })
        };
        return <div>
            <Button type={'link'} size={"small"} style={{width: 100, color: '#707272'}}
                    icon={<PlusSquareOutlined/>}
                    onClick={() => {
                        this.setState({
                            isDrawerOpen: true
                        });
                    }}
            >
                创建任务
            </Button>
            <Drawer
                title="创建性能测试任务"
                placement={'right'}
                width={"80vh"}
                onClose={() => {
                    this.setState({
                        isDrawerOpen: false
                    })
                }}
                open={this.state.isDrawerOpen}
            >
                <Spin tip="请稍等片刻....." spinning={this.state.spinning}>
                    <div style={{marginTop: 40, marginLeft: 20}}>
                        <div style={{
                            // textAlign: 'center',
                            background: "rgba(0, 0, 0, 0.02)",
                            marginTop: 16,
                            borderRadius: "8px",
                            border: "1px dashed rgb(217, 217, 217)"
                        }}>

                            <Form
                                ref={this.formRef}
                                name="dynamic_form_item"
                                {...formItemLayoutWithOutLabel}
                                onFinish={onFinish}
                                style={{
                                    maxWidth: 600,
                                    marginTop: 40,
                                    marginLeft: 40
                                }}
                            >
                                <Form.Item
                                    {...formItemLayout}
                                    label="任务名称"
                                    name="TaskName"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请填写任务名称",
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{
                                            width: '90%',
                                        }}
                                        placeholder="请填写任务名称"/>
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    label="APP类型"
                                    name="App"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请选择App类型",
                                        },
                                    ]}
                                    initialValue={"1"}
                                >
                                    <CheckCard.Group value={"small"}>
                                        <CheckCard
                                            title={<b style={{marginLeft: 5}}>智家</b>}
                                            avatar={<img style={{
                                                width: 50, height: 50, borderRadius: 10
                                            }}
                                                         src={"https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/%E5%85%AC%E7%89%9B%E6%99%BA%E5%AE%B6log.png"}
                                                         alt="pro-logo"/>}
                                            // description="选项一"
                                            value="1"
                                            style={{width: 140, height: 80}}
                                        />
                                        <CheckCard
                                            title={<b style={{marginLeft: 5}}>沐光</b>}
                                            avatar={<img style={{width: 50, height: 50}}
                                                         src={"https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/%E6%B2%90%E5%85%89log.png"}
                                                         alt="pro-logo"/>}
                                            // description="选项一"
                                            value="2"
                                            style={{width: 140, height: 80}}
                                        />
                                        <CheckCard
                                            title={<b style={{marginLeft: 5}}>调试</b>}
                                            avatar={<img style={{width: 50, height: 50, borderRadius: 10}}
                                                         src={"https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/favicon.ico"}
                                                         alt="pro-logo"/>}
                                            // description="选项一"
                                            value="3"
                                            style={{width: 140, height: 80}}
                                        />
                                    </CheckCard.Group>
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    label="App版本"
                                    name="AppVersion"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请填写App版本信息",
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{
                                            width: '90%',
                                        }}
                                        placeholder="请填写App版本信息"/>
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    label="测试环境"
                                    name="Env"
                                    initialValue={this.state.Env}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Radio.Group buttonStyle="solid"
                                                 onChange={(e) => {
                                                     this.setState({
                                                         Env: e.target.value,
                                                     })
                                                 }}>
                                        <Radio.Button value="Developer">开发环境</Radio.Button>
                                        <Radio.Button value="Test">测试环境</Radio.Button>
                                        <Radio.Button value="Online" disabled={false}>生产环境</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.List
                                    name="TestingItems"
                                    rules={[
                                        {
                                            validator: async (_, names) => {
                                                if (!names || names.length < 1) {
                                                    return Promise.reject(new Error('至少需要一个测试项目'));
                                                }
                                            },
                                        },

                                    ]}
                                    initialValue={[""]}
                                >

                                    {
                                        (fields, {add, remove}, {errors}) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Form.Item
                                                        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                        label={index === 0 ? '测试检点' : ''}
                                                        required={false}
                                                        key={field.key}
                                                    >
                                                        <Form.Item
                                                            {...field}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: "请填写测试检点名称",
                                                                },
                                                            ]}
                                                            noStyle
                                                        >
                                                            <Input
                                                                placeholder="请填写测试检点名称"
                                                                style={{
                                                                    width: '90%',
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        {fields.length > 1 ? (
                                                            <MinusCircleOutlined
                                                                style={{
                                                                    marginLeft: 10,
                                                                }}
                                                                className="dynamic-delete-button"
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}
                                                    </Form.Item>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        style={{
                                                            width: '90%',
                                                        }}
                                                        icon={<PlusOutlined/>}
                                                    >
                                                        添加测试检点
                                                    </Button>
                                                    <Form.ErrorList errors={errors}/>
                                                </Form.Item>
                                            </>
                                        )}
                                </Form.List>
                                <Form.Item
                                    {...formItemLayout}
                                    label="设备信息"
                                    name="TaskNote"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请填写所使用的设备信息",
                                        },
                                    ]}
                                >
                                    <Input.TextArea placeholder={"请填写性能测试使用的设备信息如：（型号、版本、配置..）"}
                                                    style={{width: '90%'}}/>
                                </Form.Item>
                                <Form.Item style={{marginLeft: "35%"}}>
                                    <Button type="primary" htmlType="submit">
                                        确认提交
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Spin>
            </Drawer>
        </div>
    }
}
