import React, {Component} from 'react';
import {Button, Modal, Space, Form, InputNumber, Slider, Mentions, Segmented, Row, Col, Card, message} from "antd";
import {getUser, Loginout} from "../../Users/AuthRouter";
import Text from "antd/es/typography/Text";
import Avatar from "antd/es/avatar/avatar";
import {SettingOutlined} from "@ant-design/icons";
import api from "../../../api/ApiConfig";
import {keys} from "@antv/util";

const {Option} = Mentions;

export class TestCaseBaseConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: props.datas ? props.datas : [],
            isModalOpen: false,
            configDatas: {}
        }
        this.form = React.createRef();
        this.userinfo = getUser()
    }

    componentDidMount() {
        this.gettestcasebaseconfig()
    }

    gettestcasebaseconfig = async (params = {}) => {
        params['userid'] = this.userinfo.UserId
        await api.GetTestCaseBaseConfig(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    configDatas: r['datas']
                })

            }
        })
    }

    posttestcasebaseconfig = async (v) => {
        const userinfo = getUser()
        let values = {}
        values['userid'] = userinfo.UserId
        values['datas'] = JSON.stringify(v);
        if (this.state.configDatas !== []) {
            values['configId'] = this.state.configDatas.id
        }
        await api.PostTestCaseBaseConfig({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("配置更新成功")
                this.setState({
                    isModalOpen: false,
                },function () {
                    this.gettestcasebaseconfig()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const handleOk = () => {
            this.setState({
                isModalOpen: false,
            }, function () {
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false,
            }, function () {
            })
        };
        const onFinish = (values) => {
            console.log("用例基数配置完成", values)
            this.posttestcasebaseconfig(values)
        }
        return <div>
            <Button type={'link'} style={{width: 100, color: '#707272'}} icon={<SettingOutlined/>}
                    onClick={() => {
                        this.setState({isModalOpen: true})
                    }
                    }>
                用例基数
            </Button>
            <Modal title="配置用例基数" open={this.state.isModalOpen}
                   footer={null} style={{minWidth: 800}}
                   onCancel={handleCancel} maskClosable={false}>
                <div style={{marginTop: 40, marginLeft: 20}}>
                    <div style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 16,
                        marginBottom: 40,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 700,
                                marginTop: 10,
                                marginLeft: 15
                            }}
                        >
                            <Card title={"接口自动化用例"} bordered={false}>
                                <Row>
                                    <Col span={8}>
                                        <Form.Item
                                            name="Interface_GNZJ"
                                            label="公牛智家"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.Interface_GNZJ : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="Interface_GNMG"
                                            label="公牛沐光"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.Interface_GNMG : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="Interface_GNEJ"
                                            label="公牛 E家"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.Interface_GNEJ : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="Interface_GNPT"
                                            label="公牛平台"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.Interface_GNPT : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="Interface_OTHER"
                                            label="其他用例"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.Interface_OTHER : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="Interface_PUBLIC"
                                            label="公共用例"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.Interface_PUBLIC : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                            <Card title={"App自动化用例"} bordered={false} style={{marginTop: 10}}>
                                <Row>
                                    <Col span={8}>
                                        <Form.Item
                                            name="AppiumTest_GNZJ"
                                            label="公牛智家"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.AppiumTest_GNZJ : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="AppiumTest_GNMG"
                                            label="公牛沐光"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.AppiumTest_GNMG : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="AppiumTest_GNEJ"
                                            label="公牛 E家"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.AppiumTest_GNEJ : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="AppiumTest_OTHER"
                                            label="其他用例"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.AppiumTest_OTHER : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="AppiumTest_PUBLIC"
                                            label="公共用例"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.AppiumTest_PUBLIC : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                            <Card title={"设备自动化用例"} bordered={false} style={{marginTop: 10}}>
                                <Row>
                                    <Col span={8}>
                                        <Form.Item
                                            name="Devices_GNZJ"
                                            label="公牛智家"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.Devices_GNZJ : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="Devices_GNMG"
                                            label="公牛沐光"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.Devices_GNMG : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="Devices_GNEJ"
                                            label="公牛 E家"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.Devices_GNEJ : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="Devices_OTHER"
                                            label="其他用例"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.Devices_OTHER : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="Devices_PUBLIC"
                                            label="公共用例"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.Devices_PUBLIC : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                            <Card title={"Web自动化用例"} bordered={false} style={{marginTop: 10}}>
                                <Row>
                                    <Col span={8}>
                                        <Form.Item
                                            name="SeleniumTest_GXYPT"
                                            label="光效云平台"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.SeleniumTest_GXYPT : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="SeleniumTest_OTHER"
                                            label="其他用例"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.SeleniumTest_OTHER : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="SeleniumTest_PUBLIC"
                                            label="公共用例"
                                            initialValue={Object.keys(this.state.configDatas).length > 0 ? this.state.configDatas.SeleniumTest_PUBLIC : 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入用例数量",
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                            <Form.Item style={{marginTop: 30, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit">
                                    确认修改
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    }
}