import React, {Component} from 'react'
import {
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    message, Popconfirm,
    Progress, Radio,
    Result,
    Row,
    Space,
    Statistic,
    Tag,
    Tooltip
} from "antd";
import {
    AndroidOutlined,
    AppleOutlined,
    CheckOutlined, FileProtectOutlined,
    FileTextOutlined,
    HarmonyOSOutlined, QuestionCircleOutlined,
    RocketOutlined
} from "@ant-design/icons";
import api from "../../../api/ApiConfig";
import {getUser, Loginout} from "../../Users/AuthRouter";
import Input from "antd/es/input/Input";

export class Step4 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: props.datas,
            fun: props.fun,
            projectTasksDatas: {},
            BuildsDatas2: {},
            TestRes2: {},
            BuildsDatas1: {},
            TestRes1: {},
            BuildsDatas3: {},
            TestRes4: {},
            taskId: props.taskId,
            ManualValue: 1,
            ManualText: "无",
        }
    }


    get_app_auto_test_build_info = async (AppPlatformType) => {
        let params = {}
        params['AppProjectTaskId'] = this.state.datas.ProjectTasksAppId
        if (AppPlatformType === 1) {
            params['JenkinsTaskNumber'] = this.state.datas.IosAutoTestJenkinsTaskNumber
        }
        if (AppPlatformType === 2) {
            params['JenkinsTaskNumber'] = this.state.datas.AndroidAutoTestJenkinsTaskNumber
        }
        if (AppPlatformType === 3) {
            params['JenkinsTaskNumber'] = this.state.datas.HarmonyOSAutoTestJenkinsTaskNumber
        }
        params['AppPlatformType'] = AppPlatformType
        await api.GetAppAutoTestBuildInfo(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (AppPlatformType === 1) {
                    this.setState({
                        BuildsDatas1: r['datas'],
                        TestRes1: Object.keys(r['datas']).length > 0 ? eval("(" + r['datas'].TestResult + ")") : '{}'
                    }, function () {
                    })
                }
                if (AppPlatformType === 2) {
                    this.setState({
                        BuildsDatas2: r['datas'],
                        TestRes2: Object.keys(r['datas']).length > 0 ? eval("(" + r['datas'].TestResult + ")") : '{}'
                    }, function () {
                    })
                }
                if (AppPlatformType === 3) {
                    this.setState({
                        BuildsDatas3: r['datas'],
                        TestRes3: Object.keys(r['datas']).length > 0 ? eval("(" + r['datas'].TestResult + ")") : '{}'
                    }, function () {
                    })
                }
            }
        })
    }
    userinfo = getUser()

    execute_again = async (AppPlatformType) => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['key'] = this.state.taskId
        values['AppPlatformType'] = AppPlatformType
        await api.ExecuteAgain({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    message.success("任务下发成功～")
                    this.state.fun()
                    this.get_app_auto_test_build_info(AppPlatformType)
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    set_app_autotest_result = async (AppPlatformType) => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['key'] = this.state.taskId
        values['AppPlatformType'] = AppPlatformType
        values['ManualValue'] = this.state.ManualValue
        values['ManualText'] = this.state.ManualText
        await api.SetAppAutoTestResult({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    message.success("任务下发成功～")
                    this.state.fun()
                    this.get_app_auto_test_build_info(AppPlatformType)
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    componentDidMount() {
        // this.get_app_auto_test_build_info(1)
        this.get_app_auto_test_build_info(2)
        // this.get_app_auto_test_build_info(3)
        this.timer = setInterval(() => {
            // 设置定时器实时获取自动化测试任务执行详情
            if (
                (this.state.datas.IosInstallResult === 1 || this.state.datas.IosInstallResult === 2) ||
                (this.state.datas.AndroidInstallResult === 1 || this.state.datas.AndroidInstallResult === 2) ||
                (this.state.datas.HarmonyOSInstallResult === 1 || this.state.datas.HarmonyOSInstallResult === 2)
            ) {
                this.state.fun()
            }
            if (this.state.datas.IosAutoTest === 1 || this.state.datas.IosAutoTest === 2) {
                this.get_app_auto_test_build_info(1)
                this.state.fun()
            }
            if (this.state.datas.AndroidAutoTest === 1 || this.state.datas.AndroidAutoTest === 2) {
                this.get_app_auto_test_build_info(2)
                this.state.fun()
            }
            if (this.state.datas.HarmonyOSAutoTest === 1 || this.state.datas.HarmonyOSAutoTest === 2) {
                this.get_app_auto_test_build_info(3)
                this.state.fun()
            }
        }, 2000);
    }

    componentWillUnmount() {
        // 在组件即将被销毁时执行清理操作
        clearInterval(this.timer);
    }


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: Object.keys(this.props.datas).length > 0 ? this.props.datas : {}
            })
        }
    }

    render() {
        return (
            <div style={{margin: 20}}>
                <Descriptions
                    title={<Space size={5}>
                        <svg d="1715914344713" className="icon" viewBox="0 0 1024 1024" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" p-id="4161" width="25" height="25">
                            <path
                                d="M420.693333 85.333333C353.28 85.333333 298.666667 139.946667 298.666667 207.36v71.68h183.04c16.64 0 30.293333 24.32 30.293333 40.96H207.36C139.946667 320 85.333333 374.613333 85.333333 442.026667v161.322666c0 67.413333 54.613333 122.026667 122.026667 122.026667h50.346667v-114.346667c0-67.413333 54.186667-122.026667 121.6-122.026666h224c67.413333 0 122.026667-54.229333 122.026666-121.642667V207.36C725.333333 139.946667 670.72 85.333333 603.306667 85.333333z m-30.72 68.693334c17.066667 0 30.72 5.12 30.72 30.293333s-13.653333 38.016-30.72 38.016c-16.64 0-30.293333-12.8-30.293333-37.973333s13.653333-30.336 30.293333-30.336z"
                                fill="#3C78AA" p-id="4162"/>
                            <path
                                d="M766.250667 298.666667v114.346666a121.6 121.6 0 0 1-121.6 121.984H420.693333A121.6 121.6 0 0 0 298.666667 656.597333v160a122.026667 122.026667 0 0 0 122.026666 122.026667h182.613334A122.026667 122.026667 0 0 0 725.333333 816.64v-71.68h-183.082666c-16.64 0-30.250667-24.32-30.250667-40.96h304.64A122.026667 122.026667 0 0 0 938.666667 581.973333v-161.28a122.026667 122.026667 0 0 0-122.026667-122.026666zM354.986667 491.221333l-0.170667 0.170667c0.512-0.085333 1.066667-0.042667 1.621333-0.170667z m279.04 310.442667c16.64 0 30.293333 12.8 30.293333 37.973333a30.293333 30.293333 0 0 1-30.293333 30.293334c-17.066667 0-30.72-5.12-30.72-30.293334s13.653333-37.973333 30.72-37.973333z"
                                fill="#FDD835" p-id="4163"/>
                        </svg>
                        <div style={{marginTop: -6}}>测试信息</div>
                    </Space>}
                    style={{display: "block", width: "70%", margin: "0 auto"}}
                />
                <Row gutter={16}>
                    <Col span={8}>
                        <Card title={
                            <div style={{textAlign: "left"}}>
                                <Space>
                                    <AppleOutlined/>IOS 测试进度
                                </Space>
                            </div>
                        }
                              bordered={true}
                              style={{textAlign: "left", fontSize: 12, fontWeight: 600}}
                        >
                            {this.state.datas.IosAutoTest !== 3 ?
                                <div/> :
                                <Result
                                    style={{height: 325}}
                                    title="UNINVOLVED"
                                    subTitle="暂时未接入IOS自动化测试待支持后开放～"
                                />
                            }
                        </Card>
                    </Col>
                    <Col span={8}>

                        <Card title={
                            <div style={{textAlign: "left"}}>
                                <Space>
                                    <AndroidOutlined/>Android 测试进度
                                </Space>
                            </div>}
                              extra={
                                  <div>
                                      <Button type={"link"} style={{fontSize: 12, fontWeight: 600, width: 80}}
                                              icon={<RocketOutlined/>}
                                              disabled={(this.state.datas.AndroidAutoTest === 1 || this.state.datas.AndroidAutoTest === 2)}
                                              onClick={() => {
                                                  this.execute_again(2)
                                              }}>
                                          再次执行
                                      </Button>
                                      <Popconfirm
                                          placement={"top"}
                                          title="App版本提测结果变更"
                                          // disabled={(this.state.datas.AndroidAutoTest === 1 || this.state.datas.AndroidAutoTest === 2)}
                                          description={
                                              <Space direction={"vertical"} size={10} style={{marginBottom: 30}}>
                                                  <Space direction={"vertical"} style={{fontSize: 12}}>
                                                      <div>确定要手动干预版本自动化测试结果吗?</div>
                                                      <div style={{color: "red", fontSize: 11}}>
                                                          Ps:此功能仅用于应对突发异常且提测结果中会显示此状态
                                                      </div>
                                                  </Space>
                                                  <Radio.Group onChange={(e) => {
                                                      this.setState({
                                                          ManualValue: e.target.value,
                                                      })
                                                  }} defaultValue={1}>
                                                      <Space style={{fontSize: 10}} size={1}>
                                                          <Radio value={1}>环境问题</Radio>
                                                          <Radio value={2}>服务依赖</Radio>
                                                          <Radio value={3}>用例问题</Radio>
                                                          <Radio value={4}>新增需求</Radio>
                                                          <Radio value={5}>其他问题</Radio>
                                                      </Space>
                                                  </Radio.Group>
                                                  <Space>问题描述<QuestionCircleOutlined/></Space>
                                                  <Input.TextArea size={"small"} maxLength={40} showCount rows={2}
                                                                  placeholder="请输入问题描述"
                                                                  onChange={(e) => {
                                                                      this.setState({
                                                                          ManualText: e.target.value
                                                                      })
                                                                  }}
                                                  />
                                              </Space>
                                          }
                                          icon={
                                              <QuestionCircleOutlined
                                                  style={{
                                                      color: 'red',
                                                  }}
                                              />
                                          }
                                          onConfirm={() => {
                                              this.set_app_autotest_result(2)
                                          }}
                                      >
                                          <Button type={"link"} style={{fontSize: 12, fontWeight: 600, width: 80}}
                                                  icon={<CheckOutlined/>}>
                                              手动通过
                                          </Button>
                                      </Popconfirm>
                                  </div>}
                              bordered={true}
                              style={{textAlign: "left", fontSize: 12, fontWeight: 600}}
                        >
                            {this.state.datas.AndroidInstallResult === 1 ?
                                <Result
                                    style={{height: 325}}
                                    title="WAITING"
                                    status="warning"
                                    subTitle="版本提测任务等待执行机响应中..."
                                /> : <Row gutter={[0, 10]} style={{margin: 10}}>
                                    <Col span={24}>
                                        <Row gutter={[5, 5]}>
                                            <Col span={8}>
                                                <Card>
                                                    <Statistic
                                                        style={{width: 50, height: 30, marginTop: -10}}
                                                        title={
                                                            <div style={{fontSize: 12, fontWeight: 900}}>
                                                                成功
                                                            </div>}
                                                        value={this.state.TestRes2.passed}
                                                        valueStyle={{fontSize: 13, fontWeight: 700, marginLeft: 10}}
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card>
                                                    <Statistic
                                                        style={{width: 50, height: 30, marginTop: -10}}
                                                        title={
                                                            <div style={{fontSize: 12, fontWeight: 900}}>
                                                                失败
                                                            </div>}
                                                        value={this.state.TestRes2.failed}
                                                        valueStyle={{fontSize: 13, fontWeight: 700, marginLeft: 10}}
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card>
                                                    <Statistic
                                                        style={{width: 50, height: 30, marginTop: -10}}
                                                        title={
                                                            <div style={{fontSize: 12, fontWeight: 900}}>
                                                                错误
                                                            </div>}
                                                        value={this.state.TestRes2.error}
                                                        valueStyle={{fontSize: 13, fontWeight: 700, marginLeft: 10}}
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card>
                                                    <Statistic
                                                        style={{width: 50, height: 30, marginTop: -10}}
                                                        title={
                                                            <div style={{fontSize: 12, fontWeight: 900}}>
                                                                重试
                                                            </div>}
                                                        value={this.state.TestRes2.rerun}
                                                        valueStyle={{fontSize: 13, fontWeight: 700, marginLeft: 10}}
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card>
                                                    <Statistic
                                                        style={{width: 50, height: 30, marginTop: -10}}
                                                        title={
                                                            <div style={{fontSize: 12, fontWeight: 900}}>
                                                                跳过
                                                            </div>}
                                                        value={this.state.TestRes2.skipped}
                                                        valueStyle={{fontSize: 13, fontWeight: 700, marginLeft: 10}}
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card>
                                                    <Statistic
                                                        style={{width: 50, height: 30, marginTop: -10}}
                                                        title={
                                                            <div style={{fontSize: 12, fontWeight: 900}}>
                                                                总数
                                                            </div>}
                                                        value={this.state.TestRes2.total}
                                                        valueStyle={{fontSize: 13, fontWeight: 700, marginLeft: 10}}
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}/>
                                    <Col span={5}>安装结果:</Col>
                                    <Col span={19}>
                                        <Tooltip title={this.state.datas.AndroidInstallMsg}>
                                            <Tag style={{height: 20, fontSize: 12}}
                                                 color={this.state.datas.AndroidInstallResult === 0 ? "green-inverse" :
                                                     this.state.datas.AndroidInstallResult === -1 ? "red-inverse" :
                                                         "blue-inverse"}>
                                                {
                                                    this.state.datas.AndroidInstallResult === 0 ? "安装成功" :
                                                        this.state.datas.AndroidInstallResult === -1 ? "安装失败" :
                                                            this.state.datas.AndroidInstallResult === 2 ? "正在安装" :
                                                                this.state.datas.AndroidInstallResult === 1 ? "等待安装" :
                                                                    this.state.datas.AndroidInstallResult === 3 ? "暂无" :
                                                                        "未知状态"
                                                }
                                            </Tag>
                                        </Tooltip>
                                    </Col>
                                    <Col span={5}>测试任务:</Col>
                                    <Col span={19}>
                                        <a style={{fontSize: 11, marginTop: 2}} href={"/TaskList/"
                                        + this.state.BuildsDatas2.TestProjectId + "/"
                                        + this.state.BuildsDatas2.TestProjectName}>
                                            {this.state.BuildsDatas2.TestProjectName}({this.state.BuildsDatas2.TestTaskName})
                                        </a>
                                    </Col>
                                    <Col span={5}>测试进度:</Col>
                                    <Col span={19}>
                                        <div style={{height: 20, marginTop: 0}}>
                                            <Progress percent={this.state.BuildsDatas2.TaskProgress} status={
                                                this.state.BuildsDatas2.JenkinsTaskStatus === "SUCCESS" ? "success" :
                                                    this.state.BuildsDatas2.JenkinsTaskStatus === "RUNNING" ? "active" :
                                                        "exception"
                                            } size={"small"}/>
                                        </div>
                                    </Col>
                                    <Col span={5}>测试报告:</Col>
                                    <Col span={19}>
                                        <Space style={{marginLeft: -15, marginTop: -10}}>
                                            <Button type={"link"} icon={<FileProtectOutlined/>}
                                                    href={this.state.BuildsDatas2.TaskReportPath}
                                                    target={"_blank"}
                                                    style={{fontSize: 12, fontWeight: 600, width: 60}}>
                                                测试报告</Button>
                                            <Divider type="vertical"/>
                                            <Button type={"link"} icon={<FileTextOutlined/>}
                                                    target={"_blank"}
                                                    href={this.state.BuildsDatas2.TaskConsoleLog}
                                                    style={{fontSize: 12, fontWeight: 600, width: 60}}>
                                                测试日志</Button>
                                        </Space>
                                    </Col>
                                    <Col span={5}>测试结果:</Col>
                                    <Col span={19}>
                                        <Space>
                                            <Tag style={{height: 20, fontSize: 12}}
                                                 color={this.state.BuildsDatas2.JenkinsTaskStatus === "SUCCESS" ? "green-inverse" :
                                                     this.state.BuildsDatas2.JenkinsTaskStatus === "RUNNING" ? "geekblue-inverse" :
                                                         this.state.BuildsDatas2.JenkinsTaskStatus === "WAITING" ? "yellow-inverse" :
                                                             "red-inverse"}>
                                                {this.state.BuildsDatas2.JenkinsTaskStatus}
                                            </Tag>
                                            <Tag style={{height: 20, fontSize: 12}}
                                                 color={"geekblue-inverse"}>{this.state.datas.AndroidAutoTestJenkinsTaskNumber}
                                            </Tag>
                                        </Space>
                                    </Col>
                                </Row>}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title={<div style={{textAlign: "left"}}>
                            <Space>
                                <HarmonyOSOutlined/>HarmonyOS 测试进度
                            </Space>
                        </div>}
                              bordered={true}
                              style={{textAlign: "left", fontSize: 12, fontWeight: 600}}>
                            {this.state.datas.IosAutoTest !== 3 ? <div/> :
                                <Result
                                    style={{height: 325}}
                                    title="UNINVOLVED"
                                    subTitle="暂时未接入HarmonyOS自动化测试待支持后开放～"
                                />}
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
