import React, {Component} from 'react'
import {PageHeader} from '@ant-design/pro-layout';
import {Affix, Button, Card, Divider, FloatButton, Steps, Space, Tooltip, Watermark} from 'antd';
import {RedoOutlined} from "@ant-design/icons";
import {Step1} from "./Step1";
import api from "../../../api/ApiConfig";
import {Loginout} from "../../Users/AuthRouter";
import {Step3} from "./Step3";
import {Step4} from "./Step4";
import {Step2} from "./Step2";
import Avatar from "antd/es/avatar/avatar";
import Text from "antd/es/typography/Text";
import {Content} from "antd/es/layout/layout";
import {Step5} from "./Step5";

export class AppProjectDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            userList: [],
            datas: {},
        }

    }

    componentDidMount() {
        this.getuserlist_atmp()
        this.get_project_tasks_app_status()
    }

    get_project_tasks_app_status = async (params = {}) => {
        params['key'] = this.props.match.params.id
        await api.ProjectTasksAppStatus(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                let current = 1
                let Step2_Status = "wait"
                let Step3_Status = "wait"
                let Step4_Status = "wait"
                if (r.datas.Approval === 2) {
                    current = 2
                    Step2_Status = "finish"
                }
                if (r.datas.Approval === 3) {
                    Step2_Status = "error"
                }
                if (r.datas.IosBuildResult === -1 || r.datas.AndroidBuildResult === -1 || r.datas.HarmonyOSBuildResult === -1) {
                    Step3_Status = "error"
                }
                if ((r.datas.IosBuildResult === 0 || r.datas.IosBuildResult === 3) && (r.datas.HarmonyOSBuildResult === 0 || r.datas.HarmonyOSBuildResult === 3) && (r.datas.AndroidBuildResult === 0 || r.datas.AndroidBuildResult === 3)) {
                    current = 3
                    Step3_Status = "finish"
                    if ((r.datas.IosAutoTest === 4 || r.datas.IosAutoTest === 0 || r.datas.HarmonyOSBuildResult === 3) && (r.datas.HarmonyOSAutoTest === 4 || r.datas.HarmonyOSAutoTest === 0 || r.datas.HarmonyOSAutoTest === 3) && (r.datas.AndroidAutoTest === 4 || r.datas.AndroidAutoTest === 0 || r.datas.AndroidAutoTest === 3)) {
                        current = 4
                        Step4_Status = "finish"
                    }
                    if (r.datas.IosAutoTest === -1 || r.datas.HarmonyOSAutoTest === -1 || r.datas.AndroidAutoTest === -1) {
                        Step4_Status = "error"
                    }
                }
                this.setState({
                    datas: r.datas,
                    current: current,
                    Step2_Status: Step2_Status,
                    Step3_Status: Step3_Status,
                    Step4_Status: Step4_Status
                }, function () {
                    console.log(this.state.datas)
                })
            }
        })
    }

    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <Space size={5}>
                            <Avatar key={key} size={30} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, fontWeight: 600}}>{value.name}({value.user_id})</Text>
                        </Space>,
                        label1: <div>
                            <Tooltip title={<div>{value.name}({value.user_id})</div>} color={"#108ee9"} key={key}>
                                <Avatar key={key} size={30} src={value.avatar_url}/>
                            </Tooltip>
                        </div>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }


    render() {
        const steps = [
            {
                title: <div style={{fontSize: 13, fontWeight: 700}}>No.1 提测信息</div>,
                status: 'finish',
                content: <Step1 taskId={this.props.match.params.id}/>
            },
            {
                title: <div style={{fontSize: 13, fontWeight: 700}}>No.2 审批信息</div>,
                status: this.state.Step2_Status,
                content: <Step2 userList={this.state.userList} taskId={this.props.match.params.id}/>
            },
            {
                title: <div style={{fontSize: 13, fontWeight: 700}}>No.3 Jenkins打包</div>,
                status: this.state.Step3_Status,
                content: <Step3 fun={this.get_project_tasks_app_status} datas={this.state.datas}/>
            },
            {
                title: <div style={{fontSize: 13, fontWeight: 700}}>No.4 自动化测试</div>,
                status: this.state.Step4_Status,
                content: <Step4 fun={this.get_project_tasks_app_status} datas={this.state.datas}
                                taskId={this.props.match.params.id}/>
            },
            {
                title: <div style={{fontSize: 13, fontWeight: 700}}>No.5 提测结果</div>,
                status: this.state.Step4_Status,
                content: <Step5 taskId={this.props.match.params.id} datas={this.state.datas}/>
            },
        ]
        return (<>
                <Affix offsetTop={60}>
                    <div style={{
                        background: '#ffffff',
                        textAlign: 'center',
                    }}>
                        <PageHeader
                            className="site-page-header-responsive"
                            title="App版本提测"
                            subTitle={"App版本提测"}
                            onBack={() => window.history.back()}
                            extra={
                                <Space size={[0]}>
                                    <Button type={'link'} icon={<RedoOutlined/>}
                                            style={{width: 100, color: '#707272'}}
                                            onClick={() => {
                                                this.get_project_tasks_app_status()
                                            }}
                                    >
                                        刷新数据
                                    </Button>
                                    <Divider type="vertical"/>
                                </Space>
                            }
                        >
                            <Content/>
                        </PageHeader>
                    </div>
                </Affix>
                <FloatButton.BackTop visibilityHeight={100}/>
                <Watermark content="公牛Bull">
                    <div style={{minHeight: '80vh', background: '#ffffff'}}>
                        <Card>
                            <Steps
                                type="navigation"
                                className="site-navigation-steps"
                                style={{marginTop: 20}}
                                size="small"
                                current={this.state.current}
                                onChange={(value) => {
                                    this.setState({
                                        current: value
                                    })
                                }}
                                items={steps}
                            />
                            <div style={{
                                minHeight: 500,
                                marginTop: 30,
                                paddingTop: 20,
                                textAlign: 'center',
                                backgroundColor: '#fafafa',
                                border: '1px dashed #e9e9e9',
                                borderRadius: 2,
                            }}>
                                {steps[this.state.current].content}
                            </div>
                        </Card>
                    </div>
                </Watermark>
            </>
        );
    }
}
