import React, {Component} from "react";
import {
    Affix,
    Badge, Button,
    Card, Divider, Input, Mentions, message, Popconfirm, Select, Space,
    Spin, Table, Tag, Tooltip, Watermark,
} from "antd";
import {getUser, Loginout} from "../../Users/AuthRouter";
import {CreatePConfig} from "../CreatePConfig";
import api from "../../../api/ApiConfig";
import {PageHeader} from "@ant-design/pro-layout";
import {
    ClearOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    RedoOutlined,
    SearchOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import Avatar from "antd/es/avatar/avatar";
import {CreateFirmwareConfig} from "./CreateFirmwareConfig";
import Text from "antd/es/typography/Text";
import {UpdateFirmwareConfig} from "./UpdateFirmwareConfig";

export class FirmwareProjectConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: [],
            spinning: false,
            FirmwareType: -1,
            FirmwareName: null,
            pageSize: 10,
            page: 1,
            Total: 0,
            userList: [],
            product_list: []
        }
    }

    componentDidMount() {
        this.get_firmware_project_task_config()
        this.getuserlist_atmp()
        this.getproducts_ypt({env: "Test"})
    }

    getproducts_ypt = async (params = {}) => {
        await api.GetProducts_YPT(params).then(r => {
            if (typeof (r.msgcode) == 'number') {
                if (r.msgcode === 20008) {
                    Loginout()
                    this.props.history.push('/login')
                    window.location.reload()
                } else {
                    this.setState({
                        product_list: r.datas.result
                    })
                }
            }
        })
    }
    userinfo = getUser()

    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <div>
                            <Tooltip title={<div>{value.name}({value.user_id})</div>} color={"#108ee9"} key={key}>
                                <Avatar key={key} size={30} src={value.avatar_url}/>
                            </Tooltip>
                        </div>,
                        label1: <Space size={5}>
                            <Avatar key={key} size={30} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, fontWeight: 600}}>{value.name}({value.user_id})</Text>
                        </Space>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }

    del_firmware_project_task_config = async (key) => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['key'] = key
        console.log("删除提测任务配置", values)
        await api.DelFirmwareProjectTaskConfig({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    message.success("删除提测任务配置成功")
                    this.get_firmware_project_task_config()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    get_firmware_project_task_config = async (params = {}) => {
        this.setState({
            spinning: true
        })
        if (this.state.FirmwareType !== -1) {
            params['FirmwareType'] = this.state.FirmwareType
        }
        if (this.state.FirmwareName !== null) {
            params['FirmwareName'] = this.state.FirmwareName
        }
        params['pageSize'] = this.state.pageSize
        params['page'] = this.state.page
        await api.GetFirmwareProjectTaskConfig(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    datas: r.datas,
                    Total: r.Total
                })
            }
            setTimeout(() => {
                this.setState({
                    spinning: false
                })
            }, 100)

        })
    }

    render() {
        const ProjectConfigColumns = [
            {
                title: '编号',
                fixed: "left",
                width: 100,
                align: 'center',
                render: (_, record, index) => {
                    return <div>{index + 1}</div>
                }
            },
            {
                title: '固件名称',
                align: 'center',
                width: 350,
                dataIndex: 'FirmwareName',
                render: (text, record, index) => {
                    return <div style={{fontSize: 12, fontWeight: 600,textAlign:"left"}}>
                        {text}
                    </div>
                }
            },
            {
                title: '固件类型',
                width: 140,
                align: 'center',
                dataIndex: 'FirmwareType',
                render: (text, record, index) => {
                    return <div style={{textAlign: "center", fontSize: 12, fontWeight: 600}}>
                        <Space direction={"vertical"} size={0}>
                            {text === 1 ? <Tag color={"#007AFF"} style={{width: 100, textAlign: "center"}}>网关设备</Tag> :
                                text === 2 ?
                                    <Tag color={"#406244"} style={{width: 100, textAlign: "center"}}>子设备</Tag> : null
                            }
                        </Space>
                    </div>
                }
            },
            {
                title: '提测环境',
                align: 'center',
                width: 120,
                dataIndex: 'FirmwareEnvironment',
                render: (text, record, index) => {
                    return <div style={{textAlign: "center", fontWeight: 700, fontSize: 12}}>
                        <Tag
                            color={
                                text === 1 ? "#5b8c00" :
                                    text === 1 ? "#8d062c" :
                                        text === 2 ? "#d05715" : "#9254de"
                            }
                            style={{
                                marginRight: 5,
                                textAlign: "center",
                                fontSize: 12,
                                fontWeight: 700,
                                width: 70,
                            }}
                        >
                            {text === 1 ? "测试环境" :
                                text === 2 ? "生产环境" :
                                    text === 3 ? "预发布" : "未知环境"}
                        </Tag></div>
                }
            },
            {
                title: '测试项目',
                align: 'center',
                dataIndex: 'AutoTestProjectInfo',
                render: (text, record, index) => {
                    return <div style={{textAlign: "center", fontWeight: 700, fontSize: 12}}>{text.split("@")[0]}</div>
                }
            },
            {
                title: '测试任务',
                align: 'center',
                dataIndex: 'AutoTestTaskInfo',
                render: (text, record, index) => {
                    return <div style={{textAlign: "center", fontWeight: 700, fontSize: 12}}>{text.split("@")[0]}</div>
                }
            },
            {
                title: '可提交审批人',
                align: 'center',
                dataIndex: 'InitiateApproverList',
                render: (text, record, index) => {
                    let users_l = []
                    try {
                        users_l = JSON.parse(text)
                    } catch (e) {
                        users_l = []
                    }
                    return <Avatar.Group
                        maxCount={10}
                        size={"default"}
                        maxStyle={{
                            color: '#6d45c2',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {users_l.map((value, index) => {
                            for (let i = 0; i < this.state.userList.length; i++) {
                                if (value === this.state.userList[i].value) {
                                    return this.state.userList[i].label
                                }
                            }
                        })}
                    </Avatar.Group>
                    // <div style={{textAlign: "left", fontWeight: 700, fontSize: 12}}>{text}</div>
                }
            },
            {
                title: '嵌入式负责人',
                align: 'center',
                dataIndex: 'ApproverList1',
                render: (text, record, index) => {
                    let users_l = []
                    try {
                        users_l = JSON.parse(text)

                    } catch (e) {
                        users_l = []
                    }
                    return <Avatar.Group
                        maxCount={10}
                        size={"default"}
                        maxStyle={{
                            color: '#6d45c2',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {users_l.map((value, index) => {
                            for (let i = 0; i < this.state.userList.length; i++) {
                                if (value === this.state.userList[i].value) {
                                    return this.state.userList[i].label
                                }
                            }
                        })}
                    </Avatar.Group>
                    // <div style={{textAlign: "left", fontWeight: 700, fontSize: 12}}>{text}</div>
                }
            },
            {
                title: '产品/项目/组长',
                align: 'center',
                dataIndex: 'ApproverList2',
                render: (text, record, index) => {
                    let users_l = []
                    try {
                        users_l = JSON.parse(text)

                    } catch (e) {
                        users_l = []
                    }
                    return <Avatar.Group
                        maxCount={10}
                        size={"default"}
                        maxStyle={{
                            color: '#6d45c2',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {users_l.map((value, index) => {
                            for (let i = 0; i < this.state.userList.length; i++) {
                                if (value === this.state.userList[i].value) {
                                    return this.state.userList[i].label
                                }
                            }
                        })}
                    </Avatar.Group>
                    // <div style={{textAlign: "left", fontWeight: 700, fontSize: 12}}>{text}</div>
                }
            },
            {
                title: '测试人员',
                align: 'center',
                dataIndex: 'ApproverList3',
                render: (text, record, index) => {
                    let users_l = []
                    try {
                        users_l = JSON.parse(text)

                    } catch (e) {
                        users_l = []
                    }
                    return <Avatar.Group
                        maxCount={10}
                        size={"default"}
                        maxStyle={{
                            color: '#6d45c2',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {users_l.map((value, index) => {
                            for (let i = 0; i < this.state.userList.length; i++) {
                                if (value === this.state.userList[i].value) {
                                    return this.state.userList[i].label
                                }
                            }
                        })}
                    </Avatar.Group>
                    // <div style={{textAlign: "left", fontWeight: 700, fontSize: 12}}>{text}</div>
                }
            },
            {
                title: '配置创建人',
                align: 'center',
                render: (text, record, index) => {
                    let value = record.cUserName + "(" + record.cUserId + ")"
                    for (let i = 0; i < this.state.userList.length; i++) {
                        if (value === this.state.userList[i].value) {
                            return this.state.userList[i].label1
                        }
                    }
                }
            },
            {
                title: '配置更新人',
                align: 'center',
                render: (text, record, index) => {
                    let value = record.uUserName + "(" + record.uUserId + ")"
                    for (let i = 0; i < this.state.userList.length; i++) {
                        if (value === this.state.userList[i].value) {
                            return this.state.userList[i].label1
                        }
                    }
                }
            },
            {
                title: '创建、更新时间',
                align: 'center',
                dataIndex: 'uDatasDate',
                render: (text, record, index) => {
                    return <Space direction={"vertical"} size={1}>
                        <div style={{
                            textAlign: "center",
                            fontWeight: 500,
                            fontSize: 11,
                            color: "#707272"
                        }}>{dayjs(record.cDatasDate).format("YYYY年MM月DD日 HH:mm:ss")}</div>
                        <div style={{
                            textAlign: "center",
                            fontWeight: 500,
                            fontSize: 11,
                            color: "#707272"
                        }}>{dayjs(record.uDatasDate).format("YYYY年MM月DD日 HH:mm:ss")}</div>
                    </Space>

                }
            },
            {
                fixed: "right",
                title: '操作',
                align: 'center',
                render: (_, record) => {
                    return <Space size={0}>
                        <UpdateFirmwareConfig datas={record} fun1={this.get_firmware_project_task_config}/>
                        <Divider type={"vertical"}/>
                        <Popconfirm
                            title="删除任务配置"
                            description="确定要删除这个提测任务配置吗?"
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                            onConfirm={() => {
                                this.del_firmware_project_task_config(record.key)
                            }}
                        >
                            <Button size={"small"} disabled={false} style={{width: 80}}
                                    type={"link"} danger>
                                <Space size={5} style={{fontSize: 12, fontWeight: 600}}>
                                    <DeleteOutlined/> 删除配置
                                </Space>
                            </Button>
                        </Popconfirm>
                    </Space>
                }
            }
            ,
        ];
        return <>
            <div style={{background: 'rgb(240, 242, 245)'}}>
                <Watermark content="公牛Bull">
                    <Card extra={<Space size={0}>
                        <CreateFirmwareConfig fun1={this.get_firmware_project_task_config}/>
                        <Divider type="vertical"/>
                        <Button type={'link'} size={"small"} style={{width: 90, color: '#707272'}}
                                icon={<RedoOutlined/>}
                                onClick={() => {
                                    this.get_firmware_project_task_config()
                                    this.getuserlist_atmp()
                                    this.getproducts_ypt({env: "Test"})
                                }}>
                            刷新数据
                        </Button>
                        <Divider type="vertical"/>
                    </Space>
                    }
                          title={
                              <Space size={10} style={{margin: "20px 20px 20px 0px"}}>
                                  <Select
                                      style={{minWidth: 150, textAlign: "center"}}
                                      showSearch
                                      value={this.state.FirmwareType}
                                      placeholder="请选择项目类型"
                                      optionFilterProp="children"
                                      onChange={(value, option) => {
                                          this.setState({
                                              FirmwareType: value
                                          })
                                      }}
                                      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                      options={
                                          [
                                              {
                                                  value: -1, label: '所有类型',
                                              },
                                              {
                                                  value: 1, label: '网关设备',
                                              },
                                              {
                                                  value: 2, label: '子设备',
                                              }
                                          ]}
                                  />
                                  <Select
                                      style={{textAlign: "center", width: 350}}
                                      showSearch
                                      placeholder="请选择固件名称"
                                      optionFilterProp="children"
                                      value={this.state.FirmwareName}
                                      filterOption={(input, option) =>
                                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                      options={this.state.product_list.map((value) => {
                                          return {
                                              value: value.productName + "_" + value.productId + "_" + value.productKey,
                                              label: "[" + value.productName + "] · [" + value.productId + "] · [" + value.productKey + "]",
                                              disabled: false,
                                          }
                                      })}
                                      onChange={(value) => {
                                          this.setState({
                                              FirmwareName: value
                                          })
                                      }}
                                  />
                                  <Button size={"middle"} type={"primary"}
                                          style={{fontSize: 13, fontWeight: 700}}
                                          onClick={() => {
                                              this.get_firmware_project_task_config()
                                          }}
                                  >
                                      <Space size={5}>
                                          <SearchOutlined/>搜索
                                      </Space>
                                  </Button>
                                  <Button size={"middle"} type={"primary"}
                                          style={{fontSize: 13, fontWeight: 700}}
                                          onClick={() => {
                                              this.setState({
                                                  FirmwareName: null,
                                                  FirmwareType: -1
                                              }, function () {
                                                  this.get_firmware_project_task_config()
                                              })
                                          }}
                                  >
                                      <Space size={5}>
                                          <ClearOutlined/>重置
                                      </Space>
                                  </Button>
                              </Space>
                          }>
                        <Table
                            scroll={{
                                x: 2800,
                            }}
                            loading={this.state.spinning}
                            bordered={true}
                            columns={ProjectConfigColumns}
                            dataSource={this.state.datas}
                            pagination={{
                                position: ['bottomRight'],
                                pageSizeOptions: [10, 20, 30],
                                responsive: true,
                                size: "middle",
                                current: this.state.current,
                                pageSize: this.state.pageSize,
                                total: this.state.Total,
                                showTotal: (total, range) => {
                                    return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                                },
                                onShowSizeChange: (current, size) => {
                                    this.setState({
                                        page: current,
                                        pageSize: size
                                    }, function () {
                                        this.get_firmware_project_task_config()
                                    })
                                },
                                onChange: (page, pageSize) => {
                                    this.setState({
                                        page: page,
                                        pageSize: pageSize
                                    }, function () {
                                        this.get_firmware_project_task_config()
                                    })
                                }
                            }}
                        />
                    </Card>
                </Watermark>
            </div>
        </>
    }
}
