import React, {Component} from "react";
import {
    Badge, Button,
    Card, Divider, Input, Mentions, message, Popconfirm, Rate, Segmented, Select, Space,
    Spin, Table, Tag, Watermark,
} from "antd";

import {Approval} from "./Approval";
import api from "../../api/ApiConfig";
import {getUser, Loginout} from "../Users/AuthRouter";
import dayjs from "dayjs";
import {NavLink} from "react-router-dom";
import {
    AppstoreOutlined, ClearOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";

export class ProjectList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: [],
            ServiceName: null,
            ServiceType: 1,
            pageSize: 10,
            page: 1,
            Total: 0,
            ServiceEnv: -1,
            ProjectTaskStatus: -1,
            spinning: false
        }
    }

    componentDidMount() {
        this.getprojecttasks()
    }

    userinfo = getUser()

    getprojecttasks = async (params = {}) => {
        this.setState({
            spinning: true
        })
        params['UserId'] = this.userinfo.UserId
        if (this.state.ServiceName !== null) {
            params['ServiceName'] = this.state.ServiceName
        }
        if (this.state.ServiceEnv !== -1) {
            params['ServiceEnv'] = this.state.ServiceEnv
        }
        if (this.state.ProjectTaskStatus !== -1) {
            params['ProjectTaskStatus'] = this.state.ProjectTaskStatus
        }
        params['ServiceType'] = this.state.ServiceType
        params['pageSize'] = this.state.pageSize
        params['page'] = this.state.page

        await api.GetProjectTasks(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    datas: r.datas,
                    Total: r.Total
                })
            }
            setTimeout(() => {
                this.setState({
                    spinning: false
                })
            }, 100)

        })
    }

    delprojecttasks = async (TaskId) => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['TaskId'] = TaskId
        console.log("删除任务", values)
        await api.DelProjectTasks({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    message.success("删除任务成功")
                    this.getprojecttasks()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const ProjectColumns = [
            {
                title: 'No.',
                align: 'center',
                width: 40,
                render: (_, record, index) => {
                    return <div>{index + 1}</div>
                }
            },
            {
                title: '服务名称',
                align: 'center',
                dataIndex: 'ServiceName',
                render: (text, record, index) => {
                    return <div style={{fontSize: 13, fontWeight: 700}}>{text}</div>
                }
            },
            {
                title: '服务版本',
                align: 'center',
                dataIndex: 'ServiceVersion',
                render: (text, record, index) => {
                    return <div style={{fontSize: 13, fontWeight: 700}}>v_{text}</div>
                }
            },
            {
                title: '提测环境',
                align: 'center',
                dataIndex: 'ServiceEnv',
                render: (text, record, index) => {
                    return <div>
                        <Tag
                            color={
                                text === "TEST" ? "#5b8c00" :
                                    text === "RELEASE" ? "#8d062c" :
                                        text === "PRE" ? "#d05715" : "#9254de"
                            }
                            style={{
                                marginRight: 5,
                                textAlign: "center",
                                fontSize: 12,
                                fontWeight: 700,
                                width: 70,
                            }}
                        >
                            {text === "TEST" ? "测试环境" :
                                text === "RELEASE" ? "生产环境" :
                                    text === "PRE" ? "预发布" : "未知环境"}
                        </Tag>
                    </div>
                }
            },
            // {
            //     title: '提测类型',
            //     align: 'center',
            //     dataIndex: 'ServiceType',
            //     render: (text, record, index) => {
            //         return <Tag
            //             style={{
            //                 marginRight: 5,
            //                 textAlign: "center",
            //                 width: 85,
            //                 fontSize: 12,
            //                 fontWeight: 700,
            //             }}
            //             color={
            //                 text === 1 ? "#1677ff" :
            //                     text === 2 ? "#13c2c2" :
            //                         text === 3 ? "#d05715" :
            //                             text === 4 ? "#9254de" : "#9254de"
            //             }>
            //             {
            //                 text === 1 ? "云服务" :
            //                     text === 2 ? "App" :
            //                         text === 3 ? "固件" :
            //                             text === 4 ? "Web" : "未知类型"
            //             }
            //         </Tag>
            //     }
            // },
            {
                title: '任务状态',
                align: 'center',
                dataIndex: 'ProjectTaskStatus',
                render: (text, record) => {
                    return <div>{text === 1 ?
                        <Space>
                            <Badge status="warning"/>
                            <Tag color="#fadb14" style={{
                                color: "black",
                                marginRight: 5,
                                textAlign: "center",
                                fontSize: 12,
                                fontWeight: 700,
                                width: 80,
                            }}>
                                待确认
                            </Tag>
                        </Space>
                        : text === 2 ?
                            <Space>
                                <Badge status="processing"/>
                                <Tag color="#9254de" style={{
                                    color: "white",
                                    marginRight: 5,
                                    textAlign: "center",
                                    fontSize: 12,
                                    fontWeight: 700,
                                    width: 80,
                                }}>
                                    进行中
                                </Tag></Space>
                            : text === 3 ?
                                <Space>
                                    <Badge status="success"/>
                                    <Tag color="green-inverse"
                                         style={{
                                             color: "white",
                                             marginRight: 5,
                                             textAlign: "center",
                                             fontSize: 12,
                                             fontWeight: 700,
                                             width: 80,
                                         }}>
                                        提测成功
                                    </Tag>
                                </Space>
                                : text === 4 ?
                                    <Space>
                                        <Badge status="error"/>
                                        <Tag color="#ff7875" style={{
                                            color: "white",
                                            marginRight: 5,
                                            textAlign: "center",
                                            fontSize: 12,
                                            fontWeight: 700,
                                            width: 80,
                                        }}>
                                            提测失败
                                        </Tag>
                                    </Space> : "未知"}
                    </div>
                }
            },
            {
                title: '操作时间',
                align: 'center',
                render: (text, record, index) => {
                    return <Space direction={"vertical"} size={0}>
                        <Space size={1} style={{
                            fontSize: 10,
                            // fontWeight: 500,
                            color: "#938f8f"
                        }}>开始时间：{dayjs(record.DatasDate).format("YYYY-MM-DD HH:mm:ss")} </Space>
                        <Space size={1} style={{
                            fontSize: 10,
                            // fontWeight: 500,
                            color: "#938f8f"
                        }}>结束时间：{dayjs(record.uDatasDate).format("YYYY-MM-DD HH:mm:ss")}</Space>
                    </Space>
                }
            },
            {
                title: '任务操作',
                align: 'center',
                width: 100,
                render: (_, record) => {
                    return <Space size={0}>
                        {
                            record.ProjectTaskStatus === 1 ?
                                <Approval TaskID={record.key} fun={this.getprojecttasks}/> :
                                <NavLink to={'/ProjectTaskDetails/' + record.key}>
                                    <Button style={{width: 80}} size={"small"} type={"link"}>
                                        <Space size={5} style={{fontWeight: 800, fontSize: 13}}>
                                            <AppstoreOutlined/> 任务详情
                                        </Space>
                                    </Button>
                                </NavLink>
                        }
                        <Divider type={"vertical"}/>
                        <Popconfirm
                            title="删除任务"
                            description="确定要删除这个提测任务吗?"
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                            onConfirm={() => {
                                this.delprojecttasks(record.key)
                            }}
                        >
                            <Button style={{width: 80}} size={"small"} disabled={false}
                                    type={"link"} danger={true}>
                                <Space size={5} style={{fontWeight: 800, fontSize: 13}}>
                                    <DeleteOutlined/> 删除任务
                                </Space>
                            </Button>
                        </Popconfirm>
                    </Space>
                }
            },
        ];
        const expandedRowRender = (record, index) => {
            let ServicesListObj = []
            try {
                // 将单引号替换为双引号
                const jsonStringWithDoubleQuotes = record.ServicesList
                // 解析 JSON 字符串为 JavaScript 对象
                ServicesListObj = JSON.parse(jsonStringWithDoubleQuotes);
            } catch (e) {
                console.log("服务列表解析异常", record)
                try {
                    // 将单引号替换为双引号
                    const jsonStringWithDoubleQuotes = record.ServicesList.replace(/'/g, "\"")
                    // 解析 JSON 字符串为 JavaScript 对象
                    ServicesListObj = JSON.parse(jsonStringWithDoubleQuotes);
                } catch (e) {
                    console.log("服务列表解析异常", record)
                }
            }
            const columns = [
                {
                    title: '子服务名称',
                    align: 'center',
                    dataIndex: 'serviceName',
                },
                {
                    title: '服务版本',
                    align: 'center',
                    dataIndex: 'version',
                },
                {
                    title: '提交分支',
                    align: 'center',
                    dataIndex: 'gitBranch',
                },
                {
                    title: '提交消息',
                    align: 'center',
                    dataIndex: 'msg',
                },
                {
                    title: '提交人',
                    align: 'center',
                    dataIndex: 'commitor',
                },
                {
                    title: '提交时间',
                    align: 'center',
                    dataIndex: 'commiteTime',
                },
            ];
            return <Card>
                <Table size={"small"} columns={columns} bordered={true} dataSource={ServicesListObj}
                       pagination={false}/>
            </Card>
        };
        return <Card title={
            <Space size={10} style={{margin: "20px 20px 20px 0px"}}>
                <Select
                    style={{minWidth: 150, textAlign: "center"}}
                    showSearch={false}
                    placeholder="请选择环境"
                    value={this.state.ServiceEnv}
                    onChange={(value, option) => {
                        this.setState({
                            ServiceEnv: value
                        })
                    }}
                    options={[
                        {
                            value: -1,
                            label: '所有环境',
                        },
                        {
                            value: "TEST",
                            label: '测试环境',
                        },
                        {
                            value: "RELEASE",
                            label: '生产环境',
                        },
                        {
                            value: "PRE",
                            label: '预发布环境',
                        }
                    ]}
                />
                <Select
                    style={{minWidth: 150, textAlign: "center"}}
                    showSearch={false}
                    placeholder="请选择任务状态"
                    value={this.state.ProjectTaskStatus}
                    onChange={(value, option) => {
                        this.setState({
                            ProjectTaskStatus: value
                        })
                    }}
                    options={[
                        {
                            value: -1,
                            label: '所有状态',
                        },
                        {
                            value: 1,
                            label: '待确认',
                        },
                        {
                            value: 2,
                            label: '进行中',
                        },
                        {
                            value: 3,
                            label: '提测成功',
                        },
                        {
                            value: 4,
                            label: '提测失败',
                        }
                    ]}
                />
                <Input style={{minWidth: 200, textAlign: "center"}}
                       value={this.state.ServiceName}
                       placeholder={"请输入服务名称"}
                       onChange={(v) => {
                           this.setState({
                               ServiceName: v.target.value
                           })
                       }}
                />
                <Button size={"middle"} type={"primary"}
                        style={{fontSize: 13, fontWeight: 700}}
                        onClick={() => {
                            this.getprojecttasks()
                        }}
                >
                    <Space size={5}>
                        <SearchOutlined/>搜索
                    </Space>
                </Button>
                <Button size={"middle"} type={"primary"}
                        style={{fontSize: 13, fontWeight: 700}}
                        onClick={() => {
                            this.setState({
                                ServiceEnv: -1,
                                ServiceName: null,
                                ServiceType: 1,
                                ProjectTaskStatus: -1,
                            }, function () {
                                this.getprojecttasks()
                            })
                        }}
                >
                    <Space size={5}>
                        <ClearOutlined/>重置
                    </Space>
                </Button>
            </Space>
        }>
            <Table
                loading={this.state.spinning}
                expandable={{
                    expandedRowRender: (record, index, indent, expanded) => {
                        return expandedRowRender(record, index)
                    },
                    defaultExpandedRowKeys: [0]
                }}
                scroll={
                    {
                        x: 1300
                    }
                }
                bordered={true} dataSource={this.state.datas}
                columns={ProjectColumns}
                pagination={{
                    position: ['bottomRight'],
                    pageSizeOptions: [10, 20, 30],
                    responsive: true,
                    size: "middle",
                    current: this.state.page,
                    pageSize: this.state.pageSize,
                    total: this.state.Total,
                    showTotal: (total, range) => {
                        return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                    },
                    onShowSizeChange: (current, size) => {
                        this.setState({
                            page: current,
                            pageSize: size
                        }, function () {
                            this.getprojecttasks()
                        })
                    },
                    onChange: (page, pageSize) => {
                        this.setState({
                            page: page,
                            pageSize: pageSize
                        }, function () {
                            this.getprojecttasks()
                        })
                    }
                }}
            />
        </Card>
    }
}
