import React, {Component} from 'react';
import '@ant-design/pro-components/dist/components.css';
import {
    Space,
    Select, Button, Card, message
} from 'antd'
import Modal from "antd/es/modal/Modal";
import {DatabaseOutlined} from "@ant-design/icons";
import {getUser, Loginout} from "../Users/AuthRouter";
import api from "../../api/ApiConfig";


export class PerformanceTestSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TaskList: this.props.TaskList,
            TestItems: this.props.TaskList ? this.props.TaskList[Object.keys(this.props.TaskList)[0]].items : [],
            ItemName: this.props.TaskList ? this.props.TaskList[Object.keys(this.props.TaskList)[0]].items[0].id : [],
            isModalOpen: false,
            t_id: this.props.TaskList ? Object.keys(this.props.TaskList)[0] : null,
            i_id: this.props.TaskList ? this.props.TaskList[Object.keys(this.props.TaskList)[0]].items[0].id : null
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            TaskList: nextProps.TaskList,
        })
    }

    tobedetermined = async () => {
        let values = {}
        const userinfo = getUser()
        values['userid'] = userinfo.UserId
        values['PerformanceTaskId'] = this.state.t_id
        values['PerformanceTestItemId'] = this.state.i_id
        await api.ToBeDetermined({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("数据归档成功")
                this.setState({
                    isModalOpen: false
                })
                this.getperformancetesttask()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        return (
            <>
                <Button type={'link'} size={"small"}
                        style={{width: 100, color: '#707272'}}
                        icon={<DatabaseOutlined/>}
                        onClick={() => {
                            this.setState({
                                isModalOpen: true
                            })
                        }}
                >
                    数据归档
                </Button>
                <Modal title="性能测试数据归档" open={this.state.isModalOpen} onOk={() => {
                    // this.setState({
                    //     isModalOpen: false
                    // })
                    this.tobedetermined()
                }
                } onCancel={() => {
                    this.setState({
                        isModalOpen: false
                    })

                }}
                >
                    <Card style={{margin: 30}}>
                        <Space direction={"vertical"} size={20} style={{marginTop: 20}}>
                            <Space size={20}>
                                <div>任务名称</div>
                                <Select
                                    key={2}
                                    defaultValue={this.state.TaskList ? Object.keys(this.state.TaskList)[0] : null}
                                    style={{
                                        width: 230,
                                    }}
                                    onChange={(value) => {
                                        this.setState({
                                            TestItems: this.state.TaskList[value]['items'],
                                            ItemName: this.state.TaskList[value]['items'][0].id,
                                            t_id: value,
                                            i_id: this.state.TaskList[value]['items'][0].id
                                        })
                                    }}
                                    options={this.state.TaskList ? Object.entries(this.state.TaskList).map(([key, value]) => ({
                                            label: value.TaskName,
                                            value: key,
                                        }
                                    )) : null}
                                />
                            </Space>
                            <Space size={20}>
                                <div>测试检点</div>
                                <Select
                                    key={1}
                                    style={{
                                        width: 230,
                                    }}
                                    value={this.state.ItemName}
                                    onChange={(value) => {
                                        this.setState({
                                            ItemName: value,
                                            i_id: value
                                        })
                                    }}
                                    options={this.state.TestItems ? Object.entries(this.state.TestItems).map(([key, value]) => ({
                                        label: value.name,
                                        value: value.id,
                                    })) : null}
                                />
                            </Space>
                            <div style={{fontSize: 10, color: "red"}}>注意: 性能测试数据多次归档到同一个测试项会覆盖原有数据!!!</div>
                        </Space>
                    </Card>
                </Modal>

            </>
        )
    }
}