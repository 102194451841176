import React from 'react';
import {Controlled as CodeMirror} from 'react-codemirror2';

import 'codemirror/lib/codemirror.css'; // 导入 CodeMirror 样式
import 'codemirror/theme/dracula.css'; // 导入主题样式
import './styles.css'
// 导入你所需的 CodeMirror 模式（如果需要）
import 'codemirror/mode/javascript/javascript';

export class CodemirrorZzy extends React.Component {
    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
        this.state = {
            logs: 'Oct 23 2019 17:06:35.664| [Radius Auth Proxy Listener] [INFO ] org.tinyradius.util.RadiusServer [APPID:UPAM] thread interrupted...reinterrupt\n'
        };
    }

    componentDidMount() {
        // this.handleChange()
        this.timer = setInterval(() => {
            this.setState({
                    logs: this.state.logs += "Oct 23 2019 17:06:35.664| [Radius Auth Proxy Listener] [INFO ] org.tinyradius.util.RadiusServer [APPID:UPAM] thread interrupted...reinterrupt\n"
                }, function () {
                    if (this.editorRef.current && this.editorRef.current.editor) {
                        // 获取 CodeMirror 编辑器实例
                        const editor = this.editorRef.current.editor;

                        // 将滚动条滚动到底部
                        const lastLine = editor.lastLine();
                        editor.scrollTo(0, editor.charCoords({line: lastLine}).top, "smooth");
                    }
                }
            )
        }, 1000);
    }

    handleChange = (editor, data, value) => {
        this.setState({code: value});
    };

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        return (
            <CodeMirror
                value={this.state.logs}
                options={{
                    mode: 'text/plain',
                    lineNumbers: true,
                    readOnly: true,
                    lineWrapping: true,
                }}
                height="200px"
                // onBeforeChange={this.handleChange}
            />
        );
    }
}
