import React, {Component} from "react";
import {
    Card, Layout, Col, Row, Watermark,
    Divider, Carousel,
    Statistic, Badge, message, Result, Avatar, Space
} from "antd";


import {
    FullScreenContainer,
    Decoration5,
    Decoration3,
    BorderBox3,
    BorderBox10,
    BorderBox12,
    Decoration11,
    ScrollBoard,
    ScrollRankingBoard
} from '@jiaminghi/data-view-react'
import Column01 from "./ChartsDir/Column01";
import Pie1 from "./ChartsDir/Pie1";
import DualAxesDp from "./ChartsDir/DualAxesDp";
import api from "../../api/ApiConfig";
import {Loginout} from "../Users/AuthRouter";
import RadarDp from "./ChartsDir/RadarDp";

const {Header, Sider, Content} = Layout;


export class BugDashboardFullScreenHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            UnresolvedBugsDatas: {
                a: [],
                b: [],
                c: [],
                d: []
            },
            DualAxesDpDatas: {
                uvBillData: [],
                transformData: []
            },
            DurationStayDatas: {},
            StatisticDatas: {},
            RadarDpDatas: {},
        }
    }


    componentDidMount() {
        this.getyearsbugcounts();
        this.getunresolvedbugsdistribution();
        this.getdurationstay();
        this.getbugcounts();
        this.getprojectbugs();
        console.log("组件被加载,开启计时器")
        this.timer = setInterval(() => {
            this.getyearsbugcounts();
            this.getunresolvedbugsdistribution();
            this.getdurationstay();
            this.getbugcounts();
            this.getprojectbugs();
        }, 600000);

    }

    componentWillUnmount() {
        clearInterval(this.timer);
        console.log("组件被卸载,停止计时器")
    }

    getbugcounts = async (params = {}) => {
        // 获取所有bug统计数据
        await api.GetBugCounts(params).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    StatisticDatas: r.datas
                }, function () {
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }
    getdurationstay = async (params = {}) => {
        // 获取未关闭的滞留bug
        await api.GetDurationStay(params).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    DurationStayDatas: r.datas
                }, function () {
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }
    getyearsbugcounts = async (params = {}) => {
        // 获取历史12个月的bug信息
        await api.GetYearsBugCounts(params).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    DualAxesDpDatas: r.datas
                }, function () {
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }
    getunresolvedbugsdistribution = async (params = {}) => {
        // 获取所有bug统计数据
        await api.GetUnresolvedBugsDistribution(params).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    UnresolvedBugsDatas: r.datas
                }, function () {
                    console.log("获取到的数据", this.state.UnresolvedBugsDatas)
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }
    getprojectbugs = async (params = {}) => {
        // 获取雷达图数据
        // params['sTime'] = this.state.QTime[0].format("YYYY-MM-DD 00:00:00")
        // params['eTime'] = this.state.QTime[1].format("YYYY-MM-DD 23:59:59")
        await api.GetProjectBugs(params).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    RadarDpDatas: r.datas
                }, function () {
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const headerStyle = {
            textAlign: 'center',
            height: 64,
            paddingInline: 48,
            lineHeight: '64px',
            backgroundColor: 'transparent',
        };
        const contentStyle = {
            textAlign: 'center',
            minHeight: 120,
            lineHeight: '120px',
            // width:"75%"
        };
        const siderStyle = {
            textAlign: 'center',
            lineHeight: '120px',
            backgroundColor: "transparent",
        };
        const layoutStyle = {
            borderRadius: 8,
            overflow: 'hidden',
            height: '100vh',
            backgroundColor: "transparent",
            // width: 'calc(50% - 8px)',
            // maxWidth: 'calc(50% - 8px)',
        };
        const ScrollRankingBoardConfig = {
            data: this.state.UnresolvedBugsDatas.b,
            colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
            unit: "个",
            sort: true,
            rowNum: 10
        }
        return <div>
            <FullScreenContainer
                style={{backgroundImage: 'url(http://datav-react.jiaminghi.com/demo/electronic-file/static/media/bg.110420cf.png)',}}>
                <Watermark content={"公牛Bull"} font={{color: "rgba(255,255,255,0.17)"}}>
                    <Carousel autoplay effect="fade">
                        <div>
                            <Layout style={layoutStyle}>
                                <BorderBox10>
                                    <Header style={headerStyle}>
                                        <Row wrap={true}>
                                            <Col span={24}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center", /* 水平居中 */
                                                }}>
                                                    <Decoration3 style={{width: '380px', height: '20px'}}/>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center", /* 水平居中 */
                                                    // alignItems: "center", /* 垂直居中 */
                                                    // height: "100vh" /* 可选，确保父容器高度充满视口高度 */
                                                }}>
                                                    <Space
                                                        style={{
                                                            color: "white",
                                                            fontSize: 25,
                                                        }}>
                                                        <Avatar
                                                            style={{marginTop: -4}}
                                                            size={40}
                                                            src={`https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/favicon.ico`}
                                                        />
                                                        数据看板
                                                    </Space>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center", /* 水平居中 */
                                                }}>
                                                    <Decoration5
                                                        style={{height: '80px', width: '90vh', marginTop: -45}}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Header>
                                    <Layout style={layoutStyle}>
                                        <Sider width="25%" style={siderStyle}>
                                            <BorderBox3
                                                style={{
                                                    marginTop: 60,
                                                    marginLeft: "10px",
                                                    height: '87vh',
                                                    // width: '37%'
                                                }}>
                                                <Row gutter={5}>
                                                    <Col span={24}>
                                                        <Divider orientation={"center"}
                                                                 style={{
                                                                     fontSize: 12,
                                                                     color: "white",
                                                                     fontWeight: 700,
                                                                     marginTop: 30,
                                                                 }}>
                                                            <Decoration11 style={{
                                                                width: '180px',
                                                                height: '50px',
                                                            }} color={['#71697e', '#a689ce']}>
                                                                全屋智能SKU分布(待处理)
                                                            </Decoration11>
                                                        </Divider>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card bordered={false} style={{
                                                            marginLeft: 30,
                                                            marginTop: -20,
                                                            marginRight: 40,
                                                            textAlign: "left",
                                                            background: "rgba(0,0,0,0)"
                                                        }}>
                                                            {this.state.UnresolvedBugsDatas.b.length > 0 ?
                                                                <ScrollRankingBoard config={ScrollRankingBoardConfig}
                                                                                    style={{height: '400px'}}/> :
                                                                <Result
                                                                    status="success"
                                                                    title={<div style={{color: "#fff"}}>
                                                                        暂无待处理的Bugs
                                                                    </div>}
                                                                />}
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Divider orientation={"center"}
                                                                 style={{
                                                                     marginTop: -10,
                                                                     fontSize: 12,
                                                                     color: "white",
                                                                     fontWeight: 700,
                                                                 }}>
                                                            <Decoration11 style={{
                                                                width: '180px',
                                                                height: '50px',
                                                            }} color={['#71697e', '#a689ce']}>
                                                                项目分布(待处理)
                                                            </Decoration11>
                                                        </Divider>
                                                    </Col>
                                                    <Col span={24}>
                                                        {/*<Pie2 datas={this.state.UnresolvedBugsDatas.c}/>*/}
                                                        <RadarDp datas={this.state.RadarDpDatas} textColor={"#ffffff"}/>
                                                    </Col>
                                                </Row>
                                            </BorderBox3>
                                        </Sider>
                                        <Content style={contentStyle}>
                                            <Row style={{
                                                marginTop: 60,
                                                marginLeft: "15px",
                                                marginRight: "20px",
                                                height: "59vh"
                                            }} gutter={[10, 10]}>
                                                <Col span={16}>
                                                    <BorderBox12>
                                                        <div style={{
                                                            top: 30,
                                                            // height: 170,
                                                            borderColor: "#282922",
                                                            marginLeft: 20,
                                                            marginRight: 20,
                                                            backgroundColor: "transparent",
                                                            textAlign: "center",
                                                        }}>
                                                            <Row gutter={[10, 10]}>
                                                                <Col span={8}>
                                                                    <Decoration3 style={{height: '20px',}}/>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <Divider orientation={"center"}
                                                                             style={{
                                                                                 fontSize: 12,
                                                                                 color: "white",
                                                                                 fontWeight: 700,
                                                                             }}>
                                                                        <Decoration11 style={{
                                                                            width: '180px',
                                                                            height: '50px',
                                                                        }} color={['#71697e', '#a689ce']}>
                                                                            数据概览
                                                                        </Decoration11>
                                                                    </Divider>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <Decoration3 style={{height: '20px'}}/>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Card
                                                                        bordered={true}
                                                                        style={{backgroundColor: "#f6f5f8"}}
                                                                    >
                                                                        <Statistic
                                                                            title={<div style={{
                                                                                color: "#707272",
                                                                                fontWeight: 700
                                                                            }}>已提交</div>}
                                                                            value={Object.keys(this.state.StatisticDatas).length > 0 ? this.state.StatisticDatas.committed : 0}
                                                                            precision={0}
                                                                            suffix={"个"}
                                                                            valueStyle={{
                                                                                color: '#3f8600',
                                                                                fontSize: 17,
                                                                            }}
                                                                        />
                                                                    </Card>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Card
                                                                        bordered={true}
                                                                        style={{backgroundColor: "#f6f5f8"}}
                                                                    >
                                                                        <Statistic
                                                                            title={<div style={{
                                                                                color: "#707272",
                                                                                fontWeight: 700
                                                                            }}>待处理</div>}
                                                                            value={Object.keys(this.state.StatisticDatas).length > 0 ? this.state.StatisticDatas.unresolved : 0}
                                                                            precision={0}
                                                                            suffix={"个"}
                                                                            valueStyle={{
                                                                                color: '#3f8600',
                                                                                fontSize: 17,
                                                                            }}
                                                                        />
                                                                    </Card>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Card
                                                                        bordered={true}
                                                                        style={{backgroundColor: "#f6f5f8"}}
                                                                    >
                                                                        <Statistic
                                                                            title={<div style={{
                                                                                color: "#707272",
                                                                                fontWeight: 700
                                                                            }}>已修复</div>}
                                                                            value={Object.keys(this.state.StatisticDatas).length > 0 ? this.state.StatisticDatas.closed : 0}
                                                                            precision={0}
                                                                            suffix={"个"}
                                                                            valueStyle={{
                                                                                color: '#3f8600',
                                                                                fontSize: 17,
                                                                            }}
                                                                        />
                                                                    </Card>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Card
                                                                        bordered={true}
                                                                        style={{backgroundColor: "#f6f5f8"}}
                                                                    >
                                                                        <Statistic
                                                                            title={<div style={{
                                                                                color: "#707272",
                                                                                fontWeight: 700
                                                                            }}>必现率</div>}
                                                                            value={Object.keys(this.state.StatisticDatas).length > 0 ? this.state.StatisticDatas.DoRate : 0}
                                                                            precision={2}
                                                                            suffix={"%"}
                                                                            valueStyle={{
                                                                                color: '#3f8600',
                                                                                fontSize: 17,
                                                                            }}
                                                                        />
                                                                    </Card>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Card
                                                                        bordered={true}
                                                                        style={{backgroundColor: "#f6f5f8"}}
                                                                    >
                                                                        <Statistic
                                                                            title={<div style={{
                                                                                color: "#707272",
                                                                                fontWeight: 700
                                                                            }}>Reopen率</div>}
                                                                            value={Object.keys(this.state.StatisticDatas).length > 0 ? this.state.StatisticDatas.reopenRate : 0}
                                                                            precision={2}
                                                                            suffix={"%"}
                                                                            valueStyle={{
                                                                                color: '#3f8600',
                                                                                fontSize: 17,
                                                                            }}
                                                                        />
                                                                    </Card>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Card
                                                                        bordered={true}
                                                                        style={{backgroundColor: "#f6f5f8"}}
                                                                    >
                                                                        <Statistic
                                                                            title={<div style={{
                                                                                color: "#707272",
                                                                                fontWeight: 700
                                                                            }}>严重率</div>}
                                                                            value={Object.keys(this.state.StatisticDatas).length > 0 ? this.state.StatisticDatas.severityRate : 0}
                                                                            precision={2}
                                                                            suffix={"%"}
                                                                            valueStyle={{
                                                                                color: '#3f8600',
                                                                                fontSize: 17,
                                                                            }}
                                                                        />
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginTop: 50,
                                                                marginLeft: 20,
                                                                marginRight: 20,
                                                                backgroundColor: "transparent",
                                                                textAlign: "left",
                                                            }}>
                                                            <DualAxesDp datas={this.state.DualAxesDpDatas}
                                                                        textColor={"#fff"} height={380}/>
                                                        </div>
                                                    </BorderBox12>
                                                </Col>
                                                <Col span={8}>
                                                    <BorderBox12>
                                                        <div style={{
                                                            height: 170,
                                                            borderColor: "#282922",
                                                            marginLeft: 20,
                                                            marginRight: 20,
                                                            backgroundColor: "transparent",
                                                            textAlign: "center",
                                                        }}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Divider orientation={"center"}
                                                                             style={{
                                                                                 fontSize: 12,
                                                                                 color: "white",
                                                                                 fontWeight: 700,
                                                                             }}>
                                                                        <Decoration11 style={{
                                                                            width: '180px',
                                                                            height: '50px'
                                                                        }} color={['#71697e', '#a689ce']}>
                                                                            滞留时长(待处理)
                                                                        </Decoration11>
                                                                    </Divider>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <ScrollBoard config={this.state.DurationStayDatas}
                                                                                 style={{height: '49vh'}}/>
                                                                </Col>
                                                            </Row>

                                                        </div>
                                                    </BorderBox12>
                                                </Col>
                                            </Row>
                                            <Row style={{
                                                marginTop: 10,
                                                marginLeft: "15px",
                                                marginRight: "20px",
                                                height: "27vh"
                                            }}
                                                 gutter={[10, 10]}>
                                                <Col span={24}>
                                                    <BorderBox12>
                                                        <Row style={{marginLeft: -10}}>
                                                            <Col span={10}>
                                                                <div style={{
                                                                    textAlign: "left",
                                                                    marginTop: 30,
                                                                    marginLeft: 20
                                                                }}>
                                                                    <Badge.Ribbon
                                                                        color={"orange"}
                                                                        style={{marginLeft: 30, marginTop: -20}}
                                                                        placement={"start"}
                                                                        text={<div style={{
                                                                            fontSize: 12,
                                                                            fontWeight: 500
                                                                        }}>责任部门分布(待处理)</div>}>
                                                                        <Pie1 datas={this.state.UnresolvedBugsDatas.d}
                                                                              textColor={"#fff"}/>
                                                                    </Badge.Ribbon>
                                                                </div>
                                                            </Col>
                                                            <Col span={14}>
                                                                <div style={{textAlign: "left", margin: 20}}>
                                                                    <Badge.Ribbon
                                                                        color="purple"
                                                                        style={{marginLeft: 30, marginTop: -10}}
                                                                        placement={"start"}
                                                                        text={<div style={{
                                                                            fontSize: 12,
                                                                            fontWeight: 500
                                                                        }}>人员分布(待处理)</div>}>
                                                                        <Column01
                                                                            datas={this.state.UnresolvedBugsDatas.a}
                                                                            textColor={"#fff"}
                                                                        />
                                                                    </Badge.Ribbon>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </BorderBox12>
                                                </Col>
                                            </Row>
                                        </Content>
                                    </Layout>
                                </BorderBox10>
                            </Layout>
                            <div style={{marginTop: 10}}/>
                        </div>
                    </Carousel>
                </Watermark>
            </FullScreenContainer>
        </div>
    }
}
