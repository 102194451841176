import React, {Component} from 'react'
import {
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    message,
    Popconfirm,
    Progress,
    Radio,
    Row,
    Space,
    Statistic,
    Tag
} from "antd";
import api from "../../../api/ApiConfig";
import {getUser, Loginout} from "../../Users/AuthRouter";
import {JsonTools} from "../../AutoTest/TestProjectManagement/JsonTools";
import {CheckOutlined, ExportOutlined, QuestionCircleOutlined, SendOutlined, SettingOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import Input from "antd/es/input/Input";

const {Meta} = Card;

export class Step4 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            taskId: props.taskId,
            datas: props.datas,
            fun: props.fun,
            userList: props.userList,
            approval_datas: props.approval_datas,
            BuildsStatus: {},
            ManualValue: 1,
            ManualText: "无",
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.approval_datas) !== JSON.stringify(prevProps.approval_datas) ||
            JSON.stringify(this.props.userList) !== JSON.stringify(prevProps.userList) ||
            JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)
        ) {
            this.setState({
                approval_datas: this.props.approval_datas,
                datas: this.props.datas,
                userList: this.props.userList,
            }, function () {
                console.log("this.state.datas.AutoTest", this.state.datas.AutoTest)
                this.getbuildsstatus_details()
                this.timer = setInterval(() => {
                    // 设置定时器实时获取自动化测试任务执行详情
                    if ((this.state.BuildsStatus.JenkinsTaskStatus === "WAITING" ||
                        this.state.BuildsStatus.JenkinsTaskStatus === "RUNNING")) {
                        this.getbuildsstatus_details()
                        this.state.fun()
                    }
                }, 5000);
            })
        }
    }

    componentDidMount() {
        this.getbuildsstatus_details()
    }

    userinfo = getUser()

    firmware_execute_again = async () => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['key'] = this.state.taskId
        await api.FirmwareExecuteAgain({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    this.state.fun()
                    message.success("任务下发成功～")
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    componentWillUnmount() {
        // 在组件即将被销毁时执行清理操作
        clearInterval(this.timer);
    }

    getbuildsstatus_details = async () => {
        let params = {}
        params['TaskId'] = this.state.approval_datas.AutoTestTaskInfo.length > 0 ? this.state.approval_datas.AutoTestTaskInfo.split("@")[1] : "-1"
        params['JenkinsTaskNumber'] = this.state.datas.AutoTestJenkinsTaskNumber
        await api.GetBuildsStatus(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    BuildsStatus: r['datas'],
                    TestRes: Object.keys(r['datas']).length > 0 ? eval("(" + r['datas'].TestResult + ")") : '{}'
                }, function () {
                })
            }
        })
    }

    firmware_set_autotest_result = async () => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['TaskId'] = this.state.taskId
        values['UserName'] = this.userinfo.username
        values['ManualValue'] = this.state.ManualValue
        values['ManualText'] = this.state.ManualText
        await api.FirmwareSetAutoTestResult({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({}, function () {
                    message.success("自动化测试结果已设置为通过")
                    this.state.fun()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        return (
            <div style={{margin: "20px 30px 30px 30px"}}>
                <Card style={{margin: 20}}>
                    <div style={{margin: 5}}>
                        <Space size={5} style={{textAlign: "center"}}>
                            <svg d="1719652478295" className="icon" viewBox="0 0 1024 1024" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="11411" width="25" height="25">
                                <path d="M592 824m-32 0a32 32 0 1 0 64 0 32 32 0 1 0-64 0Z" p-id="11412"/>
                                <path d="M432 200m-32 0a32 32 0 1 0 64 0 32 32 0 1 0-64 0Z" p-id="11413"/>
                                <path
                                    d="M858.666667 272H752V165.333333c0-55.893333-45.44-101.333333-101.333333-101.333333H373.333333c-55.893333 0-101.333333 45.44-101.333333 101.333333v106.666667H165.333333c-55.893333 0-101.333333 45.44-101.333333 101.333333v277.333334c0 55.893333 45.44 101.333333 101.333333 101.333333h106.666667v106.666667c0 55.893333 45.44 101.333333 101.333333 101.333333h277.333334c55.893333 0 101.333333-45.44 101.333333-101.333333V752h106.666667c55.893333 0 101.333333-45.44 101.333333-101.333333V373.333333c0-55.893333-45.44-101.333333-101.333333-101.333333zM165.333333 688c-20.586667 0-37.333333-16.746667-37.333333-37.333333V373.333333c0-20.586667 16.746667-37.333333 37.333333-37.333333h312c17.706667 0 32-14.293333 32-32s-14.293333-32-32-32H336V165.333333c0-20.586667 16.746667-37.333333 37.333333-37.333333h277.333334c20.586667 0 37.333333 16.746667 37.333333 37.333333v287.253334c0 15.146667-12.266667 27.413333-27.413333 27.413333H363.413333c-50.453333 0-91.413333 41.066667-91.413333 91.413333V688H165.333333z m730.666667-37.333333c0 20.586667-16.746667 37.333333-37.333333 37.333333H546.666667c-17.706667 0-32 14.293333-32 32s14.293333 32 32 32H688v106.666667c0 20.586667-16.746667 37.333333-37.333333 37.333333H373.333333c-20.586667 0-37.333333-16.746667-37.333333-37.333333V571.413333c0-15.146667 12.266667-27.413333 27.413333-27.413333h297.173334c50.453333 0 91.413333-41.066667 91.413333-91.413333V336h106.666667c20.586667 0 37.333333 16.746667 37.333333 37.333333v277.333334z"
                                    p-id="11414"/>
                            </svg>
                            <div style={{fontSize: 14, fontWeight: 800, marginTop: -5}}>自动化测试</div>
                        </Space>
                    </div>
                    <Space>
                        <a target={"_blank"} rel="noopener noreferrer"
                           href={Object.keys(this.state.approval_datas).length > 0 ? "/TaskList/"
                               + this.state.approval_datas.AutoTestProjectInfo.split("@")[1] + "/"
                               + this.state.approval_datas.AutoTestProjectInfo.split("@")[0] : "#"}
                        >
                            <Space size={5} style={{fontSize: 11, marginTop: 5}}>
                                <div>
                                    {Object.keys(this.state.approval_datas).length > 0 ? this.state.approval_datas.AutoTestTaskInfo.split("@")[0] : null}({Object.keys(this.state.approval_datas).length > 0 ? this.state.approval_datas.AutoTestProjectInfo.split("@")[0] : null})
                                </div>
                                <ExportOutlined/>
                            </Space>
                        </a>
                    </Space>
                    <div style={{width: "80%", marginLeft: "10%"}}>
                        <Space size={2} style={{margin: 20}}>
                            <Divider type={"vertical"}/>
                            <Popconfirm
                                title="测试任务执行" description={<div style={{fontSize: 12}}>确定要为该版本再次执行自动化测试任务吗?</div>}
                                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                                onConfirm={() => {
                                    this.firmware_execute_again()
                                }}
                            >
                                <Button type={"link"} size={"small"} icon={<SendOutlined/>}
                                        style={{fontSize: 12, fontWeight: 700}}>再次执行</Button>
                            </Popconfirm>
                            <Divider type={"vertical"}/>
                            <a href={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.TaskReportPath : "#"}
                               target={'_blank'} rel="noopener noreferrer">
                                <Space style={{fontSize: 12, fontWeight: 700}}>
                                    <div style={{marginTop: 3, padding: -5}}>
                                        <svg d="1701498648732" className="icon" viewBox="0 0 1024 1024"
                                             version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" p-id="5612" width="14"
                                             height="14">
                                            <path
                                                d="M651.636364 349.090909h46.545454v-69.818182h-46.545454v69.818182z m116.363636-116.363636a23.272727 23.272727 0 0 0-23.272727-23.272728h-139.636364a23.272727 23.272727 0 0 0-23.272727 23.272728v162.909091a23.272727 23.272727 0 0 0 23.272727 23.272727h139.636364a23.272727 23.272727 0 0 0 23.272727-23.272727v-162.909091z m-477.090909 186.181818h186.181818a34.909091 34.909091 0 1 0 0-69.818182h-186.181818a34.909091 34.909091 0 1 0 0 69.818182z m0-139.636364h186.181818a34.909091 34.909091 0 1 0 0-69.818182h-186.181818a34.909091 34.909091 0 1 0 0 69.818182z m581.818182 442.181818a34.909091 34.909091 0 0 0 34.909091-34.90909V93.090909a69.818182 69.818182 0 0 0-69.818182-69.818182H186.181818a69.818182 69.818182 0 0 0-69.818182 69.818182v837.818182a69.818182 69.818182 0 0 0 69.818182 69.818182h651.636364a69.818182 69.818182 0 0 0 69.818182-69.818182v-81.454546a34.909091 34.909091 0 1 0-69.818182 0V930.909091H186.181818V93.090909h651.636364v593.454546a34.909091 34.909091 0 0 0 34.909091 34.90909z m-139.636364 23.272728h-442.181818a34.909091 34.909091 0 1 0 0 69.818182h442.181818a34.909091 34.909091 0 1 0 0-69.818182z m0-139.636364h-442.181818a34.909091 34.909091 0 1 0 0 69.818182h442.181818a34.909091 34.909091 0 1 0 0-69.818182z"
                                                fill="#1296db" p-id="5613"/>
                                        </svg>
                                    </div>
                                    测试报告
                                </Space>
                            </a>
                            <Divider type={"vertical"}/>
                            <a href={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.TaskConsoleLog : "#"}
                               target={'_blank'} rel="noopener noreferrer">
                                <Space style={{fontSize: 12, fontWeight: 700}}>
                                    <div style={{marginTop: 3}}>
                                        <svg d="1701498200237" className="icon" viewBox="0 0 1024 1024"
                                             version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" p-id="3248" width="19"
                                             height="19">
                                            <path
                                                d="M665.6 396.8h-256c-12.8 0-25.6-6.4-25.6-19.2s6.4-19.2 19.2-19.2h249.6c12.8 0 19.2 6.4 19.2 19.2s0 19.2-6.4 19.2zM665.6 531.2h-256c-12.8 0-25.6-12.8-25.6-25.6s6.4-19.2 19.2-19.2h249.6c12.8 0 19.2 6.4 19.2 19.2 12.8 12.8 0 25.6-6.4 25.6zM665.6 665.6h-256c-12.8 0-19.2-6.4-19.2-19.2s6.4-19.2 19.2-19.2h249.6c12.8 0 19.2 6.4 19.2 19.2s-6.4 19.2-12.8 19.2z"
                                                fill="#1296db" p-id="3249"/>
                                            <path
                                                d="M780.8 851.2H326.4c-64 0-115.2-44.8-115.2-108.8v-32c0-12.8 12.8-19.2 25.6-19.2s25.6 6.4 25.6 19.2v32c0 32 32 64 70.4 64h403.2c38.4 0 70.4-32 70.4-64V595.2c0-12.8 12.8-19.2 25.6-19.2s25.6 6.4 25.6 19.2v147.2c-12.8 64-12.8 108.8-76.8 108.8z m-550.4-256c-12.8 0-25.6-6.4-25.6-19.2V441.6c0-12.8 12.8-19.2 25.6-19.2 12.8-6.4 25.6 6.4 25.6 19.2V576c0 6.4-12.8 19.2-25.6 19.2z m595.2-153.6c-12.8 0-25.6-6.4-25.6-19.2V275.2c0-32-32-64-70.4-64H326.4c-38.4 0-70.4 25.6-70.4 64v32c0 12.8-12.8 19.2-25.6 19.2s-25.6-6.4-25.6-19.2v-32c0-57.6 51.2-108.8 121.6-108.8h403.2c64 0 115.2 44.8 115.2 108.8v147.2c0 6.4-6.4 19.2-19.2 19.2z"
                                                fill="#1296db" p-id="3250"/>
                                            <path
                                                d="M313.6 396.8H211.2c-12.8 0-25.6-6.4-25.6-19.2s12.8-19.2 25.6-19.2h102.4c12.8 0 25.6 6.4 25.6 19.2s-6.4 19.2-25.6 19.2zM313.6 665.6H211.2c-12.8 0-25.6-6.4-25.6-19.2s12.8-19.2 25.6-19.2h102.4c12.8 0 25.6 6.4 25.6 19.2s-6.4 19.2-25.6 19.2zM812.8 595.2c-51.2 0-89.6-38.4-89.6-89.6s38.4-89.6 89.6-89.6c51.2 0 89.6 44.8 89.6 89.6 0 51.2-38.4 89.6-89.6 89.6z m0-128c-25.6 0-44.8 19.2-44.8 44.8s19.2 44.8 44.8 44.8c19.2 0 44.8-19.2 44.8-44.8 0-25.6-19.2-44.8-44.8-44.8z"
                                                fill="#1296db" p-id="3251"/>
                                        </svg>
                                    </div>
                                    测试日志
                                </Space>
                            </a>
                            <Divider type={"vertical"}/>
                            <Popconfirm
                                placement={"top"}
                                title="版本提测结果变更"
                                description={
                                    <Space direction={"vertical"} size={10} style={{marginBottom: 30}}>
                                        <Space direction={"vertical"} style={{fontSize: 12}}>
                                            <div>确定要手动干预版本自动化测试结果吗?</div>
                                            <div style={{color: "red", fontSize: 11}}>
                                                Ps:此功能仅用于应对突发异常且提测结果中会显示此状态
                                            </div>
                                        </Space>
                                        <Radio.Group onChange={(e) => {
                                            this.setState({
                                                ManualValue: e.target.value,
                                            })
                                        }} defaultValue={1}>
                                            <Space style={{fontSize: 10}} size={1}>
                                                <Radio value={1}>环境问题</Radio>
                                                <Radio value={2}>服务依赖</Radio>
                                                <Radio value={3}>用例问题</Radio>
                                                <Radio value={4}>新增需求</Radio>
                                                <Radio value={5}>其他问题</Radio>
                                            </Space>
                                        </Radio.Group>
                                        <Space>问题描述<QuestionCircleOutlined/></Space>
                                        <Input.TextArea size={"small"} maxLength={40} showCount rows={2}
                                                        placeholder="请输入问题描述"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                ManualText: e.target.value
                                                            })
                                                        }}
                                        />
                                    </Space>
                                }
                                icon={
                                    <QuestionCircleOutlined
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                }
                                onConfirm={() => {
                                    this.firmware_set_autotest_result()
                                }}
                            >
                                <Button type={"link"} size={"small"} icon={<CheckOutlined/>}
                                        style={{fontSize: 12, fontWeight: 700}}>手动通过</Button>
                            </Popconfirm>
                            <Divider type={"vertical"}/>
                        </Space>
                        <Card style={{width: "100%"}}>
                            <Meta style={{textAlign: "center"}}
                                  description={
                                      <div style={{minHeight: 300, marginLeft: 0}}>
                                          <Space>
                                              <Card style={{
                                                  backgroundColor: "#2f54eb"
                                              }}>
                                                  <Statistic
                                                      style={{width: 60}}
                                                      title={<div style={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 900,
                                                      }}>构建编号</div>}
                                                      // value={"FAILED"}
                                                      value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.JenkinsTaskNumber : " "}
                                                      valueStyle={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 700,
                                                          // marginLeft: -10,
                                                          // textAlign: "left"
                                                      }}
                                                  />
                                              </Card>
                                              <Card style={{
                                                  backgroundColor: "#2f54eb"
                                              }}>
                                                  <Statistic
                                                      style={{width: 60}}
                                                      title={<div style={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 900,
                                                      }}>测试结果</div>}
                                                      // value={"FAILED"}
                                                      value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.JenkinsTaskStatus : " "}
                                                      valueStyle={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 700,
                                                          // marginLeft: -10,
                                                          // textAlign: "left"
                                                      }}
                                                  />
                                              </Card>
                                              <Card style={{backgroundColor: "#2f54eb"}}>
                                                  <Statistic
                                                      style={{width: 60}}
                                                      title={<div style={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 900
                                                      }}>总 数</div>}
                                                      // value={"2000"}
                                                      value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.CaseTotal : 0}
                                                      // value={this.state.TestRes ? this.state.TestRes.collected : 0}
                                                      valueStyle={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 700
                                                      }}
                                                  />
                                              </Card>
                                              <Card style={{backgroundColor: "#3f8600"}}>
                                                  <Statistic
                                                      style={{width: 60}}
                                                      title={<div style={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 900
                                                      }}>成 功</div>}
                                                      value={this.state.TestRes ? this.state.TestRes.passed : 0}
                                                      // value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.CaseExecuted + "/" + this.state.BuildsStatus.CaseTotal : "0/0"}
                                                      valueStyle={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 700
                                                      }}
                                                  />
                                              </Card>
                                              <Card style={{backgroundColor: "#de1147"}}>
                                                  <Statistic
                                                      style={{width: 60}}
                                                      title={<div style={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 900
                                                      }}>失 败</div>}
                                                      value={this.state.TestRes ? this.state.TestRes.failed : 0}
                                                      // value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.CaseExecuted + "/" + this.state.BuildsStatus.CaseTotal : "0/0"}
                                                      valueStyle={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 700
                                                      }}
                                                  />
                                              </Card>
                                              <Card style={{backgroundColor: "#9254de"}}>
                                                  <Statistic
                                                      style={{width: 60}}
                                                      title={<div style={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 900
                                                      }}>跳 过</div>}
                                                      value={this.state.TestRes ? this.state.TestRes.skipped : 0}
                                                      // value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.CaseExecuted + "/" + this.state.BuildsStatus.CaseTotal : "0/0"}
                                                      valueStyle={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 700
                                                      }}
                                                  />
                                              </Card>
                                              <Card style={{backgroundColor: "#faad14"}}>
                                                  <Statistic
                                                      style={{width: 60}}
                                                      title={<div style={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 900
                                                      }}>错 误</div>}
                                                      value={this.state.TestRes ? this.state.TestRes.error : 0}
                                                      // value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.CaseExecuted + "/" + this.state.BuildsStatus.CaseTotal : "0/0"}
                                                      valueStyle={{
                                                          color: '#ffffff',
                                                          fontSize: 12,
                                                          fontWeight: 700
                                                      }}
                                                  />
                                              </Card>
                                          </Space>
                                          <Card style={{
                                              backgroundColor: 'rgb(240, 242, 245)',
                                              textAlign: "center",
                                              fontSize: 12,
                                              fontWeight: 800,
                                              marginTop: 30,
                                          }}>
                                              <Row gutter={10}
                                                   style={{marginTop: 10, marginBottom: 10, width: "100%"}}>
                                                  <Col span={10}>
                                                      <Card bordered={false}>
                                                          <div style={{
                                                              whiteSpace: 'nowrap',
                                                              overflow: 'hidden',
                                                              textOverflow: 'ellipsis',
                                                              textAlign: "center",
                                                          }}>
                                                              <Statistic
                                                                  title={<div
                                                                      style={{fontSize: 11}}>正在执行的用例</div>}
                                                                  value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.ExecutingCase : "无"}
                                                                  valueStyle={{
                                                                      color: '#3f8600',
                                                                      fontSize: 11,
                                                                  }}
                                                              />
                                                          </div>
                                                      </Card>
                                                  </Col>
                                                  <Col span={4}>
                                                      <Card bordered={false}>
                                                          <div style={{
                                                              whiteSpace: 'nowrap',
                                                              overflow: 'hidden',
                                                              textOverflow: 'ellipsis',
                                                              textAlign: "center",
                                                          }}>
                                                              <Statistic
                                                                  title={<div
                                                                      style={{fontSize: 11}}>已执行/总数</div>}
                                                                  value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.CaseExecuted + "/" + this.state.BuildsStatus.CaseTotal : "0/0"}
                                                                  valueStyle={{
                                                                      color: '#3f8600',
                                                                      fontSize: 11,
                                                                  }}
                                                              />
                                                          </div>
                                                      </Card>
                                                  </Col>
                                                  <Col span={10}>
                                                      <Card bordered={false}>
                                                          <div style={{
                                                              whiteSpace: 'nowrap',
                                                              overflow: 'hidden',
                                                              textOverflow: 'ellipsis',
                                                              textAlign: "center",
                                                          }}>
                                                              <Statistic
                                                                  title={<div
                                                                      style={{fontSize: 11}}>即将执行的用例</div>}
                                                                  value={Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.NextExecutionCase : "无"}
                                                                  valueStyle={{
                                                                      color: '#3f8600',
                                                                      fontSize: 11,
                                                                  }}
                                                              />
                                                          </div>
                                                      </Card>
                                                  </Col>
                                              </Row>
                                          </Card>
                                          <div style={{
                                              position: "absolute",
                                              marginTop: "-18px",
                                              marginLeft: "20px",
                                              background: "#ffffff",
                                              borderRadius: "15px 15px 15px 15px",
                                              border: '0.5px solid rgb(240, 242, 245)',
                                              textAlign: 'center',
                                              padding: "4px 10px  0px 10px",
                                              fontSize: 12,
                                              fontWeight: 800,
                                          }}>
                                              <Space size={5}
                                                     style={{fontSize: 12, fontWeight: 800, marginTop: -5}}>
                                                  <svg style={{marginTop: 5}} d="1703558095197" width="20"
                                                       height="20"
                                                       className="icon" viewBox="0 0 1024 1024"
                                                       version="1.1"
                                                       xmlns="http://www.w3.org/2000/svg" p-id="8414">
                                                      <path
                                                          d="M399.58718 72.8c-90.4 16-106.8 49.4-106.8 111.2v81.4h213.8v27.2h-294c-62.2 0-116.6 37.4-133.6 108.4-19.6 81.4-20.4 132.2 0 217.2 15.2 63.2 51.4 108.4 113.6 108.4H265.98718v-97.6c0-70.6 61-132.8 133.6-132.8h213.6c59.4 0 106.8-49 106.8-108.6V183.8c0-58-48.8-101.4-106.8-111.2-71.6-11.8-149.4-11.2-213.6 0.2z m-13.4 56.8c22 0 40.2 18.4 40.2 40.8s-18 40.6-40.2 40.6c-22.2 0-40.2-18.2-40.2-40.6 0.2-22.6 18-40.8 40.2-40.8z m370.4 162.8v95c0 73.6-62.4 135.6-133.6 135.6H409.38718c-58.4 0-106.8 50-106.8 108.6v203.6c0 58 50.4 92 106.8 108.6 67.6 19.8 132.6 23.4 213.6 0 53.8-15.6 106.8-47 106.8-108.6v-81.4H516.38718v-27.2h320.4c62.2 0 85.2-43.4 106.8-108.4 22.4-67 21.4-131.4 0-217.2-15.4-61.8-44.6-108.4-106.8-108.4h-80.2zM636.38718 808c22.2 0 40.2 18.2 40.2 40.6 0 22.6-18 40.8-40.2 40.8-22 0-40.2-18.4-40.2-40.8 0.2-22.6 18.2-40.6 40.2-40.6z"
                                                          fill="" p-id="8415"
                                                      />
                                                  </svg>
                                                  {
                                                      Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.JenkinsTaskStatus === "RUNNING" || this.state.BuildsStatus.JenkinsTaskStatus === "WAITING" ?

                                                              <>
                                                                  <div style={{fontSize: 12}}>执行进度</div>
                                                                  <Progress percent={
                                                                      Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.TaskProgress : 0
                                                                  } style={{width: 100, marginLeft: 5, marginTop: 5}}
                                                                            size={"small"}/>
                                                              </>
                                                              :
                                                              <>
                                                                  <div style={{color: "#000000", fontSize: 12}}>测试结果</div>
                                                                  <div style={{color: "goldenrod", fontSize: 12}}>
                                                                      {Object.keys(this.state.BuildsStatus).length > 0 ? this.state.BuildsStatus.JenkinsTaskStatus : " "}
                                                                  </div>
                                                              </>
                                                          :
                                                          <>
                                                              <div style={{color: "#000000", fontSize: 12}}>测试结果</div>
                                                              <div style={{color: "goldenrod", fontSize: 12}}>未知状态</div>
                                                          </>
                                                  }
                                              </Space>
                                          </div>
                                      </div>
                                  }
                            />
                        </Card>
                    </div>
                </Card>
            </div>
        );
    }
}
