import React, {Component} from 'react';
import {
    Button, Row, Col, Form, Card, Input, Select, message, Space, Checkbox, Tooltip, Avatar, InputNumber
} from "antd";
import Modal from "antd/es/modal/Modal";
import {
    AppstoreAddOutlined, EditOutlined, InfoCircleOutlined, QuestionCircleOutlined, RedoOutlined, RocketOutlined
} from "@ant-design/icons";
import {getUser, Loginout} from "../../Users/AuthRouter";
import api from "../../../api/ApiConfig";

export class DevCreateTask extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            record: props.record,
            fun: props.fun,
            IosVersion: props.record.AppPlatformType ? JSON.parse(props.record.AppPlatformType).includes(1) : false,
            AndroidVersion: props.record.AppPlatformType ? JSON.parse(props.record.AppPlatformType).includes(2) : false,
            HarmonyOSVersion: props.record.AppPlatformType ? JSON.parse(props.record.AppPlatformType).includes(3) : false,
            submitBtnLoading: false
        }
        this.form = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
        }
    }

    componentDidMount() {

    }

    userinfo = getUser()
    post_app_project_tasks_dev = async (values) => {
        await api.PostAppProjectTasksDev({values}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    isModalOpen: false
                }, function () {
                    message.success("配置完成,开始执行提测任务~")
                    this.state.fun()
                    this.form.current.resetFields();
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const showModal = () => {
            this.setState({
                isModalOpen: true
            })
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = (values) => {
            this.setState({
                submitBtnLoading: true
            })
            console.log("提交任务", values)
            values['key'] = this.state.record.key
            values['DevUserId'] = this.userinfo.UserId
            values['DevUserName'] = this.userinfo.username
            values['UserId'] = this.userinfo.UserId
            values['UserName'] = this.userinfo.username
            values['AppPlatformType'] = JSON.stringify(values['AppPlatformType'])
            values['ProjectTaskStatus'] = 2
            if (!Object.keys(this.state.record).length > 0) {
                //是开发自测
                values['PmUserId'] = this.userinfo.UserId
                values['PmUserName'] = this.userinfo.username
                values['DevSelfTest'] = 1
            }
            try {
                const PackageConfigurationObj = JSON.parse(values['PackageConfiguration'])
                if (typeof PackageConfigurationObj === "object") {
                    this.post_app_project_tasks_dev(values).then(() => {
                        this.setState({submitBtnLoading: false})
                    })
                } else {
                    message.error("打包配置参数非Json格式")
                }
            } catch (e) {
                message.error("打包配置参数非Json格式")
            }
        }
        return <>
            <Button type={'link'} style={{width: 80, fontWeight: 700, fontSize: 12}} size={"small"} disabled={false}
                    onClick={showModal} icon={<EditOutlined/>}>
                {Object.keys(this.state.record).length > 0 ? "任务配置" : "开发自测"}
            </Button>
            <Modal title="配置提测任务"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{minWidth: 700}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        maxHeight: "70vh",
                        overflowY: "auto",
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 470, marginTop: 30, marginLeft: 50, fontWeight: 600,
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="AppTitle"
                                        label="提测标题"
                                        initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.AppTitle : "开发自测"}
                                        rules={[{
                                            required: true, message: "请输入提测标题",
                                        }]}
                                    >
                                        <Input disabled={Object.keys(this.state.record).length > 0} showCount
                                               autoSize={true} allowClear={true}
                                               maxLength={100}
                                               placeholder={"请输入提测标题"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="AppType"
                                        label="App类型"
                                        initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.AppType : null}
                                        rules={[{
                                            required: true, message: "请选择App类型",
                                        }]}
                                    >
                                        <Select
                                            disabled={Object.keys(this.state.record).length > 0}
                                            style={{textAlign: "left"}}
                                            showSearch
                                            placeholder="请选择项目类型"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[{
                                                value: 1, label: 'MosHome', disabled: false
                                            }, {
                                                value: 2, label: '公牛E家',
                                            }]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="AppEnv"
                                        label="提测环境"
                                        initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.AppEnv : "TEST"}
                                        rules={[{
                                            required: true, message: "请选择提测环境",
                                        }]}
                                    >
                                        <Select
                                            disabled={Object.keys(this.state.record).length > 0}
                                            style={{textAlign: "left"}}
                                            showSearch
                                            placeholder="请选择提测环境"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[
                                                {
                                                    value: "TEST", label: '测试环境',
                                                },
                                                {
                                                    value: "PRE", label: '预发环境', disabled: true
                                                },
                                                {
                                                    value: "RELEASE", label: '生产环境', disabled: true
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="AppPlatformType"
                                        label="App平台"
                                        initialValue={Object.keys(this.state.record).length > 0 ? JSON.parse(this.state.record.AppPlatformType) : null}
                                        rules={[{
                                            required: true, message: "请选择App平台",
                                        }]}
                                    >
                                        <Checkbox.Group
                                            disabled={Object.keys(this.state.record).length > 0}
                                            options={[
                                                {label: 'IOS', value: 1, disabled: false},
                                                {label: 'Android', value: 2, disabled: false},
                                                {label: 'HarmonyOS', value: 3, disabled: true}
                                            ]}
                                            onChange={(checkedValue) => {
                                                console.log("e", checkedValue)
                                                if (checkedValue.includes(1)) {
                                                    this.setState({
                                                        IosVersion: true
                                                    })
                                                } else {
                                                    this.setState({
                                                        IosVersion: false
                                                    })
                                                }
                                                if (checkedValue.includes(2)) {
                                                    this.setState({
                                                        AndroidVersion: true
                                                    })
                                                } else {
                                                    this.setState({
                                                        AndroidVersion: false
                                                    })
                                                }
                                                if (checkedValue.includes(3)) {
                                                    this.setState({
                                                        HarmonyOSVersion: true
                                                    })
                                                } else {
                                                    this.setState({
                                                        HarmonyOSVersion: false
                                                    })
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                {this.state.IosVersion ? <Col span={24}>
                                    <Form.Item
                                        name="IosVersion"
                                        label="苹果版本"
                                        initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.IosVersion : null}
                                        rules={[{
                                            required: true, message: "请输入苹果版本号",
                                        }]}
                                    >
                                        <Input showCount autoSize={true} allowClear={true} maxLength={100}
                                               placeholder="请输入苹果版本号"/>
                                    </Form.Item>
                                </Col> : null
                                }
                                {this.state.AndroidVersion ? <Col span={24}>
                                    <Form.Item
                                        name="AndroidVersion"
                                        label="安卓版本"
                                        initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.AndroidVersion : null}
                                        rules={[{
                                            required: true, message: "请输入安卓版本号",
                                        }]}
                                    >
                                        <Input showCount autoSize={true} allowClear={true} maxLength={100}
                                               placeholder="请输入安卓版本号"/>
                                    </Form.Item>
                                </Col> : null
                                }
                                {this.state.HarmonyOSVersion ? <Col span={24}>
                                    <Form.Item
                                        name="HarmonyOSVersion"
                                        label="鸿蒙版本"
                                        initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.HarmonyOSVersion : null}
                                        rules={[{
                                            required: true,
                                            message: "请输入鸿蒙版本号",
                                        }]}
                                    >
                                        <Input showCount autoSize={true} allowClear={true} maxLength={100}
                                               placeholder="请输入鸿蒙版本号"/>
                                    </Form.Item>
                                </Col> : null
                                }
                                <Col span={24}>
                                    <Form.Item
                                        name="GitBranch"
                                        label="代码分支"
                                        // initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.GitBranch : null}
                                        initialValue={null}
                                        rules={[{
                                            required: true, message: "请输入代码分支",
                                        }]}
                                    >
                                        <Input showCount
                                               autoSize={true} allowClear={true}
                                               maxLength={100}
                                               placeholder={"请输入代码分支"}/>
                                    </Form.Item>
                                </Col>
                                {Object.keys(this.state.record).length > 0 ?
                                    <>
                                        <Col span={24}>
                                            <Form.Item
                                                name="AppNotes"
                                                label="提测备注"
                                                initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.AppNotes : "开发自测"}
                                                rules={[{
                                                    required: true, message: "请输入提测备注",
                                                }]}
                                            >
                                                <Input.TextArea disabled={Object.keys(this.state.record).length > 0}
                                                                showCount autoSize={true} allowClear={true}
                                                                maxLength={300}
                                                                placeholder={"请输入提测备注"}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="TestNumber"
                                                label="提测轮数"
                                                // initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.TestNumber : 1}
                                                initialValue={Object.keys(this.state.record).length > 0 ? null : null}
                                                rules={[{
                                                    required: true, message: "请输入提测轮数",
                                                }]}
                                            >
                                                <InputNumber min={1}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="Subproject"
                                                label="所属项目"
                                                // initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.Subproject : "开发自测"}
                                                initialValue={Object.keys(this.state.record).length > 0 ? null : "开发自测"}
                                                rules={[{
                                                    required: true, message: "请输入所属项目",
                                                }]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="TestContents"
                                                label="提测内容"
                                                // initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.TestContents : "开发自测"}
                                                initialValue={Object.keys(this.state.record).length > 0 ? null : "开发自测"}
                                                rules={[{
                                                    required: true, message: "请输入提测内容",
                                                }]}
                                            >
                                                <Input.TextArea showCount autoSize={true} allowClear={true}
                                                                maxLength={700}
                                                                placeholder={"请输入提测内容"}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="TestSuggestions"
                                                label="测试建议"
                                                // initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.TestSuggestions : "开发自测"}
                                                initialValue={Object.keys(this.state.record).length > 0 ? null : "开发自测"}
                                                rules={[{
                                                    required: true, message: "请输入测试建议",
                                                }]}
                                            >
                                                <Input.TextArea showCount autoSize={true} allowClear={true}
                                                                maxLength={700}
                                                                placeholder={"请输入测试建议"}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="AffectedScope"
                                                label="影响范围"
                                                initialValue={Object.keys(this.state.record).length > 0 ? null : "开发自测"}
                                                // initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.AffectedScope : "开发自测"}
                                                rules={[{
                                                    required: true, message: "请输入影响范围",
                                                }]}
                                            >
                                                <Input.TextArea showCount autoSize={true} allowClear={true}
                                                                maxLength={700}
                                                                placeholder={"请输入测试建议"}/>
                                            </Form.Item>
                                        </Col>
                                    </> : null
                                }
                                <Col span={24}>
                                    <Form.Item
                                        name="PackageConfiguration"
                                        label="打包配置"
                                        initialValue={Object.keys(this.state.record).length > 0 ? this.state.record.PackageConfiguration : "{}"}
                                        rules={[{
                                            required: true, message: "请输入打包配置数据为Json格式",
                                        }]}
                                    >
                                        <Input.TextArea onChange={(e) => {
                                            this.form.current.setFieldsValue({PackageConfiguration: e.target.value})
                                        }} autoSize={{minRows: 2, maxRows: 20}} allowClear={true} placeholder={"请输入打包配置"}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item style={{marginTop: 20, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit" style={{fontSize: 13, fontWeight: 800}}
                                        loading={this.state.submitBtnLoading}>
                                    发起提测
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}