import React, {Component} from 'react';
import {Button, Row, Col, Form, Card, Input, Select, message, Space, Mentions, Tooltip} from "antd";
import Modal from "antd/es/modal/Modal";
import {
    AppstoreAddOutlined,
    InfoCircleOutlined,
    QuestionCircleOutlined,
    RedoOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {getUser, Loginout} from "../Users/AuthRouter";
import Avatar from "antd/es/avatar/avatar";
import Text from "antd/es/typography/Text";
import api from "../../api/ApiConfig";

const {TextArea} = Input;
const {Option} = Mentions;

export class SpecialConfiguration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            specialconfiguration:{}
        }
        this.form = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({})
        }
    }

    componentDidMount() {
        this.getspecialconfiguration()
    }


    userinfo = getUser()

    getspecialconfiguration = async () => {
        await api.GetSpecialConfiguration({}).then(r => {
            if (r.msgcode === 200) {
                this.setState({
                    specialconfiguration:r['datas']
                }, function () {
                    // message.success("获取特殊配置成功")
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    postspecialconfiguration = async (values) => {
        values['UserId'] = this.userinfo.UserId
        console.log("添加任务配置", values)
        await api.PostSpecialConfiguration({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("添加配置成功")
                this.setState({
                    isModalOpen: false
                }, function () {
                    this.getspecialconfiguration()
                })

            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }

    render() {
        const showModal = () => {
            this.setState({
                isModalOpen: true
            })
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const handleCancel = () => {
            this.setState({
                isModalOpen: false
            })
        };
        const onFinish = (values) => {
            this.postspecialconfiguration(values)
        }
        return <>
            <Button type={'link'} size={"small"} style={{width: 90, color: '#707272'}}
                    icon={<SettingOutlined/>} onClick={showModal}>
                特殊配置
            </Button>
            <Modal title="自动部署回调配置"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{minWidth: 700, marginTop: 50}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 450,
                                marginTop: 30,
                                marginLeft: 50,
                                fontWeight: 600
                            }}
                        >
                            <Row>
                                <Col span={22}>
                                    <Form.Item
                                        name="DeploymentConfigValue"
                                        label="部署回调"
                                        initialValue={Object.keys(this.state.specialconfiguration).length>0?this.state.specialconfiguration.DeploymentConfigValue:null}
                                        rules={[
                                            {
                                                required: true,
                                                validator: (rule, value, callback) => {
                                                    const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+)(?::(\d+))?(?:\/|$)/;
                                                    if (!domainRegex.test(value)) {
                                                        callback("请输入合法的回调地址");
                                                    } else {
                                                        callback();
                                                    }
                                                }
                                            },
                                        ]}
                                    >
                                        <Input showCount placeholder={"请输入回调地址"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Tooltip title={<Space direction={"vertical"} placeholder={"bottom"}>
                                        <div>部署审批通过后平台会调用该地址下发部署指令,请求方式[Post]</div>
                                        <div> 请求参数 = {'{'} </div>
                                        <div> 'TaskID': 'TaskID(任务ID)',</div>
                                        <div> 'ServiceName': 'paascloud-provider-skill(服务名称)',</div>
                                        <div> 'ServiceEnv': 'TEST(环境)',</div>
                                        <div> 'ServiceType': '1(提测类型)'</div>
                                        <div>{'}'} </div>
                                    </Space>}
                                    >
                                        <Button type={"link"} style={{marginLeft: 0}}><QuestionCircleOutlined/></Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Form.Item style={{marginTop: 30, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit" style={{fontSize: 13, fontWeight: 800}}>
                                    确认添加
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}